import { Icon } from '@acciona/ui-ionic-kit';
import { useTranslation } from 'react-i18next';
import { PortalPermissionsData } from '../../_api/services/roles/types';
import styles from './styles.module.scss';

type Props = {
	inputData: PortalPermissionsData;
	onClick: (id: any, employees: any) => void;
};

export const InputSelectUsers = (props: Props) => {
	const { inputData, onClick } = props;
	const { t } = useTranslation();

	return (
		<div className={styles.element}>
			<div className={styles.h3}>{inputData.name}</div>
			<div className={`${styles.footnote} ${styles.space}`}>{inputData.description}</div>
			<div className={styles.action}>
				{inputData.roles.map((ele, idx) => {
					return (
						<div key={idx} className={styles.input} onClick={() => onClick(ele.roleId, ele.employees)}>
							<div className={styles.body}>
								{ele.readOnly === true ? t('lbl_users_only_read') : t('lbl_users')}
							</div>
							<div className={`${styles.align}`}>
								<div className={`${styles.body} ${ele.employees.length < 1 && styles.color_dark}`}>
									{ele.employees.length > 0
										? t('lbl_total_select', {
												total: ele.employees.length,
										  })
										: t('lbl_select')}
								</div>
								<Icon className="icon icon-chevron-right icon24" slot="end"></Icon>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};
