import {
	LotteryScores,
	ParkingReservationsAndRequests,
	PkReservationsConfig,
	ReservationPolicy,
	SendPlatesInfo,
} from './types';

export const pkDefaultReservationConfig: PkReservationsConfig = {
	policies: [
		{
			id: 1,
			name: 'Dia completo',
			fullHoraMin: '00:00',
			fullHoraMax: '23:59',
			isActive: true,
		},
		{
			id: 2,
			name: 'Morning',
			fullHoraMin: '00:00',
			fullHoraMax: '15:00',
			isActive: true,
		},
		{
			id: 3,
			name: 'Tarde',
			fullHoraMin: '15:00',
			fullHoraMax: '23:59',
			isActive: true,
		},
		{
			id: 4,
			name: 'Personalizada',
			fullHoraMin: '09:00',
			fullHoraMax: '22:00',
			isActive: true,
		},
	],
	buffer: '60',
	parkingPlannedWeeksExtend: 2,
	parkingPlannedTurningPoint: { day: 5, hour: '01:00' },
	companionPermissionRestriction: false,
};

export const pkDefaultSedePolicy: ReservationPolicy[] = [
	{
		id: 1,
		name: 'Dia completo',
		isActive: true,
		isDefault: false,
		fullHoraMax: '23:59',
		fullHoraMin: '00:00',
	},
	{
		id: 2,
		name: 'Morning',
		isActive: true,
		isDefault: false,
		fullHoraMax: '15:00',
		fullHoraMin: '00:00',
	},
	{
		id: 3,
		name: 'Tarde',
		isActive: true,
		isDefault: false,
		fullHoraMax: '23:59',
		fullHoraMin: '15:00',
	},
	{
		id: 4,
		name: 'Personalizada',
		isActive: true,
		isDefault: true,
		fullHoraMax: '22:00',
		fullHoraMin: '09:00',
	},
];

export const mockLotteryScores: LotteryScores = {
	homeOffice: [
		{
			id: 0,
			name: 'Con home office',
			points: 20.0,
		},
		{
			id: 1,
			name: 'Sin home office',
			points: 23.0,
		},
	],
	level: [
		{
			id: 2,
			name: 'Nivel 1',
			points: 21.0,
		},
		{
			id: 3,
			name: 'Nivel 2',
			points: 22.0,
		},
		{
			id: 4,
			name: 'Nivel 3',
			points: 23.0,
		},
		{
			id: 5,
			name: 'Nivel 4',
			points: 24.0,
		},
		{
			id: 6,
			name: 'Nivel 5',
			points: 25.0,
		},
		{
			id: 7,
			name: 'Nivel 6',
			points: 26.0,
		},
		{
			id: 8,
			name: 'Nivel 7',
			points: 27.0,
		},
		{
			id: 9,
			name: 'Nivel 8',
			points: 28.0,
		},
	],
	seniority: [
		{
			id: 10,
			name: '0-5',
			points: 21.0,
		},
		{
			id: 11,
			name: '5-10',
			points: 22.0,
		},
		{
			id: 12,
			name: '10-20',
			points: 23.0,
		},
		{
			id: 13,
			name: '20-30',
			points: 24.0,
		},
		{
			id: 14,
			name: '30+',
			points: 25.0,
		},
	],
	environmentalBadge: [
		{
			id: 15,
			name: 'B - Amarillo',
			points: 21.0,
		},
		{
			id: 16,
			name: 'C - Verde',
			points: 22.0,
		},
		{
			id: 17,
			name: 'ECO',
			points: 23.0,
		},
		{
			id: 18,
			name: '0 emisiones - Azul',
			points: 24.0,
		},
		{
			id: 19,
			name: 'Sin etiqueta',
			points: 20.0,
		},
	],
	transportZone: [
		{
			id: 20,
			name: 'A',
			points: 3.0,
		},
		{
			id: 21,
			name: 'B1',
			points: 3.0,
		},
		{
			id: 22,
			name: 'B2',
			points: 3.0,
		},
		{
			id: 23,
			name: 'B3',
			points: 1.0,
		},
		{
			id: 24,
			name: 'C1',
			points: 2.0,
		},
		{
			id: 25,
			name: 'C2',
			points: 2.0,
		},
		{
			id: 26,
			name: 'E1',
			points: 2.0,
		},
		{
			id: 27,
			name: 'E2',
			points: 2.0,
		},
	],
	companions: [
		{
			id: 28,
			name: 'Fix points',
			points: 2.0,
		},
		{
			id: 29,
			name: 'Factor multiply',
			points: 2.25,
		},
		{
			id: 30,
			name: 'Dias reservas',
			points: 10.0,
		},
	],
};

export const sendPlatesInfoMock: SendPlatesInfo = {
	lastExecution: '2024-09-07T02:34:00Z',
	platesOk: 130,
	platesKo: 24,
};

export const mockUserVehicles = [
	{
		vehicleId: 890,
		brand: 'Volkswagen',
		model: 'Touareg',
		color: 'Negro',
		environmentalBadge: 4,
		plate: '4567ABC',
		transportZone: 1,
		type: 1,
		plugin: false,
	},
	{
		vehicleId: 4002,
		brand: 'moto',
		model: 'silence',
		color: 'rojo',
		environmentalBadge: 1,
		plate: '3456ABC',
		transportZone: 6,
		type: 3,
		plugin: false,
	},
];

export const mockReservationVehicles = mockUserVehicles.map((vehicle, index) => ({
	vehicleId: vehicle.vehicleId,
	brand: vehicle.brand,
	model: vehicle.model,
	plate: vehicle.plate,
	selected: index === 1,
}));

export const mockParkingReservations: ParkingReservationsAndRequests = {
	reservations: [
		{
			reservationDate: '2024-08-08',
			reservationId: 282696,
			reservationType: 'Dia completo',
			startHour: '00:00',
			endHour: '23:59:59',
			vehicles: mockReservationVehicles,
		},
		{
			reservationDate: '2024-08-09',
			reservationId: 282697,
			reservationType: 'Dia completo',
			startHour: '00:00',
			endHour: '23:59:59',
			vehicles: mockReservationVehicles,
		},
	],
	requests: [
		{
			reservationDate: '2024-08-12',
			reservationId: 282698,
			reservationType: 'Dia completo',
			startHour: '00:00',
			endHour: '23:59:59',
			vehicles: mockReservationVehicles,
		},
	],
};
