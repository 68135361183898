import { Icon, Snackbar } from '@acciona/ui-ionic-kit';
import { IonButton, IonLabel, IonItem, IonPopover, IonContent, IonList } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import _ from 'lodash';
type Props = {
	selectedLang: string;
	setSelectedLang: (selectedLang: string) => void;
	snackbarText?: string;
};

export const LanguageSelector: React.FC<Props> = (props) => {
	const { t } = useTranslation();
	const idPopover = _.uniqueId('select-lang-popover-');
	return (
		<div className={styles.langSelector}>
			<div>
				<Snackbar
					mode="admin"
					type="info"
					text={props?.snackbarText ?? t('language_selector_snackbar_text')}
					icon="icon icon-info"
					align="left"
				/>
			</div>
			<div>
				<IonItem lines="none" slot="start" className={styles.smallInputModal}>
					<IonLabel className="lblSelector ion-text-end">{t(`language_${props.selectedLang}`)}</IonLabel>
					<IonButton className={`${styles.languageButton}`} fill="clear" id={idPopover}>
						<Icon className={`icon icon-chevron-down ${styles.dropDownIcon}`} />
					</IonButton>

					<IonPopover trigger={idPopover} dismissOnSelect={true} side="bottom" alignment="end">
						<IonContent>
							<IonList className={styles.listPadding}>
								<IonItem lines="full" button={true} onClick={() => props.setSelectedLang('es')} detail={false}>
									<IonLabel>{t('language_es')}</IonLabel>
								</IonItem>
								<IonItem lines="full" button={true} onClick={() => props.setSelectedLang('en')} detail={false}>
									<IonLabel>{t('language_en')}</IonLabel>
								</IonItem>
								<IonItem lines="full" button={true} onClick={() => props.setSelectedLang('pt')} detail={false}>
									<IonLabel>{t('language_pt')}</IonLabel>
								</IonItem>
							</IonList>
						</IonContent>
					</IonPopover>
				</IonItem>
			</div>
		</div>
	);
};
