import { createContext } from 'react';
import { Sede, Language, ConfigurationPreference } from '../../_api/services/user/types';
import { SplitMenuItem } from '../../components/SplitPane/menuConfiguration';

type Props = {
	isAuth: boolean;
	setAuth: (value: boolean) => void;
	thereAreUnsavedChanges: boolean;
	setThereAreUnsavedChanges: (value: boolean) => void;
	userLanguage: Language;
	setUserLanguage: (value: Language) => void;
	sedeEmployee?: string;
	setSedeEmployee?: (value: string) => void;
	sedePortal: Sede;
	setSedePortal: (value: Sede) => void;
	sedePortalOptions: Sede[];
	setSedePortalOptions: (value: Sede[]) => void;
	updateSedePortal: (sede: Sede, preference: ConfigurationPreference) => void;
	updateUserLanguage: (value: Language) => void;
	configurationPreference: ConfigurationPreference;
	updateConfigurationPreference: (value: ConfigurationPreference) => void;
	transversal: boolean;
	setTransversal: (value: boolean) => void;
	menuOptions: SplitMenuItem[];
	updateSedePortalOptions: (
		options: Sede[],
		defaultSede: Sede,
		transversal: boolean,
		configurationPreference: ConfigurationPreference,
	) => void;
};

const initialValue = {
	isAuth: false,
	setAuth: () => {},
	thereAreUnsavedChanges: false,
	setThereAreUnsavedChanges: () => {},
	userLanguage: null,
	setUserLanguage: () => {},
	sedePortal: null,
	setSedePortal: () => {},
	sedePortalOptions: [],
	setSedePortalOptions: () => {},
	updateSedePortal: () => {},
	updateUserLanguage: () => {},
	configurationPreference: '',
	updateConfigurationPreference: () => {},
	transversal: false,
	setTransversal: () => {},
	menuOptions: [],
	updateSedePortalOptions: () => {},
};

const AppContext = createContext<Props>(initialValue);

export default AppContext;
