import { useEffect, useState } from 'react';
import { Button, Icon, Modal } from '@acciona/ui-ionic-kit';
import { useTranslation } from 'react-i18next';
import {
	IonContent,
	IonFooter,
	IonItem,
	IonLabel,
	IonSelect,
	IonSelectOption,
	IonTitle,
	IonToolbar,
} from '@ionic/react';
import { Props } from './types';
import { District } from '../../_api/services/workstation/types';
import styles from './styles.module.scss';

export const ModalAddZone: React.FC<Props> = (props) => {
	const { t } = useTranslation();
	const { districts } = props;
	const [optionSelected, setOptionSelected] = useState<District>(null);
	const [zonesCanDesglose, setZonesCanDesglose] = useState<string[]>(
		props.zoneToEdit ? props.zoneToEdit.overflowDistricts : [],
	);
	useEffect(() => {
		optionSelected && setZonesCanDesglose(optionSelected.overflowDistricts);
		props.zoneToEdit && setZonesCanDesglose(props.zoneToEdit.overflowDistricts);
	}, [optionSelected, props.zoneToEdit]);

	const handleSaveZone = () => {
		const updatedZone = props.zoneToEdit
			? {
					...props.zoneToEdit,
					overflowDistricts: zonesCanDesglose,
			  }
			: { ...optionSelected, overflowDistricts: zonesCanDesglose };
		props.saveChanges(updatedZone);
		props.onClose();
	};
	return (
		<Modal
			isOpen={props.showModal}
			onDidDismiss={() => {
				setOptionSelected(null);
				setZonesCanDesglose([]);
				props.onClose();
			}}
			className={styles.modal}
		>
			<IonToolbar>
				<Button slot="end" fill={'clear'} color="light" className="btnCloseModal" onClick={() => props.onClose()}>
					<IonLabel className="dark">
						<Icon className="icon icon-close"></Icon>
					</IonLabel>
				</Button>
				<IonTitle>
					<h3 className="ion-text-center">
						<b>{props.zoneToEdit ? t('btn_edit_zone') : t('btn_add_zone')}</b>
					</h3>
				</IonTitle>
			</IonToolbar>
			<IonContent>
				<div className={`${styles.footnote} ${styles.space}`}>{t('modal_add_zone_subtitle')}</div>
				<div className={styles.addZoneModal}>
					<IonItem
						lines="none"
						className={`${styles.inputModal} ${optionSelected?.districtName !== '' && styles.touchedInput}`}
					>
						<IonLabel slot="start" className="lblSelector">
							{t('zone_to_desglose')}
						</IonLabel>
						<IonSelect
							placeholder={t('lbl_select')}
							name="zoneToDesglose"
							mode="ios"
							slot="end"
							className={`lblSelector ${styles.selector}`}
							interface="popover"
							interfaceOptions={{
								cssClass: styles.selectInterfacePopoverZone,
								side: 'bottom',
							}}
							disabled={!props.hasWritePermission || props.zoneToEdit !== null}
							onIonChange={(e) => {
								setOptionSelected(districts.find((d) => d.districtName === e.target.value));
								props.zoneToEdit
									? setZonesCanDesglose(props.zoneToEdit.overflowDistricts)
									: optionSelected
									? setZonesCanDesglose(optionSelected.overflowDistricts)
									: setZonesCanDesglose([]);
							}}
							value={
								props.zoneToEdit
									? props.zoneToEdit.districtName
									: optionSelected
									? optionSelected.districtName
									: ''
							}
							selectedText={
								props.zoneToEdit
									? props.zoneToEdit.districtName
									: optionSelected
									? optionSelected.districtName
									: ''
							}
							aria-label={t('zone_to_desglose')}
						>
							{districts
								.filter((d) => d.overflowDistricts.length === 0)
								.map((district) => {
									return (
										<IonSelectOption key={district.districtName} value={district.districtName}>
											{district.districtName}({district.buildingName}, {district.floorName})
										</IonSelectOption>
									);
								})}
						</IonSelect>
					</IonItem>
					<IonItem
						lines="none"
						className={`${styles.inputModal} ${zonesCanDesglose.length > 0 && styles.touchedInput}`}
					>
						<IonLabel slot="start" className="lblSelector">
							{t('lbl_zone_type')}
						</IonLabel>
						<IonSelect
							name="overflowDistricts"
							mode="ios"
							slot="end"
							className={`lblSelector ${styles.selector}`}
							interfaceOptions={{
								cssClass: styles.selectInterfacePopoverOverflow,
							}}
							interface="popover"
							disabled={!props.hasWritePermission || (!optionSelected && !props.zoneToEdit)}
							multiple
							value={zonesCanDesglose}
							onIonChange={(e) => {
								setZonesCanDesglose(e.target.value);
							}}
							aria-label={t('lbl_zone_type')}
						>
							{(optionSelected || props.zoneToEdit !== null) &&
								zonesCanDesglose &&
								districts
									.filter((d) =>
										props.zoneToEdit
											? d.districtName !== props.zoneToEdit.districtName
											: d.districtName !== optionSelected.districtName,
									)
									.map((d) => {
										return (
											<IonSelectOption
												className={styles.selectoption}
												key={d.districtName}
												value={d.districtName}
											>
												{d.districtName}
											</IonSelectOption>
										);
									})}
						</IonSelect>
					</IonItem>
				</div>
			</IonContent>
			<IonFooter className={styles.btnModal}>
				<div>
					<Button
						color="light"
						className={styles.btnHeader}
						onClick={() => {
							props.onClose();
						}}
					>
						{t('btn_cancel')}
					</Button>
				</div>
				<div>
					<Button
						color="primary"
						disabled={
							!props.hasWritePermission ||
							(!optionSelected && !props.zoneToEdit) ||
							zonesCanDesglose.length === 0
						}
						className={styles.btnHeader}
						onClick={handleSaveZone}
					>
						{t('btn_accept')}
					</Button>
				</div>
			</IonFooter>
		</Modal>
	);
};
