import i18n from 'i18next';
import muleSoftInstance from '../../utils/httpCommon';
import { Policy, PolicyCategory } from './types';
import { getFormDataArray } from './helpers';

const getGeneralTerms = async (): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/termsPolicies`;
	try {
		const resp = await muleSoftInstance.get(path);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const saveGeneralTerms = async (form): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/termsPolicies`;
	try {
		const resp = await muleSoftInstance.post(path, form, { headers: { 'Content-Type': 'multipart/form-data' } });

		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const getReservationsTerms = async (): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/link/reservations`;
	try {
		const resp = await muleSoftInstance.get(path);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const saveReservationsTerms = async (form): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/link/reservations`;
	try {
		const resp = await muleSoftInstance.post(path, form, { headers: { 'Content-Type': 'multipart/form-data' } });

		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const getOtherPoliciesCategories = async (): Promise<PolicyCategory[]> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/otherPolicies/categories`;
	try {
		const resp = await muleSoftInstance.get(path);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const getOtherPolicies = async (): Promise<Policy[]> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/otherPolicies`;
	try {
		const resp = await muleSoftInstance.get(path);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const saveOtherPoliciesCategories = async (categories: PolicyCategory[]): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/otherPolicies/categories`;
	try {
		// Filter to avoid eliminating categories that only exist locally
		const filteredCategories = categories.filter((category) => !(!category.id && category.disabled));
		const resp = await muleSoftInstance.put(path, filteredCategories);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const saveOtherPolicies = async (policies: Policy[]): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/otherPolicies`;
	const formDataArray = getFormDataArray(policies);

	try {
		const requests = formDataArray.map(async (formDataItem) => {
			const response = await muleSoftInstance.put(path, formDataItem, {
				headers: { 'Content-Type': 'multipart/form-data' },
			});
			return response;
		});

		const responses = await Promise.all(requests);
		return responses;
	} catch (err) {
		throw new Error(i18n.t('error_save_other_policies'));
	}
};

export const termsServices = {
	getGeneralTerms,
	saveGeneralTerms,
	getReservationsTerms,
	saveReservationsTerms,
	getOtherPoliciesCategories,
	getOtherPolicies,
	saveOtherPoliciesCategories,
	saveOtherPolicies,
};
