import { useCallback, useEffect, useState } from 'react';
import { Button, List, Toast } from '@acciona/ui-ionic-kit';
import { IonLoading } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import _ from 'lodash';
import { roleService } from '../../_api/services/roles';
import { PortalPermissionsData } from '../../_api/services/roles/types';
import useAppContext from '../../hooks/useAppContext';
import LayoutNoTabs from '../../components/LayoutNoTabs/LayoutNoTabs';
import UsersModal from '../../components/UsersModal/UsersModal';
import { InputSelectUsers } from '../../components/InputSelectUsers/InputSelectUsers';
import { usePermissions } from '../../hooks/usePermissions';
import { FUNCTIONALITIES } from '../../utils/constants';
import styles from './styles.module.scss';

const getUpdatedUsers = (previousData: PortalPermissionsData[], currentRole: number, newUsers: any[]) => {
	return previousData.map((e) => ({
		...e,
		roles: e.roles.map((ele) => ({
			...ele,
			employees: ele.roleId === currentRole ? newUsers : ele.employees,
		})),
	}));
};

const RolesTransversal: React.FC = () => {
	const { t } = useTranslation();
	const { setThereAreUnsavedChanges } = useAppContext();
	const [showModaPermissions, setShowModaPermissions] = useState(false);
	const [idRol, setIdRol] = useState<number>();
	const [selectedEmployees, setSelectedEmployees] = useState([]);
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);
	const [isEdited, setIsEdited] = useState(false);
	const [dataRoles, setDataRoles] = useState<PortalPermissionsData[]>([]);
	const { permissions } = usePermissions(FUNCTIONALITIES.superadmin, 'WRITE');

	useEffect(() => {
		setThereAreUnsavedChanges(isEdited);
	}, [isEdited]);

	const { data: employeesPortal, isLoading: isLoadingEmployees } = useQuery(
		['employeesPortal'],
		async () => await roleService.getPortalEmployees(),
		{
			refetchOnWindowFocus: false,
		},
	);

	const { isLoading: isLoadingRoles } = useQuery(['rolesPortal'], async () => await roleService.getPortal(), {
		refetchOnWindowFocus: false,
		onSuccess: (data) => {
			setDataRoles(data.filter((item) => item.permission === 'superadmin'));
		},
	});

	const { mutate, isLoading: loadingSave } = useMutation(roleService.modifyPortal, {
		onSuccess: () => {
			setSuccess(t('msg_success'));
			setIsEdited(false);
		},
		onError: (error) => {
			setError(error as string);
		},
	});

	useEffect(() => {
		employeesPortal &&
			employeesPortal.map((e) => {
				e.isChecked = false;
			});
	}, [employeesPortal]);

	const handleOpenModal = (id, employees) => {
		setIdRol(id);
		const employeesIds = _.map(employees, 'employeeId');
		setSelectedEmployees(
			employeesPortal.map((e) => {
				return { ...e, isChecked: employeesIds.includes(e.employeeId) };
			}),
		);
		setShowModaPermissions(true);
	};

	const saveUsers = (newUsers) => {
		setDataRoles((prev) => getUpdatedUsers(prev, idRol, newUsers));
		setIsEdited(true);
		setSuccess(t('lbl_usersModal_accept'));
	};

	const handleSaveRolesEmployees = () => {
		mutate(dataRoles);
	};

	const getPermissionsModalLabel = useCallback(
		(idRol) => {
			let label = '';

			const rolesList = {};
			dataRoles &&
				dataRoles.forEach((role) => {
					role.roles.forEach((r) => {
						rolesList[r.roleId] = {
							name: role.permission,
							readOnly: r.readOnly,
						};
					});
				});

			const currentRole = rolesList[idRol];

			if (currentRole) {
				label = t(`roles_portal.${currentRole.name}.modal`);
				if (currentRole.readOnly) {
					label = `${label} (${t('read_only')})`;
				}
			}
			return label;
		},
		[dataRoles],
	);

	return (
		<LayoutNoTabs title={t('menu_roles')}>
			<>
				<List>
					{dataRoles &&
						dataRoles.map((e, i) => <InputSelectUsers key={i} inputData={e} onClick={handleOpenModal} />)}
				</List>
				<UsersModal
					showModal={showModaPermissions}
					onClose={() => setShowModaPermissions(false)}
					onSave={saveUsers}
					usersData={selectedEmployees}
					label={getPermissionsModalLabel(idRol)}
					isLoading={isLoadingEmployees}
					clearDataOnClose
					hasWritePermission={permissions.WRITE}
				/>
				<div className={`${styles.footerButton}`}>
					<Button
						className={styles.btnHeader}
						onClick={handleSaveRolesEmployees}
						disabled={!isEdited}
						color="primary"
					>
						{t('btn_save_data')}
					</Button>
				</div>
				<Toast isOpen={!!error} message={error} onDidDismiss={() => setError('')} position="bottom" type="error" />
				<Toast
					isOpen={!!success}
					message={success}
					onDidDismiss={() => setSuccess('')}
					position="bottom"
					type="success"
				/>
				<IonLoading isOpen={isLoadingRoles || loadingSave} message={t('msg_loading')} duration={0} />
			</>
		</LayoutNoTabs>
	);
};

export default RolesTransversal;
