import { useCallback, useEffect, useState } from 'react';
import { Button, Icon, List, Toast } from '@acciona/ui-ionic-kit';
import { IonLoading } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import _ from 'lodash';
import useAppContext from '../../../hooks/useAppContext';
import { roleService } from '../../../_api/services/roles';
import UsersModal from '../../../components/UsersModal/UsersModal';
import { InputSelectUsers } from '../../../components/InputSelectUsers/InputSelectUsers';
import { PortalPermissionsData } from '../../../_api/services/roles/types';
import styles from './styles.module.scss';

type Props = {
	footerStyle: string;
};
export const Portal: React.FC<Props> = (props) => {
	const { t } = useTranslation();
	const { setThereAreUnsavedChanges } = useAppContext();
	const [showModaPermissions, setShowModaPermissions] = useState(false);
	const [idRol, setIdRol] = useState<number>();
	const [selectedEmployees, setSelectedEmployees] = useState([]);
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);
	const [isEdited, setIsEdited] = useState(false);
	const [dataRoles, setDataRoles] = useState<PortalPermissionsData[]>([]);

	useEffect(() => {
		setThereAreUnsavedChanges(isEdited);
	}, [isEdited]);

	const { data: employeesPortal, isLoading: isLoadingEmployees } = useQuery(
		['employeesPortal'],
		async () => await roleService.getPortalEmployees(),
		{
			refetchOnWindowFocus: false,
		},
	);

	useQuery(['rolesPortal'], async () => await roleService.getPortal(), {
		refetchOnWindowFocus: false,
		onSuccess: (data) => {
			setDataRoles(data.filter((item) => item.permission !== 'superadmin'));
		},
	});

	const { mutate, isLoading: loadingSave } = useMutation(roleService.modifyPortal, {
		onSuccess: () => {
			setSuccess(t('msg_success'));
			setIsEdited(false);
		},
		onError: (error) => {
			setError(error as string);
		},
	});

	useEffect(() => {
		employeesPortal &&
			employeesPortal.map((e) => {
				e.isChecked = false;
			});
	}, [employeesPortal]);

	const handleOpenModal = (id, employees) => {
		setIdRol(id);
		const employeesIds = _.map(employees, 'employeeId');
		setSelectedEmployees(
			employeesPortal.map((e) => {
				return { ...e, isChecked: employeesIds.includes(e.employeeId) };
			}),
		);
		setShowModaPermissions(true);
	};

	const saveUsers = (newUsers) => {
		const tempPermissions = dataRoles;
		tempPermissions.map((e) => {
			e.roles.map((ele) => {
				ele.roleId === idRol && (ele.employees = [...newUsers]);
			});
		});
		setIsEdited(true);
	};

	const handleSaveRolesEmployees = () => {
		mutate(dataRoles);
	};

	const getPermissionsModalLabel = useCallback(
		(idRol) => {
			let label = '';

			const rolesList = {};
			dataRoles &&
				dataRoles.forEach((role) => {
					role.roles.forEach((r) => {
						rolesList[r.roleId] = {
							name: role.permission,
							readOnly: r.readOnly,
						};
					});
				});

			const currentRole = rolesList[idRol];

			if (currentRole) {
				label = t(`roles_portal.${currentRole.name}.modal`);
				if (currentRole.readOnly) {
					label = `${label} (${t('read_only')})`;
				}
			}
			return label;
		},
		[dataRoles],
	);

	return (
		<>
			<div className={styles.snackBar}>
				<div>
					<Icon className="icon icon-info icon24" />
				</div>
				<div className={styles.footnote}>{t('lbl_rol_tip')}</div>
			</div>
			<List>
				{dataRoles && dataRoles.map((e, i) => <InputSelectUsers key={i} inputData={e} onClick={handleOpenModal} />)}
			</List>
			<UsersModal
				showModal={showModaPermissions}
				onClose={() => setShowModaPermissions(false)}
				onSave={saveUsers}
				usersData={selectedEmployees}
				label={getPermissionsModalLabel(idRol)}
				isLoading={isLoadingEmployees}
				clearDataOnClose
			/>
			<div className={`${props.footerStyle} ${styles.footerButton}`}>
				<Button
					className={styles.btnHeader}
					onClick={handleSaveRolesEmployees}
					disabled={!isEdited}
					color="primary"
				>
					{t('btn_save_data')}
				</Button>
			</div>
			<Toast isOpen={!!error} message={error} onDidDismiss={() => setError('')} position="bottom" type="error" />
			<Toast
				isOpen={!!success}
				message={success}
				onDidDismiss={() => setSuccess('')}
				position="bottom"
				type="success"
			/>
			<IonLoading isOpen={loadingSave} message={t('msg_loading')} duration={0} />
		</>
	);
};
