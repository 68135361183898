import { CONFIGURATION_PREFERENCES } from '../../utils/constants';
import { tokenServices } from '../services/token/';
import muleSoftInstance from './httpCommon';

export const jwtInterceptor = () => {
	muleSoftInstance.interceptors.request.use((request) => {
		const token = tokenServices.getLocalIdToken();
		const isApiUrl = request.baseURL.startsWith(process.env.REACT_APP_API_URL);

		if (token && isApiUrl) {
			request.headers['Authorization'] = `Bearer ${token}`;
		}

		const userSedePortal = JSON.parse(localStorage.getItem('userSedePortal') ?? '{}');
		const sedeId = userSedePortal?.id;

		const configurationPreference = localStorage.getItem('configurationPreference');

		if (sedeId && configurationPreference !== CONFIGURATION_PREFERENCES.transversal.id) {
			request.headers['sedePortal'] = sedeId;
		}

		return request;
	});
};
