import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { IonLoading } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { ImagesDashboardIndexed, TextsDashboardIndexed } from '../../../_api/services/Communications/types';
import { FileInput } from '../../../components/FileInput/FileInput';
import { LanguageSelector } from '../../../components/LanguageSelector/LanguageSelector';
import { Button } from '@acciona/ui-ionic-kit';
import styles from './styles.module.scss';
import { TextInput } from '../../../components/TextInput/TextInput';

type Props = {
	dataImages: ImagesDashboardIndexed;
	dataTexts: TextsDashboardIndexed;
	selectedLanguage: string;
	setSelectedLanguage: Dispatch<SetStateAction<string>>;
	onChangeImage: ({
		event,
		language,
		platform,
	}: {
		event: ChangeEvent<HTMLInputElement>;
		language: string;
		platform: string;
	}) => void;
	onChangeText: ({ language, platform, text }: { language: string; platform: string; text: string }) => void;
	onSave: (text: TextsDashboardIndexed) => void;
	hasWritePermissions: boolean;
	saveIsDisabled: boolean;
	isLoading: boolean;
	footerStyle: string;
};

export const DashboardView: React.FC<Props> = (props) => {
	const {
		dataImages,
		selectedLanguage,
		setSelectedLanguage,
		onChangeImage,
		onChangeText,
		onSave,
		hasWritePermissions,
		dataTexts,
		saveIsDisabled,
		isLoading,
		footerStyle,
	} = props;
	const { t } = useTranslation();

	const images = (dataImages && dataImages[selectedLanguage]) ?? {};
	const texts = (dataTexts && dataTexts[selectedLanguage]) ?? {};

	return (
		<>
			<LanguageSelector selectedLang={selectedLanguage} setSelectedLang={setSelectedLanguage} />
			<div className={styles.h3}>{t('label_img')}</div>
			<div className={`${styles.footnote} ${styles.space}`}>{t('text_img')}</div>
			<FileInput
				title={t('img_smartphone')}
				subtitle={t('max_file_values')}
				fileName={images?.mobile ?? ''}
				disabled={!hasWritePermissions}
				onChange={(e) => onChangeImage({ event: e, language: selectedLanguage, platform: 'mobile' })}
				inputOptions={{
					accept: 'image/*',
				}}
			/>
			<FileInput
				title={t('img_desktop')}
				subtitle={t('max_file_values')}
				fileName={images?.desktop ?? ''}
				disabled={!hasWritePermissions}
				onChange={(e) => onChangeImage({ event: e, language: selectedLanguage, platform: 'desktop' })}
				inputOptions={{
					accept: 'image/*',
				}}
			/>
			<div className={` ${styles.space}`}>
				<div className={styles.h3}>{t('lbl_text')}</div>
				<div className={`${styles.footnote} ${styles.space}`}>{t('lbl_sel_text')}</div>
			</div>
			<TextInput
				name="dashboard-desktop"
				className={`ion-text-end`}
				value={texts?.desktop ?? ''}
				onIonChange={(e) => onChangeText({ language: selectedLanguage, platform: 'desktop', text: e.detail.value })}
				placeholder={t('write_text')}
				aria-label={t(`label_dashboard_text_${selectedLanguage}`)}
				label={t(`label_dashboard_text_${selectedLanguage}`)}
				disabled={!hasWritePermissions}
			/>
			<TextInput
				name="dashboard-smartphone"
				className={`ion-text-end`}
				value={texts?.mobile ?? ''}
				defaultValue=""
				onIonChange={(e) => onChangeText({ language: selectedLanguage, platform: 'mobile', text: e.detail.value })}
				placeholder={t('write_text')}
				aria-label={t(`label_phone_text_${selectedLanguage}`)}
				label={t(`label_phone_text_${selectedLanguage}`)}
				disabled={!hasWritePermissions}
			/>

			{hasWritePermissions && (
				<div className={`${footerStyle} ${styles.footerButton}`}>
					<Button
						disabled={saveIsDisabled}
						color="primary"
						className={styles.btnHeader}
						onClick={() => onSave(dataTexts)}
					>
						{t('btn_save_data')}
					</Button>
				</div>
			)}

			<IonLoading isOpen={isLoading} message={t('msg_loading')} duration={5000} />
		</>
	);
};
