import { useMemo } from 'react';
import { Column } from '@tanstack/react-table';
import styles from './styles.module.scss';
import { CustomSearchBar } from '../../CustomSearchBar/CustomSearchBar';

type Props = {
	column: Column<any, unknown>;
	placeholder?: string;
};

export const ExternalSearch: React.FC<Props> = ({ column, placeholder }) => {
	const columnFilterValue = column.getFilterValue();

	const sortedUniqueValues = useMemo(
		() => Array.from(column.getFacetedUniqueValues().keys()).sort(),
		[column.getFacetedUniqueValues()],
	);

	return (
		<div className={styles.searchContainer}>
			<datalist id={column.id + 'list'}>
				{sortedUniqueValues.slice(0, 5000).map((value: any) => (
					<option value={value} key={value} />
				))}
			</datalist>
			<CustomSearchBar
				value={(columnFilterValue ?? '') as string}
				onIonInput={(e) => column.setFilterValue(e.target.value)}
				placeholder={placeholder}
				debounce={1000}
			/>
		</div>
	);
};
