import { Toast } from '@acciona/ui-ionic-kit';

type Props = {
	info: string;
	success: string;
	error: string;
	onDidDismissToast: Function;
};

export const Feedback: React.FC<Props> = (props) => {
	const { info, success, error, onDidDismissToast } = props;

	return (
		<>
			<Toast
				isOpen={!!error}
				message={error}
				onDidDismiss={() => onDidDismissToast('error')}
				position="bottom"
				type="error"
			/>
			<Toast
				isOpen={!!success}
				message={success}
				onDidDismiss={() => onDidDismissToast('success')}
				position="bottom"
				type="success"
			/>
			<Toast
				isOpen={!!info}
				message={info}
				onDidDismiss={() => onDidDismissToast('info')}
				position="bottom"
				type="info"
			/>
		</>
	);
};
