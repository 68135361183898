import { useEffect, useState } from 'react';
import { Button, Icon, Modal } from '@acciona/ui-ionic-kit';
import {
	IonContent,
	IonFooter,
	IonItem,
	IonLabel,
	IonList,
	IonRadio,
	IonRadioGroup,
	IonTitle,
	IonToolbar,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { CustomSearchBar } from '../CustomSearchBar/CustomSearchBar';
import { Props } from './types';
import { Language } from '../../_api/services/user/types';
import styles from './styles.module.scss';

const filterCoincidentItems = (items: Language[], query: string) => {
	if (query === '') {
		return items;
	} else {
		return items.filter((item) => item.name.toLowerCase().includes(query.toLowerCase()));
	}
};

const ModalLanguageSelector: React.FC<Props> = (props) => {
	const { t } = useTranslation();
	const [selectedItem, setSelectedItem] = useState<Language>(null);
	const [itemSearch, setItemSearch] = useState('');
	const [results, setResults] = useState<Language[]>([]);
	const [tempSelectedItem, setTempSelectedItem] = useState<Language>(null);
	const canClose = props?.canClose !== false;

	useEffect(() => {
		setResults(props.data);
	}, [props.data]);

	useEffect(() => {
		setSelectedItem(props.selectedItem);
		setTempSelectedItem(props.selectedItem);
	}, [props.selectedItem]);

	const handleSearchChange = (query: string) => {
		setItemSearch(query);
		setResults(filterCoincidentItems(props.data, query));
	};

	const handleSaveChanges = () => {
		setItemSearch('');
		props.onSave(tempSelectedItem);
		props.onClose();
	};

	const handleCloseModal = () => {
		setSelectedItem(props.selectedItem);
		setTempSelectedItem(props.selectedItem);
		props.onClose();
	};

	return (
		<Modal
			{...props}
			isOpen={props.showModal}
			onDidDismiss={() => {
				props.onClose();
			}}
			className={`${styles.modal} ${props.backdropOpaque ? styles.backdropOpaque : ''} ${
				props.header ? styles.withHeaderText : ''
			}`}
			backdropDismiss={canClose}
		>
			<IonToolbar className={styles.title}>
				{canClose && (
					<Button slot="end" fill={'clear'} color="light" className={styles.closeBtn} onClick={handleCloseModal}>
						<IonLabel className="dark">
							<Icon color="primary" className="icon icon-close icon20"></Icon>
						</IonLabel>
					</Button>
				)}

				<IonTitle className={`${styles.centerTitle} ${styles.headline}`}>{props.title}</IonTitle>
			</IonToolbar>
			<IonContent>
				<div className={styles.contentModal}>
					{props.header && (
						<IonItem lines="none">
							<p className={styles.body}>{props.header}</p>
						</IonItem>
					)}
					<CustomSearchBar
						className={styles.searchBar}
						value={itemSearch}
						onIonInput={(e) => handleSearchChange(e?.target?.value ?? '')}
						onIonClear={() => setItemSearch('')}
						placeholder={props.placeholderSearch}
						debounce={500}
						clearIcon={undefined}
					/>
					<IonList className={styles.list}>
						<IonRadioGroup value={tempSelectedItem} onIonChange={(e) => setTempSelectedItem(e.detail.value)}>
							{!_.isEmpty(results) &&
								results?.map((item) => {
									const isSelected = item.code === tempSelectedItem?.code;
									return (
										<IonItem key={item.code} className={styles.selector} lines="none">
											<IonRadio value={item} labelPlacement="start">
												{item.name ?? ''}
											</IonRadio>
											{isSelected && (
												<Icon className={`icon icon-check icon24`} color="primary" slot="end"></Icon>
											)}
										</IonItem>
									);
								})}
						</IonRadioGroup>
					</IonList>
				</div>
			</IonContent>

			<IonFooter className={styles.footer}>
				<div className={styles.buttons}>
					{canClose && (
						<Button className={`${styles.secondaryBtn} ${styles.btnFooterAction}`} onClick={handleCloseModal}>
							{t('btn_cancel')}
						</Button>
					)}

					<Button
						color="primary"
						className={styles.btnFooterAction}
						onClick={handleSaveChanges}
						disabled={!selectedItem}
					>
						{t('btn_accept')}
					</Button>
				</div>
			</IonFooter>
		</Modal>
	);
};

export default ModalLanguageSelector;
