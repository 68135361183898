import { Button, Icon, List, MultiSelect, Toast } from '@acciona/ui-ionic-kit';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { SelectHolidaysModal } from '../SelectHolidaysModal';
import dayjs from 'dayjs';
import { containSameItems, listFormat } from '../../../utils/functions';
import AppContext from '../../../context/App/App.context';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { transversalServices } from '../../../_api/services/transversal';
import { HolidaysAndDisabledDays } from '../../../_api/services/transversal/types';
import { IonLoading } from '@ionic/react';
import useAppContext from '../../../hooks/useAppContext';
import { WEEKDAYS } from '../../../utils/constants';
import CustomModal from '../../../components/CustomModal/CustomModal';

interface Props {
	footerStyle?: string;
	setSuccess: Function;
	setError: Function;
	hasWritePermission: boolean;
}

export const CalendarConfiguration: FC<Props> = ({ footerStyle, setError, setSuccess, hasWritePermission }) => {
	const { t } = useTranslation();
	const [holidaysModalOpen, setHolidaysModalOpen] = useState(false);
	const [reservableAlertOpen, setReservableAlertOpen] = useState(false);
	const [holidays, setHolidays] = useState<Date[]>([]);
	const [reservableDays, setReservableDays] = useState<number[]>([]);
	const [showToast, setShowToast] = useState(false);
	const { userLanguage } = useContext(AppContext);
	const [isEdited, setIsEdited] = useState(false);
	const queryClient = useQueryClient();
	const { setThereAreUnsavedChanges } = useAppContext();

	const [data, setData] = useState<HolidaysAndDisabledDays>({
		deskDisabledDays: [],
		holidays: [],
		buildingOpeningDays: [],
	});

	const { buildingOpeningDays } = data;

	const handleReservableUpdate = useCallback(
		(value: number) => {
			if (reservableDays.includes(value)) {
				setReservableDays(reservableDays.filter((day) => day !== value));
			} else {
				setReservableDays([...reservableDays, value]);
			}
		},
		[reservableDays],
	);

	const disabledDays = useMemo(
		() => WEEKDAYS.map((weekDay) => weekDay.id).filter((day) => !reservableDays.includes(day)),
		[reservableDays],
	);
	useEffect(() => {
		if (data) {
			setReservableDays(WEEKDAYS.map((weekDay) => weekDay.id).filter((day) => !data.deskDisabledDays.includes(day)));
			setHolidays(data.holidays.map((day) => dayjs(day).toDate()));
		}
	}, [data]);

	useEffect(() => {
		setThereAreUnsavedChanges(isEdited);
	}, [isEdited]);

	useEffect(() => {
		if (
			containSameItems(data.deskDisabledDays, disabledDays) &&
			containSameItems(
				data.holidays,
				holidays.map((date) => dayjs(date).format('YYYY-MM-DD')),
			)
		) {
			setIsEdited(false);
		} else {
			setIsEdited(true);
		}
	}, [data, disabledDays, holidays]);

	const { isLoading: isLoading } = useQuery(
		['calendar_configuration'],
		async () => await transversalServices.getHolidaysAndDisabledDays(),
		{
			refetchOnWindowFocus: false,
			onError: (error) => setError(error as string),
			onSuccess: (data) => setData(data),
		},
	);

	const { mutate: handleSave, isLoading: loadingSave } = useMutation(transversalServices.saveHolidaysAndDisabledDays, {
		onSuccess: (savedData: HolidaysAndDisabledDays) => {
			setSuccess(t('msg_success'));
			setIsEdited(false);
			queryClient.refetchQueries('calendar_configuration');
			setData(savedData);
		},
		onError: (error: string) => {
			setError(error);
		},
	});

	const onClickSave = useCallback(() => {
		const formattedHolidays = holidays.map((day) => dayjs(day).format('YYYY-MM-DD'));
		if (disabledDays.some((day) => buildingOpeningDays.includes(day))) {
			setReservableAlertOpen(true);
		} else {
			handleSave({ deskDisabledDays: disabledDays, holidays: formattedHolidays });
		}
	}, [reservableDays, holidays, buildingOpeningDays]);

	const weekdaysClashesString = useMemo(() => {
		const days = listFormat(
			WEEKDAYS.filter((day) => buildingOpeningDays.includes(day.id)).map((day) => day.description.toLowerCase()),
			userLanguage.code,
		);
		return t('msg_aperture_clash', { days: days });
	}, [userLanguage, buildingOpeningDays]);

	return (
		<>
			<List>
				<div className={styles.element}>
					<div className={styles.h3}>{t('lbl_reservable_days')}</div>
					<div className={`${styles.footnote} ${styles.space}`}>{t('subtitle_reservable_days')}</div>
					<MultiSelect
						label={t('lbl_days')}
						className={`${styles.multiSelect} ${reservableDays.length > 0 && styles.touchedInput}`}
						options={WEEKDAYS.map((weekday) => ({
							value: weekday.id.toString(),
							label: weekday.description,
						}))}
						selected={reservableDays.map((day) => day.toString())}
						placeholder={t('lbl_select')}
						disabled={isLoading || !hasWritePermission}
						updateSelection={(value) => handleReservableUpdate(parseInt(value))}
					/>
				</div>
				<div className={styles.element}>
					<div className={styles.h3}>{t('lbl_holidays')}</div>
					<div className={`${styles.footnote} ${styles.space}`}>{t('subtitle_holidays')}</div>
					<div className={`${styles.input}`} onClick={() => setHolidaysModalOpen(true)}>
						<div className={styles.body}>{t('lbl_holidays_headoffice')}</div>
						<div className={`${styles.align}`}>
							<div className={`${styles.body} ${holidays.length < 1 && styles.color_dark}`}>
								{holidays.length ? `${holidays.length} ${t('lbl_selected').toLowerCase()}` : t('lbl_select')}
							</div>
							<Icon slot="end" className="icon icon24 icon-chevron-right" />
						</div>
					</div>
					<div className={`${styles.footnote} ${styles.space}`}>
						{holidays
							.sort((holidayA, holidayB) => (dayjs(holidayA).isAfter(dayjs(holidayB)) ? 1 : -1))
							.map((holiday) => {
								const date = dayjs(holiday);
								return t('day_of_month', {
									day: date.get('date'),
									month: date.locale(userLanguage.code).format('MMMM'),
								});
							})
							.join(', ')}
					</div>
				</div>
			</List>

			{hasWritePermission && (
				<div className={`${footerStyle} ${styles.footerButton}`}>
					<Button disabled={!isEdited} color="primary" className={styles.btnHeader} onClick={() => onClickSave()}>
						{t('btn_save_data')}
					</Button>
				</div>
			)}

			<SelectHolidaysModal
				className={styles.calendarModal}
				handleClose={() => setHolidaysModalOpen(false)}
				isOpen={holidaysModalOpen}
				handleSave={(days: Date[]) => {
					if (
						!holidays.every((festive) => days.includes(festive)) ||
						!days.every((day) => holidays.includes(day))
					) {
						setShowToast(true);
						setIsEdited(true);
					}
					setHolidays(days);
				}}
				selectedDays={holidays}
			/>
			<CustomModal
				onClose={() => setReservableAlertOpen(false)}
				onConfirm={() => setReservableAlertOpen(false)}
				showModal={reservableAlertOpen}
				cancelText={t('btn_cancel')}
				confirmText={t('btn_accept')}
				description={weekdaysClashesString}
				title={t('title_aperture_clash')}
			/>
			<Toast
				isOpen={showToast}
				onDidDismiss={() => setShowToast(false)}
				animated
				message={t('alert_save_calendar')}
			/>
			<IonLoading isOpen={isLoading || loadingSave} message={t('msg_loading')} />
		</>
	);
};
