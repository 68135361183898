import styles from './styles.module.scss';

export const ManualPoints = ({ points }) => {
	if (points < 0) {
		return <span className={styles.negative}>{points}</span>;
	} else if (points > 0) {
		return <span className={styles.positive}>{points}</span>;
	} else {
		return <span className={styles.empty}>-</span>;
	}
};
