import { useEffect, useMemo, useState } from 'react';
import { IonItem, IonLabel, IonText, IonButton, IonIcon, IonSelect, IonSelectOption } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Icon, Toast } from '@acciona/ui-ionic-kit';
import _ from 'lodash';
import { BuildingTurningPointsConfig, TurningPointsConfig } from '../../../../../_api/services/workstation/types';
import { Employee } from '../../../../../_api/services/roles/types';
import { roleService } from '../../../../../_api/services/roles';
import UsersModal from '../../../../../components/UsersModal/UsersModal';
import CustomModal from '../../../../../components/CustomModal/CustomModal';
import { ModalTurningPointConfiguration } from '../ModalTurningPointConfiguration/ModalTurningPointConfiguration';
import styles from '../../styles.module.scss';

type Props = {
	data: TurningPointsConfig;
	updateData: (data: TurningPointsConfig) => void;
	hasWritePermission: boolean;
};

const defaultTurningPointsConfig: TurningPointsConfig = {
	buildings: [],
	usersToSendEmail: [],
	visitsBuilding: null,
};

export const TurningPointsConfiguration = (props: Props) => {
	const { data: initialTurningPointsConfig, updateData, hasWritePermission } = props;
	const { t } = useTranslation();
	const [toastMessage, setToastMessage] = useState(null);
	const [turningPointsConfig, setTurningPointsConfig] = useState(defaultTurningPointsConfig);
	const [selectedBuilding, setSelectedBuilding] = useState<BuildingTurningPointsConfig>(null);
	const [showConfigurationModal, setShowConfigurationModal] = useState(false);
	const [showConfirmChangesModal, setShowConfirmChangesModal] = useState(false);
	const [showEmailRecipientsModal, setShowEmailRecipientsModal] = useState(false);
	const [emailRecipients, setEmailRecipients] = useState<Employee[]>([]);
	const backUp = initialTurningPointsConfig;
	const { data: employees, isLoading: isLoadingEmployees } = useQuery(
		['employees'],
		async () => await roleService.getPortalEmployees(),
		{
			refetchOnWindowFocus: false,
			onSuccess: (data: Employee[]) => {
				setEmailRecipients(
					data.map((employee) => {
						return {
							...employee,
							isChecked: turningPointsConfig?.usersToSendEmail?.includes(employee?.employeeId),
						};
					}),
				);
			},
		},
	);

	useEffect(() => {
		setTurningPointsConfig(initialTurningPointsConfig);
	}, [initialTurningPointsConfig]);

	const handleOpenModalTurningPoint = (building: BuildingTurningPointsConfig) => {
		setSelectedBuilding(building);
		setShowConfigurationModal(true);
	};

	const handleCloseModalTurningPoint = () => {
		setSelectedBuilding(null);
		setShowConfigurationModal(false);
	};

	const handleSaveBuildingTurningPoint = (nextData: BuildingTurningPointsConfig) => {
		setShowConfigurationModal(false);
		setSelectedBuilding(null);
		setTurningPointsConfig((prev) => ({
			...prev,
			buildings: prev.buildings.map((building) =>
				building.buildingId === nextData.buildingId ? nextData : building,
			),
		}));
		setShowConfirmChangesModal(true);
	};

	const confirmSaveTurningPoint = () => {
		setShowConfirmChangesModal(false);
		updateData(turningPointsConfig);
		setToastMessage(t('lbl_timeAndReservations_turning_point_toast'));
	};
	const cancelSaveTurningPoint = () => {
		setTurningPointsConfig(backUp);
		setShowConfirmChangesModal(false);
	};

	const handleOpenModalEmailRecipients = () => {
		setShowEmailRecipientsModal(true);
	};

	const saveEmailRecipients = (nextRecipients: Employee[]) => {
		const nextRecipientsIds = nextRecipients.map((r) => r.employeeId);
		setEmailRecipients(
			employees.map((recipient) => ({
				...recipient,
				isChecked: nextRecipientsIds.includes(recipient.employeeId),
			})),
		);
		const nextTurningPointsConfig: TurningPointsConfig = {
			...turningPointsConfig,
			usersToSendEmail: nextRecipientsIds,
		};
		setTurningPointsConfig(nextTurningPointsConfig);
		updateData(nextTurningPointsConfig);
		setToastMessage(t('lbl_timeAndReservations_turning_point_email_modal_toast'));
	};

	const checkedRecipients = useMemo(
		() => emailRecipients.filter((recipient) => recipient.isChecked),
		[emailRecipients],
	);

	const getBuildingDescription = (building: BuildingTurningPointsConfig) => {
		const weekdaysText = building.dayOfWeek
			.map((weekDay) => {
				return `${t(`weekdays_indexed.${weekDay.day}`)} ${weekDay.hour}`;
			})
			.join(', ');
		const visibleDaysText = `${t('lbl_timeAndReservations_turning_point_lbl_visible_days')}: ${
			building.numberOpenDays
		}`;
		return `${building.dayOfWeek.length > 0 ? `${weekdaysText} | ` : ''}${visibleDaysText}`;
	};

	const updateBuildingTurningPointForVisits = (id: number) => {
		const selectedBuilding = turningPointsConfig.buildings.find((building) => building.buildingId === id);
		if (!selectedBuilding) {
			return;
		}
		const nextTurningPointsConfig: TurningPointsConfig = {
			...turningPointsConfig,
			visitsBuilding: {
				buildingId: selectedBuilding?.buildingId,
				buildingName: selectedBuilding?.buildingName,
			},
		};
		setTurningPointsConfig(nextTurningPointsConfig);
		updateData(nextTurningPointsConfig);
	};

	return (
		<>
			{/* BUILDINGS TURNING POINT */}
			<div className={styles.element}>
				<div className={`${styles.h2} ${styles.headerSpace}`}>{t('lbl_timeAndReservations_turning_point')}</div>
				<div className={`${styles.footnote} ${styles.space}`}>
					{t('lbl_timeAndReservations_turning_point_description')}
				</div>
				{!_.isEmpty(turningPointsConfig) &&
					turningPointsConfig.buildings.map((building) => (
						<IonItem key={building.buildingId}>
							<IonLabel className={`ion-text-wrap`}>
								<IonText className={`${styles.body} ion-text-wrap block`}>{building?.buildingName}</IonText>
								<IonText className={`${styles.footnote} ${styles.listSubtitle} ion-text-wrap block`}>
									{getBuildingDescription(building)}
								</IonText>
							</IonLabel>

							<IonButton
								slot="end"
								fill="clear"
								className={styles.buttonEdit}
								onClick={() => handleOpenModalTurningPoint(building)}
							>
								<IonIcon slot="end" className="icon icon-edit" />
							</IonButton>
						</IonItem>
					))}
			</div>
			{/* EMAIL RECIPIENTS */}
			<div className={styles.element}>
				<div className={`${styles.footnote} ${styles.space}`}>
					{t('lbl_timeAndReservations_turning_point_email_description')}
					<div className={styles.input} onClick={handleOpenModalEmailRecipients}>
						<div>{t('lbl_users')}</div>
						<div className={`${styles.align}`}>
							<div className={`${checkedRecipients.length < 1 && styles.color_dark}`}>
								{checkedRecipients.length > 0
									? t('lbl_total_select', {
											total: checkedRecipients.length,
									  })
									: t('lbl_select')}
							</div>
							<Icon className="icon icon-chevron-right icon24" slot="end" />
						</div>
					</div>
				</div>
			</div>
			{/* BUILDING TURNING POINT FOR VISITS */}
			<div className={styles.element}>
				<p className={styles.blockSubtitle}>
					{t(`lbl_timeAndReservations_building_turning_point_for_visits_description`)}
				</p>
				<IonItem
					lines="none"
					className={`${styles.inputModal} ${
						turningPointsConfig?.visitsBuilding?.buildingId !== undefined && styles.touchedInput
					}`}
				>
					<IonLabel slot="start" className="lblSelector">
						{t('lbl_building')}
					</IonLabel>
					<IonSelect
						name="buildingTurningPointForVisits"
						slot="end"
						className={`lblSelector ${styles.selector}`}
						interface="popover"
						onIonChange={(e) => updateBuildingTurningPointForVisits(e.target.value)}
						disabled={!hasWritePermission}
						value={turningPointsConfig?.visitsBuilding?.buildingId}
						aria-label={t('lbl_building')}
					>
						{turningPointsConfig?.buildings?.map((building) => {
							return (
								<IonSelectOption key={building.buildingId} value={building.buildingId}>
									{building.buildingName}
								</IonSelectOption>
							);
						})}
					</IonSelect>
				</IonItem>
			</div>
			<UsersModal
				onClose={() => setShowEmailRecipientsModal(false)}
				onSave={saveEmailRecipients}
				showModal={showEmailRecipientsModal}
				usersData={emailRecipients}
				label={t('lbl_timeAndReservations_turning_point_email_modal_description')}
				isLoading={isLoadingEmployees}
			/>
			<ModalTurningPointConfiguration
				data={selectedBuilding}
				onClose={handleCloseModalTurningPoint}
				onSave={handleSaveBuildingTurningPoint}
				showModal={showConfigurationModal}
			/>
			<CustomModal
				showModal={showConfirmChangesModal}
				onConfirm={confirmSaveTurningPoint}
				onClose={cancelSaveTurningPoint}
				title={t('lbl_timeAndReservations_turning_point_changes_modal_title')}
				description={t('lbl_timeAndReservations_turning_point_changes_modal_description')}
			/>
			<Toast
				isOpen={!!toastMessage}
				message={toastMessage}
				onDidDismiss={() => setToastMessage(null)}
				position="bottom"
				type="success"
			/>
		</>
	);
};
