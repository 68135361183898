import { useContext, useEffect, useState } from 'react';
import { Modal } from '@acciona/ui-ionic-kit';
import { useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { roleService } from '../../../../_api/services/roles';
import { AppPermissionsContext } from '../../../../context/AppPermissions/AppPermissions.context';
import { AppPermissionsActions } from '../../../../context/AppPermissions/AppPermissions.actions';
import { Employee } from '../../../../_api/services/roles/types';
import { ModalContentUsersList } from '../ModalContentUsersList/ModalContentUsersList';
import { ModalContentConfiguration } from '../ModalContentConfiguration/ModalContentConfiguration';
import { getUserData } from '../../helpers';
import styles from './styles.module.scss';

type Props = {
	onClose: () => void;
	onSave: (newData: Employee[]) => void;
	showModal: boolean;
	currentSelectedUsers: number[];
	label: string;
};

export const ModalAddUsers: React.FC<Props> = (props) => {
	const { dispatch } = useContext(AppPermissionsContext);
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const [allUsers, setAllUsers] = useState<Employee[]>([]);
	const [selectedUsers, setSelectedUsers] = useState<Employee[]>([]);
	const [step, setStep] = useState(1);

	useQuery(['usersData'], async () => await roleService.getAppEmployees(), {
		onSuccess: (data) => {
			props.showModal && setAllUsers(getUserData(data, props.currentSelectedUsers));
		},
		refetchOnWindowFocus: false,
	});

	useEffect(() => {
		if (props.showModal) {
			queryClient.refetchQueries('usersData');
			setStep(1);
		}
	}, [props.showModal]);

	useEffect(() => {
		setSelectedUsers(allUsers.filter((u) => u?.isChecked && !u?.disabled));
	}, [allUsers]);

	const handleCloseModal = () => {
		setAllUsers([]);
		dispatch(AppPermissionsActions.resetConfigChanges());
		props.onClose();
	};

	const handleSaveChanges = () => {
		props.onSave(selectedUsers);
		setAllUsers([]);
	};

	return (
		<Modal isOpen={props.showModal} onDidDismiss={handleCloseModal} className={styles.modal}>
			{step === 1 ? (
				<ModalContentUsersList
					title={`${t('title_select_users')} (1/2)`}
					subtitle={props.label}
					dataUsers={allUsers}
					setDataUsers={setAllUsers}
					selectedUsers={selectedUsers}
					onClose={handleCloseModal}
					onSave={() => setStep(2)}
				/>
			) : (
				<ModalContentConfiguration
					mode="add"
					title={`${t('title_assign_permissions')} (2/2)`}
					subtitle={t('modal_add_users_2')}
					data={selectedUsers}
					setData={setSelectedUsers}
					onClose={handleCloseModal}
					onSave={handleSaveChanges}
					onBack={() => setStep(1)}
				/>
			)}
		</Modal>
	);
};
