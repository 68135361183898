import { Button, List } from '@acciona/ui-ionic-kit';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { FC, useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { transversalServices } from '../../../_api/services/transversal';
import { SeekerConfiguration } from '../../../_api/services/transversal/types';
import { IonLoading } from '@ionic/react';
import useAppContext from '../../../hooks/useAppContext';
import { NumberInput } from '../../../components/NumberInput/NumberInput';
import { UsersExcluded } from './components/UsersExcluded';
import _ from 'lodash';
import { Employee } from '../../../_api/services/roles/types';

interface Props {
	footerStyle?: string;
	setSuccess: Function;
	setError: Function;
	hasWritePermission: boolean;
}

export const Seeker: FC<Props> = ({ footerStyle, setError, setSuccess, hasWritePermission }) => {
	const { t } = useTranslation();
	const [isEdited, setIsEdited] = useState(false);
	const queryClient = useQueryClient();
	const { setThereAreUnsavedChanges } = useAppContext();
	const [seekerConfig, setSeekerConfig] = useState<SeekerConfiguration>();

	useEffect(() => {
		setThereAreUnsavedChanges(isEdited);
	}, [isEdited]);

	useEffect(() => {
		setIsEdited(!_.isEqual(seekerConfig, initialValues));
	}, [seekerConfig]);

	const { data: initialValues, isLoading: isLoading } = useQuery(
		['seeker_configuration'],
		async () => await transversalServices.getSeekerConfiguration(),
		{
			refetchOnWindowFocus: false,
			onError: (error) => setError(error as string),
			onSuccess: (data) => setSeekerConfig(data),
		},
	);

	const { mutate: handleSave, isLoading: loadingSave } = useMutation(transversalServices.updateSeekerConfiguration, {
		onSuccess: () => {
			setSuccess(t('msg_success'));
			setIsEdited(false);
			queryClient.refetchQueries('seeker_configuration');
		},
		onError: (error: Error) => {
			setError(error.message);
		},
	});

	const handleInputChange = (e) => {
		const fieldName = e.target.name;
		const value = +e.target.value;
		setSeekerConfig((prevValues) => {
			const nextValues = _.cloneDeep(prevValues);
			return {
				...nextValues,
				[fieldName]: value,
			};
		});
	};

	const handleUpdateUsersExcluded = (users: Employee[]) => {
		setSeekerConfig((prevValues) => {
			const nextValues = _.cloneDeep(prevValues);
			return {
				...nextValues,
				usersExcluded: users,
			};
		});
	};
	const handleSaveConfig = () => {
		handleSave(seekerConfig);
	};

	return (
		<>
			{isLoading ? (
				<IonLoading isOpen={isLoading} message={t('msg_loading')} />
			) : (
				seekerConfig && (
					<List>
						<div className={styles.h3}>{t('lbl_searches_number')}</div>
						<div className={styles.element}>
							<div className={`${styles.footnote} ${styles.space}`}>{t('lbl_recently_searches')}</div>
							<NumberInput
								name="recentlySearches"
								className={`ion-text-end`}
								value={seekerConfig.recentlySearches}
								onIonChange={handleInputChange}
								min={0}
								max={20}
								disabled={!hasWritePermission}
								aria-label={t('lbl_number')}
								label={t('lbl_number')}
								required
								allowDecimals={false}
							></NumberInput>
						</div>
						<div className={styles.element}>
							<div className={`${styles.footnote} ${styles.space}`}>{t('lbl_popular_searches')}</div>
							<NumberInput
								name="popularSearches"
								className={`ion-text-end`}
								value={seekerConfig.popularSearches}
								onIonChange={handleInputChange}
								min={0}
								max={20}
								disabled={!hasWritePermission}
								aria-label={t('lbl_number')}
								label={t('lbl_number')}
								required
								allowDecimals={false}
							></NumberInput>
						</div>
						<UsersExcluded
							users={seekerConfig.usersExcluded}
							handleUpdate={handleUpdateUsersExcluded}
							hasWritePermissions={hasWritePermission}
							disabled={!hasWritePermission}
						/>
					</List>
				)
			)}

			{hasWritePermission && (
				<div className={`${footerStyle} ${styles.footerButton}`}>
					<Button
						disabled={!isEdited || loadingSave}
						color="primary"
						className={styles.btnHeader}
						onClick={handleSaveConfig}
					>
						{t('btn_save_data')}
					</Button>
				</div>
			)}
		</>
	);
};
