import { useEffect, useRef, useState } from 'react';
import { IonContent, IonLabel } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { SegmentButton, Segmented, Toast } from '@acciona/ui-ionic-kit';
import useAppContext from '../../../hooks/useAppContext';
import { useScrollBar } from '../../../hooks/useScrollBar';
import { usePermissions } from '../../../hooks/usePermissions';
import { HeaderPage } from '../../../components/HeaderPage/HeaderPage';
import { Shuttles } from './Shuttles/Shuttles';
import { FUNCTIONALITIES } from '../../../utils/constants';
import styles from './styles.module.scss';

const Mobility: React.FC = () => {
	const { t } = useTranslation();
	const { setThereAreUnsavedChanges } = useAppContext();
	const [segmentedSelected, setSegmentedSelected] = useState('shuttles');
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);
	const [scroll, setScroll] = useState(false);
	let footerStyle = null;
	const refContainer = useRef(null);
	const refHeader = useRef(null);
	const hasScroll = useScrollBar(refContainer, refHeader, segmentedSelected);
	footerStyle = hasScroll ? styles.hasScroll : styles.noScroll;
	const { permissions, error: errorPermissions } = usePermissions(FUNCTIONALITIES.services, 'WRITE');

	useEffect(() => {
		setError(errorPermissions);
	}, [errorPermissions]);

	useEffect(() => {
		return () => {
			setThereAreUnsavedChanges(false);
		};
	}, []);

	return (
		<IonContent scrollEvents forceOverscroll={true} onIonScroll={(x) => setScroll(x.detail.scrollTop > 0)}>
			<HeaderPage scroll={scroll} title={t('menu_mobility')}>
				<div ref={refHeader}>
					<Segmented value={segmentedSelected}>
						<SegmentButton onClick={() => setSegmentedSelected('shuttles')} value={'shuttles'} mode={'md'}>
							<IonLabel>{t('tab_shuttles')}</IonLabel>
						</SegmentButton>
					</Segmented>
				</div>
			</HeaderPage>
			<div className="container" ref={refContainer}>
				<div className={segmentedSelected === 'shuttles' ? styles.display : styles.hide}>
					<Shuttles
						setError={setError}
						setSuccess={setSuccess}
						hasWritePermission={permissions.WRITE}
						footerStyle={footerStyle}
					/>
				</div>
			</div>
			<Toast isOpen={!!error} message={error} onDidDismiss={() => setError('')} position="bottom" type="error" />
			<Toast
				isOpen={!!success}
				message={success}
				onDidDismiss={() => setSuccess('')}
				position="bottom"
				type="success"
			/>
		</IonContent>
	);
};

export default Mobility;
