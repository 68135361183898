import _ from 'lodash';
import { ShuttleData, Shuttle } from '../../../../_api/services/generalServices/types';
import { LANGUAGES } from '../../../../utils/constants';

export const fillEmptyTranslations = (translations: ShuttleData[]) => {
	if (_.isEmpty(translations)) {
		return [];
	}
	return translations.map((tr) => ({
		...tr,
		name: _.isEmpty(tr.name) ? translations.find((l) => !_.isEmpty(l.name))?.name : tr.name,
		schedule: _.isEmpty(tr.schedule) ? translations.find((l) => !_.isEmpty(l.schedule))?.schedule : tr.schedule,
	}));
};

/**
 * @param {Shuttle} shuttle
 * @returns {boolean} true if shuttle has empty fields or false if it hasn't
 */
export const checkEmptyFields = (shuttle: Shuttle) => {
	if (_.isEmpty(shuttle?.translations)) {
		return true;
	}

	const acceptedLanguagesTranslations = shuttle.translations.filter((t) => LANGUAGES.includes(t.language));
	const translationsToCheck = fillEmptyTranslations(acceptedLanguagesTranslations);

	const hasEmptyFields = (translation) => {
		const { name, schedule } = translation;
		const nameIsEmpty = !name || _.isEmpty(name) || name === '';
		const scheduleIsEmpty = !schedule || _.isEmpty(schedule) || schedule === '' || schedule === '<p><br></p>';
		return nameIsEmpty || scheduleIsEmpty;
	};

	if (shuttle.new) {
		return translationsToCheck.every((translation) => hasEmptyFields(translation));
	} else if (shuttle.enabled) {
		return translationsToCheck.some((translation) => hasEmptyFields(translation));
	} else if (shuttle.edited && !shuttle.enabled) {
		// In case of deleted shuttle, empty fields are irrelevant
		return false;
	}

	return true;
};

/**
 * @param {Shuttle[]} shuttles
 * @returns {boolean} true if none shuttle has empty fields or false if almost one shuttle has empty fields
 */
export const validateData = (shuttles: Shuttle[]): boolean => {
	return shuttles.every((shuttle) => !checkEmptyFields(shuttle));
};

export const getDefaultShuttleName = (initialData: Shuttle[], item: Shuttle, selectedLang: string) => {
	const defaultData = initialData.find((e) => e.id === item.id);
	return defaultData?.translations ? defaultData?.translations.find((e) => e.language === selectedLang).name : '';
};
