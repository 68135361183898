import { useEffect, useMemo, useState } from 'react';
import { IonCheckbox, IonContent, IonFooter, IonSpinner, IonText, IonTitle, IonToolbar } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Button, Icon, Item, Modal } from '@acciona/ui-ionic-kit';
import { IEmployeeResult, Props } from './types';
import { VirtualizedList } from './VirtualizedList';
import { getFilteredUsers } from './helpers';
import styles from './styles.module.scss';
import { CustomSearchBar } from '../CustomSearchBar/CustomSearchBar';

const UsersModal: React.FC<Props> = (props) => {
	const { t } = useTranslation();
	const {
		onClose,
		onSave,
		showModal,
		usersData,
		title,
		label,
		clearDataOnClose,
		isLoading,
		listConfig,
		hasWritePermission = true,
		allowEmptySelection = true,
		multiselect = true,
		ordered = true,
		textButtonConfirm = t('btn_save'),
	} = props;
	const [dataUsers, setDataUsers] = useState<IEmployeeResult[]>([]);
	const [userNameSearch, setUserNameSearch] = useState<string>();
	const [checkAll, setCheckAll] = useState(false);
	const [showOnlyCheckeds, setShowOnlyCheckeds] = useState(false);

	useEffect(() => {
		usersData && setDataUsers(usersData);
	}, [usersData]);

	const checkUser = (detailChecked: boolean, id: number) => {
		if (!multiselect) {
			setDataUsers((prev) =>
				prev.map((item) => ({
					...item,
					isChecked: item.employeeId === id ? detailChecked : false,
				})),
			);
		}

		setDataUsers((prev) =>
			prev.map((item) => ({
				...item,
				isChecked: item.employeeId === id ? detailChecked : item.isChecked,
			})),
		);
	};

	const countCheckedUsers = useMemo(() => {
		return dataUsers.filter((e) => e.isChecked).length;
	}, [dataUsers]);

	const checkAllUsers = () => {
		setDataUsers((prev) =>
			prev.map((item) => ({
				...item,
				isChecked: !checkAll,
			})),
		);
		setCheckAll((v) => !v);
	};

	const showAllSelected = () => {
		setUserNameSearch('');
		setShowOnlyCheckeds((v) => !v);
	};

	const closeModal = () => {
		setUserNameSearch('');
		setShowOnlyCheckeds(false);
		onClose();
		clearDataOnClose && setDataUsers([]);
	};

	const handleSaveChanges = () => {
		onClose();
		onSave(dataUsers.filter((user) => user.isChecked));
		clearDataOnClose && setDataUsers([]);
	};

	const usersList: IEmployeeResult[] = useMemo(
		() => getFilteredUsers(dataUsers, showOnlyCheckeds, userNameSearch, ordered),
		[dataUsers, showOnlyCheckeds, userNameSearch],
	);

	return (
		<Modal isOpen={showModal} onDidDismiss={closeModal} className={styles.modal}>
			<IonToolbar>
				<Button
					slot="end"
					fill={'clear'}
					color="light"
					className={styles.closeModalBtn}
					aria-label={t('lbl_aria_close_modal')}
					onClick={closeModal}
				>
					<Icon className="icon icon-close" aria-hidden="true"></Icon>
				</Button>
				<IonTitle className={styles.toolbarTitle}>{title ?? t('title_select_users')}</IonTitle>
			</IonToolbar>
			<IonContent scrollY={false}>
				<IonText className={styles.labelInstructions}>{label}</IonText>
				<CustomSearchBar
					value={userNameSearch}
					onIonInput={(e) => setUserNameSearch(e.detail.value!)}
					cancelButtonText="cancel-button-text"
					placeholder={t('plholder_search_user_modal')}
					debounce={500}
				/>
				{multiselect && (
					<>
						<p className={styles.labelCountCheckedUsers}>{` ${countCheckedUsers} ${t('lbl_selected_users')}`}</p>
						<div className={styles.headerSelectAllUsers}>
							<Item mode="ios">
								<IonCheckbox
									checked={checkAll}
									onClick={checkAllUsers}
									labelPlacement="end"
									justify="start"
									disabled={!hasWritePermission}
								>
									<b>{t('lbl_select_all_users')}</b>
								</IonCheckbox>
							</Item>

							<Icon
								onClick={showAllSelected}
								className={`${styles.onlyCheckeds} icon icon-24 icon-selection`}
								slot="end"
							/>
						</div>
					</>
				)}
				<IonContent className={styles.usersList}>
					{isLoading ? (
						<div className="ion-text-center">
							<IonSpinner />
						</div>
					) : (
						!_.isEmpty(usersList) && (
							<VirtualizedList
								usersList={usersList}
								checkUser={checkUser}
								hasWritePermission={hasWritePermission}
								listConfig={listConfig}
							/>
						)
					)}
				</IonContent>
			</IonContent>
			<IonFooter className={styles.btnModal}>
				<div>
					<Button className={`${styles.btnHeader} ${styles.secondaryBtn}`} onClick={closeModal}>
						{t('btn_cancel')}
					</Button>
				</div>
				<div>
					<Button
						color="primary"
						className={styles.btnHeader}
						onClick={handleSaveChanges}
						disabled={!hasWritePermission || (!allowEmptySelection && countCheckedUsers < 1)}
					>
						{textButtonConfirm}
					</Button>
				</div>
			</IonFooter>
		</Modal>
	);
};

export default UsersModal;
