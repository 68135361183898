import _ from 'lodash';
import { CONFIGURATION_PREFERENCES, MILLISECONDS_IN_A_DAY } from './constants';
import dayjs from 'dayjs';
import { Sede } from '../_api/services/user/types';

/**
 * Moves an element within an array from one index to another.
 * @template T
 * @param {T[]} arr - The input array.
 * @param {number} fromIndex - The index from which the element will be moved.
 * @param {number} toIndex - The index to which the element will be moved.
 * @returns {T[]} - The modified array with the element moved to the new index.
 */
export const arraymove = <T>(arr: T[], fromIndex: number, toIndex: number): T[] => {
	const element = arr[fromIndex];
	arr.splice(fromIndex, 1);
	arr.splice(toIndex, 0, element);
	return arr;
};

export const zeroPad = (num, places) => String(num).padStart(places, '0');

export const startOfDay = (date: Date) => {
	let utcDate = null;
	if (date) {
		utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
	}
	return utcDate;
};

/* Legacy */
export const sortDateString = (date: Date) => {
	if (!date) {
		return '';
	}
	let dateString = null;
	if (date) {
		dateString = date.toISOString().substring(0, 10);
	}
	return dateString;
};

/**
 * Get a string date from a Date object
 * @param {Date} date
 * @returns {string} date string 'yyyy-MM-dd'
 */
export const getSortDateString = (date: Date) => {
	if (!date) {
		return '';
	}
	const day = date.getDate();
	const month = (date.getMonth() + 1).toString().padStart(2, '0');
	const year = date.getFullYear();

	const dayString = day < 10 ? '0' + day.toString() : day.toString();
	return `${year}-${month}-${dayString}`;
};

export const getUniqueObjectsInArray = <T extends Record<string, any>>(arr: T[], key: string): T[] => {
	const mapedData = new Map<any, T>();
	arr.forEach((item) => {
		if (key in item) {
			mapedData.set(item[key], item);
		}
	});
	const uniqueObjects = Array.from(mapedData.values());
	return uniqueObjects;
};

export const isValidPhone = (phone: string): boolean => {
	const re = /^\+?[0-9]{3,}$/;
	return re.test(phone);
};

/**
 * Capitalize the first letter of each word
 * @param {string} str
 * @returns {string}
 */
export const toTitlecase = (str: string) => {
	const capitalizeFirstLetter = (word) => word.charAt(0).toUpperCase() + word.slice(1);
	return str
		.toLowerCase()
		.split(' ')
		.map((word) => capitalizeFirstLetter(word))
		.join(' ');
};

/**
 * Sorts a list of Sede objects based on the specified ordering criteria and moves the selectedSede object to the beginning of the list
 * @param sedesList
 * @param selectedSede
 * @param orderedBy
 * @returns Items ordered by a field in ascending
 */
export const sortSedesList = (sedesList: Sede[], selectedSede: Sede, orderedBy: string): Sede[] => {
	const sortedItems = _.orderBy(sedesList, orderedBy, 'asc');
	const selectedItemIndex = sortedItems.findIndex((sede: Sede) => {
		return sede?.id === selectedSede?.id;
	});

	if (selectedItemIndex > -1) {
		sortedItems.splice(selectedItemIndex, 1);
		sortedItems.unshift(selectedSede);
	}

	return sortedItems;
};

/**
 * Remove accents (diacritical marks) from any text
 * @param {string} str - text
 * @returns
 */
export const diacriticalReplace = (string) => {
	if (!string) {
		return '';
	}
	return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

/**
 * Compare if a string includes a query string
 * (case and diacritic-insensitive)
 * @param {string}  str - string
 * @param {string} query - query string
 */
export const includesQueryString = (str: string, query: string) => {
	return diacriticalReplace(str).toLowerCase().includes(diacriticalReplace(query).toLowerCase());
};

/**
 * Promise-based alternative to setTimeout.
 * The promise resolves after x milliseconds,
 * so we can add .then to it, like this:
 * delay(3000).then(() => alert('runs after 3 seconds'));
 * @param {number} milliseconds
 * @returns {Promise}
 */
export const delay = (milliseconds: number): Promise<any> => {
	return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

/**
 * Add or subtract days from a date
 * @param {Date} date - base date
 * @param {number} days - days to be added or subtracted (accepts negative numbers)
 * @returns {Date}
 */
export const addDays = (date: Date, days: number): Date => new Date(date.getTime() + days * MILLISECONDS_IN_A_DAY);

/**
 * Add or subtract days from a date string
 * @param {string} date - base date 'yyyy-MM-dd'
 * @param {number} days - days to be added or subtracted (accepts negative numbers)
 * @returns {string} 'yyyy-MM-dd'
 */
export const addDaysToDateString = (date: string, days: number): string => {
	if (!date) {
		return null;
	}
	const newDate = addDays(new Date(date), days);
	return getSortDateString(newDate);
};

/**
 * Extracts year, month and day from  a given date string
 * @param {string} date - date string 'yyyy-MM-dd'
 * @returns {Array} [year, month, day]
 */
export const getDateParts = (date: string): string[] => {
	const pattern = /\b\d{4}-\d{2}-\d{2}\b/g;
	if (!date || !date.match(pattern)) {
		return null;
	}
	const [year, month, day] = date.split('-');
	return [year, month, day];
};

/**
 * Ignore minutes of a date string and always return 00 minutes
 * @param {string} hour - hour string 'XX:XX'
 * @returns {string} hour string 'XX:00'
 */
export const roundHour = (hour: string) => {
	const pattern = /.+:.*/g;
	if (!hour || !hour.match(pattern)) {
		return null;
	}
	const [hourPart = '00'] = hour.split(':');
	return `${hourPart}:00`;
};

/**
 * Returns a string, with all elements separated by commas,
 * except the last one, which is separated by "y" or "and",
 * depending on the language passed as a parameter
 * @param {string[]} arr
 * @param {string} language
 * @returns
 */
export const listFormat = (arr: string[], language: string) => {
	if (arr.length === 1) {
		return arr[0];
	}
	const str = arr.join(', ');
	const lastIndex = str.lastIndexOf(',');
	const languageSelected = ['es', 'en', 'pt'].includes(language) ? language : 'es';
	const replacement = {
		es: 'y',
		en: 'and',
		pt: 'e',
	};
	return str.substring(0, lastIndex) + ' ' + replacement[languageSelected] + str.substring(lastIndex + 1);
};

/**
 * Checks if two unidimensional array of primitives contain the same items
 * @param {string[] | number[]} arr1
 * @param {string[] | number[]} arr2
 * @returns {boolean}
 */
export const containSameItems = (arr1: any[], arr2: any[]) =>
	arr1.every((item) => arr2.includes(item)) && arr2.every((item) => arr1.includes(item));

/**
 * Given an array of objects, returns the highest number of a key.
 * - If array is empty, null or undefined, returns 0.
 * - If the value of the key is not a number, returns 0.
 * @param {array} arr - array of objects
 * @param {string} key - key of the object
 * @returns {number}
 */
export const getMax = <T extends Object, K extends keyof T>(arr: T[], key: K): number => {
	if (!arr || arr.length === 0) {
		return 0;
	}
	const prevValues = arr.map((item) => {
		if (typeof item[key] === 'number') {
			return item[key];
		} else {
			return 0;
		}
	}) as number[];

	return Math.max(...prevValues, 0);
};

/**
 * Given an array of objects, returns the following number of a key.
 * - If array is empty, null or undefined, returns 1.
 * - If the value of the key is not a number, returns 1.
 * @param {array} arr - array of objects
 * @param {string} key - key of the object
 * @returns {number}
 */
export const getNext = <T extends Object, K extends keyof T>(arr: T[], key: K): number => {
	return getMax(arr, key) + 1;
};

/**
 * Given a date in UTC, returns the date and hour in the sede selected timezone.
 * @param {string} dateUTC - date in utc 0 string
 * @param {string} sedeTimeZone - sede selected timezone
 * @returns { Object } { date: string, time: string }
 */
export const extractDateAndTimeInSedeTimeZone = (
	dateUTC: string,
	sedeTimeZone: string,
): { date: string; time: string } => {
	if (_.isEmpty(dateUTC)) return { date: '', time: '' };
	const sedeDate = dayjs(dateUTC).tz(sedeTimeZone);
	const date = sedeDate.format('DD-MM-YYYY');
	const time = sedeDate.format('HH:mm');
	return { date, time };
};

export const getPreferencesOptions = (hasTransversalAcces: boolean, sedePortalOptions: Sede[]) => {
	if (hasTransversalAcces && !_.isEmpty(sedePortalOptions)) {
		return Object.values(CONFIGURATION_PREFERENCES);
	} else if (hasTransversalAcces) {
		return [CONFIGURATION_PREFERENCES.transversal];
	} else if (!_.isEmpty(sedePortalOptions)) {
		return [CONFIGURATION_PREFERENCES.sede];
	} else {
		return [];
	}
};

/**
 * Sorts an array of objects in ascending order based on a specified key,
 * ignoring diacritics (accents) in the string values.
 *
 * @template T
 * @param {T[]} data - The array of objects to be sorted.
 * @param {string} key - The key of the objects to sort by.
 * @returns {T[]} The sorted array of objects.
 */
export const sortAscDiatriticInsensitive = <T>(data: T[], key: string): T[] => {
	return data.sort((a, b) => {
		return diacriticalReplace(a[key]).localeCompare(diacriticalReplace(b[key]));
	});
};
