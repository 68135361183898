import { useEffect, useState } from 'react';
import { IonItem, IonLabel, IonLoading, IonSelect, IonSelectOption } from '@ionic/react';
import { Button } from '@acciona/ui-ionic-kit';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { roomsServices } from '../../../_api/services/rooms';
import _ from 'lodash';
import { TimeParams } from '../../../_api/services/rooms/types';
import useAppContext from '../../../hooks/useAppContext';
import { timeOptions, timeUnitOptions } from './helpers';

import styles from './styles.module.scss';

type Props = {
	setError?: Function;
	setSuccess?: Function;
	hasWritePermission?: boolean;
	footerStyle: string;
};

export const Time: React.FC<Props> = (props) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const { setThereAreUnsavedChanges } = useAppContext();
	const [dataState, setData] = useState<TimeParams>();
	const [isEdited, setIsEdited] = useState(false);

	useEffect(() => {
		setThereAreUnsavedChanges(isEdited);
	}, [isEdited]);

	const { data, isLoading: isLoading } = useQuery<TimeParams>(
		['time_room_values'],
		async () => await roomsServices.getTimeParams(),
		{
			refetchOnWindowFocus: false,
			onError: (error) => props.setError(error as string),
			onSuccess: (data) => setData(data),
		},
	);

	const { mutate: handleSave, isLoading: loadingSave } = useMutation(roomsServices.saveTimeParams, {
		onSuccess: () => {
			props.setSuccess(t('msg_success'));
			setIsEdited(false);
			queryClient.refetchQueries();
		},
		onError: (error) => {
			props.setError(error as string);
		},
	});

	const editDataValue = (field, value) => {
		setData({ ...dataState, [field]: value });
	};

	useEffect(() => {
		setIsEdited(!_.isEqual(dataState, data));
	}, [dataState]);

	return (
		<>
			<div className={`${styles.footnote} ${styles.space}`}>{t('maxTimeCheckInRoom')}</div>
			<IonItem
				lines="none"
				className={`${styles.inputModal} ${
					data?.maxTimeCheckInRoom !== dataState?.maxTimeCheckInRoom && styles.touchedInput
				}`}
				disabled={!props.hasWritePermission}
			>
				<IonLabel slot="start" className="lblSelector">
					{t('value_numeric_minutes')}
				</IonLabel>
				<IonSelect
					slot="end"
					className={`lblSelector ${styles.selector}`}
					interface="popover"
					value={dataState?.maxTimeCheckInRoom}
					onIonChange={(e) => dataState && editDataValue('maxTimeCheckInRoom', parseInt(e.detail?.value))}
					aria-label={t('value_numeric_minutes')}
				>
					{timeOptions.checkInLimit.map((l, index) => {
						return (
							<IonSelectOption key={index} value={l}>
								{l}
							</IonSelectOption>
						);
					})}
				</IonSelect>
			</IonItem>
			<div className={`${styles.footnote} ${styles.space}`}>{t('maxTimeExtendRoom')}</div>
			<div className={`${styles.inputsContainer}`}>
				<IonItem lines="none" disabled className={`${styles.inputModal}`}>
					<IonLabel slot="start" className="lblSelector">
						{t('unit_of_time')}
					</IonLabel>
					<IonSelect
						slot="end"
						className={`lblSelector ${styles.selector}`}
						interface="popover"
						value={'minutes'}
						aria-label={t('unit_of_time')}
					>
						{timeUnitOptions.map(({ value, label }) => {
							return (
								<IonSelectOption key={value} value={value}>
									{t(label)}
								</IonSelectOption>
							);
						})}
					</IonSelect>
				</IonItem>
				<IonItem
					lines="none"
					className={`${styles.inputModal} ${
						data?.maxTimeExtendRoom !== dataState?.maxTimeExtendRoom && styles.touchedInput
					}`}
					disabled={!props.hasWritePermission}
				>
					<IonLabel slot="start" className="lblSelector">
						{t('value_numeric')}
					</IonLabel>
					<IonSelect
						slot="end"
						className={`lblSelector ${styles.selector}`}
						interface="popover"
						value={dataState?.maxTimeExtendRoom}
						onIonChange={(e) => dataState && editDataValue('maxTimeExtendRoom', parseInt(e.detail?.value))}
						aria-label={t('value_numeric')}
					>
						{timeOptions.extensionTime.map((l, index) => {
							return (
								<IonSelectOption key={index} value={l}>
									{l}
								</IonSelectOption>
							);
						})}
					</IonSelect>
				</IonItem>
			</div>
			<div className={`${styles.footnote} ${styles.space}`}>{t('availabilityExtendRoomWhenLeftMinutes')}</div>
			<IonItem
				lines="none"
				className={`${styles.inputModal} ${
					data?.availabilityExtendRoomWhenLeftMinutes !== dataState?.availabilityExtendRoomWhenLeftMinutes &&
					styles.touchedInput
				}`}
				disabled={!props.hasWritePermission}
			>
				<IonLabel slot="start" className="lblSelector">
					{t('value_numeric_minutes')}
				</IonLabel>
				<IonSelect
					slot="end"
					className={`lblSelector ${styles.selector}`}
					interface="popover"
					value={dataState?.availabilityExtendRoomWhenLeftMinutes}
					onIonChange={(e) =>
						dataState && editDataValue('availabilityExtendRoomWhenLeftMinutes', parseInt(e.detail?.value))
					}
					aria-label={t('value_numeric_minutes')}
				>
					{timeOptions.extensionWindow.map((l, index) => {
						return (
							<IonSelectOption key={index} value={l}>
								{l}
							</IonSelectOption>
						);
					})}
				</IonSelect>
			</IonItem>
			<div className={`${styles.footnote} ${styles.space}`}>{t('notificationRoomReservationStart')}</div>
			<div className={`${styles.inputsContainer}`}>
				<IonItem lines="none" disabled className={`${styles.inputModal}`}>
					<IonLabel slot="start" className="lblSelector">
						{t('unit_of_time')}
					</IonLabel>
					<IonSelect
						slot="end"
						className={`lblSelector ${styles.selector}`}
						interface="popover"
						value={'minutes'}
						aria-label={t('unit_of_time')}
					>
						{timeUnitOptions.map(({ value, label }) => {
							return (
								<IonSelectOption key={value} value={value}>
									{t(label)}
								</IonSelectOption>
							);
						})}
					</IonSelect>
				</IonItem>
				<IonItem
					lines="none"
					className={`${styles.inputModal} ${
						data?.notificationRoomReservationStart !== dataState?.notificationRoomReservationStart &&
						styles.touchedInput
					}`}
					disabled={!props.hasWritePermission}
				>
					<IonLabel slot="start" className="lblSelector">
						{t('value_numeric')}
					</IonLabel>
					<IonSelect
						slot="end"
						className={`lblSelector ${styles.selector}`}
						interface="popover"
						value={dataState?.notificationRoomReservationStart}
						onIonChange={(e) =>
							dataState && editDataValue('notificationRoomReservationStart', parseInt(e.detail?.value))
						}
						aria-label={t('value_numeric')}
					>
						{timeOptions.notificationTime.map((l, index) => {
							return (
								<IonSelectOption key={index} value={l}>
									{l}
								</IonSelectOption>
							);
						})}
					</IonSelect>
				</IonItem>
			</div>

			{props.hasWritePermission && (
				<div className={`${props.footerStyle} ${styles.footerButton}`}>
					<Button
						disabled={!isEdited}
						color="primary"
						className={styles.btnHeader}
						onClick={() => handleSave(dataState)}
					>
						{t('btn_save_data')}
					</Button>
				</div>
			)}

			<IonLoading isOpen={isLoading || loadingSave} message={t('msg_loading')} duration={1500} />
		</>
	);
};
