import React from 'react';
import { InputCustomEvent, IonInput, IonItem } from '@ionic/react';
import styles from './styles.module.scss';

type Props = {
	name: string;
	className: string;
	wrapperClass?: string;
	value: string;
	defaultValue?: string;
	onIonChange?: (e: InputCustomEvent) => void;
	onIonInput?: (e: InputCustomEvent) => void;
	placeholder?: string;
	disabled?: boolean;
	label: string;
	'aria-label'?: string;
	isValid?: boolean;
	required?: boolean;
	slot?: string;
	wrapperSlot?: string;
	maxlength?: number;
	labelPlacement?: 'fixed' | 'start' | 'end' | 'floating' | 'stacked' | undefined;
};

export const TextInput: React.FC<Props> = ({
	name,
	className,
	wrapperClass,
	value,
	defaultValue = '',
	onIonChange,
	onIonInput,
	placeholder,
	disabled,
	label,
	'aria-label': ariaLabel,
	isValid = true,
	required = false,
	slot = '',
	wrapperSlot = '',
	labelPlacement,
	maxlength,
}) => {
	const onIonChangeWrapper = (e: InputCustomEvent) => {
		onIonChange && onIonChange(e);
	};
	const onIonInputWrapper = (e: InputCustomEvent) => {
		onIonInput && onIonInput(e);
	};
	return (
		<IonItem
			lines="none"
			className={`${styles.inputModal} ${styles.inputWithLabel} ${!isValid && styles.errorInput} ${
				defaultValue !== value && styles.touchedInput
			} ${wrapperClass}`}
			slot={wrapperSlot}
		>
			<IonInput
				type="text"
				name={name}
				className={className}
				value={value}
				defaultValue={defaultValue}
				onIonChange={onIonChangeWrapper}
				onIonInput={onIonInputWrapper}
				disabled={disabled}
				label={label}
				aria-label={ariaLabel}
				placeholder={placeholder}
				{...(slot ? { slot } : {})}
				required={required}
				labelPlacement={labelPlacement}
				maxlength={maxlength}
			></IonInput>
		</IonItem>
	);
};
