import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';

import {
	useReactTable,
	ColumnFiltersState,
	getCoreRowModel,
	getFilteredRowModel,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getFacetedMinMaxValues,
	getPaginationRowModel,
	getSortedRowModel,
	FilterFn,
} from '@tanstack/react-table';

import { RankingInfo } from '@tanstack/match-sorter-utils';
import { TableGrid } from './TableGrid';
import { TableHeader } from './TableHeader';
import { TableFooter } from './TableFooter';
import { customTableFilter } from './../../utils/table';
declare module '@tanstack/table-core' {
	interface FilterFns {
		customTableFilter: FilterFn<unknown>;
	}
	interface FilterMeta {
		itemRank: RankingInfo;
	}
}

export type Props = {
	initialData: any[];
	columns: any[];
	showHeader: boolean;
	showFooter?: boolean;
	showExternalSort?: boolean;
	sortColumn?: string;
	sortPlaceholder?: string;
	searchColumn?: string;
	searchPlaceholder?: string;
	showSelectedInfo?: boolean;
	totalSelectedRows?: number;
	handleEdit?: (selected: any) => void;
	handleDelete?: (selected: any) => void;
	hasWritePermission?: boolean;
	isLoading?: boolean;
	maxHeight?: string;
};

const Table: React.FC<Props> = ({ initialData: data, columns, isLoading = false, ...rest }) => {
	const tableContainerRef = useRef(null);
	const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
	const [sort, setSort] = useState('');

	const table = useReactTable({
		data,
		columns,
		filterFns: {
			customTableFilter: customTableFilter,
		},
		state: {
			columnFilters,
		},
		onColumnFiltersChange: setColumnFilters,
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getFacetedRowModel: getFacetedRowModel(),
		getFacetedUniqueValues: getFacetedUniqueValues(),
		getFacetedMinMaxValues: getFacetedMinMaxValues(),
		debugTable: false,
		debugHeaders: false,
		debugColumns: false,
	});

	useEffect(() => {
		if (table.getState().columnFilters[0]?.id === rest.sortColumn) {
			if (table.getState().sorting[0]?.id !== rest.sortColumn) {
				table.setSorting([{ id: rest.sortColumn, desc: false }]);
			}
		}
	}, [table.getState().columnFilters[0]?.id]);

	const handleChangeSort = (e) => {
		setSort(e.target.value);
	};

	useEffect(() => {
		if (sort === 'de A a Z') {
			table.setSorting([{ id: rest.sortColumn, desc: false }]);
		} else if (sort === 'de Z a A') {
			table.setSorting([{ id: rest.sortColumn, desc: true }]);
		} else {
			table.setSorting([]);
		}
	}, [sort]);

	useLayoutEffect(() => {
		if (tableContainerRef?.current && rest.maxHeight) {
			tableContainerRef.current.style.setProperty('--container-max-height', rest.maxHeight);
		}
	}, [rest.maxHeight]);

	if (!isLoading) {
		return (
			<div className={styles.tableExternalContainer}>
				{rest.showHeader && (
					<TableHeader
						table={table}
						showExternalSort={rest.showExternalSort}
						handleChangeSort={handleChangeSort}
						sortPlaceholder={rest.sortPlaceholder}
						searchColumn={rest.searchColumn}
						searchPlaceholder={rest.searchPlaceholder}
						showSelectedInfo={rest.showSelectedInfo}
						totalRows={data.length}
						totalSelectedRows={rest.totalSelectedRows}
						handleEdit={rest.handleEdit}
						handleDelete={rest.handleDelete}
						hasWritePermission={rest.hasWritePermission}
					/>
				)}
				<div ref={tableContainerRef} className={styles.tableContainer}>
					<TableGrid headers={table.getFlatHeaders()} rows={table.getRowModel()} isLoading={isLoading} />
				</div>
				{rest.showFooter && <TableFooter table={table} display={true} />}
			</div>
		);
	}

	return <></>;
};

export default Table;
