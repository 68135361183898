export const timeOptions: { [key: string]: number[] } = {
	checkInLimit: [5, 10, 15, 20, 25, 30],
	extensionTime: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60],
	extensionWindow: [5, 10, 15, 20, 25, 30],
	notificationTime: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60],
};

export const timeUnitOptions = [
	{
		value: 'minutes',
		label: 'label_minutes',
	},
];
