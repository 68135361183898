import { ModifiedVehicleReservation, ReservationItem } from '../../../../../_api/services/parking/types';
import _ from 'lodash';

export const getUpdatedVehicleReservations = (
	reservations: ReservationItem[],
	reservationId: number,
	vehicleId: number,
) => {
	return reservations.map((reservation) => {
		if (reservation.reservationId === reservationId) {
			return {
				...reservation,
				vehicles: reservation.vehicles.map((vehicle) => ({
					...vehicle,
					selected: vehicle.vehicleId === vehicleId,
				})),
			};
		} else {
			return reservation;
		}
	});
};

export const getModifiedObject = (
	currentData: ReservationItem[],
	initialData: ReservationItem[],
): ModifiedVehicleReservation[] => {
	const objectsModified = currentData
		.filter((object, index) => {
			const objectOriginal = initialData[index];
			return !_.isEqual(objectOriginal, object);
		})
		.map((r) => {
			return { reservationId: r.reservationId, vehicleId: r.vehicles.find((v) => v.selected)?.vehicleId };
		});
	return objectsModified;
};
