import { Reorder, ReorderGroup } from '@acciona/ui-ionic-kit';
import styles from './styles.module.scss';
import { IonIcon, IonItem, IonLabel, IonText } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Toggle } from '../Toggle/Toggle';
import { checkIfServiceIsConfigured } from '../WarningNotConfiguredService/helpers';
import WarningNotConfiguredService from '../WarningNotConfiguredService/WarningNotConfiguredService';

type Props = {
	hasWritePermission?: boolean;
	sort?: boolean;
	onItemReorder?: (e: any) => void;
	onItemToggle?: (id: string) => void;
	items?: any[];
	selectedLang?: string;
};
const SortAndToggleList: React.FC<Props> = ({
	hasWritePermission,
	sort,
	onItemReorder = () => {},
	onItemToggle = () => {},
	items,
	selectedLang,
}) => {
	const { t } = useTranslation();
	return (
		<div className={styles.wrapper}>
			<ReorderGroup disabled={!hasWritePermission || !sort} onIonItemReorder={(e) => onItemReorder(e)}>
				{!_.isEmpty(items) &&
					_.sortBy(items, 'order')
						?.filter((e) => !e.disabled)
						.map((item) => {
							const itemData =
								item.translations.find((translation) => translation.language === selectedLang) ||
								item.translations[0];
							return sort ? (
								<Reorder key={item.id}>
									<IonItem>
										<IonLabel className={`ion-text-wrap`}>
											<IonText className={`${styles.body} ion-text-wrap block ${styles.itemTitle}`}>
												{itemData.text}
											</IonText>
											<IonText
												className={`${styles.footnote} ion-text-wrap block ${styles.itemStatus} ${
													item.active ? styles.itemActive : ''
												}`}
											>
												{t(item.active ? 'lbl_active' : 'lbl_inactive')}
											</IonText>
										</IonLabel>
										{hasWritePermission && <IonIcon slot="end" className="icon icon-reordenar" />}
									</IonItem>
								</Reorder>
							) : (
								<IonItem key={item.id}>
									<IonLabel className={`ion-text-wrap`}>
										<IonText className={`${styles.body} ion-text-wrap block ${styles.itemTitle}`}>
											{itemData.text}
										</IonText>
										<IonText
											className={`${styles.footnote} ion-text-wrap block ${styles.itemStatus} ${
												item.active ? styles.itemActive : ''
											}`}
										>
											{t(item.active ? 'lbl_active' : 'lbl_inactive')}
										</IonText>
									</IonLabel>
									<WarningNotConfiguredService item={item} hasWritePermission={hasWritePermission} />
									{hasWritePermission && (
										<Toggle
											checked={item.active}
											onChange={() => {
												onItemToggle(item.id);
											}}
											disabled={!checkIfServiceIsConfigured(item)}
										/>
									)}
								</IonItem>
							);
						})}
			</ReorderGroup>
		</div>
	);
};

export default SortAndToggleList;
