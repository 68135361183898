import { Button, Icon, Modal, MultipleDaysCalendar } from '@acciona/ui-ionic-kit';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styles from './styles.module.scss';
import { IonContent, IonFooter, IonLabel, IonTitle, IonToolbar } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import AppContext from '../../context/App/App.context';
import { LocaleString } from '@acciona/ui-ionic-kit/lib/types/shared';
interface Props {
	isOpen: boolean;
	handleClose: () => void;
	handleSave: (days: any) => void;
	className?: string;
	selectedDays: Date[];
}
export const SelectHolidaysModal: FC<Props> = ({ isOpen, handleClose, className = '', handleSave, selectedDays }) => {
	const { t } = useTranslation();
	const { userLanguage, sedePortal } = useContext(AppContext);
	const [days, setDays] = useState(selectedDays);
	const [mounted, setMounted] = useState(false);

	const onSaveClick = () => {
		handleSave(days);
		handleClose();
	};

	useEffect(() => {
		setDays(selectedDays);
	}, [selectedDays]);

	const handleSelectedDate = useCallback((date) => {
		setDays(date);
	}, []);

	const saveDisabled = useMemo(() => {
		return selectedDays.every((day) => days.includes(day)) && days.every((day) => selectedDays.includes(day));
	}, [days, selectedDays]);

	return (
		<Modal
			isOpen={isOpen}
			onDidDismiss={handleClose}
			className={`${styles.modal} ${className}`}
			onIonModalDidPresent={() => setMounted(true)}
			onIonModalDidDismiss={() => {
				setDays(selectedDays);
				setMounted(false);
			}}
		>
			<IonToolbar>
				<Button
					slot="end"
					fill={'clear'}
					color="light"
					className={styles.closeModalBtn}
					onClick={() => handleClose()}
				>
					<IonLabel className="dark">
						<Icon className="icon icon-close icon24"></Icon>
					</IonLabel>
				</Button>
				<IonTitle className="centerTitle">
					<h3>
						<b>{t('lbl_select_holidays')}</b>
					</h3>
				</IonTitle>
			</IonToolbar>
			<IonContent>
				<div className={styles.selectedText}>
					<p>{t('lbl_select_holiday', { sede: sedePortal.description })}</p>
					<p>
						{t('lbl_selected')}: {days.length}
					</p>
				</div>
				{mounted && (
					<div className={styles.calendar}>
						<MultipleDaysCalendar
							locale={userLanguage.code as LocaleString}
							selectedDates={selectedDays}
							selectedDate={(date) => handleSelectedDate(date)}
							moreModifiers={{
								disabled: (date: Date) => [0, 6].includes(dayjs(date).get('day')),
							}}
							moreModifiersClassNames={{ disabled: '-disabled' }}
						/>
					</div>
				)}
			</IonContent>
			<IonFooter className={styles.btnModal}>
				<div>
					<Button className={`${styles.btnHeader} ${styles.secondaryBtn}`} onClick={handleClose}>
						{t('btn_cancel')}
					</Button>
				</div>
				<div>
					<Button color="primary" disabled={saveDisabled} className={styles.btnHeader} onClick={onSaveClick}>
						{t('btn_save')}
					</Button>
				</div>
			</IonFooter>
		</Modal>
	);
};
