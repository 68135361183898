import React, { useEffect, useState } from 'react';
import { es } from 'date-fns/locale';
import { DateRangePickerCalendar } from 'react-nice-dates';
import 'react-nice-dates/build/style.css';
import { RangeCalendarProps } from './types';
import { startOfDay } from '../../utils/functions';
import './override-calendar-styles.css';

export const RangeCalendar: React.FC<RangeCalendarProps> = (props: RangeCalendarProps): any => {
	const [focus, setFocus] = useState<any>('startDate');
	const [mounted, setMounted] = useState(false);
	const [dates, setDates] = useState({ startDate: null, endDate: null });

	useEffect(() => {
		setDates({ startDate: props.startDate, endDate: props.endDate });
	}, []);

	const handleDayClick = (inputDate: any) => {
		const newDate = startOfDay(inputDate);

		let newStartDate = dates.startDate;
		let newEndDate = dates.endDate;

		if ((!dates.startDate && !dates.endDate) || dates.startDate !== dates.endDate) {
			newStartDate = newDate;
			newEndDate = newDate;
		} else if (newDate > dates.endDate) {
			newStartDate = dates.startDate;
			newEndDate = newDate;
		} else {
			newStartDate = newDate;
			newEndDate = dates.endDate;
		}

		setDates({
			startDate: newStartDate,
			endDate: newEndDate,
		});

		props.onSelectDates({ startDate: newStartDate, endDate: newEndDate });
	};

	const handleFocusChange = (newFocus: any) => {
		setFocus(newFocus || 'startDate');
	};

	useEffect(() => {
		const mount = setTimeout(() => setMounted(true), 300);
		return () => {
			clearTimeout(mount);
		};
	}, [mounted]);

	return (
		<div>
			{mounted && (
				<DateRangePickerCalendar
					focus={focus}
					startDate={dates.startDate}
					endDate={dates.endDate}
					onStartDateChange={handleDayClick}
					onEndDateChange={handleDayClick}
					minimumDate={props.minimumDate ?? new Date()}
					maximumDate={props.maximumDate}
					locale={es}
					onFocusChange={handleFocusChange}
				/>
			)}
		</div>
	);
};
