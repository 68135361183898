import { useMemo, useRef } from 'react';
import { IonRadioGroup, IonRadio } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { Item } from '@acciona/ui-ionic-kit';
import dayjs from 'dayjs';
import { RESERVATIONS_SCHEDULES } from '../../../../../utils/constants';
import styles from './styles.module.scss';

type Props = {
	reservationId: number;
	date: string;
	schedule: string;
	selectedVehicle: any;
	userVehicles: any[];
	reservationType: 'reservation' | 'request';
	onChange: (reservationId: number, vehicleId: number) => void;
};

export const EditReservationItem = (props: Props) => {
	const { reservationId, date, schedule, selectedVehicle, userVehicles, reservationType, onChange } = props;
	const { t } = useTranslation();
	const isOrdered = useRef(false);

	const textHeader =
		reservationType === 'reservation' ? t('reservation_vehicle_for_day') : t('request_vehicle_for_day');

	const onChangeHandler = (e: any) => {
		onChange(reservationId, e.target.value);
	};

	const reservationSchedule = RESERVATIONS_SCHEDULES[schedule] ? t(RESERVATIONS_SCHEDULES[schedule]) : null;

	const sortedVehicles = useMemo(() => {
		if (isOrdered.current) {
			return userVehicles;
		} else {
			isOrdered.current = true;
			return structuredClone(userVehicles).sort((a, b) => b.selected - a.selected);
		}
	}, [userVehicles]);

	return (
		<div className={styles.reservationContainer}>
			<p>
				{textHeader}
				<b>
					{` ${dayjs(date).format('DD/MM/YYYY')}`}
					{reservationSchedule ? ` (${reservationSchedule})` : ''}
				</b>
				.
			</p>
			<IonRadioGroup
				className={styles.reservationVehicles}
				value={selectedVehicle.vehicleId}
				onIonChange={onChangeHandler}
			>
				{sortedVehicles.map((vehicle: any) => (
					<Item key={vehicle.vehicleId} lines="none" mode="md" type="button">
						<IonRadio
							mode="md"
							color="primary"
							slot="start"
							value={vehicle.vehicleId}
							aria-label={`${date} ${vehicle.brand} ${vehicle.model}`}
							labelPlacement="end"
						>{`${vehicle.brand} ${vehicle.model} (${vehicle.plate})`}</IonRadio>
					</Item>
				))}
			</IonRadioGroup>
		</div>
	);
};
