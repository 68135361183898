import { Button, List } from '@acciona/ui-ionic-kit';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { IonItem, IonLabel, IonLoading, IonSelect, IonSelectOption } from '@ionic/react';
import { HourSelector } from '../../../components/HourSelector/HourSelector';
import ReactQuill from 'react-quill';
import { DEFAULT_REACT_QUILL_EDITOR_CONFIG } from '../../../utils/constants';
import { NotificationsProps, PkNotificationsConfig } from './types';
import { useEffect, useMemo, useState } from 'react';
import { MINUTES_OPTIONS, defaultNotificationsConfig } from './helpers';
import _ from 'lodash';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import useAppContext from '../../../hooks/useAppContext';
import { parkingService } from '../../../_api/services/parking';
import { LanguageSelector } from '../../../components/LanguageSelector/LanguageSelector';
import { Toggle } from '../../../components/Toggle/Toggle';
import { NumberInput } from '../../../components/NumberInput/NumberInput';

export const Notifications: React.FC<NotificationsProps> = (props) => {
	const { t } = useTranslation();
	const { setError, setSuccess, hasWritePermission } = props;
	const [notificationsConfig, setNotificationsConfig] = useState<PkNotificationsConfig>(defaultNotificationsConfig);
	const [isEdited, setIsEdited] = useState(false);
	const queryClient = useQueryClient();
	const { setThereAreUnsavedChanges } = useAppContext();
	const [selectedLang, setSelectedLang] = useState('es');
	const [changeQuill, setChangeQuill] = useState(false);
	useEffect(() => {
		setThereAreUnsavedChanges(isEdited && hasWritePermission);
	}, [isEdited, hasWritePermission]);

	useEffect(() => {
		setIsEdited(!_.isEqual(notificationsConfig, initialValues));
	}, [notificationsConfig]);

	const { data: initialValues, isLoading: isLoading } = useQuery(
		['PkNotificationsConfig'],
		async () => parkingService.getPkNotificationConfig(),
		{
			refetchOnWindowFocus: false,
			onError: (error) => setError(error as string),
			onSuccess: (data) => {
				setNotificationsConfig(data);
			},
		},
	);

	const { isLoading: loadingSave, mutate: handleSave } = useMutation(parkingService.updatePkNotificationConfig, {
		onSuccess: () => {
			setSuccess(t('msg_success'));
			queryClient.refetchQueries('PkNotificationsConfig');
		},
		onError: (error) => {
			setError(error as string);
		},
	});

	const emailBody = useMemo(() => {
		return notificationsConfig.emailTranslations.find((translate) => translate.language === selectedLang)?.body;
	}, [notificationsConfig.emailTranslations, selectedLang]);
	const checkinBody = useMemo(() => {
		return notificationsConfig.checkinTranslations.find((translate) => translate.language === selectedLang)?.body;
	}, [notificationsConfig.checkinTranslations, selectedLang]);

	const isTranslationInvalid = (translation: string): boolean => {
		return _.isEmpty(translation) || translation === '<p><br></p>';
	};

	// Handlers
	const handleInputChange = (e) => {
		const fieldName = e.target.name;
		const value = String(e.target.value); //Sometimes value is number if click with mouse instead of keyboard
		setNotificationsConfig((prevValues) => {
			const nextValues = _.cloneDeep(prevValues);
			return {
				...nextValues,
				[fieldName]: value,
			};
		});
	};

	const handleToggleChange = (fieldName: string) => {
		setNotificationsConfig((prevValues) => {
			const nextValues = _.cloneDeep(prevValues);
			return {
				...nextValues,
				[fieldName]: !nextValues[fieldName],
			};
		});
	};

	const handleEmailTextChange = (e) => {
		if (changeQuill) {
			const newTranslation = notificationsConfig.emailTranslations.map((translation) =>
				translation.language === selectedLang ? { ...translation, body: e } : translation,
			);
			const newConfig = { ...notificationsConfig, emailTranslations: newTranslation };
			setNotificationsConfig(newConfig);
		}
	};
	const handleCheckinEmailTextChange = (e) => {
		if (changeQuill) {
			const newTranslation = notificationsConfig.checkinTranslations.map((translation) =>
				translation.language === selectedLang ? { ...translation, body: e } : translation,
			);
			const newConfig = { ...notificationsConfig, checkinTranslations: newTranslation };
			setNotificationsConfig(newConfig);
		}
	};
	const completeAllTranslations = (config: PkNotificationsConfig): PkNotificationsConfig => {
		const isAnyBodyEmpty =
			config.emailTranslations.some(
				(translation) => translation.body.trim() === '' || translation.body === '<p><br></p>',
			) ||
			config.checkinTranslations.some(
				(translation) => translation.body.trim() === '' || translation.body === '<p><br></p>',
			);
		const translationForAll = config.emailTranslations.find((t) => t.language === selectedLang)?.body;
		const checkinTranslationForAll = config.checkinTranslations.find((t) => t.language === selectedLang)?.body;
		if (isAnyBodyEmpty && !_.isEmpty(translationForAll) && !_.isEmpty(checkinTranslationForAll)) {
			const newTranslation = config.emailTranslations.map((translation) =>
				translation.body === '' || translation.body === '<p><br></p>'
					? { ...translation, body: translationForAll }
					: translation,
			);
			const newCheckinTranslation = config.checkinTranslations.map((translation) =>
				translation.body === '' || translation.body === '<p><br></p>'
					? { ...translation, body: checkinTranslationForAll }
					: translation,
			);
			const newConfig = {
				...notificationsConfig,
				emailTranslations: newTranslation,
				checkinTranslations: newCheckinTranslation,
			};
			return newConfig;
		}
		return config;
	};
	const handleSaveConfig = () => {
		handleSave(completeAllTranslations(notificationsConfig));
		setIsEdited(false);
	};
	return (
		<>
			{isLoading ? (
				<IonLoading isOpen={isLoading || loadingSave} message={t('msg_loading')} duration={0} />
			) : (
				<>
					<LanguageSelector selectedLang={selectedLang} setSelectedLang={setSelectedLang} />
					<List>
						{/* CHECK-IN */}
						<header className={`${styles.h2} ${styles.headerSpace}`}>
							{t('title_pk_notifications_checkin')}
						</header>
						{/* DESCRIPTION */}
						<div className={`${styles.element} ${styles.toggles}`}>
							<IonLabel className={`${styles.footnote}`}>{t('lbl_pk_notifications_desc')}</IonLabel>
						</div>
						{/* CHECKIN TOGGLES */}
						<div className={styles.element}>
							<div className={`${styles.h3} ${styles.space}`}>{t('lbl_enable_notifications')}</div>
							<div className={`${styles.footnote} ${styles.space}`}>{t('lbl_checkin_toggles_description')}</div>
							<div className={styles.checkinToggles}>
								<div className={styles.toggleItem}>
									<Toggle
										checked={notificationsConfig.permanentReservations}
										onChange={() => handleToggleChange('permanentReservations')}
										disabled={!hasWritePermission}
									/>
									<IonLabel className={`${styles.footnote} ${styles.space}`}>
										{t('lbl_pk_notifications_toggles.permanent')}
									</IonLabel>
								</div>
								<div className={styles.toggleItem}>
									<Toggle
										checked={notificationsConfig.reservations}
										onChange={() => handleToggleChange('reservations')}
										disabled={!hasWritePermission}
									/>
									<IonLabel className={`${styles.footnote} ${styles.space}`}>
										{t('lbl_pk_notifications_toggles.reservations')}
									</IonLabel>
								</div>
							</div>
						</div>
						{/* CHECKIN NOTIFICATIONS BEFORE SEND MAIL*/}
						<div className={styles.element}>
							<div className={`${styles.h3} ${styles.space}`}>{t('lbl_checkin_notifications_title')}</div>
							<div className={`${styles.footnote} ${styles.space}`}>
								{t('lbl_pk_notifications_checkinBeforeMail_description')}
							</div>
							<NumberInput
								name="notificationsBeforeMail"
								className={`ion-text-end`}
								value={+notificationsConfig.notificationsBeforeMail}
								onIonChange={handleInputChange}
								min={0}
								disabled={!hasWritePermission}
								aria-label={t('lbl_pk_notifications_number_notifications')}
								label={t('lbl_pk_notifications_number_notifications')}
								required
							></NumberInput>
						</div>
						{/* CHECKIN EMAIL BODY */}
						<div className={styles.element}>
							<div className={`${styles.footnote} ${styles.space}`}>
								{t('lbl_pk_notifications_checkinMailBody')}
							</div>
							<ReactQuill
								id="pkCheckinMailNotificationBody"
								theme="snow"
								modules={DEFAULT_REACT_QUILL_EDITOR_CONFIG}
								className={styles.quill}
								value={checkinBody}
								onChange={handleCheckinEmailTextChange}
								onFocus={() => setChangeQuill(true)}
								readOnly={!hasWritePermission}
								preserveWhitespace={true}
							/>
						</div>
						{/* END CHECK-IN */}
						<header className={`${styles.h2} ${styles.headerSpace}`}>{t('title_pk_notifications')}</header>
						{/* TOGGLES */}
						<div className={`${styles.element} ${styles.toggles}`}>
							<div className={styles.toggleItem}>
								<Toggle
									checked={notificationsConfig.sendNotificationEnabled}
									onChange={() => handleToggleChange('sendNotificationEnabled')}
									disabled={!hasWritePermission}
								/>
								<IonLabel className={`${styles.footnote} ${styles.space}`}>
									{t('lbl_pk_notifications_toggles.notification')}
								</IonLabel>
							</div>
							<div className={styles.toggleItem}>
								<Toggle
									checked={notificationsConfig.sendEmailEnabled}
									onChange={() => handleToggleChange('sendEmailEnabled')}
									disabled={!hasWritePermission}
								/>
								<IonLabel className={`${styles.footnote} ${styles.space}`}>
									{t('lbl_pk_notifications_toggles.email')}
								</IonLabel>
							</div>
						</div>
						{/* MINUTES BEFORE NOTIFICATION*/}
						<div className={styles.element}>
							<div className={`${styles.footnote} ${styles.space}`}>
								{t('lbl_pk_notifications_minutesBeforeEnd.notification')}
							</div>
							<IonItem
								lines="none"
								className={`${styles.inputModal} ${
									notificationsConfig.minutesBeforeEndNotification !==
										defaultNotificationsConfig.minutesBeforeEndNotification && styles.touchedInput
								}`}
								disabled={!hasWritePermission || !notificationsConfig.sendNotificationEnabled}
							>
								<IonLabel slot="start" className="lblSelector">
									{t('label_minutes')}
								</IonLabel>
								<IonSelect
									name="minutesBeforeEndNotification"
									slot="end"
									className={`lblSelector ${styles.selector}`}
									interface="popover"
									value={notificationsConfig.minutesBeforeEndNotification}
									onIonChange={handleInputChange}
									aria-label={t('label_minutes')}
								>
									{MINUTES_OPTIONS.map((option, index) => {
										return (
											<IonSelectOption key={index} value={option}>
												{option}
											</IonSelectOption>
										);
									})}
								</IonSelect>
							</IonItem>
						</div>
						{/* MINUTES BEFORE EMAIL*/}
						<div className={styles.element}>
							<div className={`${styles.footnote} ${styles.space}`}>
								{t('lbl_pk_notifications_minutesBeforeEnd.email')}
							</div>
							<IonItem
								lines="none"
								className={`${styles.inputModal} ${
									notificationsConfig.minutesBeforeEndEmail !==
										defaultNotificationsConfig.minutesBeforeEndEmail && styles.touchedInput
								}`}
								disabled={!hasWritePermission || !notificationsConfig.sendEmailEnabled}
							>
								<IonLabel slot="start" className="lblSelector">
									{t('label_minutes')}
								</IonLabel>
								<IonSelect
									name="minutesBeforeEndEmail"
									slot="end"
									className={`lblSelector ${styles.selector}`}
									interface="popover"
									value={notificationsConfig.minutesBeforeEndEmail}
									onIonChange={handleInputChange}
									aria-label={t('label_minutes')}
								>
									{MINUTES_OPTIONS.map((option, index) => {
										return (
											<IonSelectOption key={index} value={option}>
												{option}
											</IonSelectOption>
										);
									})}
								</IonSelect>
							</IonItem>
						</div>
						{/* DO NO SEND AFTER */}
						<div className={styles.element}>
							<div className={styles.blockSubtitle}>{t('lbl_pk_notifications_doNotSendAfter')}</div>
							<HourSelector
								name="doNotSendAfter"
								label={t('lbl_hour_selector')}
								value={notificationsConfig.doNotSendAfter}
								disabled={
									!hasWritePermission ||
									(!notificationsConfig.sendEmailEnabled && !notificationsConfig.sendNotificationEnabled)
								}
								onChange={handleInputChange}
							/>
						</div>
						{/* EMAIL BODY */}
						<div className={styles.element}>
							<div className={`${styles.footnote} ${styles.space}`}>{t('lbl_pk_notifications_emailBody')}</div>
							<ReactQuill
								id="pkMailNotificationBody"
								theme="snow"
								modules={DEFAULT_REACT_QUILL_EDITOR_CONFIG}
								className={styles.quill}
								value={emailBody}
								onChange={() => handleEmailTextChange}
								onFocus={() => setChangeQuill(true)}
								readOnly={!hasWritePermission}
								preserveWhitespace={true}
							/>
						</div>
					</List>
					{/* -------- FOOTER -------------------------------------------------------- */}
					{hasWritePermission && (
						<div className={`${props.footerStyle} ${styles.footerButton}`}>
							<Button
								onClick={handleSaveConfig}
								className={styles.btnHeader}
								disabled={
									!isEdited ||
									isTranslationInvalid(
										notificationsConfig.emailTranslations.find(
											(translate) => translate.language === selectedLang,
										)?.body,
									) ||
									isTranslationInvalid(
										notificationsConfig.checkinTranslations.find(
											(translate) => translate.language === selectedLang,
										)?.body,
									)
								}
								color="primary"
							>
								{t('btn_save_data')}
							</Button>
						</div>
					)}
				</>
			)}
		</>
	);
};
