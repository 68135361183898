import { useTranslation } from 'react-i18next';
import { Button, Icon } from '@acciona/ui-ionic-kit';
import styles from './styles.module.scss';

export const OrganizersAndHosts = ({
	id: organizerId,
	hosts,
	organizer,
	onClickOrganizer,
	onClickHosts,
	onDelete,
	hasWritePermission,
}) => {
	const { t } = useTranslation();
	return (
		<div className={styles.hostOrganizerContainer}>
			<div className={styles.input} onClick={() => onClickOrganizer(organizerId)}>
				<div className={styles.body}>Organizador</div>
				<div className={styles.align}>
					<div className={`${styles.description} ${styles.body} ${!organizer && styles.color_dark}`}>
						{organizer ? organizer : t('lbl_select')}
					</div>
					<Icon className="icon icon-chevron-right icon24" slot="end" />
				</div>
			</div>
			<div className={styles.input} onClick={() => onClickHosts(organizerId)}>
				<div className={styles.body}>Anfitrión</div>
				<div className={styles.align}>
					<div className={`${styles.body} ${hosts.length > 0 && styles.color_dark}`}>
						{hosts.length > 0
							? t('lbl_total_select', {
									total: hosts.length,
							  })
							: t('lbl_select')}
					</div>
					<Icon className="icon icon-chevron-right icon24" slot="end" />
				</div>
			</div>
			{hasWritePermission && (
				<Button fill="clear" onClick={() => onDelete(organizerId)} className={styles.buttonDelete}>
					<Icon className={`icon icon-delete icon24`} />
				</Button>
			)}
		</div>
	);
};
