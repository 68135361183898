const getLocalRefreshToken = () => {
	const account = JSON.parse(localStorage.getItem('account'));
	return account?.refresh_token;
};

const getLocalAccessToken = () => {
	const account = JSON.parse(localStorage.getItem('account'));
	return account?.access_token;
};
const getLocalIdToken = () => {
	const account = JSON.parse(localStorage.getItem('account'));
	return account?.id_token;
};

const updateLocalTokens = (idToken, accessToken) => {
	const account = JSON.parse(localStorage.getItem('account'));
	account.access_token = accessToken;
	account.id_token = idToken;
	localStorage.setItem('account', JSON.stringify(account));
};

const getAccount = () => {
	return JSON.parse(localStorage.getItem('account'));
};

const getUser = () => {
	return JSON.parse(localStorage.getItem('user'));
};

const setAccount = (token, user) => {
	localStorage.setItem('account', JSON.stringify(token));
	localStorage.setItem('user', JSON.stringify(user));
};

const removeAccount = () => {
	localStorage.removeItem('account');
	localStorage.removeItem('user');
};

export const tokenServices = {
	getLocalRefreshToken,
	getLocalAccessToken,
	getLocalIdToken,
	updateLocalTokens,
	getAccount,
	getUser,
	setAccount,
	removeAccount,
};
