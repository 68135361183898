import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Toast } from '@acciona/ui-ionic-kit';
import { IonLabel } from '@ionic/react';
import useAppContext from '../../../../hooks/useAppContext';
import { usePermissions } from '../../../../hooks/usePermissions';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import _ from 'lodash';
import { AppPermissionsContext } from '../../../../context/AppPermissions/AppPermissions.context';
import { AppPermissionsActions } from '../../../../context/AppPermissions/AppPermissions.actions';
import { AppPermission, Employee } from '../../../../_api/services/roles/types';
import Table from '../../../../components/Table/Table';
import { IndeterminateCheckbox } from '../../../../components/Table/IndeterminateCheckbox';
import { ActionIcon } from '../../../../components/Table/ActionIcon/ActionIcon';
import { ModalAddUsers } from '../ModalAddUsers/ModalAddUsers';
import { ModalEditUsers } from '../ModalEditUsers/ModalEditUsers';
import { ModalDeleteUsers } from '../ModalDeleteUsers/ModalDeleteUsers';
import {
	getNewAllSelectedRows,
	getNewSelectedRows,
	allRowsAreSelected,
	rowIsSelected,
	customTableFilter,
} from '../../../../utils/table';
import { FUNCTIONALITIES } from '../../../../utils/constants';
import { getUpdatedUsersData, getPlannedDate } from '../../helpers';
import styles from './styles.module.scss';

type Props = {
	permission: AppPermission;
	disabled?: boolean;
};

export const UsersPermissionsSection: React.FC<Props> = (props) => {
	const { permission, disabled = false } = props;
	const { state, dispatch } = useContext(AppPermissionsContext);
	const { t } = useTranslation();
	const { permissions: userHasPermissions } = usePermissions(FUNCTIONALITIES.admin, 'WRITE');
	const { userLanguage } = useAppContext();
	const columnHelper = createColumnHelper<Employee>();
	const [selectedRows, setSelectedRows] = useState<Employee[]>([]);
	const [showModalAddUsers, setShowModalAddUsers] = useState(false);
	const [showModalEditUsers, setShowModalEditUsers] = useState(false);
	const [showModalDeleteUsers, setShowModalDeleteUsers] = useState(false);
	const [toastMessage, setToastMessage] = useState('');

	const currentUsersIds = useMemo(() => {
		if (_.isEmpty(state.usersData)) {
			return [];
		}
		return state.usersData.map((u) => u.employeeId);
	}, [state.usersData]);

	const handleAddUsers = () => {
		setShowModalAddUsers(true);
	};

	const handleEditUser = (selected: Employee = null) => {
		if (selected?.employeeId) {
			setSelectedRows([selected]);
			dispatch(AppPermissionsActions.setUserPermissions(selected?.permissionType));
			dispatch(AppPermissionsActions.setStartDate(selected?.startDate ? new Date(selected?.startDate) : null));
			dispatch(AppPermissionsActions.setEndDate(selected?.endDate ? new Date(selected?.endDate) : null));
		}
		setShowModalEditUsers(true);
	};
	const handleDeleteUser = (selected: Employee = null) => {
		if (selected?.employeeId) {
			setSelectedRows([selected]);
		}
		setShowModalDeleteUsers(true);
	};

	const handleConfirmAddUsers = (newUsers: Employee[]) => {
		const newUsersData = getUpdatedUsersData(newUsers);
		dispatch(AppPermissionsActions.addUsers(newUsersData));
		setToastMessage(t('app_permissions.toast.add_edit_success'));
		setShowModalAddUsers(false);
	};

	const handleConfirmEditUsers = (updatedUsers: Employee[]) => {
		const newUsersData = getUpdatedUsersData(updatedUsers);
		dispatch(AppPermissionsActions.updateUsers(newUsersData));
		setShowModalEditUsers(false);
	};

	const handleConfirmDeleteUsers = () => {
		const usersIds = selectedRows.map((row) => row.employeeId);
		dispatch(AppPermissionsActions.deleteUsers(usersIds));
		setSelectedRows([]);
		setShowModalDeleteUsers(false);
		setToastMessage(t('app_permissions.toast.delete_success'));
	};

	const onCloseModalDeleteUsersHandler = () => {
		setShowModalDeleteUsers(false);
		if (selectedRows.length === 1) {
			setSelectedRows([]);
		}
	};

	const onCloseModalEditUsersHandler = () => {
		setShowModalEditUsers(false);
		if (selectedRows.length === 1) {
			setSelectedRows([]);
		}
	};

	const onCloseModalAddUsersHandler = () => {
		setShowModalAddUsers(false);
	};

	const selectAllRowsInPageHandler = (currentPageRows) => {
		setSelectedRows((prev) => getNewAllSelectedRows(prev, currentPageRows, 'employeeId'));
	};

	const selectRowHandler = (rowSelected) => {
		setSelectedRows((prev) => getNewSelectedRows(prev, rowSelected, 'employeeId'));
	};

	const columns = useMemo<ColumnDef<Employee, any>[]>(
		() => [
			{
				id: 'select',
				header: ({ table }) => {
					const currentPageRows = table.getRowModel().rows.map((row) => row.original);

					return (
						<div>
							<IndeterminateCheckbox
								indeterminate={table.getIsSomeRowsSelected()}
								checked={allRowsAreSelected(currentPageRows, selectedRows, 'employeeId')}
								onChange={() => selectAllRowsInPageHandler(currentPageRows)}
								disabled={!userHasPermissions.WRITE}
							/>
						</div>
					);
				},
				cell: ({ row }) => (
					<div>
						<IndeterminateCheckbox
							indeterminate={row.getIsSomeSelected()}
							checked={rowIsSelected(selectedRows, row.original, 'employeeId')}
							onChange={() => selectRowHandler(row.original)}
							disabled={!userHasPermissions.WRITE}
						/>
					</div>
				),
				size: 15,
				enableSorting: false,
			},
			{
				id: 'fullName',
				accessorKey: 'fullName',
				header: () => t('table_header_fullname'),
				cell: ({ row }) => `${row.original.employeeId} - ${row.original.fullName}`,
				size: 100,
				enableSorting: true,
				enableColumnFilter: true,
				filterFn: customTableFilter,
			},
			columnHelper.accessor('profile', {
				header: () => t('table_header_profile'),
				cell: (info) => info.getValue() || '',
				size: 100,
				enableSorting: true,
			}),
			{
				id: 'plannedDate',
				accessorKey: 'permissionType',
				header: () => t('table_header_planned_date'),
				cell: ({ row }) =>
					getPlannedDate(row.original.startDate, row.original.endDate, row.original.permissionType),
				size: 120,
				enableSorting: true,
			},
			{
				id: 'edit',
				cell: ({ row }) => (
					<ActionIcon
						hasPermission={userHasPermissions.WRITE}
						icon="edit"
						onClick={() => handleEditUser(row.original)}
						disabled={selectedRows.length > 0}
					/>
				),
				size: 25,
				enableSorting: false,
			},
			{
				id: 'delete',
				cell: ({ row }) => (
					<ActionIcon
						hasPermission={userHasPermissions.WRITE}
						icon="delete"
						onClick={() => handleDeleteUser(row.original)}
						disabled={selectedRows.length > 0}
					/>
				),
				size: 25,
				enableSorting: false,
			},
		],
		[selectedRows, userHasPermissions.WRITE, t],
	);

	return (
		<>
			<div className={styles.userPermissions}>
				<h3 className={styles.blockTitle}>{t(`app_permissions.individual_permission_title`)}</h3>
				<p className={styles.blockSubtitle}>{permission.individual_permission_subtitle}</p>
				{userHasPermissions.WRITE && (
					<Button slot="start" className={styles.addUserBtn} onClick={handleAddUsers} disabled={disabled}>
						<Icon className="icon icon-plus iconPlus" />
						<IonLabel>{t('app_permissions.add_user')}</IonLabel>
					</Button>
				)}
				{!_.isEmpty(state.usersData) && (
					<Table
						key={userLanguage.code}
						initialData={state.usersData}
						columns={columns}
						showHeader
						showFooter
						showSelectedInfo
						sortColumn={'fullName'}
						sortPlaceholder={t('sort')}
						searchColumn={'fullName'}
						searchPlaceholder={t('plholder_search_user')}
						totalSelectedRows={selectedRows.length}
						handleEdit={handleEditUser}
						handleDelete={handleDeleteUser}
						hasWritePermission={userHasPermissions.WRITE}
						isLoading={state.isLoading}
					/>
				)}
			</div>
			<ModalAddUsers
				showModal={showModalAddUsers}
				currentSelectedUsers={currentUsersIds}
				onClose={onCloseModalAddUsersHandler}
				onSave={handleConfirmAddUsers}
				label={state.permission.modal}
			/>
			<ModalDeleteUsers
				showModal={showModalDeleteUsers}
				selectedRows={selectedRows}
				onClose={onCloseModalDeleteUsersHandler}
				onConfirm={handleConfirmDeleteUsers}
			/>
			<ModalEditUsers
				showModal={showModalEditUsers}
				selectedUsers={selectedRows}
				onClose={onCloseModalEditUsersHandler}
				onSave={handleConfirmEditUsers}
				label={state.permission.modal}
			/>
			<Toast
				isOpen={!!toastMessage}
				message={toastMessage}
				onDidDismiss={() => setToastMessage('')}
				position="bottom"
				type="success"
			/>
		</>
	);
};
