import { Button, List } from '@acciona/ui-ionic-kit';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { NumberInput } from '../../../components/NumberInput/NumberInput';
import useAppContext from '../../../hooks/useAppContext';
import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { LotteryScores, Score } from '../../../_api/services/parking/types';
import { parkingService } from '../../../_api/services/parking';
import _ from 'lodash';
import { IonLoading } from '@ionic/react';
import { mockLotteryScores } from '../../../_api/services/parking/mockData';

type Props = {
	setError?: Function;
	setSuccess?: Function;
	setInfo?: Function;
	hasWritePermission?: boolean;
	footerStyle: string;
};
const hasEmptyValues = (formValues: LotteryScores) => {
	return Object.values(formValues).some((scores) =>
		scores.some((score) => score.points === null || score.points === undefined),
	);
};

const defaultLotteryScores: LotteryScores = mockLotteryScores;

const sortSeniorityScoresByName = (elements: Score[]): Score[] => {
	const customSortKey = (element: Score): number => {
		const numericPart = element.name.split('-')[0].split('+')[0];
		return parseInt(numericPart);
	};
	const sortedElements = elements.sort((a, b) => customSortKey(a) - customSortKey(b));
	return sortedElements;
};

export const BonusAndPenalties: React.FC<Props> = (props) => {
	const { t } = useTranslation();
	const { setThereAreUnsavedChanges } = useAppContext();
	const queryClient = useQueryClient();
	const { setError, setSuccess, hasWritePermission } = props;
	const [lotteryScores, setLotteryScores] = useState<LotteryScores>(defaultLotteryScores);

	const { isLoading: isLoading, data: initialValues } = useQuery(
		['lotteryScores'],
		async () => parkingService.getPkLotteryScores(),
		{
			refetchOnWindowFocus: false,
			onError: (error) => setError(error as string),
			onSuccess: (data) => {
				setLotteryScores(data);
			},
		},
	);
	const { isLoading: loadingSave, mutate: handleSave } = useMutation(parkingService.updatePkLotteryScores, {
		onSuccess: () => {
			setSuccess(t('msg_success'));
			queryClient.refetchQueries('lotteryScores');
		},
		onError: (error) => {
			setError(error as string);
		},
	});

	const isEdited = useMemo(() => {
		const isNotEqual = !_.isEqual(initialValues, lotteryScores);
		return isNotEqual;
	}, [initialValues, lotteryScores]);

	useEffect(() => {
		setThereAreUnsavedChanges(isEdited);
	}, [isEdited]);

	const handleInputChange = (e) => {
		const [fieldName, id] = e.target.name.split('_');
		const scoreId = +id;
		if (_.isEqual(lotteryScores, defaultLotteryScores)) {
			return;
		}
		setLotteryScores((prevValues) => {
			const nextValues = _.cloneDeep(prevValues);
			return {
				...nextValues,
				[fieldName]: nextValues[fieldName].map((score) =>
					score.id === scoreId ? { ...score, points: parseAndFormatValue(e.target.value) } : score,
				),
			};
		});
	};

	const handleOnSave = () => {
		const updatedData = _.cloneDeep(lotteryScores);
		if (hasEmptyValues(updatedData)) {
			setError(t('msg_error_all_fields_required'));
		} else {
			handleSave(updatedData);
		}
	};

	const parseAndFormatValue = (value: string): number => {
		if (!value) return 0;
		const number = parseFloat(value);
		if (Number.isInteger(number)) {
			return number;
		} else {
			return Math.round(number * 100) / 100;
		}
	};
	return (
		<>
			{' '}
			{isLoading ? (
				<IonLoading isOpen={isLoading || loadingSave} message={t('msg_loading')} duration={0} />
			) : (
				<>
					<List>
						{/* EMPLOYEE SCORE */}
						<header className={`${styles.h2} ${styles.headerSpace}`}>{t('title_bonusAndPenalties_score')}</header>
						{/* HOME OFFICE SCORE */}
						<div className={styles.element}>
							<h3 className={styles.blockTitle}>{t('lbl_bonusAndPenalties_homeOffice_title')}</h3>
							<p className={`${styles.blockSubtitle}`}>{t('lbl_bonusAndPenalties_homeOffice_desc')}</p>
							<div className={styles.multipleItemContainer}>
								{_.sortBy(lotteryScores.homeOffice, 'id').map((score) => (
									<NumberInput
										key={score.id}
										name={`homeOffice_${score.id}`}
										className={`ion-text-end`}
										value={score.points}
										onIonChange={handleInputChange}
										min={-999}
										max={999}
										disabled={!hasWritePermission}
										aria-label={t(`lbl_bonusAndPenalties_scores_indexed.${score.id}`)}
										label={t(`lbl_bonusAndPenalties_scores_indexed.${score.id}`)}
										required
									></NumberInput>
								))}
							</div>
						</div>
						{/* LEVEL SCORE */}
						<div className={styles.element}>
							<h3 className={styles.blockTitle}>{t('lbl_bonusAndPenalties_level_title')}</h3>
							<p className={`${styles.blockSubtitle}`}>{t('lbl_bonusAndPenalties_level_desc')}</p>
							<div className={styles.multipleItemContainer}>
								{_.sortBy(lotteryScores.level, 'id').map((score) => (
									<NumberInput
										key={score.id}
										name={`level_${score.id}`}
										className={`ion-text-end`}
										value={score.points}
										onIonChange={handleInputChange}
										min={-999}
										max={999}
										disabled={!hasWritePermission}
										aria-label={t(`lbl_bonusAndPenalties_scores_indexed.${score.id}`)}
										label={t(`lbl_bonusAndPenalties_scores_indexed.${score.id}`)}
										required
									></NumberInput>
								))}
							</div>
						</div>
						{/* SENIORITY SCORE */}
						<div className={styles.element}>
							<h3 className={styles.blockTitle}>{t('lbl_bonusAndPenalties_seniority_title')}</h3>
							<p className={`${styles.blockSubtitle}`}>{t('lbl_bonusAndPenalties_seniority_desc')}</p>
							<div className={styles.multipleItemContainer}>
								{sortSeniorityScoresByName(lotteryScores.seniority).map((score) => (
									<NumberInput
										key={score.id}
										name={`seniority_${score.id}`}
										className={`ion-text-end`}
										value={score.points}
										onIonChange={handleInputChange}
										min={-999}
										max={999}
										disabled={!hasWritePermission}
										aria-label={t(`lbl_bonusAndPenalties_scores_indexed.${score.id}`)}
										label={t(`lbl_bonusAndPenalties_scores_indexed.${score.id}`)}
										required
									></NumberInput>
								))}
							</div>
						</div>
						{/* RESERVATION SCORE */}
						<header className={`${styles.h2} ${styles.headerSpace}`}>
							{t('title_bonusAndPenalties_reservation_score')}
						</header>
						{/* ENVIRONMENTALBADGE SCORE */}
						<div className={styles.element}>
							<h3 className={styles.blockTitle}>{t('lbl_bonusAndPenalties_environmentalBadge_title')}</h3>
							<p className={`${styles.blockSubtitle}`}>{t('lbl_bonusAndPenalties_environmentalBadge_desc')}</p>
							<div className={styles.multipleItemContainer}>
								{_.sortBy(lotteryScores.environmentalBadge, 'id').map((score) => (
									<NumberInput
										key={score.id}
										name={`environmentalBadge_${score.id}`}
										className={`ion-text-end ${styles.overflowVisible}`}
										value={score.points}
										onIonChange={handleInputChange}
										min={-999}
										max={999}
										disabled={!hasWritePermission}
										aria-label={t(`lbl_bonusAndPenalties_scores_indexed.${score.id}`)}
										label={t(`lbl_bonusAndPenalties_scores_indexed.${score.id}`)}
										required
									></NumberInput>
								))}
							</div>
						</div>
						{/* TRANSPORTZONE SCORE */}
						<div className={styles.element}>
							<h3 className={styles.blockTitle}>{t('lbl_bonusAndPenalties_transportZone_title')}</h3>
							<p className={`${styles.blockSubtitle}`}>{t('lbl_bonusAndPenalties_transportZone_desc')}</p>
							<div className={styles.multipleItemContainer}>
								{_.sortBy(lotteryScores.transportZone, 'id').map((score) => (
									<NumberInput
										key={score.id}
										name={`transportZone_${score.id}`}
										className={`ion-text-end `}
										value={score.points}
										onIonChange={handleInputChange}
										min={-999}
										max={999}
										disabled={!hasWritePermission}
										aria-label={t(`lbl_bonusAndPenalties_scores_indexed.${score.id}`)}
										label={t(`lbl_bonusAndPenalties_scores_indexed.${score.id}`)}
										required
									></NumberInput>
								))}
							</div>
						</div>
						{/* COMPANION SCORE */}
						<div className={styles.element}>
							<h3 className={styles.blockTitle}>{t('lbl_bonusAndPenalties_companion_title')}</h3>
							<p className={`${styles.blockSubtitle}`}>{t('lbl_bonusAndPenalties_companion_desc')}</p>
							<div className={styles.containerCompanions}>
								{_.sortBy(lotteryScores.companions, 'id').map((score) => (
									<NumberInput
										key={score.id}
										name={`companions_${score.id}`}
										className={`ion-text-end ${styles.overflowVisible}`}
										value={score.points}
										onIonChange={handleInputChange}
										min={-999}
										max={999}
										disabled={!hasWritePermission}
										aria-label={t(`lbl_bonusAndPenalties_scores_indexed.${score.id}`)}
										label={t(`lbl_bonusAndPenalties_scores_indexed.${score.id}`)}
										required
									></NumberInput>
								))}
							</div>
						</div>
					</List>
					{hasWritePermission && (
						<div className={`${props.footerStyle} ${styles.footerButton}`}>
							<Button onClick={handleOnSave} className={styles.btnHeader} disabled={!isEdited} color="primary">
								{t('btn_save_data')}
							</Button>
						</div>
					)}
				</>
			)}
		</>
	);
};
