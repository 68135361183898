import { IonButton, IonLoading } from '@ionic/react';
import { LanguageSelector } from '../../../components/LanguageSelector/LanguageSelector';

import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import styles from './styles.module.scss';
import { Button, FilterChip, Icon } from '@acciona/ui-ionic-kit';

import SortAndToggleList from '../../../components/SortAndToggleList/SortAndToggleList';
import { Toggle } from '../../../components/Toggle/Toggle';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { generalServicesService } from '../../../_api/services/generalServices';
import GroupsModal from './components/GroupsModal';

import { moveItemInArrayByKey, setModificationFlag } from './components/utils';
import useAppContext from '../../../hooks/useAppContext';

type Props = {
	setError?: Function;
	setSuccess?: Function;
	setInfo?: Function;
	hasWritePermission?: boolean;
	isTabActive: boolean;
};
const ServicesGeneral: React.FC<Props> = ({ hasWritePermission, setError, setSuccess, isTabActive }) => {
	const { setThereAreUnsavedChanges } = useAppContext();
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const [sort, setSort] = useState(false);
	const [showGroupsModal, setShowGroupsModal] = useState(false);
	const [selectedLang, setSelectedLang] = useState('es');
	const [ungroupedSectionId, setUngroupedSectionId] = useState(0);
	const [selectedSection, setSelectedSection] = useState(0);
	const [isConfigDataMutated, setIsConfigDataMutated] = useState(false);

	const [fetchedData, setFetchedData] = useState([]);
	const [configData, setConfigData] = useState([]);

	const { isLoading: isLoading } = useQuery(
		['serviceConfig'],
		async () => generalServicesService.getServicesConfig(),
		{
			refetchOnWindowFocus: false,
			onError: (err: { message: string }) => {
				setError(err.message);
			},
			onSuccess: (data) => {
				setFetchedData(data);
				setConfigData(data);
			},
		},
	);

	const { isLoading: isLoadingMutate, mutate: saveToServer } = useMutation(generalServicesService.saveServicesConfig, {
		onSuccess: () => {
			setSuccess(t('msg_success'));
			queryClient.refetchQueries('serviceConfig');
		},
		onError: (err: { message: string }) => {
			setError(err.message);
		},
	});

	const checkSelectedSectionExists = () => {
		const section = configData.find((section) => section.id === selectedSection);
		if (!section || section.deleted) {
			setSelectedSection(ungroupedSectionId);
		}
	};

	const handleItemReorder = (e: any) => {
		e.detail.complete();
		const action = e.detail as any;

		setConfigData((oldState) => {
			const newState = structuredClone(oldState);
			const section = newState.find((section) => {
				return section.id === selectedSection;
			});
			if (!section) return oldState;

			section.items = moveItemInArrayByKey(section.items, 'order', action.from, action.to);

			return newState;
		});
	};

	const handleItemToggle = (id: string) => {
		setConfigData((oldState) => {
			const newState = structuredClone(oldState);
			const item = newState
				.find((section) => {
					return section.id === selectedSection;
				})
				?.items.find((item) => {
					return item.id === id;
				});
			if (!item) return oldState;
			item.active = !item.active;
			return newState;
		});
	};

	const handleSave = () => {
		const toSendConfigData = structuredClone(configData)
			.map((section) => {
				return setModificationFlag(section, fetchedData);
			})
			// filter sections not created, not modifiend and not deleted
			// and sections created and deleted (no need to saved them)
			.filter((s) => (s.created || s.modified || s.deleted) && !(s.deleted && s.created));
		saveToServer(toSendConfigData);
	};

	useEffect(() => {
		const checkChanges = () => {
			return configData.some((section) => {
				// creation or deletion
				if (section.created && !section.deleted) return true;
				if (section.deleted && !section.created) return true;

				const referenceSection = fetchedData.find((s) => s.id === section.id);
				if (referenceSection.order !== section.order) return true;
				const translation = section.translations.find((t) => t.language === selectedLang);
				const referenceTranslation = referenceSection.translations.find((t) => t.language === selectedLang);
				if (translation.text !== referenceTranslation.text) return true;

				return section.items.some((item) => {
					const referenceItem = referenceSection.items.find((i) => i.id === item.id);
					if (!referenceItem) return true;
					return referenceItem.order !== item.order || referenceItem.active !== item.active;
				});
			});
		};
		const areThereChanges = checkChanges();

		checkSelectedSectionExists();
		setThereAreUnsavedChanges(areThereChanges);
		setIsConfigDataMutated(areThereChanges);
	}, [configData, fetchedData]);

	useEffect(() => {
		fetchedData.forEach((section) => {
			if (section.ungroupedSection) {
				setUngroupedSectionId(section.id);
				setSelectedSection(section.id);
			}
		});
	}, [fetchedData]);

	useEffect(() => {
		isTabActive && queryClient.refetchQueries('serviceConfig');
	}, [isTabActive]);

	return (
		<>
			{isLoading ? (
				<IonLoading isOpen={isLoading} message={t('msg_loading')} duration={0} />
			) : (
				<>
					<div className={styles.servicesBody}>
						<LanguageSelector selectedLang={selectedLang} setSelectedLang={setSelectedLang} />
						<p className={styles.footnote}>{t('lbl_service_general_desc')}</p>
						<div className={styles.filterBar}>
							<div className={styles.titleBar}>
								<div className={styles.h3}>{t('categories')}</div>
								{hasWritePermission && (
									<div className={styles.flexEndButton}>
										<IonButton
											fill="clear"
											className={styles.buttonEdit}
											onClick={() => setShowGroupsModal(true)}
										>
											<Icon className="icon icon-edit icon24" />
										</IonButton>
									</div>
								)}
							</div>
							{/* CHIPS */}
							<div className={styles.chipContainer}>
								{configData
									.filter((section) => !section.deleted)
									.sort((a, b) => a.order - b.order)
									.map((section) => {
										let title = section?.translations.find((tr) => tr.language === selectedLang).text;
										if (!title) {
											title = t('lbl_no_category_items', { lng: selectedLang });
										}
										return (
											<FilterChip
												key={`filter-chip-${section.id}`}
												id={section.id}
												text={`${title} (${section.items.length})`}
												checked={selectedSection === section.id}
												onChange={() => setSelectedSection(section.id)}
											/>
										);
									})}
							</div>
						</div>
						{/* ITEMS LIST */}
						<div className={styles.servicesTable}>
							<div className={styles.titleBar}>
								<div className={styles.h3}>{t('lbl_services')}</div>
								{hasWritePermission && (
									<div className={styles.sortToggleButtonWrapper}>
										<Toggle
											checked={sort}
											onChange={() => {
												setSort((v) => !v);
											}}
										/>
										{t('lbl_edit_order')}
									</div>
								)}
							</div>
							<SortAndToggleList
								sort={sort}
								items={configData.find((section) => section.id === selectedSection)?.items || []}
								selectedLang={selectedLang}
								hasWritePermission={hasWritePermission}
								onItemReorder={handleItemReorder}
								onItemToggle={handleItemToggle}
							/>
						</div>
						{/* FOOTER */}
						{hasWritePermission ? (
							<div className={`${styles.footerButton}`}>
								<Button
									color="primary"
									className={styles.btnHeader}
									onClick={handleSave}
									disabled={!isConfigDataMutated || isLoadingMutate}
								>
									{t('btn_save_data')}
								</Button>
							</div>
						) : null}
					</div>
				</>
			)}
			{showGroupsModal && (
				<GroupsModal
					showModal={showGroupsModal}
					onClose={() => {
						setShowGroupsModal(false);
					}}
					lang={selectedLang}
					hasWritePermission={hasWritePermission}
					handleChangeToggle={() => {}}
					sections={configData}
					saveChanges={setConfigData}
					setSuccess={setSuccess}
					ungroupedSectionId={ungroupedSectionId}
				/>
			)}
		</>
	);
};
export default ServicesGeneral;
