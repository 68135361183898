import { IonContent, IonLoading, IonPage } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { Button } from '@acciona/ui-ionic-kit';
import { useInitialConfig } from '../../hooks/useInitialConfig';
import { LocationState } from './types';
import ModalHeadOfficeSelector from '../../components/ModalHeadOfficeSelector/ModalHeadOfficeSelector';
import styles from './login.module.scss';

const Login: React.FC<any> = () => {
	const { t } = useTranslation();
	const { isLoading, msgNotPermission, changeSedeModal, userIsLogged } = useInitialConfig();
	const state = useLocation().state as LocationState;

	return (
		<IonPage id="main">
			<IonContent>
				{state?.forcedRefresh ? (
					<></>
				) : (
					<>
						<div className={styles.main}>
							<div className={styles.land}>
								<div className={styles.imgOverlay} />
								<h1 className={styles.title}>
									{t('tile_login_one')} <br></br> {t('tile_login_two')}
								</h1>
							</div>
							<div className={styles.form}>
								<div className={styles.login_logo}>
									<img src="/campus_acciona.svg" alt="Logo Acciona" />
								</div>
								<div className={styles.login_text}>
									<p>{t('msg_login_wellcome')}</p>
								</div>
								{msgNotPermission && userIsLogged && (
									<div className={styles.not_permission}>
										<p>{t('msg_not_permission_access')}</p>
									</div>
								)}
								<Button
									slot="end"
									color="primary"
									className={styles.btnLogin}
									href={process.env.REACT_APP_COGNITO_WEB_LINK}
								>
									{t('btn_guest_access')}
								</Button>
							</div>
						</div>
						<ModalHeadOfficeSelector
							data={changeSedeModal.options}
							showModal={changeSedeModal.show}
							onSave={changeSedeModal.onSave}
							onClose={changeSedeModal.onClose}
							selectedItem={null}
							backdropOpaque={true}
							canClose={false}
						/>

						{/* <Toast
						isOpen={!!error}
						message={t('msg_system_unavailable')}
						position="bottom"
						type="error"
						/> */}
					</>
				)}
				<IonLoading isOpen={isLoading && userIsLogged && !changeSedeModal.show} message={t('msg_loading')} />
			</IonContent>
		</IonPage>
	);
};

export default Login;
