import { IonSelect, IonSelectOption, IonItem } from '@ionic/react';
import styles from './styles.module.scss';

type Props = {
	optionSelected: string;
	options: { value: string; label: string }[];
	selectOption: (optionValue: string, id?: number) => void;
	id?: number;
	label?: string;
};

export const CustomSelect: React.FC<Props> = ({ optionSelected, selectOption, id, label, options }) => {
	return (
		<div className={styles.selectorContainer}>
			<IonItem
				lines="none"
				className={`${styles.inputModal} ${optionSelected !== 'undefined' && styles.touchedInput}`}
			>
				<IonSelect
					slot="end"
					className={`lblSelector ${styles.selector}`}
					interface="popover"
					value={optionSelected}
					onIonChange={(e) => selectOption(e.target.value, id)}
					aria-label={label ? label : ''}
					label={label ? label : ''}
				>
					{options.map((item) => {
						return (
							<IonSelectOption key={item.value} value={item.value}>
								{item.label}
							</IonSelectOption>
						);
					})}
				</IonSelect>
			</IonItem>
		</div>
	);
};
