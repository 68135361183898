import { useEffect, useRef, useState } from 'react';
import { IonContent, IonLabel } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { SegmentButton, Segmented } from '@acciona/ui-ionic-kit';
import useAppContext from '../../hooks/useAppContext';
import { useScrollBar } from '../../hooks/useScrollBar';
import { Portal } from './Portal/Portal';
import { HeaderPage } from '../../components/HeaderPage/HeaderPage';
import styles from './styles.module.scss';

const RolesPortal: React.FC = () => {
	const { t } = useTranslation();
	const { setThereAreUnsavedChanges } = useAppContext();
	const [segmentedSelected, setSegmentedSelected] = useState('portal');
	const [scroll, setScroll] = useState(false);
	let footerStyle = null;
	const refContainer = useRef(null);
	const refHeader = useRef(null);
	const hasScroll = useScrollBar(refContainer, refHeader, segmentedSelected);
	footerStyle = hasScroll ? styles.hasScroll : styles.noScroll;

	useEffect(() => {
		return () => {
			setThereAreUnsavedChanges(false);
		};
	}, []);

	return (
		<IonContent scrollEvents forceOverscroll={true} onIonScroll={(x) => setScroll(x.detail.scrollTop > 0)}>
			<HeaderPage scroll={scroll} title={t('menu_roles_portal')}>
				<div ref={refHeader}>
					<Segmented value={segmentedSelected}>
						<SegmentButton onClick={() => setSegmentedSelected('portal')} value={'portal'} mode={'md'}>
							<IonLabel>{t('tab_roles_portal')}</IonLabel>
						</SegmentButton>
					</Segmented>
				</div>
			</HeaderPage>
			<div className="container" ref={refContainer}>
				<div className={segmentedSelected === 'portal' ? styles.display : styles.hide}>
					<Portal footerStyle={footerStyle} />
				</div>
			</div>
		</IonContent>
	);
};

export default RolesPortal;
