import styles from './styles.module.scss';

type Props = {
	scroll: boolean;
	title?: string;
	children?: React.ReactNode;
	className?: string;
	separator?: boolean;
};

export const HeaderPage: React.FC<Props> = ({ scroll, title, children, className, separator = true }) => {
	return (
		<>
			<div
				className={`${styles.menuSegmented} ${scroll ? styles.headerContainerScroll : ''} ${
					className ? className : ''
				}`}
			>
				<div className={`${styles.header} ${scroll ? styles.hide : ''}`}>
					<div className={styles.h1}>{title}</div>
				</div>
				<div className={styles.tabsMenu}>{children}</div>
				{separator && <div className={`${styles.tabsLineSeparator} ${scroll ? styles.noMarginLeft : ''}`} />}
			</div>
		</>
	);
};
