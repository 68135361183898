import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { Button, Icon } from '@acciona/ui-ionic-kit';
import { CustomModal as SendPlatesModal } from '../../../components/CustomModal/CustomModal';
import { useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { parkingService } from '../../../_api/services/parking';
import { SendPlatesInfo } from '../../../_api/services/parking/types';
import { IonLoading } from '@ionic/react';
import { extractDateAndTimeInSedeTimeZone } from '../../../utils/functions';
import useAppContext from '../../../hooks/useAppContext';
import _ from 'lodash';

type Props = {
	setError?: Function;
	setSuccess?: Function;
	setInfo?: Function;
	hasWritePermission?: boolean;
};
const defaultSendPlatesInfo = { lastExecution: '', platesOk: 0, platesKo: 0 };

export const SendPlates: React.FC<Props> = (props) => {
	const { t } = useTranslation();
	const { sedePortal } = useAppContext();
	const queryClient = useQueryClient();
	const [lastSendPlatesInfo, setLastSendPlatesInfo] = useState<SendPlatesInfo>(defaultSendPlatesInfo);
	const [showSendPlatesModal, setShowSendPlatesModal] = useState(false);
	const [showLoadingForJobDelay, setShowLoadingForJobDelay] = useState(false);
	const { setError, setSuccess, hasWritePermission } = props;
	const sedeDate = useMemo(() => {
		return extractDateAndTimeInSedeTimeZone(lastSendPlatesInfo.lastExecution, sedePortal.campusTimezone);
	}, [lastSendPlatesInfo]);

	const { isLoading: isLoading } = useQuery(
		['sendPlatesInfo'],
		async () => parkingService.getLastSendPlatesToLenelInfo(),
		{
			refetchOnWindowFocus: false,
			onError: (error) => setError(error as string),
			onSuccess: (data) => {
				setLastSendPlatesInfo(data);
			},
		},
	);
	const { isLoading: loadingSave, mutate: handleSave } = useMutation(parkingService.sendPlatesToLenel, {
		onSuccess: () => {
			setShowLoadingForJobDelay(true);
			setTimeout(() => {
				setSuccess(t('msg_sendPlates_success'));
				setShowLoadingForJobDelay(false);
			}, 3000);
			queryClient.refetchQueries('sendPlatesInfo');
		},
		onError: (error: Error) => {
			setShowLoadingForJobDelay(false);
			if (error.message === 'jobInProgress') {
				setError(t('msg_sendPlates_inProgress'));
			} else {
				setError(error.message);
			}
		},
	});

	const sendPlatesToLenel = () => {
		handleSave();
		setShowSendPlatesModal(false);
	};
	const checkLiteralDescription = (info: SendPlatesInfo, sedeDate: { date: string; time: string }): string => {
		const sendToLenelDesc = t('last_sendToLenel_desc', {
			date: sedeDate.date,
			hour: sedeDate.time,
		});
		const plateOkDesc = t(info?.platesOk === 1 ? 'lbl_platesOk_singular' : 'lbl_platesOk_plural', {
			platesOk: info?.platesOk,
		});
		const plateKoDesc = t(info?.platesKo === 1 ? 'lbl_platesKo_singular' : 'lbl_platesKo_plural', {
			platesKo: info?.platesKo,
		});
		return `${sendToLenelDesc} ${plateOkDesc} ${t('lbl_and')} ${plateKoDesc}`;
	};
	return (
		<>
			{isLoading ? (
				<IonLoading isOpen={isLoading} message={t('msg_loading')} duration={0} />
			) : (
				<>
					<IonLoading isOpen={showLoadingForJobDelay || loadingSave} message={t('msg_loading')} duration={0} />
					<div className={styles.h3}>{t('tab_send_plates')}</div>
					{!_.isEmpty(lastSendPlatesInfo.lastExecution) && (
						<div className={styles.footnote}>{checkLiteralDescription(lastSendPlatesInfo, sedeDate)}</div>
					)}
					{hasWritePermission && (
						<Button
							disabled={showLoadingForJobDelay}
							color="lighter"
							className={styles.sendPlatesBtn}
							onClick={() => setShowSendPlatesModal(true)}
						>
							<Icon className={`icon icon-refresh icon24  ${styles.icon}`} />
							{t('btn_send_plates')}
						</Button>
					)}
					<SendPlatesModal
						showModal={showSendPlatesModal}
						onConfirm={sendPlatesToLenel}
						onClose={() => setShowSendPlatesModal(false)}
						title={t('title_send_plates_modal')}
						description={t('desc_send_plates_modal')}
					/>
				</>
			)}
		</>
	);
};
