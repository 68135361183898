import { useEffect, useState } from 'react';
import { RefresherEventDetail, IonLoading, InputCustomEvent } from '@ionic/react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Toast, Alert, Button } from '@acciona/ui-ionic-kit';
import _ from 'lodash';
import { incidentServices } from '../../../_api/services/incidents';
import CategoriesModal from './components/CategoriesModal/CategoriesModal';
import SubCategoriesModal from './components/SubcategoriesModal/SubcategoriesModal';
import { Subcategory } from '../../../_api/services/incidents/types';
import useAppContext from '../../../hooks/useAppContext';
import { LanguageSelector } from '../../../components/LanguageSelector/LanguageSelector';
import { FilterChipsBar } from '../../../components/FilterChipsBar/FilterChipsBar';
import { arraymove } from '../../../utils/functions';
import { EditableList } from '../../../components/EditableList/EditableList';
import { NumberInput } from '../../../components/NumberInput/NumberInput';
import styles from './styles.module.scss';

export type Props = {
	hasWritePermission: boolean;
	setError: (msg: string) => void;
	setSuccess: (msg: string) => void;
	footerStyle: string;
};

export const Categories: React.FC<Props> = (props) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const { setThereAreUnsavedChanges } = useAppContext();
	const [selectedCategory, setSelectedCategory] = useState(0);
	const [selectedLang, setSelectedLang] = useState('es');
	const [selectedSubcat, setSelectedSubcat] = useState<Subcategory[]>(null);
	const [subcatId, setSubcatId] = useState(0);
	const [showCategories, setShowCategories] = useState(false);
	const [alertDelSC, setAlertDelSC] = useState(false);
	const [showSubCategories, setShowSubCategories] = useState(false);
	const [categoriesState, setCategoriesState] = useState<any>(null);
	const [subcatState, setSubcatState] = useState<Subcategory[]>(null);
	const [isEditedSubcategories, setIsEditedSubCategories] = useState(false);
	const [isEditedCategories, setIsEditedCategories] = useState(false);
	const [saveChangesMessage, setSaveChangesMessage] = useState('');
	const [incidencesToShow, setIncidencesToShow] = useState(0);

	useEffect(() => {
		setThereAreUnsavedChanges(isEditedCategories || isEditedSubcategories);
	}, [isEditedCategories || isEditedSubcategories]);

	const { data: intitialCategories, isLoading: isLoadingCategories } = useQuery(
		['categories'],
		async () => await incidentServices.getCategories(),
		{
			refetchOnWindowFocus: false,
			onError: (error) => props.setError(error as string),
		},
	);
	const { data: initialSubcategoriesConfig, isLoading: isLoadingSubcategories } = useQuery(
		['subcategories'],
		async () => await incidentServices.getSubcategories(),
		{
			refetchOnWindowFocus: false,
			onError: (error) => props.setError(error as string),
		},
	);

	const { mutate: saveCategories, isLoading: loadingSaveCat } = useMutation(incidentServices.saveCategories, {
		onSuccess: (data) => {
			if (isEditedSubcategories) {
				const maxIncidenceId = _.maxBy(initialSubcategoriesConfig?.subcategories, function (o) {
					return o.incidenceId;
				}).incidenceId;
				const nextSubcategories = subcatState.map((s) => ({
					...s,
					categoryId: _.first(data.filter((d) => d.idInsert === s.categoryId))?.categoryId,
					incidenceId: s.incidenceId > maxIncidenceId ? null : s.incidenceId,
				}));
				saveSubCategories({
					incidencesToShow: incidencesToShow,
					subcategories: nextSubcategories,
				});
			} else {
				queryClient.refetchQueries('categories');
				setIsEditedCategories(false);
				props.setSuccess(t('msg_success'));
			}
		},
		onError: (error) => {
			props.setError(error as string);
		},
	});

	const { mutate: saveSubCategories, isLoading: loadingSaveSubCat } = useMutation(incidentServices.saveSubCategories, {
		onSuccess: () => {
			queryClient.refetchQueries('subcategories');
			setIsEditedSubCategories(false);
			props.setSuccess(t('msg_success'));
		},
		onError: (error) => {
			props.setError(error as string);
		},
	});

	useEffect(() => {
		intitialCategories && setCategoriesState(_.sortBy(intitialCategories, 'ordinal'));
	}, [intitialCategories]);

	useEffect(() => {
		initialSubcategoriesConfig?.subcategories &&
			setSelectedSubcat(initialSubcategoriesConfig?.subcategories.filter((e) => e.categoryId === selectedCategory));
		initialSubcategoriesConfig?.subcategories &&
			setSubcatState(_.sortBy(initialSubcategoriesConfig?.subcategories, 'ordinal'));
	}, [initialSubcategoriesConfig?.subcategories]);

	useEffect(() => {
		setIncidencesToShow(initialSubcategoriesConfig?.incidencesToShow);
	}, [initialSubcategoriesConfig?.incidencesToShow]);

	useEffect(() => {
		subcatState && setSelectedSubcat(subcatState.filter((e) => e.categoryId === selectedCategory));
	}, [selectedCategory]);

	useEffect(() => {
		categoriesState && setSelectedCategory(_.first(_.sortBy(categoriesState, 'ordinal'))?.categoryId);
		setIsEditedCategories(!_.isEqual(_.sortBy(intitialCategories, 'ordinal'), _.sortBy(categoriesState, 'ordinal')));
	}, [categoriesState]);

	useEffect(() => {
		setIsEditedSubCategories(
			!_.isEqual(
				_.sortBy(initialSubcategoriesConfig?.subcategories, 'categoryId', 'ordinal'),
				_.sortBy(subcatState, 'categoryId', 'ordinal'),
			) || initialSubcategoriesConfig?.incidencesToShow !== incidencesToShow,
		);
	}, [initialSubcategoriesConfig, subcatState, incidencesToShow]);

	const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
		event.detail.complete();
		const action = event.detail as any;

		const changesMoved = _.sortBy(
			arraymove(
				_.sortBy(_.filter(subcatState, { categoryId: selectedCategory }), 'ordinal'),
				action.from,
				action.to,
			).map((e: Subcategory, index) => {
				return {
					...e,
					ordinal: index + 1,
				};
			}),
			'ordinal',
		);
		setSubcatState(
			_.union(
				subcatState.filter((e) => e.categoryId !== selectedCategory),
				changesMoved,
			),
		);
	};

	const handleEditSubcat = (id: number) => {
		setSubcatId(id);
		setShowSubCategories(true);
	};

	const handleDeleteSubcat = (id: number) => {
		setSubcatId(id);
		setAlertDelSC(true);
	};

	const handleAddSubcat = () => {
		handleEditSubcat(0);
	};

	const deleteSubcat = () => {
		const ordinal = _.first(selectedSubcat.filter((c) => c.incidenceId === subcatId)).ordinal;
		const selectedSC = selectedSubcat.map((sc) => {
			if (sc.incidenceId === subcatId) {
				return { ...sc, disabled: true, ordinal: null };
			} else {
				if (sc.ordinal > ordinal) {
					return { ...sc, ordinal: sc.ordinal - 1 };
				} else {
					return sc;
				}
			}
		});
		setSubcatState(
			_.union(
				subcatState.filter((e) => e.categoryId !== selectedCategory),
				selectedSC,
			),
		);
		setSelectedSubcat(selectedSC);
	};

	const handleSave = () => {
		const categoriesIds = !intitialCategories ? [0] : intitialCategories.map((c) => c.categoryId);
		const maxCategoryId = Math.max(...categoriesIds);

		if (isEditedCategories) {
			saveCategories(
				categoriesState.map((c) => {
					return {
						...c,
						categoryId: c.categoryId > maxCategoryId ? null : c.categoryId,
						idInsert: c.categoryId,
					};
				}),
			);
		} else if (isEditedSubcategories) {
			const incidencesIds = !initialSubcategoriesConfig?.subcategories
				? [0]
				: intitialCategories.map((c) => c.incidenceId);
			const maxIncidenceId = Math.max(...incidencesIds);
			const nextSubcategories = subcatState.map((s) => ({
				...s,
				incidenceId: s.incidenceId > maxIncidenceId ? null : s.incidenceId,
			}));
			saveSubCategories({
				incidencesToShow: incidencesToShow,
				subcategories: nextSubcategories,
			});
		}
	};

	const handleSaveChangesCategories = (changes) => {
		setCategoriesState(changes);
		setSaveChangesMessage(t('msg_save_categorie'));
	};
	const handleSaveChangesSubCategories = (changes) => {
		setSubcatState(
			_.union(
				subcatState.filter((e) => e.categoryId !== selectedCategory),
				[...selectedSubcat.filter((c) => c.incidenceId !== subcatId), changes],
			),
		);
		setSelectedSubcat([...selectedSubcat.filter((c) => c.incidenceId !== subcatId), changes]);
		setSaveChangesMessage(t('msg_save_subcategorie'));
	};

	return (
		<>
			<LanguageSelector selectedLang={selectedLang} setSelectedLang={setSelectedLang} />
			{/* NUMBER OF INCIDENTS TO SHOW */}
			<div className={`${styles.h2} ${styles.headerSpace}`}>{t('lbl_incidences_list')}</div>
			<div className={styles.element}>
				<div className={`${styles.footnote} ${styles.space}`}>{t('lbl_incidencies_list_description')}</div>
				<NumberInput
					name="maxFavoriteDesks"
					className={`ion-text-end`}
					value={incidencesToShow}
					onIonChange={(e: InputCustomEvent) => setIncidencesToShow(+e.target.value)}
					min={0}
					max={20}
					disabled={!props.hasWritePermission}
					aria-label={t('quantity')}
					label={t('quantity')}
					required
					allowDecimals={false}
				></NumberInput>
			</div>
			{/* INCIDENCES TYPES */}
			<h2 className={`${styles.h2} ${styles.incidencesHeaderSpace}`}>{t('lbl_incidences_types')}</h2>
			<h3>{t('categories')}</h3>
			<FilterChipsBar
				selectedLang={selectedLang}
				selectedCategory={selectedCategory}
				setSelectedCategory={setSelectedCategory}
				categoriesState={categoriesState}
				setShowCategories={setShowCategories}
				keyField="categoryId"
			/>
			<EditableList
				title={t('subcategories')}
				titleClassName={styles.h3}
				hasWritePermission={props.hasWritePermission}
				selectedLang={selectedLang}
				data={selectedSubcat}
				keyField="incidenceId"
				loading={isLoadingSubcategories}
				onItemReorder={doRefresh}
				onEdit={handleEditSubcat}
				onDelete={handleDeleteSubcat}
				onAdd={handleAddSubcat}
				buttonAddText={t('btn_add_subcategory')}
				buttonAddPosition="end"
			/>
			{props.hasWritePermission ? (
				<div className={`${props.footerStyle} ${styles.footerButton}`}>
					<Button
						disabled={!(isEditedCategories || isEditedSubcategories)}
						color="primary"
						className={styles.btnHeader}
						onClick={handleSave}
					>
						{t('btn_save_data')}
					</Button>
				</div>
			) : null}
			<CategoriesModal
				categories={categoriesState}
				showModal={showCategories}
				onClose={() => setShowCategories(false)}
				hasWritePermission={props.hasWritePermission}
				saveChanges={handleSaveChangesCategories}
				lang={selectedLang}
			/>
			<SubCategoriesModal
				subcategory={selectedSubcat?.filter((c) => c.incidenceId === subcatId)}
				categoryId={selectedCategory}
				ordinal={
					!_.isEmpty(selectedSubcat)
						? _.maxBy(selectedSubcat, function (o) {
								return o.ordinal;
						  })?.ordinal + 1
						: 1
				}
				idSubCat={
					subcatState
						? _.maxBy(subcatState, function (o) {
								return o.incidenceId;
						  })?.incidenceId + 1
						: null
				}
				showModal={showSubCategories}
				onClose={() => setShowSubCategories(false)}
				hasWritePermission={props.hasWritePermission}
				saveChanges={(changes) => handleSaveChangesSubCategories(changes)}
				lang={selectedLang}
			/>
			<IonLoading
				isOpen={isLoadingCategories || isLoadingSubcategories || loadingSaveCat || loadingSaveSubCat}
				message={t('msg_loading')}
				duration={1500}
			/>
			<Alert
				isOpen={alertDelSC}
				onDidDismiss={() => setAlertDelSC(false)}
				header={t('delete_subcategory')}
				message={t('confirm_delete_subcategory')}
				buttons={[
					{ text: 'No', role: 'cancel' },
					{ text: t('lbl_affirmative'), handler: () => deleteSubcat() },
				]}
				mode="ios"
			/>
			<Toast
				isOpen={!!saveChangesMessage}
				message={saveChangesMessage}
				onDidDismiss={() => setSaveChangesMessage('')}
				position="bottom"
				type="success"
			/>
		</>
	);
};
