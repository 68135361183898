import { createContext, useReducer } from 'react';
import { AppPermissionsContextType } from './AppPermissions.model';
import { reducer, initialState } from './AppPermissions.reducer';

const AppPermissionsContext = createContext<AppPermissionsContextType>(null);

const AppPermissionsContextProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState);

	return <AppPermissionsContext.Provider value={{ state, dispatch }}>{children}</AppPermissionsContext.Provider>;
};

export { AppPermissionsContext, AppPermissionsContextProvider };
