import { useRef, useEffect, useState } from 'react';
import { IonContent, IonLabel } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { SegmentButton, Segmented } from '@acciona/ui-ionic-kit';
import useAppContext from '../../hooks/useAppContext';
import { usePermissions } from '../../hooks/usePermissions';
import { HeaderPage } from '../../components/HeaderPage/HeaderPage';
import { Feedback } from '../../components/Feedback/Feedback';
import { FUNCTIONALITIES } from '../../utils/constants';
import styles from './styles.module.scss';
import ServicesGeneral from './ServicesGeneral/ServicesGeneral';
import { ServicesSettings } from './ServicesSettings/ServicesSettings';

const Services: React.FC = () => {
	const { t } = useTranslation();
	const { setThereAreUnsavedChanges } = useAppContext();
	const [segmentedSelected, setSegmentedSelected] = useState('settings');
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);
	const [scroll, setScroll] = useState(false);
	const [info, setInfo] = useState(null);
	const { permissions, error: errorPermissions } = usePermissions(FUNCTIONALITIES.services, 'WRITE');
	const refContainer = useRef(null);
	const refHeader = useRef(null);

	const handleDismissToast = (type: 'error' | 'success' | 'info') => {
		if (type === 'error') {
			setError('');
		}
		if (type === 'success') {
			setSuccess('');
		}
		if (type === 'info') {
			setInfo(null);
		}
	};

	useEffect(() => {
		setError(errorPermissions);
	}, [errorPermissions]);

	useEffect(() => {
		return () => {
			setThereAreUnsavedChanges(false);
		};
	}, []);

	return (
		<IonContent scrollEvents forceOverscroll={true} onIonScroll={(x) => setScroll(x.detail.scrollTop > 0)}>
			<HeaderPage scroll={scroll} title={t('menu_general_settings')}>
				<div ref={refHeader}>
					<Segmented value={segmentedSelected}>
						<SegmentButton onClick={() => setSegmentedSelected('settings')} value={'settings'} mode={'md'}>
							<IonLabel>{t('tab_services_settings')}</IonLabel>
						</SegmentButton>
						<SegmentButton
							onClick={() => setSegmentedSelected('activateAndSort')}
							value={'activateAndSort'}
							mode={'md'}
						>
							<IonLabel>{t('tab_services_activate_and_sort')}</IonLabel>
						</SegmentButton>
					</Segmented>
				</div>
			</HeaderPage>
			<div className="container" ref={refContainer}>
				<div className={segmentedSelected === 'settings' ? styles.display : styles.hide}>
					<ServicesSettings
						hasWritePermission={permissions.WRITE}
						isTabActive={segmentedSelected === 'settings'}
						setSuccess={setSuccess}
						setError={setError}
					/>
				</div>
				<div className={segmentedSelected === 'activateAndSort' ? styles.display : styles.hide}>
					<ServicesGeneral
						hasWritePermission={permissions.WRITE}
						isTabActive={segmentedSelected === 'activateAndSort'}
						setSuccess={setSuccess}
						setError={setError}
					/>
				</div>
			</div>
			<Feedback error={error} success={success} info={info} onDidDismissToast={handleDismissToast} />
		</IonContent>
	);
};

export default Services;
