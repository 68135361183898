import { useEffect, useMemo, useState } from 'react';
import { IonItem, IonLabel, IonLoading, IonRadio, IonRadioGroup, IonSelect, IonSelectOption } from '@ionic/react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Button, Icon, List, MultiSelect, Toast } from '@acciona/ui-ionic-kit';
import { workstationService } from '../../../_api/services/workstation';
import { District, PermissionLevelOption, PermissionsAndDistricts } from '../../../_api/services/workstation/types';
import useAppContext from '../../../hooks/useAppContext';
import { ModalAddZone } from '../../../components/ModalAddZone/ModalAddZone';
import { ZoneList } from '../../../components/ZonesList/ZoneList';
import styles from './styles.module.scss';

type Props = {
	setError?: Function;
	setSuccess?: Function;
	setInfo?: Function;
	hasWritePermission?: boolean;
	footerStyle: string;
};

const getDefaultPreferenceId = (initialData: PermissionsAndDistricts) => {
	return initialData?.deskReservationConfiguration.find((config) => config?.isDefault)?.id;
};

const checkIfDefaultConfigHasChanged = (initialData: PermissionsAndDistricts, id: number) => {
	return getDefaultPreferenceId(initialData) !== id;
};

const findDifferentDistricts = (newDistricts: District[], initialDistricts: District[]): District[] => {
	return newDistricts.filter(
		(district, index) =>
			!_.isEqual(district.overflowDistricts.sort(), initialDistricts[index].overflowDistricts.sort()),
	);
};

const hasEmptyValues = (formValues: any) =>
	Object.values(formValues).some((value) => value === null || value === undefined);

export const WsPermissions: React.FC<Props> = (props) => {
	const { t } = useTranslation();
	const { setError, setSuccess, hasWritePermission } = props;
	const reservationPermissionsList = [
		{ id: 1, description: t('reservation_permissions.1') },
		{ id: 2, description: t('reservation_permissions.2') },
		{ id: 3, description: t('reservation_permissions.3') },
		{ id: 4, description: t('reservation_permissions.4') },
	];

	const defaultValues: PermissionsAndDistricts = {
		lastMinuteReservationsConfiguration: null,
		deskReservationConfiguration: [],
		districts: [],
		updateUserDefaults: false,
		restrictedLevels: [],
	};
	const [showAddZoneModal, setShowAddZoneModal] = useState(false);
	const [values, setValues] = useState<PermissionsAndDistricts>(defaultValues);
	const [toastMessage, setToastMessage] = useState(null);
	const [zoneToEdit, setZoneToEdit] = useState<District>(null);
	const [districts, setDistricts] = useState<District[]>([]);
	const [levels, setLevels] = useState<PermissionLevelOption[]>([]);

	const queryClient = useQueryClient();
	const { setThereAreUnsavedChanges, sedePortal } = useAppContext();

	const { isLoading: isLoadingLevels } = useQuery(['sedeLevels'], async () => workstationService.getSedeLevels(), {
		refetchOnWindowFocus: false,
		onError: (error) => setError(error as string),
		onSuccess: (data) => {
			const levels: PermissionLevelOption[] = data.map((level) => ({ value: level, label: level }));
			setLevels(levels);
		},
	});
	const { isLoading: isLoading, data: initialValues } = useQuery(
		['configPermissions'],
		async () => workstationService.getPermissionsAndDistricts(),
		{
			refetchOnWindowFocus: false,
			onError: (error) => setError(error as string),
			onSuccess: (data) => {
				setValues(data);
				setDistricts(data.districts);
			},
		},
	);

	const { isLoading: loadingSave, mutate: handleSave } = useMutation(
		workstationService.updatePermissionsAndDistricts,
		{
			onSuccess: () => {
				setSuccess(t('msg_success'));
				queryClient.refetchQueries('configPermissions');
			},
			onError: (error) => {
				setError(error as string);
			},
		},
	);

	const selectedConfigurationOptions = useMemo(
		() => values?.deskReservationConfiguration.filter((option) => option.isActive),
		[values?.deskReservationConfiguration],
	);

	const defaultPreferenceId = useMemo(() => {
		const valueSelected =
			values?.deskReservationConfiguration.filter((option) => option.isActive).find((config) => config?.isDefault)
				?.id ?? _.first(values?.deskReservationConfiguration.filter((option) => option.isActive))?.id;
		return valueSelected;
	}, [values?.deskReservationConfiguration]);

	const configurationOptions = useMemo(
		() =>
			values.deskReservationConfiguration.map((config) => ({
				value: config.id.toString(),
				label: t(config.configurationName),
			})),
		[values?.deskReservationConfiguration],
	);

	const isEdited = useMemo(() => {
		return !_.isEqual(initialValues, values) || !_.isEqual(initialValues?.districts, districts);
	}, [initialValues, values, initialValues?.districts, districts]);

	useEffect(() => {
		setThereAreUnsavedChanges(isEdited);
	}, [isEdited]);

	const handleAddZone = (zone: District) => {
		const districtExists = districts.some((district) => district.districtName === zone.districtName);
		if (districtExists) {
			const updatedDistricts = districts.map((district) => {
				if (district.districtName === zone.districtName) {
					return {
						...district,
						overflowDistricts: zone.overflowDistricts,
					};
				}
				return district;
			});
			setDistricts(updatedDistricts);
		} else {
			const newDistricts = [...districts, zone];
			setDistricts(newDistricts);
		}
		setToastMessage(t('desk_permissions_toast.edit_add_confirm'));
	};

	const handleEditZone = (zone: string) => {
		const districtToEdit = districts.find((d) => d.districtName === zone);
		setZoneToEdit(districtToEdit);
		setShowAddZoneModal(true);
	};

	const handleRemoveZone = (zone: string) => {
		const updatedDistricts = districts.map((d) => {
			if (d.districtName === zone) {
				return { ...d, overflowDistricts: [] };
			} else {
				return d;
			}
		});
		setDistricts(updatedDistricts);
		setToastMessage(t('desk_permissions_toast.delete_confirm'));
	};

	const handleInputChange = (e) => {
		if (_.isEqual(values, defaultValues)) {
			return;
		}

		const { name, type, value } = e.target;

		setValues((prev) => {
			const updatedValues = _.cloneDeep(prev);
			updatedValues[name] = type === 'number' ? +value : value;
			return updatedValues;
		});
	};

	const handlePreferencesChange = (id: string) => {
		setValues((prev) => {
			const updatedValues = _.cloneDeep(prev);
			updatedValues.deskReservationConfiguration = prev.deskReservationConfiguration.map((item) => {
				if (item.id === parseInt(id)) {
					return {
						...item,
						isActive: item.isActive && selectedConfigurationOptions.length > 1 ? false : true,
					};
				}
				return item;
			});
			return updatedValues;
		});
	};

	const handleLevelsChange = (value: string) => {
		setValues((prev) => {
			const updatedValues = _.cloneDeep(prev);
			if (updatedValues.restrictedLevels.includes(value)) {
				updatedValues.restrictedLevels = updatedValues.restrictedLevels.filter((level) => level !== value);
			} else {
				updatedValues.restrictedLevels.push(value);
			}
			return updatedValues;
		});
	};

	const handleChangeDefaultPreference = (id: number) => {
		setValues((prev) => {
			const updatedValues = _.cloneDeep(prev);
			updatedValues.deskReservationConfiguration = prev.deskReservationConfiguration.map((item) => ({
				...item,
				isDefault: item.id === id ? true : false,
			}));
			return updatedValues;
		});
	};

	const handleOnSave = () => {
		const differentOverflowDistrictsObjects = findDifferentDistricts(districts, initialValues.districts);

		const updatedData = _.cloneDeep(values);
		updatedData.districts = differentOverflowDistrictsObjects;
		updatedData.updateUserDefaults = checkIfDefaultConfigHasChanged(initialValues, defaultPreferenceId);

		if (hasEmptyValues(updatedData)) {
			setError(t('msg_error_all_fields_required'));
		} else {
			handleSave({
				...updatedData,
				deskReservationConfiguration: updatedData.deskReservationConfiguration.map((item) => ({
					...item,
					headOffice: sedePortal.id,
				})),
			});
		}
	};

	return (
		<>
			{isLoading || isLoadingLevels ? (
				<IonLoading
					isOpen={isLoading || loadingSave || isLoadingLevels}
					message={t('msg_loading')}
					duration={3000}
				/>
			) : (
				<>
					<ModalAddZone
						showModal={showAddZoneModal}
						onClose={() => {
							setZoneToEdit(null);
							setShowAddZoneModal(false);
						}}
						saveChanges={handleAddZone}
						hasWritePermission={hasWritePermission}
						districts={districts}
						zoneToEdit={zoneToEdit}
					/>
					<List>
						<header className={`${styles.h2} ${styles.headerSpace}`}>
							{t('title_timeAndReservations_permissions_and_restrictions')}
						</header>
						{/* PREFERENCES ACTIVE */}
						<div className={styles.element}>
							<h3 className={styles.blockTitle}>{t('desk_pref_permission')}</h3>
							<p className={`${styles.blockSubtitle}`}>{t('desk_pref_description')}</p>

							{!_.isEmpty(values.deskReservationConfiguration) && (
								<MultiSelect
									className={`${styles.multiSelect} ${
										selectedConfigurationOptions.length > 0 && styles.touchedInput
									}`}
									label={t('lbl_duration')}
									options={configurationOptions}
									selected={selectedConfigurationOptions.map((option) => option.id.toString())}
									updateSelection={handlePreferencesChange}
									disabled={!hasWritePermission}
								/>
							)}
						</div>
						{/* DEFAULT PREFERENCE */}
						{!_.isEmpty(selectedConfigurationOptions) && (
							<div className={styles.element}>
								<h3 className={styles.blockTitle}>{t('desk_default_pref_permission')}</h3>
								<p className={`${styles.blockSubtitle}`}>{t('desk_default_pref_description')}</p>
								<IonRadioGroup
									className={styles.defautPreferencesOptions}
									value={defaultPreferenceId}
									onIonChange={(e) => handleChangeDefaultPreference(e.detail.value)}
								>
									{selectedConfigurationOptions.map((option) => (
										<IonItem key={option.id} className={styles.selector} lines="none">
											<IonRadio
												slot="start"
												mode="md"
												value={option.id}
												aria-label={t(option.configurationName)}
												labelPlacement="end"
											>
												{t(option.configurationName)}
											</IonRadio>
										</IonItem>
									))}
								</IonRadioGroup>
							</div>
						)}
						{/* PERMISSION BY LEVEL */}
						<div className={styles.element}>
							<h3 className={styles.blockTitle}>{t('lbl_desk_liberate_permissions_levels_title')}</h3>
							<p className={`${styles.blockSubtitle}`}>{t('desk_liberate_permissions_levels_desc')}</p>
							{!_.isEmpty(levels) && (
								<MultiSelect
									className={`${styles.multiSelect} 
									${values?.restrictedLevels?.length > 0 && styles.touchedInput} 
									`}
									label={t('lbl_levels')}
									options={levels.sort((a, b) => a.label.localeCompare(b.label))}
									selected={values.restrictedLevels}
									updateSelection={handleLevelsChange}
									disabled={!hasWritePermission}
								/>
							)}
						</div>
						{/* ZONE TYPE */}
						<div className={styles.element}>
							<h3 className={styles.blockTitle}>{t('lbl_timeAndReservations_reservation_permissions')}</h3>
							<p className={`${styles.blockSubtitle}`}>
								{t('lbl_timeAndReservations_reservation_permissions_description')}
							</p>
							<IonItem
								lines="none"
								className={`${styles.inputModal} ${
									values.lastMinuteReservationsConfiguration !==
										defaultValues.lastMinuteReservationsConfiguration && styles.touchedInput
								}`}
							>
								<IonLabel slot="start" className="lblSelector">
									{t('lbl_zone_type')}
								</IonLabel>
								<IonSelect
									name="lastMinuteReservationsConfiguration"
									slot="end"
									className={`lblSelector ${styles.selector}`}
									interfaceOptions={{
										cssClass: styles.selectInterface,
									}}
									interface="popover"
									value={values.lastMinuteReservationsConfiguration}
									onIonChange={handleInputChange}
									disabled={!hasWritePermission}
									aria-label={t('lbl_zone_type')}
								>
									{reservationPermissionsList.map((permission) => {
										return (
											<IonSelectOption key={permission.id} value={permission.id}>
												{permission.description}
											</IonSelectOption>
										);
									})}
								</IonSelect>
							</IonItem>
						</div>
						{/* OVERFLOW ZONES */}
						<div className={styles.element}>
							<h3 className={styles.blockTitle}>{t('districts_overflow')}</h3>
							<p className={`${styles.blockSubtitle}`}>{t('districts_overflow_description')}</p>
							<div className={styles.pageContent}>
								<ZoneList
									hasWritePermission={hasWritePermission}
									districtsConfig={districts.filter((d) => d.overflowDistricts.length > 0)}
									handlerDeleteSubcat={handleRemoveZone}
									handlerEditZone={handleEditZone}
								/>
							</div>
							{hasWritePermission && (
								<Button
									slot="start"
									className={styles.addZoneBtn}
									onClick={() => {
										setShowAddZoneModal(true);
									}}
								>
									<Icon className="icon icon-plus iconPlus" />
									<IonLabel>{t('btn_add_zone')}</IonLabel>
								</Button>
							)}
						</div>
					</List>
					{hasWritePermission && (
						<div className={`${props.footerStyle} ${styles.footerButton}`}>
							<Button onClick={handleOnSave} className={styles.btnHeader} disabled={!isEdited} color="primary">
								{t('btn_save_data')}
							</Button>
						</div>
					)}

					<Toast
						isOpen={!!toastMessage}
						message={toastMessage}
						onDidDismiss={() => setToastMessage(null)}
						position="bottom"
						type="success"
					/>
				</>
			)}
		</>
	);
};
