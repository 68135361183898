import { IonContent } from '@ionic/react';
import styles from './styles.module.scss';

type Props = {
	title?: string;
	children?: React.ReactNode;
};

const LayoutNoTabs: React.FC<Props> = ({ title, children }) => {
	return (
		<IonContent>
			<div className={styles.header}>
				<div className={styles.h1}>{title}</div>
			</div>
			<div className={styles.container}>{children}</div>
		</IonContent>
	);
};

export default LayoutNoTabs;
