import _ from 'lodash';
import { Employee } from '../../_api/services/roles/types';
import { AppPermissionsActionTypesEnum } from './AppPermissions.actions.types';
import { AppPermissionsAction, AppPermissionsState } from './AppPermissions.model';
import { USER_APP_PERMISSIONS_TYPES } from '../../utils/constants';

export const initialState: AppPermissionsState = {
	permission: {
		id: -999,
		permission: '',
		tab: '',
		profile_permission_subtitle: '',
		individual_permission_subtitle: '',
		modal: '',
	},
	profiles: [],
	selectedProfiles: [],
	deletedUsers: [],
	usersData: [],
	isLoading: true,
	userPermissionType: USER_APP_PERMISSIONS_TYPES.undefined,
	startDate: null,
	endDate: null,
	errorMessage: '',
	successMessage: '',
};

export const reducer = (state: AppPermissionsState, action: AppPermissionsAction) => {
	switch (action.type) {
		case AppPermissionsActionTypesEnum.SET_INITIAL_STATE:
			return {
				...initialState,
				profiles: state.profiles,
			};
		case AppPermissionsActionTypesEnum.SET_PERMISSION:
			return {
				...state,
				permission: action.payload,
			};
		case AppPermissionsActionTypesEnum.SET_USERS_DATA:
			return {
				...state,
				usersData: action.payload,
			};
		case AppPermissionsActionTypesEnum.ADD_USERS:
			return {
				...state,
				usersData: [...state.usersData, ...action.payload],
			};
		case AppPermissionsActionTypesEnum.DELETE_USERS: {
			const nextDeletedUsers = [...state.deletedUsers, ...action.payload];
			return {
				...state,
				deletedUsers: nextDeletedUsers,
				usersData: state.usersData.filter((user) => !nextDeletedUsers.includes(user.employeeId)),
			};
		}
		case AppPermissionsActionTypesEnum.UPDATE_USERS: {
			const updatedUsersData = action.payload;
			if (!state.usersData || _.isEmpty(state.usersData) || !updatedUsersData || _.isEmpty(updatedUsersData)) {
				return state;
			}

			const indexedData = {};

			state.usersData.forEach((item) => {
				indexedData[item.employeeId] = item;
			});

			updatedUsersData.forEach((user) => {
				indexedData[user.employeeId] = {
					...indexedData[user.employeeId],
					...user,
				};
			});

			const nextData = Object.values(indexedData) as Employee[];

			return {
				...state,
				usersData: nextData,
			};
		}
		case AppPermissionsActionTypesEnum.SET_PROFILES_LIST:
			return {
				...state,
				profiles: action.payload,
			};
		case AppPermissionsActionTypesEnum.SET_SELECTED_PROFILES:
			return {
				...state,
				selectedProfiles: action.payload,
			};
		case AppPermissionsActionTypesEnum.TOGGLE_SELECTED_PROFILE: {
			const newProfileId = action.payload;
			let nextProfiles = [];

			if (!_.isEmpty(state.selectedProfiles) && state.selectedProfiles.includes(newProfileId)) {
				nextProfiles = state.selectedProfiles.filter((s) => s !== newProfileId);
			} else {
				nextProfiles = [...state.selectedProfiles, newProfileId];
			}

			return {
				...state,
				selectedProfiles: nextProfiles,
			};
		}
		case AppPermissionsActionTypesEnum.SET_IS_LOADING:
			return {
				...state,
				isLoading: action.payload,
			};
		case AppPermissionsActionTypesEnum.SET_USER_PERMISSION_TYPE:
			return {
				...state,
				userPermissionType: action.payload,
			};
		case AppPermissionsActionTypesEnum.SET_START_DATE:
			return {
				...state,
				startDate: action.payload,
			};
		case AppPermissionsActionTypesEnum.SET_END_DATE:
			return {
				...state,
				endDate: action.payload,
			};
		case AppPermissionsActionTypesEnum.RESET_CONFIG_CHANGES:
			return {
				...state,
				userPermissionType: USER_APP_PERMISSIONS_TYPES.undefined,
				startDate: null,
				endDate: null,
			};
		case AppPermissionsActionTypesEnum.SET_ERROR_MESSAGE:
			return {
				...state,
				errorMessage: action.payload,
			};
		case AppPermissionsActionTypesEnum.SET_SUCCESS_MESSAGE:
			return {
				...state,
				successMessage: action.payload,
			};
		default:
			return state;
	}
};
