import { Button, Icon, Modal } from '@acciona/ui-ionic-kit';
import { IonContent, IonFooter, IonTitle, IonToolbar } from '@ionic/react';
import { Props } from './types';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { useState } from 'react';

const ModalCSV: React.FC<Props> = (props) => {
	const { t } = useTranslation();
	const [file, setFile] = useState(null);

	const closeModal = () => {
		props.onClose();
	};

	const handleCancel = () => {
		setFile(null);
		props.onClose();
	};

	const handleSaveChanges = () => {
		props.saveChanges(file);
		props.onClose();
	};

	const onChange = (e) => {
		setFile({ file: e.target.files[0] });
	};

	return (
		<Modal isOpen={props.showModal} onDidDismiss={closeModal} className={styles.modal}>
			<IonToolbar className={styles.title}>
				<Button
					slot="end"
					fill={'clear'}
					color="light"
					className={styles.closeModalBtn}
					aria-label={t('lbl_aria_close_modal')}
					onClick={closeModal}
				>
					<Icon className="icon icon-close" aria-hidden="true"></Icon>
				</Button>
				<IonTitle className="centerTitle">
					<h3>
						<b>{t('lbl_csv_modal')}</b>
					</h3>
				</IonTitle>
			</IonToolbar>
			<IonContent>
				<div>
					<div className={`${styles.footnote} ${styles.text_csv}`}>{t('txt_csv')}</div>

					{props.hasWritePermission && (
						<label className={`${styles.input}`}>
							<span className={`${styles.body} ${!file && styles.color_dark}`}>
								{file ? (
									file.file.name
								) : (
									<>
										<u>{t('lbl_select_one')}</u> {t('or_add_file')}
									</>
								)}
							</span>
							<input type="file" accept=".xlsx" multiple={false} onChange={onChange} />
							<Icon className={`icon icon-arrow_down icon24 ${styles.iconRotate} ${styles.icon}`} />
						</label>
					)}

					<div className={styles.btnDownloadContainer}>
						<div>
							<Button
								className={styles.btnDownload}
								color="lighter"
								onClick={() => window.open(props.downloadCSV)}
							>
								<div className={`icon icon-xls icon24`} />
								<div>{t('lbl_download_csv')}</div>
							</Button>
						</div>
					</div>
				</div>
			</IonContent>
			<IonFooter className={styles.btnModal}>
				<div>
					<Button className={`${styles.btnHeader} ${styles.secondaryBtn}`} onClick={handleCancel}>
						{t('btn_cancel')}
					</Button>
				</div>

				{props.hasWritePermission && (
					<div>
						<Button color="primary" className={styles.btnHeader} disabled={!file} onClick={handleSaveChanges}>
							{t('btn_save')}
						</Button>
					</div>
				)}
			</IonFooter>
		</Modal>
	);
};

export default ModalCSV;
