import { DeskNotificationsConfig } from '../../../pages/Workstations/Notifications/types';
import muleSoftInstance from '../../utils/httpCommon';
import {
	TimeAndReservationsConfig,
	ReservationsConfig,
	TurningPointsConfig,
	PermissionsAndDistricts,
	ReservationPolicy,
	BuildingData,
	ErrorManagementConfig,
} from './types';

const downloadCSV = async (): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/v1/salto/template`;
	try {
		const resp = await muleSoftInstance.get(path);

		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const updateCSV = async (file): Promise<any> => {
	try {
		const formData = new FormData();
		formData.append('deskLockerExcel', file.file);

		const path = `${process.env.REACT_APP_API_PORTAL}/v1/deskLocker/file`;
		const config = {
			headers: {
				'content-type': 'multipart/form-data',
			},
		};
		const resp = await muleSoftInstance.post(path, formData, config);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const getTimeAndReservationsConfig = async (): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/desk/timeAndReservations`;
	try {
		const resp = await muleSoftInstance.get(path);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const updateTimeAndReservationsConfig = async (updatedData: TimeAndReservationsConfig): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/desk/timeAndReservations`;
	try {
		const resp = await muleSoftInstance.patch(path, updatedData);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};
const getPermissionsAndDistricts = async (): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/v2/configuration/desk/permissionsAndDistricts`;
	try {
		const resp = await muleSoftInstance.get(path);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const updatePermissionsAndDistricts = async (updatedData: PermissionsAndDistricts): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/configuration/desk/permissionsAndDistricts`;
	try {
		const resp = await muleSoftInstance.put(path, updatedData);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const getReservationsConfig = async (): Promise<ReservationsConfig> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/v2/configuration/desk/reservations`;
	try {
		const resp = await muleSoftInstance.get(path);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const updateReservationsConfig = async (updatedData: ReservationsConfig): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/v2/configuration/desk/reservations`;
	try {
		const resp = await muleSoftInstance.put(path, updatedData);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const getTurningPointsConfig = async (): Promise<TurningPointsConfig> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/configuration/desk/turningPoints`;
	try {
		const resp = await muleSoftInstance.get(path);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const updateTurningPointsConfig = async (updatedData: TurningPointsConfig): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/configuration/desk/turningPoints`;
	try {
		const resp = await muleSoftInstance.put(path, updatedData);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const deleteFavouriteDesks = async (idSede: number): Promise<any> => {
	const path = `${process.env.REACT_APP_API_HABITAT}/job/deleteFavouriteDesks?headOfficeId=${idSede}`;
	try {
		const resp = await muleSoftInstance.post(path, {});
		return resp.data;
	} catch (err) {
		// Error is ignored
	}
};

const sendBuildingOpeningEmails = async (idSede: number): Promise<any> => {
	const path = `${process.env.REACT_APP_API_HABITAT}/job/buildingOpeningEmails?headOfficeId=${idSede}`;
	try {
		const resp = await muleSoftInstance.post(path, {});
		return resp.data;
	} catch (err) {
		// Error is ignored
	}
};

const getDefaultSedePolicy = async (): Promise<ReservationPolicy[]> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/configuration/desk/defaultSedePolicy`;
	try {
		const resp = await muleSoftInstance.get(path);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const updateDefaultSedePolicy = async (updatedData: ReservationPolicy): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/configuration/desk/defaultSedePolicy`;
	try {
		const resp = await muleSoftInstance.put(path, updatedData);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const getBuildingData = async (): Promise<BuildingData[] | undefined> => {
	try {
		const url = `${process.env.REACT_APP_API_PORTAL}/v1/configuration/mapLegends`;
		const resp = await muleSoftInstance.get(url);
		return resp.data.buildings;
	} catch (err) {
		return undefined;
	}
};

const saveBuildingData = async (data: BuildingData[]): Promise<BuildingData[] | undefined> => {
	try {
		const url = `${process.env.REACT_APP_API_PORTAL}/v1/configuration/mapLegends`;
		const resp = await muleSoftInstance.put(url, { buildings: data });
		return resp.data.buildings;
	} catch (err) {
		return undefined;
	}
};
const getErrorManagementConfig = async (): Promise<ErrorManagementConfig> => {
	try {
		const url = `${process.env.REACT_APP_API_PORTAL}/configuration/desk/errorManagement`;
		const resp = await muleSoftInstance.get(url);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const updateErrorManagementConfig = async (updatedData: ErrorManagementConfig): Promise<any> => {
	try {
		const url = `${process.env.REACT_APP_API_PORTAL}/configuration/desk/errorManagement`;
		const resp = await muleSoftInstance.put(url, updatedData);
		return resp.data.buildings;
	} catch (err) {
		throw new Error(err);
	}
};

const getSedeLevels = async (): Promise<string[]> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/v1/levels/mash`;
	try {
		const resp = await muleSoftInstance.get(path);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};
const getDeskNotificationConfig = async (): Promise<DeskNotificationsConfig> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/configuration/desk/checkinNotifications`;
	try {
		const resp = await muleSoftInstance.get(path);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const updateDeskNotificationConfig = async (updatedData: DeskNotificationsConfig): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/configuration/desk/checkinNotifications`;
	try {
		const resp = await muleSoftInstance.put(path, updatedData);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};
export const workstationService = {
	downloadCSV,
	updateCSV,
	getTimeAndReservationsConfig,
	updateTimeAndReservationsConfig,
	getReservationsConfig,
	updateReservationsConfig,
	getTurningPointsConfig,
	updateTurningPointsConfig,
	getPermissionsAndDistricts,
	updatePermissionsAndDistricts,
	deleteFavouriteDesks,
	sendBuildingOpeningEmails,
	getDefaultSedePolicy,
	updateDefaultSedePolicy,
	getBuildingData,
	saveBuildingData,
	getErrorManagementConfig,
	updateErrorManagementConfig,
	getSedeLevels,
	getDeskNotificationConfig,
	updateDeskNotificationConfig,
};
