import { useEffect, useMemo, useState } from 'react';
import { IonLabel, IonLoading } from '@ionic/react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Button, Icon, List, Toast } from '@acciona/ui-ionic-kit';
import { District } from '../../../_api/services/workstation/types';
import useAppContext from '../../../hooks/useAppContext';
import { ModalAddZone } from '../../../components/ModalAddZone/ModalAddZone';
import { ZoneList } from '../../../components/ZonesList/ZoneList';
import { roomsServices } from '../../../_api/services/rooms';
import { OrganizerHostItem, RoomsDistricts } from '../../../_api/services/rooms/types';
import { ModalDelegatedReservation } from './components/ModalDelegatedReservation/ModalDelegatedReservation';
import styles from './styles.module.scss';
import { getUpdatedOrganizerHostsItems } from './helpers';

type Props = {
	setError?: Function;
	setSuccess?: Function;
	setInfo?: Function;
	hasWritePermission?: boolean;
	footerStyle: string;
};

const findDifferentDistricts = (newDistricts: District[], initialDistricts: District[]): District[] => {
	return newDistricts.filter(
		(district, index) =>
			!_.isEqual(district.overflowDistricts.sort(), initialDistricts[index].overflowDistricts.sort()),
	);
};

const hasEmptyValues = (formValues: any) =>
	Object.values(formValues).some((value) => value === null || value === undefined);

export const Permissions: React.FC<Props> = (props) => {
	const { t } = useTranslation();
	const { setError, setSuccess, hasWritePermission } = props;

	const defaultValues: RoomsDistricts = {
		districts: [],
	};
	const [showAddZoneModal, setShowAddZoneModal] = useState(false);
	const [showDelegatedReservationModal, setShowDelegatedReservationModal] = useState(false);
	const [overflowDistrictsValues, setOverflowDistrictsValues] = useState<RoomsDistricts>(defaultValues);
	const [toastMessage, setToastMessage] = useState(null);
	const [zoneToEdit, setZoneToEdit] = useState<District>(null);
	const [overflowDistricts, setOverflowDistricts] = useState<District[]>([]);
	const [roomsOrganizers, setRoomsOrganizers] = useState<any[]>([]);

	const queryClient = useQueryClient();
	const { setThereAreUnsavedChanges } = useAppContext();

	const { isLoading: isLoadingRoomsOrganizers, data: initialRoomsOrganizers } = useQuery(
		['roomsOrganizers'],
		async () => roomsServices.getRoomOrganizers(),
		{
			refetchOnWindowFocus: false,
			onError: (error) => setError(error as string),
			onSuccess: (data) => {
				setRoomsOrganizers(data);
			},
		},
	);

	const { isLoading: isLoadingOverflowDistricts, data: initialOverflowDistricts } = useQuery(
		['roomsOverflowDistricts'],
		async () => roomsServices.getOverflowDistricts(),
		{
			refetchOnWindowFocus: false,
			onError: (error) => setError(error as string),
			onSuccess: (data) => {
				setOverflowDistrictsValues(data);
				setOverflowDistricts(data.districts);
			},
		},
	);

	const { isLoading: loadingSaveRoomsOrganizers, mutate: saveRoomsOrganizers } = useMutation(
		roomsServices.updateRoomOrganizers,
		{
			onSuccess: () => {
				handleSaveRoomsOverflowDistricts();
				queryClient.refetchQueries('roomsOrganizers');
			},
			onError: (error) => {
				setError(error as string);
			},
		},
	);

	const { isLoading: loadingSaveRoomsOverflowDistricts, mutate: saveRoomsOverflowDistricts } = useMutation(
		roomsServices.updateOverflowDistricts,
		{
			onSuccess: () => {
				setSuccess(t('msg_success'));
				queryClient.refetchQueries('roomsOverflowDistricts');
			},
			onError: (error) => {
				setError(error as string);
			},
		},
	);

	const isEdited = useMemo(() => {
		return (
			!_.isEqual(initialOverflowDistricts, overflowDistrictsValues) ||
			!_.isEqual(initialOverflowDistricts.districts, overflowDistricts) ||
			!_.isEqual(initialRoomsOrganizers, roomsOrganizers)
		);
	}, [initialOverflowDistricts, overflowDistrictsValues, overflowDistricts, initialRoomsOrganizers, roomsOrganizers]);

	useEffect(() => {
		setThereAreUnsavedChanges(isEdited);
	}, [isEdited]);

	const handleAddZone = (zone: District) => {
		const districtExists = overflowDistricts.some((district) => district.districtName === zone.districtName);
		if (districtExists) {
			const updatedDistricts = overflowDistricts.map((district) => {
				if (district.districtName === zone.districtName) {
					return {
						...district,
						overflowDistricts: zone.overflowDistricts,
					};
				}
				return district;
			});
			setOverflowDistricts(updatedDistricts);
		} else {
			const newDistricts = [...overflowDistricts, zone];
			setOverflowDistricts(newDistricts);
		}
		setToastMessage(t('desk_permissions_toast.edit_add_confirm'));
	};

	const handleEditZone = (zone: string) => {
		const districtToEdit = overflowDistricts.find((d) => d.districtName === zone);
		setZoneToEdit(districtToEdit);
		setShowAddZoneModal(true);
	};

	const handleRemoveZone = (zone: string) => {
		const updatedDistricts = overflowDistricts.map((d) => {
			if (d.districtName === zone) {
				return { ...d, overflowDistricts: [] };
			} else {
				return d;
			}
		});
		setOverflowDistricts(updatedDistricts);
		setToastMessage(t('room_permissions_toast.delete_confirm'));
	};

	const handleOnSave = () => {
		const nextData = getUpdatedOrganizerHostsItems(initialRoomsOrganizers, roomsOrganizers);
		saveRoomsOrganizers(nextData);
	};

	const handleSaveRoomsOverflowDistricts = () => {
		const differentOverflowDistrictsObjects = findDifferentDistricts(
			overflowDistricts,
			initialOverflowDistricts.districts,
		);

		const updatedData = _.cloneDeep(overflowDistrictsValues);
		updatedData.districts = differentOverflowDistrictsObjects;

		if (hasEmptyValues(updatedData)) {
			setError(t('msg_error_all_fields_required'));
		} else {
			saveRoomsOverflowDistricts(updatedData);
		}
	};

	const handleOpenModalDelegatedReservation = () => {
		setShowDelegatedReservationModal(true);
	};

	const handleSaveDelegatedReservationModalChanges = (newData: OrganizerHostItem[]) => {
		setRoomsOrganizers(newData);
		setShowDelegatedReservationModal(false);
		setToastMessage(t('room_permissions_toast.edit_permission_confirm'));
	};

	return (
		<>
			{isLoadingOverflowDistricts || isLoadingRoomsOrganizers ? (
				<IonLoading
					isOpen={
						isLoadingOverflowDistricts ||
						isLoadingRoomsOrganizers ||
						loadingSaveRoomsOverflowDistricts ||
						loadingSaveRoomsOrganizers
					}
					message={t('msg_loading')}
					duration={0}
				/>
			) : (
				<>
					<ModalAddZone
						showModal={showAddZoneModal}
						onClose={() => {
							setZoneToEdit(null);
							setShowAddZoneModal(false);
						}}
						saveChanges={handleAddZone}
						hasWritePermission={hasWritePermission}
						districts={overflowDistricts}
						zoneToEdit={zoneToEdit}
					/>
					<ModalDelegatedReservation
						showModal={showDelegatedReservationModal}
						onClose={() => {
							setShowDelegatedReservationModal(false);
						}}
						onSave={handleSaveDelegatedReservationModalChanges}
						hasWritePermission={hasWritePermission}
						data={roomsOrganizers}
					/>
					<List>
						<div className={styles.element}>
							<h3 className={styles.blockTitle}>{t('delegated_reservation')}</h3>
							<p className={`${styles.blockSubtitle}`}>{t('delegated_reservation_description')}</p>

							<div className={styles.input} onClick={handleOpenModalDelegatedReservation}>
								<div>{t('tab_permissions')}</div>
								<div className={`${styles.align}`}>
									<div>{t('configure')}</div>
									<Icon className="icon icon-chevron-right icon24" slot="end" />
								</div>
							</div>
						</div>

						<div className={styles.element}>
							<h3 className={styles.blockTitle}>{t('districts_overflow')}</h3>
							<p className={`${styles.blockSubtitle}`}>{t('districts_overflow_description')}</p>
							<div>
								<ZoneList
									hasWritePermission={hasWritePermission}
									districtsConfig={overflowDistricts.filter((d) => d.overflowDistricts.length > 0)}
									handlerDeleteSubcat={handleRemoveZone}
									handlerEditZone={handleEditZone}
								/>
							</div>
							{hasWritePermission && (
								<Button
									slot="start"
									className={styles.addZoneBtn}
									onClick={() => {
										setShowAddZoneModal(true);
									}}
								>
									<Icon className="icon icon-plus iconPlus" />
									<IonLabel>{t('btn_add_zone')}</IonLabel>
								</Button>
							)}
						</div>
					</List>
					{hasWritePermission && (
						<div className={`${props.footerStyle} ${styles.footerButton}`}>
							<Button onClick={handleOnSave} className={styles.btnHeader} disabled={!isEdited} color="primary">
								{t('btn_save_data')}
							</Button>
						</div>
					)}

					<Toast
						isOpen={!!toastMessage}
						message={toastMessage}
						onDidDismiss={() => setToastMessage(null)}
						position="bottom"
						type="success"
					/>
				</>
			)}
		</>
	);
};
