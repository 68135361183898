export enum AppPermissionsActionTypesEnum {
	SET_INITIAL_STATE = 'SET_INITIAL_STATE',
	SET_PERMISSION = 'SET_PERMISSION',
	ADD_USERS = 'ADD_USERS',
	DELETE_USERS = 'DELETE_USERS',
	UPDATE_USERS = 'UPDATE_USERS',
	SET_USERS_DATA = 'SET_USERS_DATA',
	SET_PROFILES_LIST = 'SET_PROFILES_LIST',
	SET_SELECTED_PROFILES = 'SET_SELECTED_PROFILES',
	TOGGLE_SELECTED_PROFILE = 'TOGGLE_SELECTED_PROFILE',
	SET_IS_LOADING = 'SET_IS_LOADING',
	SET_USER_PERMISSION_TYPE = 'SET_USER_PERMISSION_TYPE',
	SET_START_DATE = 'SET_START_DATE',
	SET_END_DATE = 'SET_END_DATE',
	RESET_CONFIG_CHANGES = 'RESET_CONFIG_CHANGES',
	SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE',
	SET_SUCCESS_MESSAGE = 'SET_SUCCESS_MESSAGE',
}
