import { useState } from 'react';
import {
	IonButton,
	IonLabel,
	IonItem,
	IonText,
	IonIcon,
	IonPopover,
	IonContent,
	IonList,
	RefresherEventDetail,
	IonSpinner,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { ReorderGroup, Reorder, Button, Icon } from '@acciona/ui-ionic-kit';
import _ from 'lodash';
import { Subcategory } from '../../_api/services/incidents/types';
import { Toggle } from '../Toggle/Toggle';
import styles from './styles.module.scss';

type Props = {
	title?: string;
	titleClassName?: string;
	hasWritePermission: boolean;
	selectedLang: string;
	data: Subcategory[];
	keyField?: string;
	loading?: boolean;
	onItemReorder: (event: CustomEvent<RefresherEventDetail>) => void;
	onEdit: (id: any) => void;
	onDelete: (id: any) => void;
	onAdd: () => void;
	buttonAddText: string;
	buttonAddPosition?: 'start' | 'end';
};

export const EditableList: React.FC<Props> = (props) => {
	const {
		title,
		titleClassName,
		hasWritePermission,
		onItemReorder,
		selectedLang,
		data,
		keyField,
		loading,
		onEdit,
		onDelete,
		onAdd,
		buttonAddText,
		buttonAddPosition = 'start',
	} = props;
	const { t } = useTranslation();
	const [edit, setEdit] = useState(false);

	const handleChangeToggle = () => {
		setEdit(!edit);
	};

	return (
		<>
			{loading && (
				<div className="ion-text-center">
					<IonSpinner />
				</div>
			)}
			<div className={styles.editableListHeader}>
				<div className={styles.title}>
					<div className={titleClassName ? titleClassName : styles.h2}>{title}</div>
				</div>
				<div className={styles.editBtn}>
					<Toggle checked={edit} onChange={handleChangeToggle} />
					<IonLabel className={styles.body}>{t('lbl_edit')}</IonLabel>
				</div>
			</div>

			{hasWritePermission && buttonAddPosition === 'start' ? (
				<Button slot="start" className={styles.editableListBtnAdd} onClick={onAdd}>
					<Icon className="icon icon-plus iconPlus" />
					<IonLabel>{buttonAddText}</IonLabel>
				</Button>
			) : null}

			<div className={styles.pageContent}>
				<ReorderGroup disabled={!hasWritePermission || edit} onIonItemReorder={(e) => onItemReorder(e)}>
					{!_.isEmpty(data) &&
						_.sortBy(data, 'ordinal')
							?.filter((e) => !e.disabled)
							.map((item) => {
								const itemId = !keyField ? item.id : item[keyField];
								const itemData = item?.translations.find(
									(translation) => translation.language === selectedLang,
								);
								return (
									<Reorder key={itemId}>
										<IonItem>
											<IonLabel className="ion-text-wrap">
												<IonText className={`${styles.body} ion-text-wrap block`}>{itemData.name}</IonText>
												<IonText className={`${styles.footnote} ion-text-wrap block`}>
													{itemData.description}
												</IonText>
											</IonLabel>
											{edit ? (
												<>
													<IonButton
														slot="end"
														fill="clear"
														id={`subcat-popover-${itemId}`}
														className={styles.buttonEdit}
													>
														<IonIcon slot="end" className="icon icon-ellipsis-vertical" />
													</IonButton>
													<IonPopover
														trigger={`subcat-popover-${itemId}`}
														dismissOnSelect={true}
														side="bottom"
														alignment="end"
														className={styles.popoverSty}
													>
														<IonContent>
															<IonList className={styles.listPadding}>
																<IonItem
																	lines="full"
																	button={true}
																	onClick={() => onEdit(itemId)}
																	detail={false}
																>
																	<IonLabel className={styles.body}>{t('lbl_modify')}</IonLabel>
																</IonItem>
																{hasWritePermission && (
																	<IonItem
																		lines="full"
																		button={true}
																		onClick={() => onDelete(itemId)}
																		detail={false}
																	>
																		<IonLabel className={styles.body}>{t('delete_text')}</IonLabel>
																	</IonItem>
																)}
															</IonList>
														</IonContent>
													</IonPopover>
												</>
											) : (
												hasWritePermission && <IonIcon slot="end" className="icon icon-reordenar" />
											)}
										</IonItem>
									</Reorder>
								);
							})}
				</ReorderGroup>
			</div>
			<div className={styles.separator}></div>
			{hasWritePermission && buttonAddPosition === 'end' ? (
				<Button slot="start" className={styles.editableListBtnAdd} onClick={onAdd}>
					<Icon className="icon icon-plus iconPlus" />
					<IonLabel>{buttonAddText}</IonLabel>
				</Button>
			) : null}
		</>
	);
};
