import _ from 'lodash';
import { AppPermission, Employee, Profile, UserPermissionType } from '../../_api/services/roles/types';
import { AppPermissionsActionTypesEnum } from './AppPermissions.actions.types';
import { AppPermissionsAction } from './AppPermissions.model';

const setInitialState = (): AppPermissionsAction => {
	return {
		type: AppPermissionsActionTypesEnum.SET_INITIAL_STATE,
	};
};

const setPermission = (permission: AppPermission): AppPermissionsAction => {
	return {
		type: AppPermissionsActionTypesEnum.SET_PERMISSION,
		payload: permission,
	};
};

const setProfilesList = (profilesList: any[]): AppPermissionsAction => {
	return {
		type: AppPermissionsActionTypesEnum.SET_PROFILES_LIST,
		payload: profilesList,
	};
};

const setSelectedProfiles = (selectedProfiles: Profile[]): AppPermissionsAction => {
	return {
		type: AppPermissionsActionTypesEnum.SET_SELECTED_PROFILES,
		payload: !_.isEmpty(selectedProfiles) ? selectedProfiles.map((profile) => profile.profileId) : [],
	};
};

const toggleSelectedProfile = (selectedProfile: string): AppPermissionsAction => {
	return {
		type: AppPermissionsActionTypesEnum.TOGGLE_SELECTED_PROFILE,
		payload: parseInt(selectedProfile),
	};
};

const deleteUsers = (deletedUsers: number[]): AppPermissionsAction => {
	return {
		type: AppPermissionsActionTypesEnum.DELETE_USERS,
		payload: deletedUsers,
	};
};

const addUsers = (usersData: Employee[]): AppPermissionsAction => {
	return {
		type: AppPermissionsActionTypesEnum.ADD_USERS,
		payload: usersData,
	};
};

const setIsLoading = (loading: boolean): AppPermissionsAction => {
	return {
		type: AppPermissionsActionTypesEnum.SET_IS_LOADING,
		payload: loading,
	};
};

export const updateUsers = (updatedUsersData: Employee[]) => {
	return {
		type: AppPermissionsActionTypesEnum.UPDATE_USERS,
		payload: updatedUsersData,
	};
};

export const setUsersData = (usersData: Employee[]) => {
	return {
		type: AppPermissionsActionTypesEnum.SET_USERS_DATA,
		payload: usersData ?? [],
	};
};

export const setUserPermissions = (permission: UserPermissionType | string) => {
	return {
		type: AppPermissionsActionTypesEnum.SET_USER_PERMISSION_TYPE,
		payload: permission,
	};
};

export const setStartDate = (date: Date) => {
	return {
		type: AppPermissionsActionTypesEnum.SET_START_DATE,
		payload: date,
	};
};

export const setEndDate = (date: Date) => {
	return {
		type: AppPermissionsActionTypesEnum.SET_END_DATE,
		payload: date,
	};
};

export const resetConfigChanges = () => {
	return {
		type: AppPermissionsActionTypesEnum.RESET_CONFIG_CHANGES,
	};
};

export const setErrorMessage = (message: string) => {
	return {
		type: AppPermissionsActionTypesEnum.SET_ERROR_MESSAGE,
		payload: message,
	};
};

export const setSuccessMessage = (message: string) => {
	return {
		type: AppPermissionsActionTypesEnum.SET_SUCCESS_MESSAGE,
		payload: message,
	};
};

export const AppPermissionsActions = {
	setInitialState,
	setPermission,
	setProfilesList,
	setSelectedProfiles,
	toggleSelectedProfile,
	addUsers,
	deleteUsers,
	setIsLoading,
	updateUsers,
	setUsersData,
	setUserPermissions,
	setStartDate,
	setEndDate,
	resetConfigChanges,
	setErrorMessage,
	setSuccessMessage,
};
