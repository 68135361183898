import { useTranslation } from 'react-i18next';
import { Icon } from '@acciona/ui-ionic-kit';
import { CONFIGURATION_PREFERENCES } from '../../../utils/constants';
import useAppContext from '../../../hooks/useAppContext';
import { toTitlecase } from '../../../utils/functions';
import styles from '../styles.module.scss';

export type Props = {
	showLogoutModal: () => void;
	showChangeLanguageModal: () => void;
	showChangeSedeModal: () => void;
	allowPreferencesChange: boolean;
};

export const FixedMenu: React.FC<Props> = (props) => {
	const { userLanguage, configurationPreference, sedePortal } = useAppContext();
	const { t } = useTranslation();

	const handleLogout = () => {
		props.showLogoutModal();
	};

	const handleChangeLanguage = () => {
		props.showChangeLanguageModal();
	};

	const handleChangeCampus = () => {
		props.showChangeSedeModal();
	};

	const languages = {
		es: t('language_es'),
		en: t('language_en'),
		pt: t('language_pt'),
	};

	const fixedMenuItems = [
		{
			id: 1,
			text: languages[userLanguage?.code],
			icon: 'icon-globe',
			onClick: handleChangeLanguage,
			disabled: false,
			visible: true,
		},
		{
			id: 2,
			text:
				configurationPreference === CONFIGURATION_PREFERENCES.transversal.id
					? toTitlecase(CONFIGURATION_PREFERENCES.transversal.id)
					: sedePortal?.description ?? '',
			icon: 'icon-campus',
			...(props.allowPreferencesChange ? { onClick: handleChangeCampus } : {}),
			disabled: !props.allowPreferencesChange,
			visible: props.allowPreferencesChange,
		},
		{
			id: 3,
			text: t('lbl_profile_close_sesion'),
			icon: 'icon-logout',
			onClick: handleLogout,
			disabled: false,
			visible: true,
		},
	];

	return (
		<div className={styles.fixedMenu}>
			{fixedMenuItems
				.filter((m) => m.visible)
				.map((item) => (
					<div
						key={item.id}
						className={`${styles.itemList} ${item.disabled ? styles.disabled : ''}`}
						onClick={item.onClick}
					>
						<div>
							<Icon className={`icon ${item.icon} icon20`} slot="start"></Icon>
							<div className={styles.footnote}>{item.text}</div>
						</div>
					</div>
				))}
		</div>
	);
};
