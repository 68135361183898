import { IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';
import styles from './styles.module.scss';

type Props = {
	name: string;
	label: string;
	value: string;
	defaultValue?: string;
	disabled: boolean;
	isValid?: boolean;
	isPlaceholder?: boolean;
	onChange?: (e: any) => void;
};

const options = [
	'00:00',
	'01:00',
	'02:00',
	'03:00',
	'04:00',
	'05:00',
	'06:00',
	'07:00',
	'08:00',
	'09:00',
	'10:00',
	'11:00',
	'12:00',
	'13:00',
	'14:00',
	'15:00',
	'16:00',
	'17:00',
	'18:00',
	'19:00',
	'20:00',
	'21:00',
	'22:00',
	'23:00',
];

export const HourSelector = (props: Props) => {
	const { name, label, value, defaultValue = '', disabled, isValid = true, onChange } = props;

	return (
		<IonItem
			lines="none"
			className={`${styles.inputWithLabel} ${!isValid && styles.errorInput} ${
				defaultValue !== value && styles.touchedInput
			}`}
		>
			<IonLabel slot="start" className="lblSelector">
				{label}
			</IonLabel>
			<IonSelect
				name={name}
				slot="end"
				className={`lblSelector ${styles.selector} ${disabled ? styles.disabled : ''}`}
				interface="popover"
				onIonChange={onChange}
				disabled={disabled}
				value={value}
				aria-label={name}
			>
				{disabled ? (
					<IonSelectOption value={value}>{value}</IonSelectOption>
				) : (
					options.map((hour) => {
						return (
							<IonSelectOption key={`name-${hour}`} value={hour}>
								{hour}
							</IonSelectOption>
						);
					})
				)}
			</IonSelect>
		</IonItem>
	);
};
