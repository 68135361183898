import { useTranslation } from 'react-i18next';
import { Employee } from '../../../../_api/services/roles/types';
import { CustomSelect } from '../../../../components/CustomSelect/CustomSelect';
import { DateSelector } from '../../../../components/DateSelector/DateSelector';
import { USER_APP_PERMISSIONS_TYPES } from '../../../../utils/constants';
import { addDays } from '../../../../utils/functions';
import styles from './styles.module.scss';

const stringToDate = (date: string): Date => {
	if (!date) {
		return null;
	}
	return new Date(date);
};

type Props = {
	user: Employee;
	setStartDate: (date: Date, employeeId?: number) => void;
	setEndDate: (date: Date, employeeId?: number) => void;
	setPermissionSelected: (permission: string, employeeId?: number) => void;
	samePermissionForAllUsers?: boolean;
};

export const ConfigurationInputs = (props: Props) => {
	const { user, setStartDate, setEndDate, setPermissionSelected, samePermissionForAllUsers } = props;
	const { t } = useTranslation();

	return (
		<div className={styles.options}>
			<CustomSelect
				options={Object.values(USER_APP_PERMISSIONS_TYPES).map((item) => {
					return {
						value: item,
						label: t(`app_permissions.permission_types.${item}`),
					};
				})}
				optionSelected={user?.permissionType ?? USER_APP_PERMISSIONS_TYPES.undefined}
				selectOption={(value) => setPermissionSelected(value, samePermissionForAllUsers ? null : user.employeeId)}
				label={t('lbl_type_of_permission')}
			/>
			<div className={styles.datesSelectors}>
				{[USER_APP_PERMISSIONS_TYPES.start_date, USER_APP_PERMISSIONS_TYPES.temporal].includes(
					user.permissionType,
				) && (
					<DateSelector
						name="start"
						id={samePermissionForAllUsers ? 0 : user.employeeId}
						label={t('lbl_start')}
						date={stringToDate(user.startDate)}
						minDate={null}
						maxDate={user.endDate ? addDays(stringToDate(user.endDate), -1) : null}
						setDate={setStartDate}
						placeholder={t('lbl_select')}
					/>
				)}
				{user.permissionType === USER_APP_PERMISSIONS_TYPES.temporal && (
					<DateSelector
						name="end"
						id={samePermissionForAllUsers ? null : user.employeeId}
						label={t('lbl_end')}
						date={stringToDate(user.endDate)}
						minDate={user.startDate ? addDays(stringToDate(user.startDate), 1) : null}
						maxDate={null}
						setDate={setEndDate}
						placeholder={t('lbl_select')}
					/>
				)}
			</div>
		</div>
	);
};
