import { useTranslation } from 'react-i18next';

import { IonItem, IonSelect, IonSelectOption } from '@ionic/react';
import { MenuItem } from '../../../_api/services/generalServices/types';
import { TextInput } from '../../../components/TextInput/TextInput';
import { Icon } from '@acciona/ui-ionic-kit';

import { availableServiceIcons } from '../helper';

import styles from '../styles.module.scss';
import { useState } from 'react';
import CustomModal from '../../../components/CustomModal/CustomModal';

type Props = {
	services: MenuItem[];
	lang: string;
	handleInput: (id: number, key: 'text' | 'icon', value: string) => void;
	handleDelete: (id: number) => void;
	userHasPermissions: boolean;
	setSuccess: Function;
};

const ServicesTransversalList = ({
	services,
	handleInput,
	handleDelete,
	lang,
	userHasPermissions,
	setSuccess,
}: Props) => {
	const { t } = useTranslation();
	const [showAlertDelService, setShowAlertDelService] = useState(false);
	const [selectedIdToDelete, setSelectedIdToDelete] = useState<number | null>(null);

	const handleDeleteService = (id: number) => {
		setSelectedIdToDelete(id);
		setShowAlertDelService(true);
	};

	return (
		<>
			{services
				?.sort((a, b) => a.id - b.id)
				.map(({ id, translations, startIcon }) => {
					const { text } = translations?.find((t) => t.language === lang) || {};
					return (
						<div key={id} className={styles.serviceInputRow}>
							<TextInput
								value={text}
								name={text}
								label={t('lbl_service_name')}
								className={`${styles.serviceNameInput} ion-text-end`}
								onIonChange={(e) => handleInput(id, 'text', e.detail.value)}
								maxlength={30}
								disabled={!userHasPermissions}
							/>
							<IonItem lines="none" className={styles.serviceIconInput}>
								<IonSelect
									slot="start"
									className={`lblSelector ${styles.selector}`}
									interface="popover"
									value={startIcon}
									onIonChange={(e) => handleInput(id, 'icon', e.detail.value)}
									disabled={!userHasPermissions}
									label={t('icon')}
								>
									{availableServiceIcons.map(({ label, value }) => {
										return (
											<IonSelectOption key={value} value={value}>
												{label}
											</IonSelectOption>
										);
									})}
								</IonSelect>
								<Icon className={`${startIcon} icon24`} slot="end"></Icon>
							</IonItem>
							{userHasPermissions && (
								<Icon
									className={`${styles.serviceIconDelete} icon icon-delete icon24 `}
									onClick={() => handleDeleteService(id)}
								></Icon>
							)}
						</div>
					);
				})}
			<CustomModal
				showModal={showAlertDelService}
				onConfirm={() => {
					handleDelete(selectedIdToDelete);
					setShowAlertDelService(false);
					setSuccess(t('msg_success_delete_service'));
				}}
				onClose={() => {
					setShowAlertDelService(false);
					setSelectedIdToDelete(null);
				}}
				title={t('service_delete_modal.title')}
				description={t('service_delete_modal.description')}
			></CustomModal>
		</>
	);
};

export default ServicesTransversalList;
