import { FilterFn } from '@tanstack/react-table';
import { getUniqueObjectsInArray, includesQueryString } from './functions';
import _ from 'lodash';

export const rowIsSelected = <T>(selected: T[], row: T, key: string) => {
	if (!selected || _.isEmpty(selected)) {
		return false;
	}

	const selectedKeys = selected && selected.map((s) => s[key]);
	return selectedKeys.includes(row[key]);
};

export const allRowsAreSelected = <T>(data: T[], selected: T[], key: string) =>
	data.length > 0 && data.every((row) => rowIsSelected(selected, row, key));

export const getNewSelectedRows = <T>(previousSelectedRows: T[], rowSelected: T, key: string) => {
	if (_.isEmpty(previousSelectedRows)) {
		return [rowSelected];
	}

	if (rowIsSelected(previousSelectedRows, rowSelected, key)) {
		return previousSelectedRows.filter((item) => item[key] !== rowSelected[key]);
	} else {
		return [...previousSelectedRows, rowSelected];
	}
};

export const getNewAllSelectedRows = <T>(previousSelectedRows: T[], currentPageRows: T[], key: string) => {
	const currentPageRowsIds = currentPageRows.map((row) => row[key]);
	if (previousSelectedRows.length === 0) {
		return currentPageRows;
	} else if (currentPageRows.every((row) => rowIsSelected(previousSelectedRows, row, key))) {
		return previousSelectedRows.filter((item) => !currentPageRowsIds.includes(item[key]));
	} else {
		return getUniqueObjectsInArray([...previousSelectedRows, ...currentPageRows], key);
	}
};

export const customTableFilter: FilterFn<any> = (row, columnId, filterValue) => {
	return (
		includesQueryString(row.getValue(columnId), filterValue) ||
		includesQueryString(row.original?.employeeId ? row.original?.employeeId.toString() : '', filterValue)
	);
};
