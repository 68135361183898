import React, { useContext, useEffect, useState } from 'react';
import { LanguageSelector } from '../../../components/LanguageSelector/LanguageSelector';

import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { IonButton, IonItem, IonList, IonLoading, IonTitle } from '@ionic/react';
import { Button, Icon } from '@acciona/ui-ionic-kit';
import { workstationService } from '../../../_api/services/workstation';
import { BuildingData } from '../../../_api/services/workstation/types';
import LegendsModal from './components/LegendsModal';
import { ISelected, ISpace } from './types';
import AppContext from '../../../context/App/App.context';

type Props = {
	setError?: Function;
	setSuccess?: Function;
	setInfo?: Function;
	hasWritePermission?: boolean;
	footerStyle: string;
};

const Legends: React.FC<Props> = ({ hasWritePermission, setError, setSuccess, footerStyle }) => {
	const [selectedLang, setSelectedLang] = useState('es');
	const { t } = useTranslation();
	const [dbBuildingData, setDbBuildingData] = useState<BuildingData[]>([]);
	const [savedBuildingData, setSavedBuildingData] = useState<BuildingData[]>([]);
	const [buildingData, setBuildingData] = useState<BuildingData[]>([]);
	const [isEdited, setIsEdited] = useState(false);
	const [showFloorModal, setShowFloorModal] = useState(false);
	const [selected, setSelected] = useState<ISelected>({
		building: undefined,
		floor: undefined,
	});

	const [isLoading, setIsLoading] = useState(false);
	const [isSaving, setIsSaving] = useState(false);

	const { setThereAreUnsavedChanges } = useContext(AppContext);

	const handleEditFloor = (building: ISpace, floor: ISpace): void => {
		setSelected({ building, floor });
		setShowFloorModal(true);
	};

	const closeModal = () => {
		setShowFloorModal(false);
	};

	const discardModal = () => {
		setSavedBuildingData(() => {
			const savedObject = JSON.parse(JSON.stringify(savedBuildingData));
			const dbFloorLegends = dbBuildingData
				.find((b) => b.id === selected.building.id)
				.floors.find((f) => f.id === selected.floor.id).legends;
			const floor = savedObject
				.find((b) => b.id === selected.building.id)
				.floors.find((f) => f.id === selected.floor.id);
			floor.legends = JSON.parse(JSON.stringify(dbFloorLegends));
			setBuildingData(savedObject);
			return savedObject;
		});
		closeModal();
	};

	const acceptModal = () => {
		closeModal();
		setSavedBuildingData(JSON.parse(JSON.stringify(buildingData)));
		setSuccess(t('map_legends_modal_closed', { building: selected.building.name, floor: selected.floor.name }));
	};

	const addNewLegend = (): void => {
		setBuildingData((oldState: BuildingData[]) => {
			const newState = JSON.parse(JSON.stringify(oldState));
			const floor = newState
				.find((b) => b.id === selected.building.id)
				.floors.find((f) => f.id === selected.floor.id);
			const newId =
				floor.legends.reduce((acc, curr) => {
					return Math.max(acc, curr.id);
				}, 0) + 1;
			floor.legends.push({
				id: newId,
				action: 'new',
				abbreviation: '',
				translations: [
					{
						language: 'es',
						translation: '',
					},
					{
						language: 'en',
						translation: '',
					},
					{
						language: 'pt',
						translation: '',
					},
				],
			});
			return newState;
		});
	};

	const editLegend = (id: number, field: 'abbreviation' | 'description', value: string): void => {
		setBuildingData((oldState: BuildingData[]) => {
			const newState = JSON.parse(JSON.stringify(oldState));
			if (field === 'description') {
				const legend = newState
					.find((b) => b.id === selected.building.id)
					.floors.find((f) => f.id === selected.floor.id)
					.legends.find((l) => l.id === id);
				const description = legend.translations?.find((t) => t.language === selectedLang);

				if (description) {
					if (legend.action !== 'new') {
						description.translation = value;
						legend.action = 'edit';
					} else {
						if (description.translation === '')
							legend.translations.forEach((t) => {
								t.translation = value;
							});
						else {
							description.translation = value;
						}
					}
				}
			}
			if (field === 'abbreviation') {
				const legend = newState
					.find((b) => b.id === selected.building.id)
					.floors.find((f) => f.id === selected.floor.id)
					.legends.find((l) => l.id === id);
				if (legend) {
					if (legend.action !== 'new') {
						legend.action = 'edit';
					}
					legend.abbreviation = value;
				}
			}

			return newState;
		});
	};

	const editLegendDescription = (id: number, value: string): void => {
		editLegend(id, 'description', value);
	};

	const editLegendAbbreviation = (id: number, value: string): void => {
		editLegend(id, 'abbreviation', value);
	};

	const removeLegend = (id: number): void => {
		setBuildingData((oldState: BuildingData[]) => {
			const newState = JSON.parse(JSON.stringify(oldState)) as BuildingData[];
			const floor = newState
				.find((b) => b.id === selected.building.id)
				.floors.find((f) => f.id === selected.floor.id);
			const legend = floor.legends.find((l) => l.id === id);
			if (legend.action === 'new') {
				floor.legends = floor.legends.filter((l) => l.id !== id);
			} else {
				floor.legends = floor.legends.map((l) => (l.id === id ? { ...l, action: 'delete' } : l));
			}
			return newState;
		});
	};

	const generateObjectToSend = (): BuildingData[] => {
		return savedBuildingData
			.map((b) => {
				const floors = b.floors
					.map((f) => {
						const legends = f.legends.filter((l) => {
							return !!l.action;
						});
						return legends.length ? { ...f, legends } : undefined;
					})
					.filter((f) => !!f);
				return floors.length ? { ...b, floors } : undefined;
			})
			.filter((b) => !!b);
	};

	const saveData = async (): Promise<void> => {
		const dataToSend = generateObjectToSend();
		setIsSaving(true);
		const resp = await workstationService.saveBuildingData(dataToSend);
		if (resp) {
			// toast success
			setSuccess(t('msg_success'));
			setDbBuildingData(resp);
			setSavedBuildingData(resp);
			setBuildingData(resp);
			setIsEdited(false);
		} else {
			// toast error
			setError(t('default_error'));
		}
		setIsSaving(false);
	};

	const updateBuildingData = async (): Promise<void> => {
		setIsLoading(true);
		const updatedBuildingData = await workstationService.getBuildingData();
		if (updatedBuildingData === undefined) {
			setError(t('default_error'));
			return;
		}
		setIsLoading(false);
		setBuildingData(updatedBuildingData);
		setSavedBuildingData(updatedBuildingData);
		setDbBuildingData(updatedBuildingData);
	};

	const checkChanges = (): void => {
		const checkEditted = !!JSON.stringify(savedBuildingData).match(/"action":"[a-z]+"/);
		setIsEdited(checkEditted);
	};

	useEffect(() => {
		updateBuildingData();
	}, []);

	useEffect(() => {
		setThereAreUnsavedChanges(isEdited);
	}, [isEdited]);

	useEffect(() => {
		checkChanges();
	}, [savedBuildingData]);

	return (
		<>
			<LanguageSelector selectedLang={selectedLang} setSelectedLang={setSelectedLang} />
			<div>
				<div className={styles.info}>
					<h2 className={styles.h2}>{t('anchor_legends')}</h2>
					<p className={`${styles.blockSubtitle}`}>{t('anchor_legends_description')}</p>
				</div>
				{savedBuildingData &&
					savedBuildingData.map((building) => {
						return (
							<div key={building.id} className={styles.building}>
								<IonTitle className={styles.buildingTitle}>{building.name}</IonTitle>
								<IonList>
									{building.floors.map((floor) => {
										const legends = floor.legends
											.filter((l) => l.action !== 'delete')
											.map((l) => l.abbreviation)
											.filter((v) => !!v);
										const description = legends.length > 0 ? legends.join(' | ') : '-';
										return (
											<IonItem key={floor.id}>
												<div className={styles.floorData}>
													<span>{floor.name}</span>
													<span>{description}</span>
												</div>

												<IonButton
													slot="end"
													fill="clear"
													onClick={() => {
														handleEditFloor(
															{ id: building.id, name: building.name },
															{ id: floor.id, name: floor.name },
														);
													}}
													className={styles.buttonEdit}
												>
													<Icon className="icon icon-edit icon24" />
												</IonButton>
											</IonItem>
										);
									})}
								</IonList>
							</div>
						);
					})}
			</div>
			{hasWritePermission && (
				<div className={`${footerStyle} ${styles.footerButton}`}>
					<Button
						className={styles.btnHeader}
						onClick={saveData}
						disabled={!hasWritePermission || !isEdited || isSaving}
						color="primary"
					>
						{t('btn_save_data')}
					</Button>
				</div>
			)}
			<LegendsModal
				showFloorModal={showFloorModal}
				discardModal={discardModal}
				acceptModal={acceptModal}
				dismissModal={closeModal}
				selected={selected}
				selectedLang={selectedLang}
				editBuildingData={buildingData}
				editLegendAbbreviation={editLegendAbbreviation}
				editLegendDescription={editLegendDescription}
				removeLegend={removeLegend}
				addNewLegend={addNewLegend}
				hasWritePermission={hasWritePermission}
			/>
			<IonLoading isOpen={isLoading && !buildingData} message={t('msg_loading')} duration={10000} />
		</>
	);
};

export default Legends;
