import _ from 'lodash';
import { PolicyCategory, PolicyTranslation } from '../../../../../_api/services/terms/types';
import { availableLanguages } from '../../../../../i18nextConf';
import { getNext } from '../../../../../utils/functions';

export const addNewCategory = (prevState: PolicyCategory[], addCategory: PolicyCategory) => {
	const newCategory = {
		...addCategory,
		id: getNext(prevState, 'id'),
		ordinal: getNext(prevState, 'ordinal'),
	};
	return _.sortBy([...prevState, newCategory], 'ordinal');
};

export const updateCategoryToAdd = (prevData: PolicyCategory, value: string) => {
	return {
		...prevData,
		translations: availableLanguages.map((language) => ({
			language: language,
			name: value,
		})),
	};
};

export const editCategory = (id: number, name: string, previousState: PolicyCategory[], lang: string) => {
	const changedItem = previousState.find((o) => o.id === id);

	if (!changedItem) {
		return previousState;
	}

	const updatedTranslations = changedItem.translations.map((translation) => {
		if (translation.language === lang) {
			return { ...translation, name: name };
		}
		return translation;
	});

	return [...previousState.filter((o) => o.id !== id), { ...changedItem, translations: updatedTranslations }];
};

export const deleteCategory = (id: number, previousData: PolicyCategory[]) => {
	const ordinal = _.first(previousData.filter((item) => item.id === id)).ordinal;
	return previousData.map((o) => {
		if (o.id === id) {
			return { ...o, disabled: true, ordinal: null };
		} else {
			if (o.ordinal > ordinal) {
				return { ...o, ordinal: o.ordinal - 1 };
			} else {
				return o;
			}
		}
	});
};

export const inputIsValid = (categoryToAdd: PolicyCategory) => {
	return !_.isEqual(categoryToAdd.translations[0], {}) || getCategoryName(categoryToAdd) !== '';
};

export const getCategoriesData = (categories: PolicyCategory[], lang: string): { id: number; name: string }[] => {
	if (_.isEmpty(categories)) {
		return [];
	}

	return _.sortBy(categories, 'ordinal')
		.filter((c) => !c.disabled)
		.map((cat) => {
			const categoriesData = cat?.translations.find((e: PolicyTranslation) => e.language === lang);
			return {
				id: cat.id,
				name: categoriesData?.name ?? '',
			};
		});
};

export const someFieldIsEmpty = (categories: PolicyCategory[]) => {
	return categories.some((c) => c.translations.some((t) => _.isEmpty(t.name)));
};

export const getCategoryName = (category: PolicyCategory) => {
	if (_.isEmpty(category?.translations)) {
		return '';
	}

	return _.first(category.translations)?.name ?? '';
};

export const contentHasBeenEdited = (initialData: PolicyCategory[], changes: PolicyCategory[]) => {
	const previous = !_.isEmpty(initialData) ? initialData.filter((item) => !item.disabled) : [];
	const next = !_.isEmpty(changes) ? changes.filter((item) => !item.disabled) : [];

	if (_.isEmpty(previous) !== _.isEmpty(next)) {
		return true;
	}

	const previousSorted = _.sortBy(previous, 'id', 'ordinal');
	const nextSorted = _.sortBy(next, 'id', 'ordinal');

	return !_.isEqual(previousSorted, nextSorted);
};
