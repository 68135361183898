import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { communicationsServices } from '../../../_api/services/Communications';
import useAppContext from '../../../hooks/useAppContext';
import { DashboardView } from './DashboardView';
import { ImagesDashboardIndexed, TextsDashboardIndexed } from '../../../_api/services/Communications/types';
import {
	transformTextsFromResponse,
	transformTextsForRequest,
	transformImagesFromResponse,
	setInitialFormImages,
} from './helpers';

const maxwidth = 2000;
const maxheight = 1440;
const maxSize = 2097152; // 2Mb

type Props = {
	onEdited?: Function;
	onChanged?: Function;
	communicationChanges?: any;
	setError?: Function;
	setSuccess?: Function;
	hasWritePermission?: boolean;
	footerStyle: string;
};

export const Dashboard: React.FC<Props> = (props) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const { setThereAreUnsavedChanges } = useAppContext();
	const [selectedLanguage, setSelectedLanguage] = useState('es');
	const [dataTexts, setDataTexts] = useState<TextsDashboardIndexed>(null);
	const [dataImages, setDataImages] = useState<ImagesDashboardIndexed>(null);
	const [imagesEdited, setImagesEdited] = useState(false);
	const [isEdited, setIsEdited] = useState(false);
	const formData = useRef<any>(new FormData(document.forms[0]));

	useEffect(() => {
		setThereAreUnsavedChanges(isEdited);
	}, [isEdited]);

	const { isLoading: isLoadingImages } = useQuery(
		['dashboardImages'],
		async () => await communicationsServices.getDashboardImages(),
		{
			refetchOnWindowFocus: false,
			onSuccess: (response) => {
				setDataImages(transformImagesFromResponse(response));
				setInitialFormImages(response, formData);
			},
			onError: (error) => props.setError(error as string),
		},
	);
	const { data: dashboardText, isLoading: isLoadingTexts } = useQuery(
		['dashboardText'],
		async () => await communicationsServices.getDashboardTexts(),
		{
			refetchOnWindowFocus: false,
			onError: (error) => props.setError(error as string),
		},
	);
	const { mutate: saveDashboardImages, isLoading: isLoadingSaveImages } = useMutation(
		communicationsServices.saveDashboardImages,
		{
			onSuccess: () => {
				props.setSuccess(t('msg_success'));
				setIsEdited(false);
				queryClient.refetchQueries();
			},
			onError: (error) => {
				props.setError(error as string);
			},
		},
	);
	const { mutate: saveText, isLoading: isLoadingSaveText } = useMutation(communicationsServices.saveDashboardTexts, {
		onSuccess: () => {
			if (imagesEdited) {
				saveDashboardImages(formData.current);
			} else {
				props.setSuccess(t('msg_success'));
				queryClient.refetchQueries();
			}
			setIsEdited(false);
		},
		onError: (error) => {
			props.setError(error as string);
		},
	});

	const changeImageHandler = ({
		event,
		language,
		platform,
	}: {
		event: ChangeEvent<HTMLInputElement>;
		language: string;
		platform: string;
	}) => {
		const file = event.target.files[0];

		if (!file || !language || !platform) {
			return;
		}

		const field = `${language}_${platform}`;

		const img = new Image();
		img.src = URL.createObjectURL(file);
		img.onload = async () => {
			if (img.width > maxwidth || img.height > maxheight || file.size > maxSize) {
				props.setError('Tamaño de imagen incorrecto');
				return;
			}
			formData.current.set(field, file);

			const nextDataImages = _.cloneDeep(dataImages);
			nextDataImages[language][platform] = file.name;
			setDataImages(nextDataImages);

			setImagesEdited(true);
			setIsEdited(true);
		};
	};

	const changeTextHandler = ({ language, platform, text }: { language: string; platform: string; text: string }) => {
		const nextDataText = _.cloneDeep(dataTexts);
		nextDataText[language][platform] = text;
		setDataTexts(nextDataText);
	};

	const saveTextHandler = (text: TextsDashboardIndexed) => {
		saveText(transformTextsForRequest(text));
	};

	useEffect(() => {
		props.setError('');
	}, []);

	useEffect(() => {
		dashboardText && setIsEdited(!_.isEqual(dataTexts, transformTextsFromResponse(dashboardText)) || imagesEdited);
	}, [dataTexts]);

	useEffect(() => {
		dashboardText && setDataTexts(transformTextsFromResponse(dashboardText));
	}, [dashboardText]);

	return (
		<DashboardView
			selectedLanguage={selectedLanguage}
			setSelectedLanguage={setSelectedLanguage}
			dataImages={dataImages}
			dataTexts={dataTexts}
			onChangeImage={changeImageHandler}
			onChangeText={changeTextHandler}
			hasWritePermissions={props.hasWritePermission}
			onSave={saveTextHandler}
			saveIsDisabled={!isEdited}
			isLoading={isLoadingImages || isLoadingTexts || isLoadingSaveText || isLoadingSaveImages}
			footerStyle={props.footerStyle}
		/>
	);
};
