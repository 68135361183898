import React from 'react';
// import { Provider } from 'react-redux';
import App from './App';
import { jwtInterceptor } from './_api/utils/jwt.interceptor';
import { errorInterceptor } from './_api/utils/error.interceptor';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import './i18nextConf';
import { createRoot } from 'react-dom/client';

jwtInterceptor();
errorInterceptor();

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
