import { IonIcon } from '@ionic/react';
import styles from './styles.module.scss';

type Props = {
	hasPermission: boolean;
	icon: string;
	onClick: () => void;
	disabled?: boolean;
};

export const ActionIcon: React.FC<Props> = ({ hasPermission, icon, onClick, disabled }) => {
	if (!hasPermission) {
		return <div></div>;
	}

	if (disabled) {
		return (
			<div className={`${styles.actionIcon} ${styles.disabled}`}>
				<IonIcon className={`icon icon-${icon}`}></IonIcon>
			</div>
		);
	}

	return (
		<div className={styles.actionIcon} onClick={onClick}>
			<IonIcon className={`icon icon-${icon}`}></IonIcon>
		</div>
	);
};
