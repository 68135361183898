import _ from 'lodash';
import { PkReservationsConfig, ReservationPolicy } from '../_api/services/parking/types';
import { ReservationsConfig, TurningPointsConfig } from '../_api/services/workstation/types';
import { IndexedPolicy } from '../pages/Workstations/TimeAndReservations/types';

export const defaultWorkstationReservationsConfig: ReservationsConfig = {
	policies: [],
	maxFavoriteDesks: 0,
	lastHistoricDesks: 0,
	favouriteDesksRestriction: false,
	isPresenceMandatory: false,
};

export const defaultParkingReservationsConfig: PkReservationsConfig = {
	policies: [],
	buffer: '0',
	parkingPlannedWeeksExtend: 2,
	parkingPlannedTurningPoint: { day: 5, hour: '01:00' },
	companionPermissionRestriction: false,
};

export const defaultTurningPointsConfig: TurningPointsConfig = {
	buildings: [],
	usersToSendEmail: [],
	visitsBuilding: null,
};

export const POLICIES_AVAILABLE_OPTIONS = {
	fullDays: '1',
	halfDays: '2',
	personalized: '3',
};

export const POLICIES_SERVICE = {
	Dia_completo: 'Dia completo',
	Morning: 'Morning',
	Tarde: 'Tarde',
	Personalizada: 'Personalizada',
};

export const SEDE_POLICIES = {
	fullDays: 1,
	morning: 2,
	afternoon: 3,
	personalized: 4,
};

export const hasEmptyValues = (obj: Object) => {
	return Object.values(obj).some((value) => {
		if (value instanceof Object) {
			return hasEmptyValues(value);
		} else {
			return value === null || value === undefined || value.length === 0;
		}
	});
};

export const getSelectedPolicies = (indexedPolicies: IndexedPolicy[]): string[] => {
	if (_.isEmpty(indexedPolicies)) {
		return [];
	}
	const selected = [];

	if (indexedPolicies[POLICIES_SERVICE.Dia_completo]?.isActive) {
		selected.push(POLICIES_AVAILABLE_OPTIONS.fullDays);
	}
	if (indexedPolicies[POLICIES_SERVICE.Morning]?.isActive || indexedPolicies[POLICIES_SERVICE.Tarde]?.isActive) {
		selected.push(POLICIES_AVAILABLE_OPTIONS.halfDays);
	}
	if (indexedPolicies[POLICIES_SERVICE.Personalizada]?.isActive) {
		selected.push(POLICIES_AVAILABLE_OPTIONS.personalized);
	}
	return selected;
};

export const checkIfPolicyIsActive = (policy: ReservationPolicy, activeOptions: string[]) => {
	const checkPolicy = {
		[POLICIES_SERVICE.Dia_completo]: activeOptions.includes(POLICIES_AVAILABLE_OPTIONS.fullDays),
		[POLICIES_SERVICE.Morning]: activeOptions.includes(POLICIES_AVAILABLE_OPTIONS.halfDays),
		[POLICIES_SERVICE.Tarde]: activeOptions.includes(POLICIES_AVAILABLE_OPTIONS.halfDays),
		[POLICIES_SERVICE.Personalizada]: activeOptions.includes(POLICIES_AVAILABLE_OPTIONS.personalized),
	};
	return checkPolicy[policy.name] ?? false;
};

export const getUpdatedPolicies = (policies, selectedOptions: string[]) => {
	return policies.map((policy) => ({
		...policy,
		isActive: checkIfPolicyIsActive(policy, selectedOptions),
	}));
};

/**
 *
 * @param {string} hourA - hour string 'XX:XX'
 * @param {string} hourB - hour string 'XX:XX'
 * @param {'isGreater' | 'isEqualOrGreater' | 'isEqualOrLess'} validationMode - B is greater than A, B is greater or equal to A, B is less than or equal to A
 * @returns {boolean} Result of the validation
 */
export const validateHours = (
	hourA: string,
	hourB: string,
	validationMode: 'isGreater' | 'isEqualOrGreater' | 'isEqualOrLess' = 'isGreater',
) => {
	const pattern = /\d\d+:\d\d/g;

	if (!hourA || !hourA.match(pattern) || !hourB || !hourB.match(pattern)) {
		return false;
	}

	const [aHourStr = null, aMinutesStr = null] = hourA.split(':');
	const [bHourStr = null, bMinutesStr = null] = hourB.split(':');

	if (aHourStr === null || bHourStr === null || aMinutesStr === null || bMinutesStr === null) {
		return false;
	}

	const aHour = parseInt(aHourStr);
	const aMinutes = parseInt(aMinutesStr);
	const bHour = bHourStr === '00' ? 24 : parseInt(bHourStr);
	const bMinutes = parseInt(bMinutesStr);

	if (validationMode === 'isGreater') {
		return aHour < bHour || (aHour === bHour && aMinutes < bMinutes);
	} else if (validationMode === 'isEqualOrGreater') {
		return aHour < bHour || (aHour === bHour && aMinutes <= bMinutes);
	} else if (validationMode === 'isEqualOrLess') {
		return aHour > bHour || (aHour === bHour && aMinutes >= bMinutes);
	}

	return false;
};

export const getAvailableOptions = (allPolicies: ReservationPolicy[], availableOptions: string[]) => {
	return allPolicies.filter(
		(option) =>
			(option.id === SEDE_POLICIES.fullDays && availableOptions.includes(POLICIES_AVAILABLE_OPTIONS.fullDays)) ||
			(option.id === SEDE_POLICIES.morning && availableOptions.includes(POLICIES_AVAILABLE_OPTIONS.halfDays)) ||
			(option.id === SEDE_POLICIES.afternoon && availableOptions.includes(POLICIES_AVAILABLE_OPTIONS.halfDays)) ||
			(option.id === SEDE_POLICIES.personalized &&
				availableOptions.includes(POLICIES_AVAILABLE_OPTIONS.personalized)),
	);
};

export const getSelectedOption = (allPolicies: ReservationPolicy[], availableOptions: string[]) => {
	return getAvailableOptions(allPolicies, availableOptions).find((p) => p?.isDefault);
};

export const PARKING_BUFFER_OPTIONS = [
	{ id: 1, description: '0' },
	{ id: 2, description: '60' },
	{ id: 3, description: '120' },
	{ id: 4, description: '180' },
];
