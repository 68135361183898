import { useEffect, useMemo, useState } from 'react';
import { RefresherEventDetail, IonLoading } from '@ionic/react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Toast, Alert, Button } from '@acciona/ui-ionic-kit';
import _ from 'lodash';
import { termsServices } from '../../../_api/services/terms';
import { Policy, PolicyCategory } from '../../../_api/services/terms/types';
import useAppContext from '../../../hooks/useAppContext';
import { LanguageSelector } from '../../../components/LanguageSelector/LanguageSelector';
import { FilterChipsBar } from '../../../components/FilterChipsBar/FilterChipsBar';
import { EditableList } from '../../../components/EditableList/EditableList';
import PoliciesCategoriesModal from './components/PoliciesCategoriesModal/PoliciesCategoriesModal';
import PolicyModal from './components/PolicyModal/PolicyModal';
import { getMax, getNext } from '../../../utils/functions';
import { availableLanguages } from '../../../i18nextConf';
import {
	deletePolicyFromState,
	getEditedCategories,
	getEditedPolicies,
	getReorderedPolicies,
	updatePoliciesState,
} from './helpers';
import styles from './styles.module.scss';

export type Props = {
	hasWritePermission: boolean;
	setError: (msg: string) => void;
	setSuccess: (msg: string) => void;
	setInfo: (msg: string) => void;
	footerStyle: string;
};

export const OtherDocuments: React.FC<Props> = (props) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const { setThereAreUnsavedChanges } = useAppContext();
	const [selectedCategoryId, setSelectedCategoryId] = useState(0);
	const [selectedPolicyId, setSelectedPolicyId] = useState(0);
	const [selectedLang, setSelectedLang] = useState('es');
	const [showCategoriesModal, setShowCategoriesModal] = useState(false);
	const [showAlertDeletePolicy, setShowAlertDeletePolicy] = useState(false);
	const [showPoliciesModal, setShowPoliciesModal] = useState(false);
	const [categoriesState, setCategoriesState] = useState<PolicyCategory[]>(null);
	const [policiesState, setPoliciesState] = useState<Policy[]>(null);
	const [isEditedCategories, setIsEditedCategories] = useState(false);
	const [isEditedPolicies, setIsEditedPolicies] = useState(false);
	const [saveChangesMessage, setSaveChangesMessage] = useState('');

	useEffect(() => {
		setThereAreUnsavedChanges(isEditedCategories || isEditedPolicies);
	}, [isEditedCategories || isEditedPolicies]);

	const { data: initialCategories, isLoading: loadingCategories } = useQuery<PolicyCategory[]>(
		['otherDocumentsCategories'],
		async () => await termsServices.getOtherPoliciesCategories(),
		{
			refetchOnWindowFocus: false,
			onError: (error) => props.setError(error as string),
		},
	);
	const { data: initialPolicies, isLoading: loadingPolicies } = useQuery<Policy[]>(
		['otherDocumentsPolicies'],
		async () => await termsServices.getOtherPolicies(),
		{
			refetchOnWindowFocus: false,
			onError: (error) => props.setError(error as string),
		},
	);

	const { mutate: saveCategories, isLoading: loadingSaveCategories } = useMutation(
		termsServices.saveOtherPoliciesCategories,
		{
			onSuccess: () => {
				if (isEditedPolicies) {
					const maxIncidenceId = _.maxBy(initialPolicies, function (o) {
						return o.incidenceId;
					}).incidenceId;

					savePolicies(
						policiesState.map((policy) => {
							return {
								...policy,
								id: policy.id > maxIncidenceId ? null : policy.id,
							};
						}),
					);
				} else {
					queryClient.refetchQueries('otherDocumentsCategories');
					setIsEditedCategories(false);
					props.setSuccess(t('msg_success'));
				}
			},
			onError: (error) => {
				props.setError(error as string);
			},
		},
	);

	const { mutate: savePolicies, isLoading: loadingSavePolicies } = useMutation(termsServices.saveOtherPolicies, {
		onSuccess: () => {
			queryClient.refetchQueries('otherDocumentsPolicies');
			setIsEditedPolicies(false);
			props.setSuccess(t('msg_success'));
		},
		onError: (error) => {
			props.setError(error as string);
		},
	});

	useEffect(() => {
		if (initialCategories) {
			const nextCategoriesState = _.sortBy(initialCategories, 'ordinal');
			setCategoriesState(nextCategoriesState);
			setSelectedCategoryId(_.first(nextCategoriesState)?.id);
		}
	}, [initialCategories]);

	const selectedCategoryPolicies = useMemo(() => {
		const selected = policiesState ? policiesState?.filter((e) => e.categoryId === selectedCategoryId) : [];
		return selected;
	}, [policiesState, selectedCategoryId]);

	useEffect(() => {
		initialPolicies && setPoliciesState(_.sortBy(initialPolicies, 'ordinal'));
	}, [initialPolicies]);

	useEffect(() => {
		setIsEditedCategories(!_.isEqual(_.sortBy(initialCategories, 'ordinal'), _.sortBy(categoriesState, 'ordinal')));
	}, [initialCategories, categoriesState]);

	useEffect(() => {
		setIsEditedPolicies(
			!_.isEqual(_.sortBy(initialPolicies, 'id', 'ordinal'), _.sortBy(policiesState, 'id', 'ordinal')),
		);
	}, [initialPolicies, policiesState]);

	const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
		const action = event.detail as any;
		event.detail.complete();
		const nextState = getReorderedPolicies(policiesState, selectedCategoryId, action.from, action.to);
		setPoliciesState(nextState);
	};

	const handleEditPolicy = (id: number) => {
		const policy = policiesState.find((p) => p.id === id);
		const isNewPolicy = !_.isEmpty(policy?.translations) && policy.translations.some((t) => t.isNew);

		if (isNewPolicy) {
			props.setInfo(t('unsaved_policy_toast'));
			return;
		}

		setSelectedPolicyId(id);
		setShowPoliciesModal(true);
	};

	const handleDeletePolicy = (id: number) => {
		setSelectedPolicyId(id);
		setShowAlertDeletePolicy(true);
	};

	const handleAddPolicy = () => {
		const isNewCategory = selectedCategoryId === 0 || selectedCategoryId > getMax(initialCategories, 'id');
		if (isNewCategory) {
			props.setInfo(t('unsaved_category_toast'));
			return;
		}
		handleEditPolicy(0);
	};

	const deletePolicy = () => {
		setPoliciesState((prev) =>
			deletePolicyFromState(prev, { policyId: selectedPolicyId, categoryId: selectedCategoryId }),
		);
		props.setSuccess(t('msg_success_delete_policy'));
	};

	const handleSave = () => {
		if (isEditedCategories) {
			const editedCategories = getEditedCategories(initialCategories, categoriesState);
			if (!_.isEmpty(editedCategories)) {
				saveCategories(editedCategories);
			}
		} else if (isEditedPolicies) {
			const editedPolicies = getEditedPolicies(initialPolicies, policiesState);

			if (!_.isEmpty(editedPolicies)) {
				savePolicies(editedPolicies);
			}
		}
	};

	const handleSaveChangesCategories = (changes) => {
		setCategoriesState(changes);
		setSaveChangesMessage(t('msg_save_categorie'));
	};

	const handleSaveChangesPolicies = (updatedPolicy: Policy) => {
		setPoliciesState((prev) => updatePoliciesState(prev, updatedPolicy));
		setShowPoliciesModal(false);
		setSaveChangesMessage(t('msg_save_policies_other_documents'));
	};

	return (
		<>
			<LanguageSelector selectedLang={selectedLang} setSelectedLang={setSelectedLang} />
			<h2 className={styles.categoriesHeader}>{t('categories')}</h2>
			<FilterChipsBar
				selectedLang={selectedLang}
				selectedCategory={selectedCategoryId}
				setSelectedCategory={setSelectedCategoryId}
				categoriesState={categoriesState}
				setShowCategories={setShowCategoriesModal}
			/>
			{!!selectedCategoryId && (
				<EditableList
					title={t('policies')}
					hasWritePermission={props.hasWritePermission}
					selectedLang={selectedLang}
					data={selectedCategoryPolicies}
					loading={loadingPolicies}
					onItemReorder={doRefresh}
					onEdit={handleEditPolicy}
					onDelete={handleDeletePolicy}
					onAdd={handleAddPolicy}
					buttonAddText={t('btn_add_policy')}
				/>
			)}

			{props.hasWritePermission ? (
				<div className={`${props.footerStyle} ${styles.footerButton}`}>
					<Button
						disabled={!(isEditedCategories || isEditedPolicies)}
						color="primary"
						className={styles.btnHeader}
						onClick={handleSave}
					>
						{t('btn_save_data')}
					</Button>
				</div>
			) : null}
			<PoliciesCategoriesModal
				categories={categoriesState}
				showModal={showCategoriesModal}
				onClose={() => setShowCategoriesModal(false)}
				hasWritePermission={props.hasWritePermission}
				saveChanges={handleSaveChangesCategories}
				lang={selectedLang}
			/>
			<PolicyModal
				mode={selectedPolicyId === 0 ? 'add' : 'edit'}
				showModal={showPoliciesModal}
				initialData={
					selectedPolicyId === 0
						? {
								id: getNext(policiesState, 'id'),
								ordinal: getNext(policiesState, 'ordinal'),
								categoryId: selectedCategoryId,
								disabled: false,
								translations: availableLanguages.map((lang) => ({
									language: lang,
									name: '',
								})),
						  }
						: policiesState.find((policy) => policy.id === selectedPolicyId)
				}
				hasWritePermission={props.hasWritePermission}
				language={selectedLang}
				onClose={() => setShowPoliciesModal(false)}
				onSave={handleSaveChangesPolicies}
			/>
			<IonLoading
				isOpen={loadingCategories || loadingSaveCategories || loadingSavePolicies}
				message={t('msg_loading')}
				duration={1500}
			/>
			<Alert
				isOpen={showAlertDeletePolicy}
				onDidDismiss={() => setShowAlertDeletePolicy(false)}
				header={t('confirm_delete_policy')}
				message={t('delete_policy')}
				buttons={[
					{ text: 'No', role: 'cancel' },
					{ text: t('lbl_affirmative'), handler: () => deletePolicy() },
				]}
				mode="ios"
			/>
			<Toast
				isOpen={!!saveChangesMessage}
				message={saveChangesMessage}
				onDidDismiss={() => setSaveChangesMessage('')}
				position="bottom"
				type="success"
			/>
		</>
	);
};
