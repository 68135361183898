import { Icon } from '@acciona/ui-ionic-kit';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SplitMenuSubMenuItem } from '../menuConfiguration';
import styles from '../styles.module.scss';

type Props = {
	item: SplitMenuSubMenuItem;
};

export const SubMenuItem = ({ item }: Props) => {
	const { t } = useTranslation();
	return (
		<Link to={item.link}>
			<div
				className={`${styles.itemList} ${
					item.link == location.pathname ? styles.itemSubMenuSelected : styles.itemSubMenuList
				}`}
			>
				<div>
					<Icon className={`${item.icon} icon20`} slot="start"></Icon>
					<div className={styles.footnote}>{t(item.text)}</div>
				</div>
			</div>
		</Link>
	);
};
