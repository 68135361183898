import muleSoftInstance from '../../utils/httpCommon';
import { OrganizerHostItem, RoomImagesConf, RoomsDistricts, TimeParams } from './types';

const getPhoneCleaner = async (): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/v1/configuration/immediateAttentionPhone`;
	try {
		const resp = await muleSoftInstance.get(path);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const savePhoneCleaner = async (phone: string): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/v1/configuration/immediateAttentionPhone`;
	try {
		const resp = await muleSoftInstance.put(path, { value: phone });

		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const getRoomImages = async (): Promise<RoomImagesConf> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/configuration/room/images`;
	try {
		const resp = await muleSoftInstance.get(path);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const delRoomImage = async (spaceId: string): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/v1/images/room?spaceId=${spaceId}`;
	try {
		const resp = await muleSoftInstance.delete(path);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const saveRoomImage = async (form): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/v1/images/room`;
	try {
		const resp = await muleSoftInstance.post(path, form, { headers: { 'Content-Type': 'multipart/form-data' } });

		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const getTimeParams = async (): Promise<TimeParams> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/v1/configuration/roomTime`;
	try {
		const resp = await muleSoftInstance.get(path);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const saveTimeParams = async (params: TimeParams): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/v1/configuration/roomTime`;
	try {
		const resp = await muleSoftInstance.put(path, params);

		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const getOverflowDistricts = async (): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/v1/configuration/room`;
	try {
		const resp = await muleSoftInstance.get(path);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const updateOverflowDistricts = async (updatedData: RoomsDistricts): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/v1/configuration/room`;
	try {
		const resp = await muleSoftInstance.put(path, updatedData);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};
const saveRoomDefaultImage = async (form): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/configuration/room/defaultImage`;
	try {
		const resp = await muleSoftInstance.post(path, form, { headers: { 'Content-Type': 'multipart/form-data' } });

		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const getRoomOrganizers = async (): Promise<OrganizerHostItem[]> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/room/employees/organizers`;
	try {
		const resp = await muleSoftInstance.get(path);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const updateRoomOrganizers = async (updatedData: OrganizerHostItem[]): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/room/employees/organizers`;
	try {
		const resp = await muleSoftInstance.put(path, updatedData);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

export const roomsServices = {
	getPhoneCleaner,
	savePhoneCleaner,
	getRoomImages,
	delRoomImage,
	saveRoomImage,
	getTimeParams,
	saveTimeParams,
	getOverflowDistricts,
	updateOverflowDistricts,
	saveRoomDefaultImage,
	getRoomOrganizers,
	updateRoomOrganizers,
};
