import { useMemo, useState } from 'react';
import { Icon, Alert } from '@acciona/ui-ionic-kit';
import { IonInput, IonItem, IonLabel, IonLoading, IonSpinner } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { roomsServices } from '../../../_api/services/rooms';
import styles from './styles.module.scss';
import { RoomImagesConf } from '../../../_api/services/rooms/types';

const maxWidth = 20000;
const maxHeight = 14400;
const maxSize = 12097152; //2Mb

type Props = {
	setError?: Function;
	setSuccess?: Function;
	hasWritePermission?: boolean;
};
const defaultConf: RoomImagesConf = {
	defaultImage: { imageS3Link: '', imageName: '' },
	rooms: [],
};
export const ImagesForm: React.FC<Props> = (props) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const [data, setData] = useState<RoomImagesConf>(defaultConf);
	const [filter, setFilter] = useState('');
	const [selectedFile, setSelectedFile] = useState(null);
	const [currentSpaceId, setCurrentSpaceId] = useState(null);
	const [isOpenAlertDelete, setIsOpenAlertDelete] = useState(false);
	const [isOpenAlertChange, setIsOpenAlertChange] = useState(false);
	const [isLoadingDelete, setIsLoadingDelete] = useState(false);

	const { isLoading } = useQuery(['roomImages'], async () => await roomsServices.getRoomImages(), {
		onSuccess: (images) => setData(images),
		refetchOnWindowFocus: false,
		onError: (error) => props.setError(error as string),
	});

	const { mutate: saveRoomImage, isLoading: isLoadingSave } = useMutation(roomsServices.saveRoomImage, {
		onSuccess: () => {
			queryClient.refetchQueries('roomImages');
			setSelectedFile(null);
			setCurrentSpaceId(null);
			props.setSuccess(t('msg_success'));
		},
		onError: (error) => {
			props.setError(error as string);
		},
	});
	const { mutate: saveRoomDefaultImage, isLoading: isLoadingSaveDefaultImage } = useMutation(
		roomsServices.saveRoomDefaultImage,
		{
			onSuccess: () => {
				queryClient.refetchQueries('roomImages');
				setSelectedFile(null);
				setCurrentSpaceId(null);
				props.setSuccess(t('msg_success'));
			},
			onError: (error) => {
				props.setError(error as string);
			},
		},
	);

	const changeImage = () => {
		if (selectedFile) {
			const im = new Image();
			im.src = URL.createObjectURL(selectedFile);
			im.onload = async () => {
				if (im.width > maxWidth || im.height > maxHeight || selectedFile.size > maxSize) {
					props.setError('Tamaño de imagen incorrecto');
					return;
				}
				const fd = new FormData(document.forms[0]);
				fd.append('file', selectedFile);
				fd.append('nameImage', selectedFile.name);
				if (currentSpaceId) {
					fd.append('spaceId', currentSpaceId.toString());
					saveRoomImage(fd);
				} else {
					saveRoomDefaultImage(fd);
				}
			};
		}
	};
	const deleteImage = async () => {
		setIsLoadingDelete(true);
		roomsServices
			.delRoomImage(currentSpaceId)
			.then((resp) => {
				const imageIndex = data.rooms.map((room) => room.spaceId).indexOf(resp.spaceId);
				const nextData = [...data.rooms];
				nextData[imageIndex] = {
					...nextData[imageIndex],
					imageDefault: true,
					imageName: resp.image.imageName,
				};
				setData((prevData) => ({ ...prevData, rooms: nextData }));
				props.setSuccess(t('msg_success'));
			})
			.catch((err) => {
				props.setError(err);
				throw new Error(err);
			})
			.finally(() => {
				setIsLoadingDelete(false);
				setCurrentSpaceId(null);
			});
	};

	const changeFilter = (text: string) => {
		text.length > 1 ? setFilter(text) : setFilter('');
	};

	const rooms = useMemo(
		() =>
			data
				? _.sortBy(data.rooms, 'name').filter((room) => room.name.toLowerCase().includes(filter.toLowerCase()))
				: [],
		[data, filter],
	);

	const onDeleteHandler = (e, spaceId) => {
		e.preventDefault();
		setCurrentSpaceId(spaceId);
		setIsOpenAlertDelete(true);
	};

	const onChangeHandler = (e, spaceId) => {
		setSelectedFile(e.target?.files?.[0]);
		setCurrentSpaceId(spaceId);
		setIsOpenAlertChange(true);
	};
	const handlerChangeDefaultImage = (e) => {
		setSelectedFile(e.target?.files?.[0]);
		setCurrentSpaceId(null);
		setIsOpenAlertChange(true);
	};

	const IconUpload = ({ spaceId }) => (
		<Icon
			className={`icon icon-arrow_down icon24 ${styles.iconRotate} ${styles.icon}`}
			onClick={(e) => onChangeHandler(e, spaceId)}
		/>
	);

	const IconDelete = ({ spaceId }) => (
		<Icon className={`icon icon-close icon24 ${styles.icon}`} onClick={(e) => onDeleteHandler(e, spaceId)} />
	);

	const ActionIcon = ({ room }) =>
		room.imageDefault || _.isEmpty(room.imageName) ? (
			<IconUpload spaceId={room.spaceId} />
		) : (
			<IconDelete spaceId={room.spaceId} />
		);

	const ItemLabel = ({ imageName, loading }) => {
		if (loading && _.isEmpty(imageName)) {
			return <IonSpinner name="crescent" />;
		} else if (_.isEmpty(imageName)) {
			return (
				<span>
					<u>{t('lbl_select_one')}</u> {t('or_add_file')}
				</span>
			);
		} else {
			return imageName;
		}
	};

	return (
		<>
			{/* BETTER USE FILEINPUT  */}
			{/* DEFAULT IMAGE */}
			<div className={styles.element}>
				<h3 className={styles.blockTitle}>{t('label_rooms_default_image_header')}</h3>
				<p className={`${styles.blockSubtitle}`}>{t('label_rooms_default_image_desc')}</p>
				<div className={`${styles.footnote} ${styles.containerInputs}`}>
					<div className={styles.fullContainer}>
						<div className={styles.body}>{t('label_image')}</div>
					</div>
					<label className={`${styles.input}`}>
						<span className={`${styles.body} ${styles.color_dark}`}>
							<ItemLabel
								imageName={data.defaultImage.imageName}
								loading={isLoading || isLoadingSave || isLoadingSaveDefaultImage || isLoadingDelete}
							/>
						</span>
						<input
							type="file"
							accept="image/*"
							multiple={false}
							onChange={handlerChangeDefaultImage}
							disabled={!props.hasWritePermission}
						/>
						<Icon className={`icon icon-arrow_down icon24 ${styles.iconRotate} ${styles.icon}`} />
					</label>
				</div>
			</div>
			{/* ROOMS */}
			<div className={styles.h3}>{t('label_images_header')}</div>
			<div className={`${styles.footnote} ${styles.containerInputs}`}>
				<div>
					<IonLabel>{t('label_images_subheader')}</IonLabel>
				</div>
				<div>
					<IonItem lines="inset">
						<IonInput
							className={`ion-text-end`}
							value={filter}
							onIonChange={(e) => changeFilter(e.detail.value)}
							maxlength={30}
							slot="end"
							aria-label={t('label_images_subheader')}
						/>
						<Icon className={`icon icon-search`} slot="end" />
					</IonItem>
				</div>
			</div>

			<div>
				{data &&
					rooms.map((room, index) => {
						return (
							<div className={styles.containerInputs} key={index}>
								<div className={styles.fullContainer}>
									<div className={styles.body}>{room.name}</div>
								</div>
								<label className={`${styles.input}`}>
									<span className={`${styles.body} ${styles.color_dark}`}>
										<ItemLabel
											imageName={room.imageName}
											loading={isLoading || isLoadingSave || isLoadingSaveDefaultImage || isLoadingDelete}
										/>
									</span>
									<input
										type="file"
										accept="image/*"
										multiple={false}
										onChange={(e) => onChangeHandler(e, room.spaceId)}
										disabled={!props.hasWritePermission}
									/>
									<ActionIcon room={room} />
								</label>
							</div>
						);
					})}
				<Alert
					className={styles.imageAlert}
					isOpen={isOpenAlertChange}
					onDidDismiss={() => setIsOpenAlertChange(false)}
					header={t('change_image')}
					message={t('confirm_change_image')}
					buttons={[
						{ text: t('lbl_negative'), role: 'cancel' },
						{ text: t('lbl_affirmative'), handler: changeImage },
					]}
					mode="ios"
				/>
				<Alert
					className={styles.imageAlert}
					isOpen={isOpenAlertDelete}
					onDidDismiss={() => setIsOpenAlertDelete(false)}
					header={`${t('delete_image')}`}
					message={t('confirm_delete_image')}
					buttons={[
						{ text: t('lbl_negative'), role: 'cancel' },
						{ text: t('lbl_affirmative'), handler: deleteImage },
					]}
					mode="ios"
				/>
			</div>
			<IonLoading
				isOpen={isLoading || isLoadingSave || isLoadingSaveDefaultImage || isLoadingDelete}
				message={t('msg_loading')}
				duration={0}
			/>
		</>
	);
};
