import { Icon, Button, List } from '@acciona/ui-ionic-kit';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { workstationService } from '../../../_api/services/workstation';
import ModalCSV from '../components/ModalCSV';
import _ from 'lodash';
import { IonLoading } from '@ionic/react';
import useAppContext from '../../../hooks/useAppContext';
import styles from './styles.module.scss';

type Props = {
	setError?: Function;
	setSuccess?: Function;
	setInfo?: Function;
	hasWritePermission?: boolean;
	footerStyle: string;
};

export const Lockers: React.FC<Props> = (props) => {
	const { setError, setSuccess, setInfo } = props;
	const { t } = useTranslation();
	const { setThereAreUnsavedChanges } = useAppContext();
	const [showModalCSV, setShowModalCSV] = useState(false);
	const [file, setFile] = useState(null);
	const [isEdited, setIsEdited] = useState(false);

	useEffect(() => {
		setThereAreUnsavedChanges(isEdited);
	}, [isEdited]);

	const { data: downloadCSV } = useQuery(['csv'], async () => await workstationService.downloadCSV(), {
		refetchOnWindowFocus: false,
	});

	const { hasWritePermission } = props;

	const { mutate: saveFile, isLoading: isLoading } = useMutation(workstationService.updateCSV, {
		onSuccess: (res) => {
			setIsEdited(false);
			if (_.isEmpty(res.errors)) {
				setSuccess(t('msg_upload_success'));
			} else {
				const errors = res.errors
					.map((err) => {
						return `   - ${t('lbl_row')} ${err.line}: ${err.description}`;
					})
					.join('\n');
				setInfo(`${t('lbl_errors')}${errors}`);
			}
		},
		onError: () => {
			setError(t('default_error'));
		},
	});
	const handleOpenModal = () => {
		setShowModalCSV(true);
	};

	const handleCancelFile = (e) => {
		if (file) {
			setFile(null);
			setIsEdited(false);
			e.stopPropagation();
		}
	};
	const handleSaveModal = (file) => {
		setFile(file);
		setIsEdited(true);
	};

	return (
		<>
			<header className={`${styles.h2} ${styles.headerSpace}`}>{t('lbl_lockers_config')}</header>
			<List>
				<div className={styles.element}>
					<div className={`${styles.footnote} ${styles.space}`}>{t('lbl_lockers_config_description')}</div>
					<div className={styles.action}>
						<div
							className={hasWritePermission ? styles.input : styles.disabledInput}
							{...(hasWritePermission ? { onClick: handleOpenModal } : {})}
						>
							<div className={styles.body}>{file ? file.file.name : t('lbl_file')}</div>
							<div className={`${styles.align}`} onClick={handleCancelFile}>
								{!file && <div className={`${styles.body} ${styles.color_dark}`}>{t('lbl_select')}</div>}
								<Icon className={`icon ${file ? 'icon-close' : 'icon-chevron-right'} icon24`} slot="end"></Icon>
							</div>
						</div>
					</div>
				</div>
			</List>
			<ModalCSV
				onClose={() => setShowModalCSV(false)}
				saveChanges={(csv) => handleSaveModal(csv)}
				showModal={showModalCSV}
				downloadCSV={downloadCSV}
				hasWritePermission={hasWritePermission}
			/>
			{hasWritePermission && (
				<div className={`${props.footerStyle} ${styles.footerButton}`}>
					<Button className={styles.btnHeader} onClick={() => saveFile(file)} disabled={!isEdited} color="primary">
						{t('btn_save_data')}
					</Button>
				</div>
			)}
			<IonLoading isOpen={isLoading} message={t('msg_loading')} duration={3000} />
		</>
	);
};
