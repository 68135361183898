import muleSoftInstance from '../../utils/httpCommon';
import { HolidaysAndDisabledDays, SeekerConfiguration, IProfileMovingDate } from './types';

const getHolidaysAndDisabledDays = async () => {
	try {
		const path = `${process.env.REACT_APP_API_PORTAL}/configuration/desk/reservableDays`;
		const resp = await muleSoftInstance.get(path);
		return resp.data;
	} catch (error) {
		throw new Error(error.message);
	}
};
const saveHolidaysAndDisabledDays = async (form: HolidaysAndDisabledDays): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/configuration/desk/reservableDays`;
	try {
		const resp = await muleSoftInstance.put(path, form);

		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const getSeekerConfiguration = async () => {
	try {
		const path = `${process.env.REACT_APP_API_PORTAL}/configuration/seeker`;
		const resp = await muleSoftInstance.get(path);
		return resp.data;
	} catch (error) {
		throw new Error(error.message);
	}
};

const updateSeekerConfiguration = async (updatedData: SeekerConfiguration): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/configuration/seeker`;
	try {
		const resp = await muleSoftInstance.put(path, updatedData);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};
const getMovingDateConfiguration = async (): Promise<IProfileMovingDate> => {
	try {
		const path = `${process.env.REACT_APP_API_PORTAL}/configuration/movingDate`;
		const resp = await muleSoftInstance.get(path);
		return resp.data;
	} catch (error) {
		throw new Error(error.message);
	}
};

const updateMovingDateConfiguration = async (updatedData: IProfileMovingDate): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/configuration/movingDate`;
	try {
		const resp = await muleSoftInstance.put(path, updatedData);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};
export const transversalServices = {
	getHolidaysAndDisabledDays,
	saveHolidaysAndDisabledDays,
	getSeekerConfiguration,
	updateSeekerConfiguration,
	getMovingDateConfiguration,
	updateMovingDateConfiguration,
};
