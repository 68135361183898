import { PkNotificationsConfig } from '../../../pages/Parking/Notifications/types';
import muleSoftInstance from '../../utils/httpCommon';
import {
	LotteryScores,
	ModifiedVehicleReservationsData,
	ParkingReservationsAndRequests,
	PkReservationsConfig,
	ReservationPolicy,
	SendPlatesInfo,
	UserVehicle,
	VehicleAttributes,
} from './types';

type GetDataParams = {
	pageNumber: number;
	pageSize: number;
	search: string | null;
	asc: boolean;
};

const getEmployeesPoints = async (params: GetDataParams): Promise<any> => {
	let path = `${process.env.REACT_APP_API_HABITAT}/parkingPoints?`;

	Object.entries(params).forEach(([key, value]) => {
		if (value !== null && value !== '') {
			path += `&${key}=${value}`;
		}
	});

	try {
		const resp = await muleSoftInstance.get(path);
		return {
			...resp.data,
			results: resp.data.results.map((item) => ({
				...item,
				manualPoints: item?.manualPoints ?? 0,
			})),
		};
	} catch (err) {
		throw new Error(err);
	}
};

const updateEmployeesPoints = async (updatedData: any): Promise<any> => {
	const path = `${process.env.REACT_APP_API_HABITAT}/parkingExtraPoints`;
	const body = { requests: updatedData };

	try {
		const resp = await muleSoftInstance.post(path, body);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const getPkNotificationConfig = async (): Promise<PkNotificationsConfig> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/configuration/parking/reservationEndNotification`;
	try {
		const resp = await muleSoftInstance.get(path);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const updatePkNotificationConfig = async (updatedData: PkNotificationsConfig): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/configuration/parking/reservationEndNotification`;
	try {
		const resp = await muleSoftInstance.put(path, updatedData);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const getPkReservationsConfig = async (): Promise<PkReservationsConfig> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/configuration/parking/reservations`;
	try {
		const resp = await muleSoftInstance.get(path);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const updatePkReservationsConfig = async (updatedData: PkReservationsConfig): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/configuration/parking/reservations`;
	try {
		const resp = await muleSoftInstance.put(path, updatedData);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};
const getPkDefaultSedePolicy = async (): Promise<ReservationPolicy[]> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/configuration/parking/defaultSedePolicy`;
	try {
		const resp = await muleSoftInstance.get(path);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const updatePkDefaultSedePolicy = async (updatedData: ReservationPolicy): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/configuration/parking/defaultSedePolicy`;
	try {
		const resp = await muleSoftInstance.put(path, updatedData);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};
const getPkLotteryScores = async (): Promise<LotteryScores> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/configuration/parking/lotteryScores`;
	try {
		const resp = await muleSoftInstance.get(path);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const updatePkLotteryScores = async (updatedData: LotteryScores): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/configuration/parking/lotteryScores`;
	try {
		const resp = await muleSoftInstance.put(path, updatedData);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const getLastSendPlatesToLenelInfo = async (): Promise<SendPlatesInfo> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/configuration/parking/lenelPlates`;
	try {
		const resp = await muleSoftInstance.get(path);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const sendPlatesToLenel = async (): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/configuration/parking/lenelPlates`;
	try {
		const resp = await muleSoftInstance.post(path);
		return resp.data;
	} catch (err) {
		if (err.response && err.response.status === 409) {
			throw new Error('jobInProgress');
		} else {
			throw new Error(err);
		}
	}
};

const getListAttributes = async (): Promise<VehicleAttributes> => {
	const path = `${process.env.REACT_APP_API_HABITAT}/v1/parking/vehicle/listAttributes`;
	try {
		const res = (await muleSoftInstance.get(path)).data;
		return res;
	} catch (error) {
		throw new Error(error.message);
	}
};

const getUserVehicles = async (employeeId: number): Promise<UserVehicle[]> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/configuration/parking/userVehicles?employeeId=${employeeId}`;
	try {
		const res = await muleSoftInstance.get(path);
		return res.data;
	} catch (error) {
		throw new Error(error.message);
	}
};

const getUserParkingReservations = async (employeeId: number): Promise<ParkingReservationsAndRequests> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/configuration/parking/reservationsAndVehicles/${employeeId}`;
	try {
		const res = await muleSoftInstance.get(path);
		return res.data;
	} catch (error) {
		throw new Error(error.message);
	}
};

const updateUserVehicles = async (userVehicles: UserVehicle[]): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/configuration/parking/userVehicles`;
	try {
		const resp = await muleSoftInstance.put(path, userVehicles);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const updateParkingReservations = async (reservationsAndRequests: ModifiedVehicleReservationsData): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/configuration/parking/reservationsAndVehicles`;
	try {
		const resp = await muleSoftInstance.put(path, reservationsAndRequests);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

export const parkingService = {
	getEmployeesPoints,
	updateEmployeesPoints,
	getPkNotificationConfig,
	updatePkNotificationConfig,
	getPkReservationsConfig,
	updatePkReservationsConfig,
	getPkDefaultSedePolicy,
	updatePkDefaultSedePolicy,
	getPkLotteryScores,
	updatePkLotteryScores,
	getLastSendPlatesToLenelInfo,
	sendPlatesToLenel,
	getListAttributes,
	getUserVehicles,
	getUserParkingReservations,
	updateUserVehicles,
	updateParkingReservations,
};
