import { useEffect, useRef, useState } from 'react';
import { IonContent, IonLabel } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { SegmentButton, Segmented, Toast } from '@acciona/ui-ionic-kit';
import useAppContext from '../../hooks/useAppContext';
import { HeaderPage } from '../../components/HeaderPage/HeaderPage';
import { usePermissions } from '../../hooks/usePermissions';
import { FUNCTIONALITIES } from '../../utils/constants';
import { useScrollBar } from '../../hooks/useScrollBar';
import { Notifications } from './Notifications/Notifications';
import { Reservations } from './Reservations/Reservations';
import { BonusAndPenalties } from './BonusAndPenalties/BonusAndPenalties';
import { SendPlates } from './SendPlates/SendPlates';
import { ParkingUsers } from './ParkingUsers/ParkingUsers';
import styles from './styles.module.scss';

const Parking: React.FC = () => {
	const { t } = useTranslation();
	const { sedePortal, setThereAreUnsavedChanges } = useAppContext();
	const plannedReservationActive = sedePortal?.configuration?.plannedReservation;
	const platesJobActive = sedePortal?.configuration?.platesJobActive;
	const [segmentedSelected, setSegmentedSelected] = useState(plannedReservationActive ? 'bonus' : 'reserv');
	const [error, setError] = useState('');
	const [success, setSuccess] = useState('');
	const [scroll, setScroll] = useState(false);
	const { permissions, error: errorPermissions } = usePermissions(FUNCTIONALITIES.parking, 'WRITE');
	const refContainer = useRef(null);
	const refHeader = useRef(null);
	const hasScroll = useScrollBar(refContainer, refHeader, segmentedSelected);
	const footerStyle = hasScroll ? styles.hasScroll : styles.noScroll;

	useEffect(() => {
		setError(errorPermissions);
	}, [errorPermissions]);

	useEffect(() => {
		return () => {
			setThereAreUnsavedChanges(false);
		};
	}, []);

	return (
		<IonContent scrollEvents forceOverscroll={true} onIonScroll={(x) => setScroll(x.detail.scrollTop > 0)}>
			<HeaderPage title={t('lbl_parking')} scroll={scroll}>
				<div ref={refHeader}>
					<Segmented value={segmentedSelected}>
						{plannedReservationActive && (
							<SegmentButton onClick={() => setSegmentedSelected('bonus')} value={'bonus'} mode={'md'}>
								<IonLabel>{t('tab_bonusesAndPenalties')}</IonLabel>
							</SegmentButton>
						)}
						<SegmentButton onClick={() => setSegmentedSelected('users')} value={'users'} mode={'md'}>
							<IonLabel>{t('lbl_users')}</IonLabel>
						</SegmentButton>
						<SegmentButton onClick={() => setSegmentedSelected('reserv')} value={'reserv'} mode={'md'}>
							<IonLabel>{t('tab_reservations')}</IonLabel>
						</SegmentButton>
						<SegmentButton
							onClick={() => setSegmentedSelected('notifications')}
							value={'notifications'}
							mode={'md'}
						>
							<IonLabel>{t('tab_notifications')}</IonLabel>
						</SegmentButton>
						{platesJobActive && (
							<SegmentButton onClick={() => setSegmentedSelected('plates')} value={'plates'} mode={'md'}>
								<IonLabel>{t('tab_send_plates')}</IonLabel>
							</SegmentButton>
						)}
					</Segmented>
				</div>
			</HeaderPage>
			<div className="container" ref={refContainer}>
				{plannedReservationActive && (
					<div className={segmentedSelected === 'bonus' ? styles.display : styles.hide}>
						<BonusAndPenalties
							setError={setError}
							setSuccess={setSuccess}
							hasWritePermission={permissions.WRITE}
							footerStyle={footerStyle}
						/>
					</div>
				)}
				<div className={segmentedSelected === 'users' ? styles.display : styles.hide}>
					<ParkingUsers />
				</div>
				<div className={segmentedSelected === 'reserv' ? styles.display : styles.hide}>
					<Reservations
						setError={setError}
						setSuccess={setSuccess}
						hasWritePermission={permissions.WRITE}
						footerStyle={footerStyle}
					/>
				</div>
				<div className={segmentedSelected === 'notifications' ? styles.display : styles.hide}>
					<Notifications
						setError={setError}
						setSuccess={setSuccess}
						hasWritePermission={permissions.WRITE}
						footerStyle={footerStyle}
					/>
				</div>
				{platesJobActive && (
					<div className={segmentedSelected === 'plates' ? styles.display : styles.hide}>
						<SendPlates setError={setError} setSuccess={setSuccess} hasWritePermission={permissions.WRITE} />
					</div>
				)}
			</div>
			<Toast isOpen={!!error} message={error} onDidDismiss={() => setError('')} position="bottom" type="error" />
			<Toast
				isOpen={!!success}
				message={success}
				onDidDismiss={() => setSuccess('')}
				position="bottom"
				type="success"
			/>
		</IonContent>
	);
};

export default Parking;
