import { sortDateString } from '../../../../../utils/functions';

export const dateOptions = {
	'0': 'Indefinido',
	'1': 'Programar fecha de inicio',
	'2': 'Periodo determinado',
};

export const initialFormData = {
	manualPoints: 0,
	startDate: null,
	endDate: null,
	textNames: <></>,
};

export const getUserText = (user) => `${user?.fullName} (${user?.employeePoints} pts.)`;

export const getMultipleUsersText = (users) => {
	const firstUsers = [...users].splice(0, 3);
	return firstUsers.map((user) => getUserText(user)).join(', ');
};

export const getUpdatedData = (originalData, formData, selectedDateOption) => {
	return originalData.map((item) => ({
		employeeId: item.employeeId,
		manualPoints: parseInt(formData.manualPoints),
		startDate: selectedDateOption === '0' ? null : sortDateString(formData.startDate),
		endDate: selectedDateOption !== '2' ? null : sortDateString(formData.endDate),
		plannedDate: item.plannedDate,
		fullName: item.fullName,
	}));
};

export const checkSaveIsEnabled = (originalData, formData, selectedDateOption: string) => {
	const originalDataSelectedDateOption =
		originalData[0]?.startDate && originalData[0]?.endDate ? '2' : originalData[0]?.startDate ? '1' : '0';

	const datesAreValid = selectedDateOption === '0' || formData.startDate;

	const checkItemHasChanged = (original) => {
		const manualPointsHaveChanged = original.manualPoints.toString() !== formData.manualPoints.toString();

		if (manualPointsHaveChanged) {
			return true;
		}

		const optionHasChanged = selectedDateOption !== originalDataSelectedDateOption;

		if (optionHasChanged) {
			return true;
		}

		if (originalDataSelectedDateOption === '1') {
			return original.startDate !== sortDateString(formData.startDate);
		} else if (originalDataSelectedDateOption === '2') {
			return (
				original.startDate !== sortDateString(formData.startDate) ||
				original.endDate !== sortDateString(formData.endDate)
			);
		} else {
			return false;
		}
	};

	const isEdited = originalData.some((item) => checkItemHasChanged(item));

	return datesAreValid && isEdited;
};
