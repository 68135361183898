import { IonButton, IonInput, IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Icon } from '@acciona/ui-ionic-kit';
import { TurningPoint } from '../../../../../_api/services/workstation/types';
import styles from './styles.module.scss';

type Props = {
	selectedDays?: TurningPoint[];
	turningPoint: TurningPoint;
	updateHour: (hour: string, weekday?: number) => void;
	updateDay: (day: number, weekday?: number) => void;
	onDelete?: Function;
	disabled?: boolean;
};

export const DaySelector = (props: Props) => {
	const { selectedDays = [], turningPoint, updateHour, updateDay, onDelete } = props;
	const { t } = useTranslation();

	const weekdayIsSelected = (weekday: { id: number; description: string }) => {
		if (_.isEmpty(selectedDays)) {
			return false;
		} else {
			return selectedDays.map((day) => day.day).includes(weekday.id);
		}
	};

	const weekdays = [
		{ id: 0, description: t('weekdays.sunday') },
		{ id: 1, description: t('weekdays.monday') },
		{ id: 2, description: t('weekdays.tuesday') },
		{ id: 3, description: t('weekdays.wednesday') },
		{ id: 4, description: t('weekdays.thursday') },
		{ id: 5, description: t('weekdays.friday') },
		{ id: 6, description: t('weekdays.saturday') },
	];

	return (
		<>
			<div key={turningPoint.day} className={styles.multipleInputsContainer}>
				<IonItem
					lines="none"
					className={`${styles.inputModal} ${turningPoint.day !== null && styles.touchedInput}`}
				>
					<IonLabel slot="start" className="lblSelector">
						{t('lbl_select_weekday')}
					</IonLabel>
					<IonSelect
						name="day"
						slot="end"
						className={`lblSelector ${styles.selector}`}
						interfaceOptions={{
							cssClass: styles.selectInterface,
						}}
						interface="popover"
						value={turningPoint.day}
						onIonChange={(e) => updateDay(e.target.value !== null ? +e.target.value : null, turningPoint.day)}
						aria-label={t('lbl_select_weekday')}
						disabled={props.disabled}
					>
						{weekdays.map((weekday) => {
							return (
								<IonSelectOption key={weekday.id} value={weekday.id} disabled={weekdayIsSelected(weekday)}>
									{weekday.description}
								</IonSelectOption>
							);
						})}
					</IonSelect>
				</IonItem>
				<IonItem
					lines="none"
					className={`${styles.inputModal} ${turningPoint.hour !== null && styles.touchedInput}`}
				>
					<IonInput
						name="hour"
						type="time"
						className={`ion-text-end ${styles.paddingEnd}`}
						value={turningPoint.hour}
						onIonChange={(e) => updateHour(e.target.value ? e.target.value.toString() : null, turningPoint.day)}
						disabled={props.disabled}
						required
						aria-label={t('lbl_hour_selector')}
						label={t('lbl_hour_selector')}
					/>
				</IonItem>
				{selectedDays.length > 1 && (
					<IonButton
						fill="clear"
						onClick={() => onDelete(turningPoint.day)}
						className={styles.buttonDelete}
						slot="end"
					>
						<Icon className={`icon icon-delete icon24`} />
					</IonButton>
				)}
			</div>
		</>
	);
};
