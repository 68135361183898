import muleSoftInstance from '../../utils/httpCommon';
import { UserSedePortalConfig, Locale, Sede, ConfigurationPreference } from './types';
import _ from 'lodash';
import i18n from '../../../i18nextConf';
import { CONFIGURATION_PREFERENCES } from '../../../utils/constants';

const getUserSedePortal = async (): Promise<UserSedePortalConfig> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/v2/getUserSedeData`;
	try {
		const resp = await muleSoftInstance.get(path);

		let sedeWithConfiguration: Sede = null;

		if (resp.data?.defaultSede?.id) {
			sedeWithConfiguration = resp.data.sedesList.find((e) => e.id === resp.data.defaultSede.id);
		}

		let configurationPreference = resp.data?.configurationPreference;

		if (configurationPreference === CONFIGURATION_PREFERENCES.transversal.id && !resp.data?.transversal) {
			configurationPreference = null;
		}

		const sedesList = resp.data?.sedesList ?? [];

		if (configurationPreference === CONFIGURATION_PREFERENCES.sede.id && sedesList.length < 1) {
			configurationPreference = null;
		}

		return {
			...resp.data,
			defaultSede: sedeWithConfiguration,
			configurationPreference,
			sedesList: sedesList,
		};
	} catch (error) {
		throw new Error(error.message);
	}
};

const updateDefaultPreferences = async (sede: Sede, configurationPreference: ConfigurationPreference) => {
	let payload = null;

	if (configurationPreference === CONFIGURATION_PREFERENCES.sede.id && sede?.id) {
		payload = {
			configurationPreference: configurationPreference,
			defaultSedeId: sede.id,
		};
	} else if (configurationPreference === CONFIGURATION_PREFERENCES.transversal.id) {
		payload = {
			configurationPreference: configurationPreference,
		};
	}

	if (!payload) {
		return;
	}

	const path = `${process.env.REACT_APP_API_PORTAL}/v1/defaultPreferences`;

	try {
		const resp = await muleSoftInstance.put(path, payload);
		localStorage.setItem('userSedePortal', JSON.stringify(sede));
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const setUserDefaultSedePortal = async (sede: Sede) => {
	const path = `${process.env.REACT_APP_API_PORTAL}/v1/modifyUserDefSede`;
	try {
		const resp = await muleSoftInstance.put(path, { id: sede?.id });
		localStorage.setItem('userSedePortal', JSON.stringify(sede));
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const getUserLanguageConfig = async (): Promise<any> => {
	const languages = {
		es: i18n.t('language_es'),
		en: i18n.t('language_en'),
		pt: i18n.t('language_pt'),
	};

	let serviceResponse = null;

	try {
		serviceResponse = (await userServices.getUserLanguage())?.code;
	} catch (e) {
		console.error(e);
	}

	const localStored = localStorage.getItem('i18nextLng') as Locale;
	const userLanguage = serviceResponse ?? localStored ?? 'es';

	if (_.isEmpty(serviceResponse)) {
		await userServices.setUserLanguage(localStored ?? 'es');
	} else if (serviceResponse !== localStored) {
		i18n.changeLanguage(serviceResponse);
		localStorage.setItem('i18nextLng', serviceResponse);
	}

	return {
		code: userLanguage,
		name: languages[userLanguage],
	};
};

const getUserLanguage = async (): Promise<any> => {
	try {
		const path = `${process.env.REACT_APP_API_HABITAT}/users/language`;
		const resp = await muleSoftInstance.get(path);
		return resp.data;
	} catch (error) {
		throw new Error(error.message);
	}
};

const setUserLanguage = async (locale: Locale): Promise<any> => {
	try {
		const path = `${process.env.REACT_APP_API_HABITAT}/users/language`;
		const resp = await muleSoftInstance.put(path, {
			code: locale,
		});
		return resp.data;
	} catch (error) {
		throw new Error(error.message);
	}
};

export const userServices = {
	getUserSedePortal,
	updateDefaultPreferences,
	setUserDefaultSedePortal,
	getUserLanguageConfig,
	getUserLanguage,
	setUserLanguage,
};
