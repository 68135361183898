import React, { useEffect, useRef, useState } from 'react';
import { authServices } from '../../_api/services/auth';

interface Props {
	functionality: string;
	children: React.ReactNode;
	action?: 'READ' | 'WRITE';
}
const Can: React.FC<Props> = ({ children, functionality, action }) => {
	const [havePermission, setHavePermission] = useState(false);
	const mountedRef = useRef(true);

	useEffect(() => {
		if (mountedRef.current) {
			authServices
				.checkPermission(functionality, action)
				.then((resultQuery) => {
					mountedRef.current && setHavePermission(resultQuery?.hasPermission);
				})
				.catch(() => {
					mountedRef.current && setHavePermission(false);
				});
		}

		return () => {
			mountedRef.current = false;
		};
	}, []);

	return <>{(functionality === 'public' || havePermission) && children}</>;
};
export default Can;
