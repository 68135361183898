import { useEffect, useState } from 'react';
import { IonButton, IonIcon, IonItem, IonLabel, IonLoading, IonText } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import useAppContext from '../../../hooks/useAppContext';
import styles from './styles.module.scss';
import { MenuConfig } from '../../../_api/services/generalServices/types';
import { useQuery, useQueryClient } from 'react-query';
import { generalServicesService } from '../../../_api/services/generalServices';
import SettingsEditModal from './components/SettingsEditModal';
import WarningNotConfiguredService from '../../../components/WarningNotConfiguredService/WarningNotConfiguredService';

type Props = {
	setError?: Function;
	setSuccess?: Function;
	setInfo?: Function;
	hasWritePermission?: boolean;
	isTabActive: boolean;
};
export const ServicesSettings: React.FC<Props> = ({ hasWritePermission, isTabActive, setError, setSuccess }) => {
	const { t } = useTranslation();
	const {
		setThereAreUnsavedChanges,
		userLanguage: { code: lang },
	} = useAppContext();
	const queryClient = useQueryClient();
	const [configData, setConfigData] = useState<MenuConfig>([]);

	const [selectedService, setSelectedService] = useState<{ section: number; service: number } | undefined>(undefined);
	const handleCloseEditModal = () => {
		setSelectedService(undefined);
	};

	useEffect(() => {
		return () => {
			setThereAreUnsavedChanges(false);
		};
	}, []);

	const { isLoading: isLoading } = useQuery(
		['serviceSettings'],
		async () => generalServicesService.getServicesConfig(),
		{
			refetchOnWindowFocus: false,
			onError: (err: { message: string }) => {
				setError(err.message);
			},
			onSuccess: (data) => {
				setConfigData(data);
			},
		},
	);

	useEffect(() => {
		isTabActive && queryClient.refetchQueries('serviceSettings');
	}, [isTabActive]);

	useEffect(() => {
		!selectedService && queryClient.refetchQueries('serviceSettings');
	}, [selectedService]);

	return (
		<>
			{isLoading ? (
				<IonLoading isOpen={isLoading} message={t('msg_loading')} duration={0} />
			) : (
				<>
					<p className={styles.servicesSettingsDescription}>{t('service_settings_text_header')}</p>
					{configData.map((section, sectionIndex) =>
						section?.items.map((item, itemIndex) => (
							<IonItem className={styles.serviceContainer} key={`${item.id}_${section.order}`} lines="full">
								<IonLabel className={`ion-text-wrap ${styles.serviceName}`}>
									<IonText className={`${styles.body} ion-text-wrap block ${styles.itemTitle}`}>
										{item.translations.find((t) => t.language === lang)?.text}
									</IonText>
									<IonText
										className={`${styles.footnote} ion-text-wrap block ${styles.itemStatus} ${
											item.active ? styles.itemActive : ''
										}`}
									>
										{t(item.active ? 'lbl_active' : 'lbl_inactive')}
									</IonText>
								</IonLabel>
								<WarningNotConfiguredService item={item} hasWritePermission={hasWritePermission} />
								{hasWritePermission && (
									<>
										<IonButton
											slot="end"
											fill="clear"
											className={styles.buttonEdit}
											onClick={() => {
												setSelectedService({ section: sectionIndex, service: itemIndex });
											}}
										>
											<IonIcon slot="end" className="icon24 icon-edit" />
										</IonButton>
									</>
								)}
							</IonItem>
						)),
					)}
					{!!selectedService && (
						<SettingsEditModal
							service={configData[selectedService?.section]?.items[selectedService?.service]}
							isOpen={!!selectedService}
							handleClose={handleCloseEditModal}
							setSuccess={setSuccess}
							setError={setError}
							setThereAreUnsavedChanges={setThereAreUnsavedChanges}
						/>
					)}
				</>
			)}
		</>
	);
};
