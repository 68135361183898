import { Modal } from '@acciona/ui-ionic-kit';
import styles from './styles.module.scss';

type Props = {
	isOpen: boolean;
	handleClose: () => void;
	children?: React.ReactNode;
};

const PageModal: React.FC<Props> = ({ isOpen, handleClose, children }) => {
	return (
		<Modal isOpen={isOpen} className={styles.pageModal} showBackdrop={false} onDidDismiss={handleClose}>
			{children}
		</Modal>
	);
};

export default PageModal;
