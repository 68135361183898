import { useEffect, useState } from 'react';
import { IonLoading } from '@ionic/react';
import { Button, FilterChip } from '@acciona/ui-ionic-kit';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import _ from 'lodash';
import useAppContext from '../../../hooks/useAppContext';
import { communicationsServices } from '../../../_api/services/Communications';
import styles from './styles.module.scss';
import { NumberInput } from '../../../components/NumberInput/NumberInput';

type Props = {
	setError: Function;
	setSuccess: Function;
	hasWritePermission: boolean;
	footerStyle: string;
};

export const News: React.FC<Props> = ({ setError, setSuccess, hasWritePermission, footerStyle }) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const { setThereAreUnsavedChanges } = useAppContext();
	const [selectedChip, setSelectedChip] = useState('dashboard');
	const [data, setData] = useState<any>({});
	const [isEdited, setIsEdited] = useState(false);

	useEffect(() => {
		setThereAreUnsavedChanges(isEdited);
	}, [isEdited]);

	const { data: initialData, isLoading: isLoading } = useQuery<any>(
		['news_panels'],
		async () => await communicationsServices.getNewsConfigurations(),
		{
			refetchOnWindowFocus: false,
			onError: (error) => setError(error as string),
			onSuccess: (data) => setData(data),
		},
	);
	const { mutate: handleSave, isLoading: loadingSave } = useMutation(communicationsServices.saveNewsConfigurations, {
		onSuccess: () => {
			queryClient.refetchQueries('news_panels');
			setSuccess(t('msg_success'));
			setIsEdited(false);
		},
		onError: (error) => {
			setError(error as string);
		},
	});

	const changeText = (field, text) => {
		setData({
			...data,
			[field]: parseInt(text),
		});
	};

	useEffect(() => {
		setError('');
		setData(initialData);
	}, [initialData]);

	useEffect(() => {
		setIsEdited(!_.isEqual(data, initialData));
	}, [data]);

	return (
		<>
			<div className={`${styles.space}`}>
				<FilterChip
					onChange={() => setSelectedChip('dashboard')}
					id={'dashboard'}
					text={t('tab_dashboard')}
					checked={selectedChip === 'dashboard'}
				/>
				<FilterChip
					onChange={() => setSelectedChip('news')}
					id={'en'}
					text={t('section_news')}
					checked={selectedChip === 'news'}
				/>
				<FilterChip
					onChange={() => setSelectedChip('search')}
					id={'search'}
					text={t('section_search')}
					checked={selectedChip === 'search'}
				/>
			</div>

			{selectedChip === 'dashboard' && (
				<>
					<div className={`${styles.h3} ${styles.space}`}>{t('title_carrousel_smartphone')}</div>
					<div className={`${styles.footnote} ${styles.space}`}>{t('subtitle_carrousel')}</div>
					<NumberInput
						name="carrousel smartphone"
						required={false}
						disabled={!hasWritePermission}
						className={`ion-text-end`}
						value={data?.dashboardNewsCarouselMobile}
						defaultValue={initialData?.dashboardNewsCarouselMobile}
						onIonChange={(e) => changeText('dashboardNewsCarouselMobile', e.detail.value)}
						placeholder={t('value_numeric')}
						aria-label={t(`value_numeric`)}
						label={t(`value_numeric`)}
					/>
					<div className={`${styles.h3} ${styles.space}`}>{t('title_carrousel_desktop')}</div>
					<div className={`${styles.footnote} ${styles.space}`}>{t('subtitle_carrousel')}</div>
					<NumberInput
						disabled={!hasWritePermission}
						name="carrousel desktop"
						className={`ion-text-end`}
						value={data?.dashboardNewsCarouselDesktop}
						defaultValue={initialData?.dashboardNewsCarouselDesktop}
						onIonChange={(e) => changeText('dashboardNewsCarouselDesktop', e.detail.value)}
						placeholder={t('value_numeric')}
						required={false}
						aria-label={t(`value_numeric`)}
						label={t(`value_numeric`)}
					/>
					<div className={`${styles.h3} ${styles.space}`}>{t('title_other')}</div>
					<div className={`${styles.footnote} ${styles.space}`}>{t('subtitle_other')}</div>
					<NumberInput
						name="other news"
						disabled={!hasWritePermission}
						className={`ion-text-end`}
						value={data?.dashboardOtherNews}
						defaultValue={initialData?.dashboardOtherNews}
						onIonChange={(e) => changeText('dashboardOtherNews', e.detail.value)}
						placeholder={t('value_numeric')}
						aria-label={t(`value_numeric`)}
						label={t(`value_numeric`)}
						required={false}
					/>
				</>
			)}
			{selectedChip === 'news' && (
				<>
					<div className={`${styles.h3} ${styles.space}`}>{t('title_carrousel')}</div>
					<div className={`${styles.footnote} ${styles.space}`}>{t('subtitle_carrousel')}</div>
					<NumberInput
						name="carousel"
						disabled={!hasWritePermission}
						className={`ion-text-end`}
						value={data?.newsIndexCarousel}
						defaultValue={initialData?.newsIndexCarousel}
						onIonChange={(e) => changeText('newsIndexCarousel', e.detail.value)}
						placeholder={t('value_numeric')}
						aria-label={t(`value_numeric`)}
						label={t(`value_numeric`)}
						required={false}
					/>
					<div className={`${styles.h3} ${styles.space}`}>{t('title_outstanding')}</div>
					<div className={`${styles.footnote} ${styles.space}`}>{t('subtitle_outstanding')}</div>
					<NumberInput
						name="destacadas"
						disabled={!hasWritePermission}
						className={`ion-text-end`}
						value={data?.newsIndexOutstandingNews}
						defaultValue={initialData?.newsIndexOutstandingNews}
						onIonChange={(e) => changeText('newsIndexOutstandingNews', e.detail.value)}
						placeholder={t('value_numeric')}
						aria-label={t(`value_numeric`)}
						label={t(`value_numeric`)}
						required={false}
					/>
					<div className={`${styles.h3} ${styles.space}`}>{t('title_other')}</div>
					<div className={`${styles.footnote} ${styles.space}`}>{t('subtitle_other')}</div>
					<NumberInput
						name="other news"
						disabled={!hasWritePermission}
						className={`ion-text-end`}
						value={data?.newsIndexOtherNews}
						defaultValue={initialData?.newsIndexOtherNews}
						onIonChange={(e) => changeText('newsIndexOtherNews', e.detail.value)}
						placeholder={t('value_numeric')}
						aria-label={t(`value_numeric`)}
						label={t(`value_numeric`)}
						required={false}
					/>
				</>
			)}
			{selectedChip === 'search' && (
				<>
					<div className={`${styles.h3} ${styles.space}`}>{t('title_other')}</div>
					<div className={`${styles.footnote} ${styles.space}`}>{t('subtitle_other')}</div>
					<NumberInput
						name="other news"
						disabled={!hasWritePermission}
						className={`ion-text-end`}
						value={data?.searcherIndexNews}
						defaultValue={initialData?.searcherIndexNews}
						onIonChange={(e) => changeText('searcherIndexNews', e.detail.value)}
						placeholder={t('value_numeric')}
						aria-label={t(`value_numeric`)}
						label={t(`value_numeric`)}
						required={false}
					/>
				</>
			)}
			{hasWritePermission && (
				<div className={`${footerStyle} ${styles.footerButton}`}>
					<Button
						disabled={!isEdited}
						color="primary"
						className={styles.btnHeader}
						onClick={() => handleSave(data)}
					>
						{t('btn_save_data')}
					</Button>
				</div>
			)}
			<IonLoading isOpen={isLoading || loadingSave} message={t('msg_loading')} duration={0} />
		</>
	);
};
