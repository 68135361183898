import { useContext, useEffect, useState } from 'react';
import { Modal } from '@acciona/ui-ionic-kit';
import { useTranslation } from 'react-i18next';
import { Employee } from '../../../../_api/services/roles/types';
import { AppPermissionsContext } from '../../../../context/AppPermissions/AppPermissions.context';
import { AppPermissionsActions } from '../../../../context/AppPermissions/AppPermissions.actions';
import { ModalContentConfiguration } from '../ModalContentConfiguration/ModalContentConfiguration';
import { getUsersDataForEdition } from '../../helpers';
import styles from './styles.module.scss';

type Props = {
	label?: string;
	onClose: () => void;
	onSave: (newUsers: Employee[]) => void;
	showModal: boolean;
	selectedUsers: Employee[];
};

export const ModalEditUsers: React.FC<Props> = (props) => {
	const { dispatch } = useContext(AppPermissionsContext);
	const { t } = useTranslation();
	const [dataUsers, setDataUsers] = useState<Employee[]>([]);

	useEffect(() => {
		setDataUsers(getUsersDataForEdition(props.selectedUsers));
	}, [props.selectedUsers]);

	const handleCloseModal = () => {
		dispatch(AppPermissionsActions.resetConfigChanges());
		props.onClose();
	};

	const handleSaveChanges = () => {
		props.onSave(dataUsers);
	};

	const title =
		t('modal_edit_users_permissions_title') +
		(props.selectedUsers.length === 1
			? ` (${props.selectedUsers[0].employeeId} - ${props.selectedUsers[0].fullName})`
			: ` (${props.selectedUsers.length} ${t('abbr_selected')})`);

	const subtitle =
		t('modal_edit_users_subtitle_common') +
		(props.selectedUsers.length === 1
			? ` ${t('modal_edit_users_subtitle_individual')}`
			: ` ${t('modal_edit_users_subtitle_multiple')}`);

	const showDetails = props.selectedUsers.length === 1 ? false : true;

	return (
		<Modal isOpen={props.showModal} onDidDismiss={handleCloseModal} className={styles.modal}>
			<ModalContentConfiguration
				mode="edit"
				title={title}
				subtitle={subtitle}
				data={dataUsers}
				setData={setDataUsers}
				onClose={handleCloseModal}
				onSave={handleSaveChanges}
				showDetails={showDetails}
			/>
		</Modal>
	);
};
