import { useMemo } from 'react';
import { IonButton } from '@ionic/react';
import { FilterChip, Icon } from '@acciona/ui-ionic-kit';
import _ from 'lodash';
import styles from './styles.module.scss';

type Props = {
	selectedLang: string;
	selectedCategory: number;
	setSelectedCategory: (selectedCategory: number) => void;
	categoriesState: any[];
	setShowCategories: (show: boolean) => void;
	keyField?: string;
};

export const FilterChipsBar = (props: Props) => {
	const { selectedLang, selectedCategory, setSelectedCategory, categoriesState, setShowCategories, keyField } = props;

	const items = useMemo(() => {
		return categoriesState
			? _.sortBy(
					categoriesState.filter((c) => !c.disabled),
					'ordinal',
			  )
			: [];
	}, [categoriesState]);

	return (
		<div className={styles.filterBar}>
			<div className={styles.chipContainer}>
				{!_.isEmpty(items) &&
					items.map((item) => {
						const itemData = item?.translations.find((tr) => tr.language === selectedLang);
						if (_.isEmpty(itemData)) {
							return null;
						} else {
							const itemId = keyField ? item[keyField] : item.id;
							return (
								<FilterChip
									key={`filter-chip-${itemId}`}
									id={itemId}
									text={itemData?.name}
									checked={selectedCategory === itemId}
									icon={item?.icon ? `icon icon-${item.icon}` : null}
									onChange={() => setSelectedCategory(itemId)}
								/>
							);
						}
					})}
			</div>
			<div className={styles.flexEndButton}>
				<IonButton fill="clear" onClick={() => setShowCategories(true)} className={styles.buttonEdit}>
					<Icon className="icon icon-edit icon24" />
				</IonButton>
			</div>
		</div>
	);
};
