import { Button, Icon } from '@acciona/ui-ionic-kit';
import { IonLoading } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import _ from 'lodash';
import { termsServices } from '../../../_api/services/terms';
import { LinkReservation } from '../../../_api/services/terms/types';
import useAppContext from '../../../hooks/useAppContext';
import { LanguageSelector } from '../../../components/LanguageSelector/LanguageSelector';
import styles from './styles.module.scss';

export type Props = {
	hasWritePermission: boolean;
	setError: (msg: string) => void;
	setSuccess: (msg: string) => void;
	footerStyle: string;
};

export const TermsReservations: React.FC<Props> = (props) => {
	const { setError, setSuccess, hasWritePermission, footerStyle } = props;
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const { setThereAreUnsavedChanges } = useAppContext();
	const [selectedLang, setSelectedLang] = useState('es');
	const [data, setData] = useState<any>([]);
	const [formData, setFormData] = useState<any>([]);
	const [isEdited, setIsEdited] = useState(false);

	useEffect(() => {
		setThereAreUnsavedChanges(isEdited);
	}, [isEdited]);

	const maxSize = parseInt(process.env.REACT_APP_MAX_FILE_TERMS_SIZE) * 1024 * 1024;
	const { isLoading: isLoading } = useQuery<LinkReservation>(
		['terms_reservations'],
		async () => await termsServices.getReservationsTerms(),
		{
			refetchOnWindowFocus: false,
			onError: (error) => setError(error as string),
			onSuccess: (data) => setData(data),
		},
	);
	const { mutate: handleSave, isLoading: loadingSave } = useMutation(termsServices.saveReservationsTerms, {
		onSuccess: () => {
			setSuccess(t('msg_success'));
			setIsEdited(false);
			queryClient.refetchQueries('terms_reservations');
		},
		onError: (error) => {
			setError(error as string);
		},
	});

	const onChange = (e, id, type, lang) => {
		if (!_.isUndefined(e.target.files[0])) {
			if (e.target.files[0].type !== 'text/html') {
				setError(t('file_invalid'));
				return;
			}

			if (e.target.files[0].size > maxSize) {
				setError(t('file_size_invalid'));
				return;
			}
			const newFormData = new FormData(document.forms[0]);

			newFormData.set('id', id);
			newFormData.set('file', e.target.files[0]);
			newFormData.set('type', type);
			newFormData.set('language', lang);

			setFormData([
				...formData.filter((e) => e.id !== id),
				{ id: id, data: newFormData, filename: e.target.files[0].name, type: type, language: lang },
			]);
			setIsEdited(true);
		}
	};

	const saveFiles = () => {
		const selectedData = formData.find((data) => data.language === selectedLang);
		if (selectedData) {
			handleSave(selectedData.data);
		}
	};

	return (
		<>
			<LanguageSelector selectedLang={selectedLang} setSelectedLang={setSelectedLang} />
			<p className={styles.footnote}>{t('text_links_reservations_policies')}</p>
			<div className={styles.h2}>{t('reservationPolicies')}</div>

			<div className={styles.containerInputs}>
				<div>
					<div className={styles.h3}>{t('seatPolicies')}</div>
					<div className={`${styles.footnoteInput}`}>{`Max.${process.env.REACT_APP_MAX_FILE_TERMS_SIZE}Mb.`}</div>
				</div>

				<label className={`${styles.input}`}>
					<span className={`${styles.h3} ${styles.color_dark}`}>
						{_.isEmpty(data.filter((e) => e.type === 'policyDesk' && e.language === selectedLang)) &&
						_.isEmpty(formData.filter((e) => e.type === 'policyDesk' && e.language === selectedLang)) ? (
							<span>
								<u>{t('lbl_select_one')}</u> {t('or_add_file')}
							</span>
						) : _.isEmpty(formData.filter((e) => e.type === 'policyDesk' && e.language === selectedLang)) ? (
							_.first(data.filter((e) => e.type === 'policyDesk' && e.language === selectedLang))?.description
						) : (
							_.first(formData.filter((e) => e.type === 'policyDesk' && e.language === selectedLang))?.filename
						)}
					</span>
					<input
						type="file"
						accept="text/html"
						multiple={false}
						onClick={(e) => (e.currentTarget.value = '')}
						onChange={(e) =>
							onChange(
								e,
								_.first(data.filter((e) => e.type === 'policyDesk' && e.language === selectedLang))?.id,
								'policyDesk',
								selectedLang,
							)
						}
						disabled={!hasWritePermission}
					/>
					{_.isEmpty(data.filter((e) => e.type === 'policyDesk' && e.language === selectedLang)) ? (
						<Icon className={`icon icon-arrow_down icon24 ${styles.iconRotate} ${styles.icon}`} />
					) : (
						<Icon className={`icon icon-close icon24  ${styles.icon}`} />
					)}
				</label>
			</div>
			<div className={styles.containerInputs}>
				<div>
					<div className={styles.h3}>{t('parkingPolicies')}</div>
					<div className={`${styles.footnoteInput}`}>{`Max.${process.env.REACT_APP_MAX_FILE_TERMS_SIZE}Mb.`}</div>
				</div>

				<label className={`${styles.input}`}>
					<span className={`${styles.h3} ${styles.color_dark}`}>
						{_.isEmpty(data.filter((e) => e.type === 'policyParking' && e.language === selectedLang)) &&
						_.isEmpty(formData.filter((e) => e.type === 'policyParking' && e.language === selectedLang)) ? (
							<span>
								<u>{t('lbl_select_one')}</u> {t('or_add_file')}
							</span>
						) : _.isEmpty(formData.filter((e) => e.type === 'policyParking' && e.language === selectedLang)) ? (
							_.first(data.filter((e) => e.type === 'policyParking' && e.language === selectedLang))?.description
						) : (
							_.first(formData.filter((e) => e.type === 'policyParking' && e.language === selectedLang))
								?.filename
						)}
					</span>
					<input
						type="file"
						accept="text/html"
						multiple={false}
						onClick={(e) => (e.currentTarget.value = '')}
						onChange={(e) =>
							onChange(
								e,
								_.first(data.filter((e) => e.type === 'policyParking' && e.language === selectedLang))?.id,
								'policyParking',
								selectedLang,
							)
						}
						disabled={!hasWritePermission}
					/>
					{_.isEmpty(data.filter((e) => e.type === 'policyParking' && e.language === selectedLang)) ? (
						<Icon className={`icon icon-arrow_down icon24 ${styles.iconRotate} ${styles.icon}`} />
					) : (
						<Icon className={`icon icon-close icon24  ${styles.icon}`} />
					)}
				</label>
			</div>
			<div className={styles.containerInputs}>
				<div>
					<div className={styles.h3}>{t('roomPolicies')}</div>
					<div className={`${styles.footnoteInput}`}>{`Max.${process.env.REACT_APP_MAX_FILE_TERMS_SIZE}Mb.`}</div>
				</div>

				<label className={`${styles.input}`}>
					<span className={`${styles.h3} ${styles.color_dark}`}>
						{_.isEmpty(data.filter((e) => e.type === 'policyRoom' && e.language === selectedLang)) &&
						_.isEmpty(formData.filter((e) => e.type === 'policyRoom' && e.language === selectedLang)) ? (
							<span>
								<u>{t('lbl_select_one')}</u> {t('or_add_file')}
							</span>
						) : _.isEmpty(formData.filter((e) => e.type === 'policyRoom' && e.language === selectedLang)) ? (
							_.first(data.filter((e) => e.type === 'policyRoom' && e.language === selectedLang))?.description
						) : (
							_.first(formData.filter((e) => e.type === 'policyRoom' && e.language === selectedLang))?.filename
						)}
					</span>
					<input
						type="file"
						accept="text/html"
						multiple={false}
						onClick={(e) => (e.currentTarget.value = '')}
						onChange={(e) =>
							onChange(
								e,
								_.first(data.filter((e) => e.type === 'policyRoom' && e.language === selectedLang))?.id,
								'policyRoom',
								selectedLang,
							)
						}
						disabled={!hasWritePermission}
					/>
					{_.isEmpty(data.filter((e) => e.type === 'policyRoom' && e.language === selectedLang)) ? (
						<Icon className={`icon icon-arrow_down icon24 ${styles.iconRotate} ${styles.icon}`} />
					) : (
						<Icon className={`icon icon-close icon24  ${styles.icon}`} />
					)}
				</label>
			</div>
			{hasWritePermission && (
				<div className={`${footerStyle} ${styles.footerButton}`}>
					<Button disabled={!isEdited} color="primary" className={styles.btnHeader} onClick={() => saveFiles()}>
						{t('btn_save_data')}
					</Button>
				</div>
			)}
			<IonLoading isOpen={isLoading || loadingSave} message={t('msg_loading')} duration={8000} />
		</>
	);
};
