/* eslint-disable @typescript-eslint/no-unused-vars */
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Button, Icon, Modal, Toast } from '@acciona/ui-ionic-kit';
import {
	InputChangeEventDetail,
	IonContent,
	IonFooter,
	IonInput,
	IonItem,
	IonLabel,
	IonRadio,
	IonRadioGroup,
	IonText,
	IonTitle,
	IonToolbar,
} from '@ionic/react';
import { IonInputCustomEvent } from '@ionic/core/dist/types/components';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Policy } from '../../../../../_api/services/terms/types';
import { FileInput } from '../../../../../components/FileInput/FileInput';
import { checkEmptyFields, checkIsEdited, getCurrentTranslationData } from './helpers';
import styles from '../../styles.module.scss';
import { TextInput } from '../../../../../components/TextInput/TextInput';

type Props = {
	mode: 'add' | 'edit';
	showModal: boolean;
	initialData: Policy;
	hasWritePermission?: boolean;
	language: string;
	onClose: () => void;
	onSave: (updatedPolicy: Policy) => void;
};

const PolicyModal: React.FC<Props> = (props) => {
	const { mode, onClose, onSave, showModal, initialData, hasWritePermission, language: selectedLanguage } = props;
	const { t } = useTranslation();
	const [data, setData] = useState<Policy>(null);
	const [selectedOption, setSelectedOption] = useState<'file' | 'link'>('link');
	const [msgError, setMsgError] = useState('');
	const [fileHasBeenChanged, setFileHasBeenChanged] = useState(false);
	const maxSize = parseInt(process.env.REACT_APP_MAX_FILE_TERMS_SIZE) * 1024 * 1024;
	const acceptedFileTypes = ['application/pdf', 'text/html'];

	useEffect(() => {
		if (!_.isEmpty(initialData)) {
			setSelectedOption(initialData?.translations?.[0]?.fileName ? 'file' : 'link');
		}
	}, [initialData]);

	useEffect(() => {
		setData(initialData);
	}, [initialData, selectedLanguage]);

	const updateTextFieldsHandler = (event: IonInputCustomEvent<InputChangeEventDetail>) => {
		const { name, value } = event.target;
		setData((prev) => ({
			...prev,
			translations: prev.translations.map((translation) => {
				if (translation.language === selectedLanguage || translation[name] === '') {
					return {
						...translation,
						[name]: value,
					};
				} else {
					return translation;
				}
			}),
		}));
	};

	const uploadFileHandler = (event: ChangeEvent<HTMLInputElement>) => {
		const uploadedFile: File = event.target.files[0];

		if (_.isUndefined(uploadedFile)) {
			return;
		}

		if (!acceptedFileTypes.includes(uploadedFile.type)) {
			setMsgError(t('file_invalid'));
			return;
		}
		if (uploadedFile.size > maxSize) {
			setMsgError(t('file_size_invalid'));
			return;
		}

		setData((prev) => ({
			...prev,
			translations: prev.translations.map((translation) => {
				if (mode === 'add' || translation.language === selectedLanguage) {
					return {
						language: translation.language,
						name: translation.name,
						file: uploadedFile,
						fileName: uploadedFile.name,
					};
				} else {
					return translation;
				}
			}),
		}));

		setFileHasBeenChanged(true);
	};

	const handleSaveChanges = () => {
		const changes = {
			...data,
			translations: data.translations.map(({ language, name, file = null, fileName = '', link = '' }) => {
				if (selectedOption === 'file') {
					return {
						language: language,
						name: name,
						file: file,
						fileName: fileName,
						...(language === selectedLanguage && mode === 'edit' ? { isEdited: true } : {}),
						...(language === selectedLanguage && mode === 'add' ? { isNew: true } : {}),
					};
				} else {
					// selectedOption === 'link'
					return {
						language: language,
						name: name,
						link: link,
						...(language === selectedLanguage && mode === 'edit' ? { isEdited: true } : {}),
						...(language === selectedLanguage && mode === 'add' ? { isNew: true } : {}),
					};
				}
			}),
		};
		onSave(changes);
	};

	const saveIsDisabled = useMemo(() => {
		const isEdited = checkIsEdited(initialData, data, selectedOption, selectedLanguage);
		const fileIsNotModified = selectedOption === 'file' && !fileHasBeenChanged;
		const thereAreEmptyFields = checkEmptyFields(data, selectedOption, selectedLanguage);

		if (!hasWritePermission) return true;
		if (thereAreEmptyFields) return true;
		if (isEdited) return false;
		if (!fileIsNotModified) return false;
		return true;
	}, [initialData, data, selectedLanguage, selectedOption, fileHasBeenChanged, hasWritePermission]);

	const title = `${mode === 'add' ? t('add_new_policy') : t('modify_policy')} (${t('lang_label')}: ${t(
		'language_' + selectedLanguage,
	)})`;

	return (
		<Modal isOpen={showModal} onDidDismiss={onClose} className={styles.modal}>
			<IonToolbar>
				{getCurrentTranslationData(data, selectedLanguage, 'language') && (
					<IonTitle>
						<h3 className="ion-text-center">
							<b>{title}</b>
						</h3>
					</IonTitle>
				)}
				<Button slot="end" fill="clear" color="light" className={styles.closeModalBtn} onClick={onClose}>
					<IonLabel className="dark">
						<Icon className="icon icon24 icon-close" />
					</IonLabel>
				</Button>
			</IonToolbar>
			<IonContent>
				<div>
					<IonItem lines="none" className={styles.categoryListText}>
						<IonText className={styles.footnote}>{t('modal_policy_description')}</IonText>
					</IonItem>
					<TextInput
						name="name"
						className="ion-text-end"
						isValid={!msgError}
						onIonInput={(e) => updateTextFieldsHandler(e)}
						value={getCurrentTranslationData(data, selectedLanguage, 'name')}
						placeholder={t('write')}
						label={t('policy_name')}
						disabled={!hasWritePermission}
					/>
					<IonRadioGroup
						className={styles.radioGroup}
						value={selectedOption}
						onIonChange={(e) => setSelectedOption(e.detail.value)}
					>
						<IonItem lines="none">
							<IonRadio labelPlacement="end" value="file">
								{t('file')}
							</IonRadio>
						</IonItem>
						<IonItem lines="none">
							<IonRadio labelPlacement="end" value="link">
								{t('link')}
							</IonRadio>
						</IonItem>
					</IonRadioGroup>

					{selectedOption === 'file' && (
						<FileInput
							className={styles.fileInput}
							fileName={getCurrentTranslationData(data, selectedLanguage, 'fileName')}
							disabled={false}
							onChange={uploadFileHandler}
							inputOptions={{
								accept: acceptedFileTypes.join(','),
							}}
						/>
					)}

					{selectedOption === 'link' && (
						<TextInput
							name="link"
							className="ion-text-end"
							isValid={!msgError}
							onIonInput={(e) => updateTextFieldsHandler(e)}
							value={getCurrentTranslationData(data, selectedLanguage, 'link')}
							placeholder={t('write')}
							label={t('link')}
							disabled={!hasWritePermission}
						/>
					)}
				</div>
			</IonContent>
			<IonFooter className={styles.btnModal}>
				<div>
					<Button color="light" className={styles.btnHeader} onClick={onClose}>
						{t('btn_cancel')}
					</Button>
				</div>
				<div>
					<Button
						color="primary"
						disabled={saveIsDisabled}
						className={styles.btnHeader}
						onClick={handleSaveChanges}
					>
						{t('btn_accept')}
					</Button>
				</div>
			</IonFooter>
			<Toast
				isOpen={!!msgError}
				type="error"
				message={msgError}
				position="bottom"
				onDidDismiss={() => setMsgError('')}
			/>
		</Modal>
	);
};

export default PolicyModal;
