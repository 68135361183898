import _ from 'lodash';
import { IEmployeeResult } from './types';
import { diacriticalReplace, sortAscDiatriticInsensitive } from '../../utils/functions';

export const matchesUserSearch = (search: string, user: IEmployeeResult) => {
	return (
		_.isEmpty(search) ||
		diacriticalReplace(`${user.employeeId} - ${user.fullName}`)
			.toLowerCase()
			.includes(diacriticalReplace(search).toLowerCase())
	);
};

export const getFilteredUsers = (
	dataUsers: IEmployeeResult[],
	showOnlyCheckeds: boolean,
	userNameSearch: string,
	ordered: boolean = true,
) => {
	if (_.isEmpty(dataUsers)) {
		return [];
	}

	const result = dataUsers.filter((user) => {
		const show = !showOnlyCheckeds || (showOnlyCheckeds && user.isChecked);
		return show && matchesUserSearch(userNameSearch, user);
	});

	return ordered ? sortAscDiatriticInsensitive(result, 'fullName') : result;
};
