import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IonContent, IonLabel } from '@ionic/react';
import { useQuery } from 'react-query';
import { Icon, SegmentButton, Segmented } from '@acciona/ui-ionic-kit';
import _ from 'lodash';
import useAppContext from '../../hooks/useAppContext';
import { AppPermissionsContextProvider } from '../../context/AppPermissions/AppPermissions.context';
import { useScrollBar } from '../../hooks/useScrollBar';
import { useSegmentedPagination } from '../../hooks/useSegmentedPagination';
import { HeaderPage } from '../../components/HeaderPage/HeaderPage';
import { AppPermissionsEdition } from './AppPermissionsEdition';
import { roleService } from '../../_api/services/roles';
import { AppPermission } from '../../_api/services/roles/types';
import { getPermissionsList } from './helpers';
import { generalServicesService } from '../../_api/services/generalServices';
import styles from './styles.module.scss';

const AppPermissionsPage: React.FC = () => {
	const { t } = useTranslation();
	const { setThereAreUnsavedChanges } = useAppContext();
	const [permissionsList, setPermissionsList] = useState<AppPermission[]>([]);
	const [segmentedSelected, setSegmentedSelected] = useState<AppPermission>();
	const [scroll, setScroll] = useState(false);
	let footerStyle = null;
	const refContainer = useRef(null);
	const refHeader = useRef(null);
	const { paginatedData, handlePreviousPage, handleNextPage, canGoToPreviousPage, canGoToNextPage } =
		useSegmentedPagination({
			segmentsContainerRef: refHeader,
			data: permissionsList,
			segmentSelected: segmentedSelected,
		});
	const hasScroll = useScrollBar(refContainer, refHeader, segmentedSelected);
	footerStyle = hasScroll ? styles.hasScroll : styles.noScroll;

	const { data: profilesList } = useQuery(['profilesList'], async () => await roleService.getAppProfilesList());

	useQuery(['servicesAppPermissions'], generalServicesService.getTransversalServices, {
		refetchOnWindowFocus: false,
		onSuccess: (data) => {
			const permissions = getPermissionsList(data);
			setPermissionsList(permissions);
			setSegmentedSelected(permissions[0]);
		},
	});

	const showNavigationButtons = canGoToPreviousPage || canGoToNextPage;

	useEffect(() => {
		return () => {
			setThereAreUnsavedChanges(false);
		};
	}, []);

	return (
		<AppPermissionsContextProvider>
			<IonContent scrollEvents forceOverscroll={true} onIonScroll={(x) => setScroll(x.detail.scrollTop > 0)}>
				{_.isEmpty(permissionsList) || !segmentedSelected ? (
					<HeaderPage
						scroll={scroll}
						title={t('menu_permissions_app_management')}
						className={styles.headerSection}
					/>
				) : (
					<>
						<HeaderPage
							scroll={scroll}
							title={t('menu_permissions_app_management')}
							className={styles.headerSection}
						>
							<div ref={refHeader} className={styles.segmentsContainer}>
								{showNavigationButtons && (
									<button
										className={styles.paginationBtn}
										onClick={handlePreviousPage}
										disabled={!canGoToPreviousPage}
									>
										<Icon className="icon icon-chevron-left" />
									</button>
								)}
								<Segmented value={segmentedSelected.id} className={styles.segmented}>
									{paginatedData.map((item) => (
										<SegmentButton
											key={item.id}
											onClick={() => setSegmentedSelected(item)}
											value={item.id}
											mode={'md'}
										>
											<IonLabel>{item.tab}</IonLabel>
										</SegmentButton>
									))}
								</Segmented>
								{showNavigationButtons && (
									<button
										className={styles.paginationBtn}
										onClick={handleNextPage}
										disabled={!canGoToNextPage}
									>
										<Icon className="icon icon-chevron-right" />
									</button>
								)}
							</div>
						</HeaderPage>
						<div className="container" ref={refContainer}>
							<AppPermissionsEdition
								key={segmentedSelected.id}
								permission={segmentedSelected}
								profilesList={profilesList}
								footerStyle={footerStyle}
							/>
						</div>
					</>
				)}
			</IonContent>
		</AppPermissionsContextProvider>
	);
};

export default AppPermissionsPage;
