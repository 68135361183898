import { useState, useRef, useEffect, useCallback } from 'react';
import { authServices } from '../_api/services/auth';

export const usePermissions = (functionality: string, action: 'READ' | 'WRITE') => {
	const initialValues = {
		READ: false,
		WRITE: false,
	};

	const [permissions, setPermissions] = useState(initialValues);
	const [error, setError] = useState('');
	const mountedRef = useRef(true);

	const checkPermissions = useCallback(async () => {
		try {
			const resultQuery = await authServices.checkPermission(functionality, action);
			setPermissions((prev) => ({ ...prev, [action]: resultQuery.hasPermission }));
		} catch (err) {
			setError(err);
			throw new Error(err);
		}
	}, [functionality, action]);

	useEffect(() => {
		if (mountedRef.current) {
			checkPermissions();
		}
		return () => {
			mountedRef.current = false;
		};
	}, [checkPermissions]);

	return {
		permissions,
		error,
	};
};
