import { useEffect, useState } from 'react';

export function useScrollBar(container, header, page) {
	const [hasScroll, setHasScroll] = useState(true);
	useEffect(() => {
		const handleResize = () => {
			const containerHeight = container?.current?.offsetHeight ?? 0;
			const headerHeight = header?.current?.offsetHeight ?? 0;
			const heightInPx = window.innerHeight;
			const containerHasScroll = containerHeight > heightInPx - headerHeight;
			setHasScroll(containerHasScroll);
		};

		setTimeout(() => {
			handleResize();
		}, 1000);

		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [page]);
	return hasScroll;
}
