import { Button } from '@acciona/ui-ionic-kit';
import { IonLoading } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import useAppContext from '../../../hooks/useAppContext';
import { roomsServices } from '../../../_api/services/rooms';
import styles from './styles.module.scss';
import _ from 'lodash';
import { isValidPhone } from '../../../utils/functions';
import { TextInput } from '../../../components/TextInput/TextInput';

type Props = {
	setError?: Function;
	setSuccess?: Function;
	hasWritePermission?: boolean;
};

export const Cleaner: React.FC<Props> = (props) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const { setThereAreUnsavedChanges } = useAppContext();
	const [phone, setPhoneValue] = useState('');
	const [errorPhone, setErrorPhone] = useState(false);
	const [isEdited, setIsEdited] = useState(false);

	useEffect(() => {
		setThereAreUnsavedChanges(isEdited);
	}, [isEdited]);

	const { data, isLoading: isLoading } = useQuery<string>(
		['phone_cleaner'],
		async () => await roomsServices.getPhoneCleaner(),
		{
			refetchOnWindowFocus: false,
			onError: (error) => props.setError(error as string),
			onSuccess: (data) => setPhoneValue(data),
		},
	);

	const { mutate: handleSave, isLoading: loadingSave } = useMutation(roomsServices.savePhoneCleaner, {
		onSuccess: () => {
			props.setSuccess(t('msg_success'));
			setIsEdited(false);
			queryClient.refetchQueries();
		},
		onError: (error) => {
			props.setError(error as string);
		},
	});

	const handlePhoneChange = (phone) => {
		setErrorPhone(!isValidPhone(phone));
		setPhoneValue(phone);
	};

	useEffect(() => {
		setIsEdited(!_.isEqual(phone, data));
	}, [phone]);

	return (
		<>
			<div className={styles.h3}>{t('header_att_inme')}</div>
			<div className={`${styles.footnote} ${styles.space}`}>{t('label_phone_att')}</div>
			<TextInput
				name="phone"
				className={`ion-text-end`}
				value={phone}
				defaultValue={data}
				onIonChange={(e) => handlePhoneChange(e.detail.value)}
				placeholder={t('write_text')}
				disabled={!props.hasWritePermission}
				aria-label={t(`phone`)}
				label={t(`phone`)}
				isValid={!errorPhone}
			/>
			{props.hasWritePermission && (
				<div className={styles.footerButton}>
					<Button
						disabled={!(isEdited && phone !== '')}
						color="primary"
						className={styles.btnHeader}
						onClick={() => (errorPhone ? props.setError(t('msg_error_phone')) : handleSave(phone))}
					>
						{t('btn_save_data')}
					</Button>
				</div>
			)}
			<IonLoading isOpen={isLoading || loadingSave} message={t('msg_loading')} duration={1500} />
		</>
	);
};
