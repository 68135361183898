import { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { AppPermission } from '../_api/services/roles/types';

type useSegmentedPaginationParams = {
	segmentsContainerRef: React.RefObject<HTMLDivElement>;
	segmentSelected: AppPermission;
	data: AppPermission[];
};

export const useSegmentedPagination = ({
	segmentsContainerRef,
	segmentSelected,
	data,
}: useSegmentedPaginationParams) => {
	const [page, setPage] = useState(1);
	const [elementsPerPage, setElementsPerPage] = useState(0);

	useEffect(() => {
		if (!segmentSelected) {
			return;
		}

		const handleResize = () => {
			const containerWidth = segmentsContainerRef?.current?.offsetWidth ?? 1000;
			let elements = 1;
			if (containerWidth > 700) {
				elements = Math.floor(containerWidth / 100 - 3);
			}

			const dataIds = data.map((item) => item.id);

			const newPage = Math.ceil((dataIds.indexOf(segmentSelected.id) + 1) / elements);
			setPage(newPage);

			setElementsPerPage(elements);
		};

		setTimeout(() => {
			handleResize();
		}, 500);

		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [segmentSelected]);

	const paginatedData = useMemo(() => {
		return data.slice(page * elementsPerPage - elementsPerPage, page * elementsPerPage);
	}, [page, elementsPerPage]);

	const handlePreviousPage = () => {
		setPage((v) => v - 1);
	};

	const handleNextPage = () => {
		setPage((v) => v + 1);
	};

	const canGoToPreviousPage = _.first(data) !== _.first(paginatedData);
	const canGoToNextPage = _.last(data) !== _.last(paginatedData);

	return { paginatedData, handlePreviousPage, handleNextPage, canGoToPreviousPage, canGoToNextPage };
};
