import i18n from '../../../i18nextConf';

export const editOptions = [
	{
		id: 1,
		text: i18n.t('modify_points'),
	},
	{
		id: 2,
		text: i18n.t('modify_vehicle_data'),
	},
	{
		id: 3,
		text: i18n.t('modify_reservation'),
	},
];

export const getSuccessMessage = (updatedRows) => {
	if (updatedRows.length === 1) {
		return i18n.t('msg_success_updated_parking_points_single_user', {
			name: updatedRows[0].fullName,
		});
	} else if (updatedRows.length > 1) {
		return i18n.t('msg_success_updated_parking_points_multiple_users', {
			number: updatedRows.length,
		});
	} else {
		return 'error';
	}
};
