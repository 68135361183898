import _ from 'lodash';
import { ConfigType, MenuItem } from '../../../_api/services/generalServices/types';

export enum FILE_SIZE {
	'1MB' = 1e6,
	'5MB' = 5e6,
	'10MB' = 1e7,
}

export const serviceConfigOptions = [
	{
		value: ConfigType.EXTERNAL_LINK,
		name: 'lbl_external_link',
	},
	{
		value: ConfigType.FILE,
		name: 'lbl_file',
	},
	{
		value: ConfigType.EXTERNAL_APP_LINK,
		name: 'lbl_app_link',
	},
	{
		value: ConfigType.INTERNAL_LINK,
		name: 'lbl_internal_link',
	},
];

export enum ServiceEndIcons {
	EXTERN = 'icon icon-extern',
	INTERN = 'icon icon-chevron-right',
	FILE = 'icon icon-news',
}

export const getSelectedConfig = (service: MenuItem, lang: string): ConfigType => {
	const translation = service?.translations.find((t) => t.language === lang);
	if (!translation) return ConfigType.EXTERNAL_LINK;
	if (translation.externalLink?.active) {
		return ConfigType.EXTERNAL_LINK;
	}
	if (translation.file?.active) {
		return ConfigType.FILE;
	}
	if (translation.externalAppLink?.active) {
		return ConfigType.EXTERNAL_APP_LINK;
	}
	if (translation.internalLink?.active) {
		return ConfigType.INTERNAL_LINK;
	}
	return ConfigType.EXTERNAL_LINK;
};

export const checkChanges = (mod: MenuItem, original: MenuItem) => {
	return _.isEqual(original, mod);
};

export const checkValidServiceConfig = (service: MenuItem): boolean => {
	const textInputsAreCompleted = service?.translations.every((t) => {
		if (t.externalAppLink.active) {
			return (
				t.externalAppLink.deepLinkAndroid !== '' &&
				t.externalAppLink.deepLinkIOS !== '' &&
				t.externalAppLink.downloadAppAndroid !== '' &&
				t.externalAppLink.downloadAppIOS !== '' &&
				t.externalAppLink.webUrl !== ''
			);
		}
		if (t.externalLink.active) {
			return t.externalLink.url !== '';
		}
		if (t.internalLink.active) {
			return t.internalLink.url !== '';
		}
		if (t.file.active) {
			return t.file.fileName && t.file.fileName !== '';
		}
	});
	return textInputsAreCompleted;
};

export const checkInitialServiceConfig = (service: MenuItem) => {
	service.translations.forEach((t) => {
		const allConfigsInactive = Object.values(ConfigType).every((config) => {
			return !t[config].active;
		});
		if (allConfigsInactive) {
			t.externalLink.active = true;
			service.endIcon = ServiceEndIcons.EXTERN;
		}
	});
	return service;
};
