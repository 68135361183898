import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { tokenServices } from '../token';
import { Token } from '../token/types';
import { User } from './types';
import { history } from '../../../_helpers/history';
import muleSoftInstance from '../../utils/httpCommon';
import { FUNCTIONALITIES } from '../../../utils/constants';

const getCurrentUserSession = async (
	code: string,
): Promise<{
	token: Token;
	user: User;
}> => {
	try {
		const path = `grant_type=authorization_code&redirect_uri=${process.env.REACT_APP_COGNITO_WEB_REDIRECT_URI}&client_id=${process.env.REACT_APP_COGNITO_WEB_CLIENT_ID}&code=${code}`;

		const resp = await axios.post(process.env.REACT_APP_COGNITO_WEB_TOKEN, path);

		const decoded = jwtDecode<any>(resp.data.id_token); // Returns with the JwtPayload type

		const userData = {
			id: decoded.identities[0].userId,
			email: decoded.email,
			name: decoded.name,
			groups: decoded.groups,
			owner: decoded['custom:employeeid'],
		};

		tokenServices.setAccount(resp.data, userData);

		history.push('/');
		return { token: resp.data, user: userData };
	} catch (err) {
		throw new Error(err);
	}
};

const refreshToken = async (): Promise<any> => {
	try {
		const path = `grant_type=refresh_token&client_id=${
			process.env.REACT_APP_COGNITO_WEB_CLIENT_ID
		}&refresh_token=${tokenServices.getLocalRefreshToken()}`;

		const resp = await axios.post(process.env.REACT_APP_COGNITO_WEB_TOKEN, path);

		tokenServices.updateLocalTokens(resp.data.id_token, resp.data.access_token);

		return {
			id_token: resp.data.id_token,
			access_token: resp.data.access_token,
		};
	} catch (err) {
		throw new Error(err);
	}
};

const checkAuth = async (): Promise<any> => {
	try {
		const token = tokenServices.getAccount();
		const user = tokenServices.getUser();
		if (token && user) {
			//Validate token
			// const path = 'auth';
			// const resp = await axios.get(path, { params: token });
			return { token: token, user: user };
		} else {
			throw new Error('User not fount');
		}
	} catch (err) {
		throw new Error(err);
	}
};

const checkPermission = async (functionality, action): Promise<any> => {
	if (functionality === FUNCTIONALITIES.public) {
		return {
			hasPermission: true,
		};
	}
	try {
		const path = `${process.env.REACT_APP_API_PORTAL}/v1/hasPermission`;
		const resp = await muleSoftInstance.get(path, {
			params: { resource: functionality, action: action },
		});

		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

export const authServices = {
	refreshToken,
	getCurrentUserSession,
	checkAuth,
	checkPermission,
};
