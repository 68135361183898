import { useEffect, useState } from 'react';
import { Button, Icon, Modal, SegmentButton, Segmented } from '@acciona/ui-ionic-kit';
import { IonContent, IonFooter, IonLabel, IonSpinner, IonTitle, IonToolbar } from '@ionic/react';
import { ModalEditVehicleProps as Props } from '../../types';
import { useTranslation } from 'react-i18next';
import { TabContent } from '../../../../../components/TabContent/TabContent';
import { EditVehicleItem } from '../EditVehicleItem/EditVehicleItem';
import { useMutation, useQuery } from 'react-query';
import { parkingService } from '../../../../../_api/services/parking';
import { UserVehicle } from '../../../../../_api/services/parking/types';
import styles from './styles.module.scss';
import _ from 'lodash';

type EmployeeData = {
	employeeId: number;
	fullName: string;
};

export const ModalEditVehicle: React.FC<Props> = (props) => {
	const { t } = useTranslation();
	const { selectedRows: originalData, onClose, showModal, hasWritePermission, setFeedback } = props;
	const employeeData = (originalData[0] as EmployeeData) ?? { employeeId: null, fullName: '' };
	const { employeeId, fullName } = employeeData;
	const [vehicles, setVehicles] = useState<UserVehicle[]>([]);
	const [segmentedSelected, setSegmentedSelected] = useState<number>();
	const [isEdited, setIsEdited] = useState(false);

	const { data: listAttributes } = useQuery<any>(
		['get_list_attributes'],
		async () => await parkingService.getListAttributes(),
		{
			refetchOnWindowFocus: false,
			onError: (error) => setFeedback('error', error as string),
		},
	);

	const { isLoading, data: initialVehicles } = useQuery<any>(
		['get_user_vehicles'],
		async () => await parkingService.getUserVehicles(employeeId),
		{
			refetchOnWindowFocus: false,
			onSuccess: (data) => {
				setVehicles(data);
				setSegmentedSelected(data[0]?.vehicleId);
			},
			onError: (error: Error) => setFeedback('error', error.message),
		},
	);

	const { mutate: updateUserVehicles } = useMutation(parkingService.updateUserVehicles, {
		onSuccess: () => {
			setFeedback('success', t('vehicle_data_updated_success'));
		},
		onError: (error) => {
			setFeedback('error', error as string);
		},
	});

	const updateValue = (id: number, field: string, value: string | number) => {
		setVehicles((prev) =>
			prev.map((vehicle) => {
				if (vehicle.vehicleId === id) {
					const val = field === 'plugin' ? (value === 1 ? true : false) : value;
					return {
						...vehicle,
						[field]: val,
					};
				} else {
					return vehicle;
				}
			}),
		);
	};

	const onSaveHandler = () => {
		const modifiedVehicles = vehicles.filter((vehicle, index) => {
			const vehicleOriginal = initialVehicles[index];
			return !_.isEqual(vehicleOriginal, vehicle);
		});
		updateUserVehicles(modifiedVehicles);
		onClose();
	};

	const onCancelHandler = () => {
		onClose();
	};
	useEffect(() => {
		setIsEdited(!_.isEqual(vehicles, initialVehicles));
	}, [vehicles]);

	return (
		<Modal isOpen={showModal} onDidDismiss={onCancelHandler} className={styles.modal}>
			<IonToolbar className={styles.toolbar}>
				<Button
					slot="end"
					fill={'clear'}
					color="light"
					className={styles.closeModalBtn}
					aria-label={t('close_text')}
					onClick={onCancelHandler}
				>
					<Icon className="icon icon-close" aria-hidden="true" />
				</Button>
				<IonTitle className={`${styles.centerTitle} ${styles.headline}`}>
					{`${fullName} - ${t('modify_vehicle_data')}`}
				</IonTitle>
			</IonToolbar>
			<IonContent>
				{isLoading ? (
					<div className={`ion-text-center ${styles.spinnerContainer}`}>
						<IonSpinner />
					</div>
				) : vehicles.length < 1 ? (
					<p>{t('user_does_not_have_any_vehicle')}</p>
				) : vehicles.length === 1 ? (
					<EditVehicleItem vehicle={vehicles[0]} onChange={updateValue} listAttributes={listAttributes} />
				) : (
					<>
						<div className={`${styles.tabsMenu} ${styles.noMarginLeft}`}>
							<Segmented value={segmentedSelected}>
								{vehicles.map((vehicle) => (
									<SegmentButton
										key={vehicle.vehicleId}
										onClick={() => setSegmentedSelected(vehicle.vehicleId)}
										value={vehicle.vehicleId}
										mode={'md'}
									>
										<IonLabel>{`${vehicle.brand} ${vehicle.model}`}</IonLabel>
									</SegmentButton>
								))}
							</Segmented>
						</div>
						<div className={styles.tabsLineSeparator} />
						<div className={styles.contentContainer}>
							{vehicles.map((vehicle) => (
								<TabContent key={vehicle.vehicleId} id={vehicle.vehicleId} selectedId={segmentedSelected}>
									<EditVehicleItem
										vehicle={vehicles.find((v) => v.vehicleId === vehicle.vehicleId)}
										onChange={updateValue}
										listAttributes={listAttributes}
									/>
								</TabContent>
							))}
						</div>
					</>
				)}
			</IonContent>
			<IonFooter className={styles.btnModal}>
				<div>
					<Button color="lighter" className={styles.btnHeader} onClick={onCancelHandler}>
						{t('btn_cancel')}
					</Button>
				</div>

				{hasWritePermission && (
					<div>
						<Button color="primary" className={styles.btnHeader} disabled={!isEdited} onClick={onSaveHandler}>
							{t('btn_save')}
						</Button>
					</div>
				)}
			</IonFooter>
		</Modal>
	);
};
