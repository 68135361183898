import { useEffect, useState } from 'react';
import { IonSplitPane, IonRouterOutlet, IonMenu } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18nextConf';
import _ from 'lodash';
import useAppContext from '../../hooks/useAppContext';
import { tokenServices } from '../../_api/services/token';
import { userServices } from '../../_api/services/user';
import { CustomModal as UnsavedChangesModal } from '../CustomModal/CustomModal';
import { Sede, Language, ConfigurationPreference } from '../../_api/services/user/types';
import { useInitialConfig } from '../../hooks/useInitialConfig';
import ModalLanguageSelector from '../ModalLanguageSelector/ModalLanguageSelector';
import ModalHeadOfficeSelector from '../ModalHeadOfficeSelector/ModalHeadOfficeSelector';
import { ExternalMenuItem } from './components/ExternalMenuItem';
import { FixedMenu } from './components/FixedMenu';
import { MenuItem } from './components/MenuItem';
import { externalLinks } from './menuConfiguration';
import { HeaderMenu } from './components/HeaderMenu';
import styles from './styles.module.scss';
interface Props {
	children: React.ReactNode;
}
const SplitPane: React.FC<Props> = (props) => {
	const { t } = useTranslation();
	const {
		isAuth,
		setAuth,
		sedePortal,
		sedePortalOptions,
		configurationPreference,
		userLanguage,
		updateUserLanguage,
		updateSedePortal,
		thereAreUnsavedChanges,
		menuOptions,
		transversal,
	} = useAppContext();

	const { getUserConfig } = useInitialConfig();
	const [showLogoutModal, setShowLogoutModal] = useState(false);
	const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
	const [showChangeLanguageModal, setShowChangeLanguageModal] = useState(false);
	const [showChangeSedeModal, setShowChangeSedeModal] = useState(false);

	const languages = [
		{ code: 'es', name: i18n.t('language_es') },
		{ code: 'en', name: i18n.t('language_en') },
		{ code: 'pt', name: i18n.t('language_pt') },
	];

	const sedes = sedePortalOptions;

	const onSaveLanguage = (language: Language) => {
		updateUserLanguage(language);
	};

	const showChangeSedeModalHandler = () => {
		if (thereAreUnsavedChanges) {
			setShowUnsavedChangesModal(true);
		} else {
			setShowChangeSedeModal(true);
		}
	};

	const onConfirmUnsavedChangesHandler = () => {
		setShowChangeSedeModal(true);
		setShowUnsavedChangesModal(false);
	};

	const handleLogout = () => {
		setShowLogoutModal(false);
		setAuth(false);
		tokenServices.removeAccount();
	};

	const onSaveConfigOptions = (sede: Sede, preference: ConfigurationPreference) => {
		updateSedePortal(sede, preference);
		getUserConfig();
	};

	useEffect(() => {
		if (userLanguage?.code) {
			userServices
				.getUserLanguageConfig()
				.then((language) => {
					if (userLanguage.code !== language.code) {
						updateUserLanguage(userLanguage);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}, [userLanguage?.code]);

	return (
		<>
			<IonSplitPane when={true} contentId="main" disabled={!isAuth}>
				<IonMenu contentId="main">
					<HeaderMenu />
					<div className={styles.sectionsMenu}>
						<div>
							{menuOptions
								.filter((option) => option.link !== '/blank')
								.map((menuItem) => (
									<MenuItem key={menuItem.id} item={menuItem} />
								))}
							{!_.isEmpty(menuOptions.filter((option) => option.link !== '/blank')) && (
								<div className={styles.separatorMenu}></div>
							)}
							{externalLinks.map((externalItem) => (
								<ExternalMenuItem key={externalItem.id} item={externalItem} />
							))}
						</div>
					</div>
					<FixedMenu
						showLogoutModal={() => setShowLogoutModal(true)}
						showChangeLanguageModal={() => setShowChangeLanguageModal(true)}
						showChangeSedeModal={showChangeSedeModalHandler}
						allowPreferencesChange={
							sedePortalOptions?.length > 1 || (sedePortalOptions?.length === 1 && transversal)
						}
					/>
				</IonMenu>
				<IonRouterOutlet id="main" ionPage>
					{props.children}
				</IonRouterOutlet>
			</IonSplitPane>
			<UnsavedChangesModal
				showModal={showLogoutModal && isAuth}
				onConfirm={handleLogout}
				onClose={() => setShowLogoutModal(false)}
				title={t('logout')}
				description={`${t('isedited_logout_msg')} ${t('logout_are_sure')}`}
			/>
			<UnsavedChangesModal
				showModal={showUnsavedChangesModal && isAuth}
				onConfirm={onConfirmUnsavedChangesHandler}
				onClose={() => setShowUnsavedChangesModal(false)}
				title={t('acciona_campus')}
				description={t(
					configurationPreference === 'transversal' ? 'change_transversal_are_sure' : 'change_sede_are_sure',
				)}
			/>
			<ModalLanguageSelector
				data={languages}
				showModal={showChangeLanguageModal && isAuth}
				onSave={onSaveLanguage}
				onClose={() => setShowChangeLanguageModal(false)}
				title={t('title_lang_selector')}
				header={t('header_lang_selector')}
				placeholderSearch={t('placeholder_lang_selector')}
				selectedItem={userLanguage}
			/>
			<ModalHeadOfficeSelector
				data={sedes}
				showModal={showChangeSedeModal && isAuth}
				onSave={onSaveConfigOptions}
				onClose={() => setShowChangeSedeModal(false)}
				selectedItem={sedePortal}
			/>
		</>
	);
};

export default SplitPane;
