import { useState } from 'react';
import { IonPopover } from '@ionic/react';
import { Icon, PickerCalendar } from '@acciona/ui-ionic-kit';
import { LocaleString } from '@acciona/ui-ionic-kit/lib/types/shared';
import useAppContext from '../../hooks/useAppContext';
import styles from './styles.module.scss';

type Props = {
	name: string;
	id: number;
	label: string;
	placeholder?: string;
	date: Date;
	minDate: Date;
	maxDate: Date;
	setDate: (date: Date, id?: number) => void;
};

export const DateSelector: React.FC<Props> = (props) => {
	const { userLanguage } = useAppContext();
	const [isOpen, setIsOpen] = useState(false);

	const handleSelectDate = (date: Date) => {
		setIsOpen(false);
		props.setDate(date, props.id);
	};

	return (
		<div id={`${props.id ?? props.name}-${props.name}-date-picker-popover`} className={styles.input}>
			<div className={styles.body}>{props.label}</div>
			<div className={`${styles.align}`}>
				<div className={`${styles.body} ${styles.color_dark}`}>
					{props.date ? props.date.toLocaleDateString() : props.placeholder ?? ''}
				</div>
				<Icon className="icon icon-chevron-right icon24" slot="end" onClick={() => setIsOpen((v) => !v)}></Icon>
			</div>
			<IonPopover
				className={styles.calendarContainer}
				isOpen={isOpen}
				trigger={`${props.id ?? props.name}-${props.name}-date-picker-popover`}
				dismissOnSelect={false}
				side="bottom"
				alignment="end"
			>
				<PickerCalendar
					minimumDate={props.minDate}
					maximumDate={props.maxDate}
					selectedDate={handleSelectDate}
					date={props.date}
					locale={userLanguage.code as LocaleString}
				/>
			</IonPopover>
		</div>
	);
};
