import { useEffect, useMemo, useState } from 'react';
import { Button, Icon, Modal } from '@acciona/ui-ionic-kit';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { IonContent, IonFooter, IonLabel, IonTitle, IonToolbar } from '@ionic/react';
import _ from 'lodash';
import { OrganizerHostItem } from '../../../../../_api/services/rooms/types';
import { roleService } from '../../../../../_api/services/roles';
import UsersModal from '../../../../../components/UsersModal/UsersModal';
import CustomModal from '../../../../../components/CustomModal/CustomModal';
import { OrganizersAndHosts } from '../OrganizersAndHosts/OrganizersAndHosts';
import { getHostsData, getOrganizersData, getUpdatedHosts } from '../../helpers';
import styles from './styles.module.scss';

type Props = {
	hasWritePermission: boolean;
	showModal: boolean;
	onClose: any;
	onSave: (newData: any[]) => void;
	data: OrganizerHostItem[];
};

const getTempId = (prevValues: number[]) => {
	const min = Math.min(...prevValues, 0);
	return min - 1;
};

export const ModalDelegatedReservation: React.FC<Props> = (props) => {
	const { hasWritePermission, showModal, onClose, onSave, data: initialData } = props;
	const { t } = useTranslation();
	const [data, setData] = useState<OrganizerHostItem[]>([]);

	useEffect(() => {
		setData(initialData);
	}, [initialData]);

	const { isLoading: isLoadingEmployees, data: allEmployees } = useQuery(
		['employees'],
		async () => await roleService.getAppEmployees(),
		{
			refetchOnWindowFocus: false,
		},
	);

	const { isLoading: isLoadingEmployeesWithAppPermission, data: employeesWithAppPermission } = useQuery(
		['employeesWithAppPermission'],
		async () => await roleService.getAppEmployeesWithRoomPermission(),
		{
			refetchOnWindowFocus: false,
		},
	);

	const [showModalOrganizer, setShowModalOrganizer] = useState(false);
	const [showModalHosts, setShowModalHosts] = useState(false);
	const [currentItemId, setCurrentItemId] = useState(null);
	const [showModalConfirmDeleteRow, setShowModalConfirmDeleteRow] = useState(false);

	const organizersData = useMemo(() => {
		return getOrganizersData(employeesWithAppPermission, data, currentItemId);
	}, [employeesWithAppPermission, data, currentItemId]);

	const hostsData = useMemo(() => {
		return getHostsData(allEmployees, data, currentItemId);
	}, [allEmployees, data, currentItemId]);

	const handleAddNewRow = () => {
		setData((prev) => [
			...prev,
			{ organizerId: getTempId(data.map((item) => item.organizerId)), organizerName: null, hosts: [] },
		]);
	};

	const handleDeleteRow = (id: number) => {
		setCurrentItemId(id);
		setShowModalConfirmDeleteRow(true);
	};

	const handleConfirmDeleteRow = () => {
		setData((prev) =>
			prev.map((item) => {
				if (item.organizerId === currentItemId) {
					return {
						...item,
						hosts: [],
						deleted: true,
						deletedHosts: item.hosts,
					};
				} else {
					return item;
				}
			}),
		);
		setShowModalConfirmDeleteRow(false);
		setCurrentItemId(null);
	};

	const handleDiscardDeleteRow = () => {
		setShowModalConfirmDeleteRow(false);
		setCurrentItemId(null);
	};

	const handleSaveChanges = () => {
		onSave(data);
	};

	const handleSaveOrganizer = (newUsers: any[]) => {
		const newOrganizerId = newUsers[0]?.employeeId;
		const newOrganizerData = allEmployees.find((employee) => employee.employeeId === newOrganizerId);

		if (!newOrganizerData) {
			return;
		}

		setData((prev) =>
			prev.map((item) => {
				if (item.organizerId === currentItemId) {
					return {
						...item,
						organizerId: newOrganizerData.employeeId,
						organizerName: newOrganizerData.fullName,
					};
				} else {
					return item;
				}
			}),
		);
	};

	const handleSaveHosts = (newUsers: any[]) => {
		const newHosts = newUsers.map((item) => item.employeeId);
		setData((currentData) => getUpdatedHosts(initialData, currentData, currentItemId, newHosts));
	};

	const handleOnClickOrganizer = (id: number) => {
		setCurrentItemId(id);
		setShowModalOrganizer(true);
	};

	const handleOnClickHosts = (id: number) => {
		setCurrentItemId(id);
		setShowModalHosts(true);
	};

	const handleCloseModalOrganizer = () => {
		setCurrentItemId(null);
		setShowModalOrganizer(false);
	};

	const handleCloseModalHosts = () => {
		setCurrentItemId(null);
		setShowModalHosts(false);
	};

	return (
		<>
			<Modal
				isOpen={showModal}
				onDidDismiss={() => {
					onClose();
				}}
				className={styles.modal}
			>
				<IonToolbar>
					<Button slot="end" fill={'clear'} color="light" className="btnCloseModal" onClick={onClose}>
						<IonLabel className="dark">
							<Icon color="primary" className="icon icon-close icon20"></Icon>
						</IonLabel>
					</Button>
					<IonTitle className={`ion-text-center ${styles.headline}`}>
						{t('modal_delegated_reservation_title')}
					</IonTitle>
				</IonToolbar>
				<IonContent>
					<div>
						<p className={styles.footnote}>{t('modal_delegated_reservation_subtitle')}</p>
						{data
							.filter((row) => !row?.deleted)
							.map((item) => (
								<OrganizersAndHosts
									key={item.organizerId}
									id={item.organizerId}
									organizer={item.organizerName}
									hosts={item.hosts}
									onClickOrganizer={() => handleOnClickOrganizer(item.organizerId)}
									onClickHosts={() => handleOnClickHosts(item.organizerId)}
									onDelete={handleDeleteRow}
									hasWritePermission={hasWritePermission}
								/>
							))}

						{hasWritePermission && (
							<Button slot="start" className={styles.addZoneBtn} onClick={handleAddNewRow}>
								<Icon className="icon icon-plus iconPlus" />
								<IonLabel>{t('btn_add_permission')}</IonLabel>
							</Button>
						)}
					</div>
				</IonContent>
				<IonFooter className={styles.btnModal}>
					<div>
						<Button
							color="light"
							className={styles.btnHeader}
							onClick={() => {
								onClose();
							}}
						>
							{t('btn_cancel')}
						</Button>
					</div>
					<div>
						<Button
							color="primary"
							disabled={!hasWritePermission || _.isEqual(initialData, data)}
							className={styles.btnHeader}
							onClick={handleSaveChanges}
						>
							{t('btn_accept')}
						</Button>
					</div>
				</IonFooter>
			</Modal>
			{/* MODAL ORGANIZER */}
			<UsersModal
				showModal={showModalOrganizer}
				onClose={handleCloseModalOrganizer}
				onSave={handleSaveOrganizer}
				usersData={organizersData}
				title={t('select_organizer')}
				label={t('select_organizer_title')}
				isLoading={isLoadingEmployeesWithAppPermission}
				multiselect={false}
				listConfig={{ listHeight: 315 }}
				ordered={false}
				textButtonConfirm={t('btn_accept')}
				hasWritePermission={hasWritePermission}
			/>
			{/* MODAL HOSTS */}
			<UsersModal
				showModal={showModalHosts}
				onClose={handleCloseModalHosts}
				onSave={handleSaveHosts}
				usersData={hostsData}
				title={t('select_hosts')}
				label={t('select_hosts_description')}
				isLoading={isLoadingEmployees}
				ordered={false}
				textButtonConfirm={t('btn_accept')}
				hasWritePermission={hasWritePermission}
			/>
			{/* CONFIRM DELETE ROW MODAL */}
			<CustomModal
				showModal={showModalConfirmDeleteRow}
				onConfirm={handleConfirmDeleteRow}
				onClose={handleDiscardDeleteRow}
				title={t('modal_delete_users_permissions_title')}
				description={t('modal_delete_delegated_reservation_users_permissions_confirmation')}
				backdropOpaque={true}
			/>
		</>
	);
};
