import useAppContext from '../../hooks/useAppContext';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import LayoutNoTabs from '../../components/LayoutNoTabs/LayoutNoTabs';
import { LanguageSelector } from '../../components/LanguageSelector/LanguageSelector';
import { Button, Icon } from '@acciona/ui-ionic-kit';
import { IonLabel } from '@ionic/react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import ServicesTransversalList from './components/ServicesTransversalList';
import { Feedback } from '../../components/Feedback/Feedback';

import { MenuItem } from '../../_api/services/generalServices/types';
import { generalServicesService } from '../../_api/services/generalServices';

import {
	checkServicesValid,
	createServicePermission,
	fillEmptyTranslations,
	generateNewService,
	setModificationFlag,
} from './helper';
import _ from 'lodash';

import styles from './styles.module.scss';
import { usePermissions } from '../../hooks/usePermissions';
import { FUNCTIONALITIES } from '../../utils/constants';

const ServicesTransversal: React.FC = () => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const [lang, setLang] = useState('es');

	const [fetchedServices, setFetchedServices] = useState<MenuItem[]>([]);
	const [modServices, setModServices] = useState<MenuItem[]>([]);
	const [canSave, setCanSave] = useState(false);

	const { setThereAreUnsavedChanges } = useAppContext();
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);
	const [info, setInfo] = useState(null);

	const { permissions: userHasPermissions } = usePermissions(FUNCTIONALITIES.superadmin, 'WRITE');

	const handleDismissToast = (type: 'error' | 'success' | 'info') => {
		if (type === 'error') {
			setError('');
		}
		if (type === 'success') {
			setSuccess('');
		}
		if (type === 'info') {
			setInfo(null);
		}
	};

	useEffect(() => {
		return () => {
			setThereAreUnsavedChanges(false);
		};
	}, []);

	const handleCreateService = () => {
		setModServices((oldState) => {
			const newState = structuredClone(oldState);
			const newService = generateNewService();
			newState.push(newService);
			return newState;
		});
	};

	const handleInput = (id: number, key: 'text' | 'icon', value: string) => {
		switch (key) {
			case 'text':
				setModServices((oldState) => {
					const newState = structuredClone(oldState);
					const service = newState.find((s) => s.id === id);
					if (!service) return oldState;
					const translation = service.translations.find((t) => t.language === lang);
					if (!translation) return oldState;
					translation.text = value.trim();
					return newState;
				});
				return;
			case 'icon':
				setModServices((oldState) => {
					const newState = structuredClone(oldState);
					const service = newState.find((s) => s.id === id);
					if (!service) return oldState;
					service.startIcon = value;
					return newState;
				});
				return;
			default:
				return;
		}
	};

	const handleDelete = (id: number) => {
		setModServices((oldState) => {
			const newState = structuredClone(oldState);
			return newState
				.map((s) => {
					if (s.id !== id) {
						return s;
					}
					return { ...s, deleted: true };
				})
				.filter((s) => {
					return !(s.deleted && s.created);
				});
		});
	};

	useQuery(['fetchServices'], generalServicesService.getTransversalServices, {
		onSuccess: (data: any) => {
			setFetchedServices(data);
			setModServices(data);
		},
		refetchOnWindowFocus: false,
	});

	const handleSave = (data: MenuItem[]) => {
		const processedData = structuredClone(data)
			.map((item) => {
				return setModificationFlag(item, fetchedServices);
			})
			// filter sections not created, not modifiend and not deleted
			// and sections created and deleted (no need to saved them)
			.filter((i) => (i.created || i.modified || i.deleted) && !(i.deleted && i.created))
			.map((i) => fillEmptyTranslations(i, lang))
			.map((i) => createServicePermission(i));
		mutate(processedData);
	};

	const { mutate, isLoading: loadingSave } = useMutation(
		async (data: MenuItem[]) => await generalServicesService.saveTransversalServices(data),
		{
			onSuccess: () => {
				queryClient.refetchQueries('fetchServices');
				setSuccess(t('msg_success'));
				setCanSave(false);
			},
			onError: (err) => {
				setError(err);
				setCanSave(true);
			},
		},
	);

	useEffect(() => {
		const isEqual = _.isEqual(fetchedServices, modServices);
		setCanSave(!isEqual);
		setThereAreUnsavedChanges(!isEqual);
	}, [fetchedServices, modServices]);

	return (
		<LayoutNoTabs title={t('menu_services')}>
			<LanguageSelector selectedLang={lang} setSelectedLang={setLang} />
			{userHasPermissions.WRITE && (
				<>
					<h3 className={styles.blockTitle}>{t('lbl_add_services')}</h3>
					<p className={`${styles.blockSubtitle}`}>{t('lbl_add_new_service')}</p>
					<Button slot="start" className={styles.editableListBtnAdd} onClick={handleCreateService}>
						<Icon className="icon icon-plus iconPlus" />
						<IonLabel>{t('btn_add_service')}</IonLabel>
					</Button>
				</>
			)}
			<ServicesTransversalList
				services={modServices.filter((s) => !s.deleted)}
				handleDelete={handleDelete}
				handleInput={handleInput}
				lang={lang}
				userHasPermissions={userHasPermissions.WRITE}
				setSuccess={setSuccess}
			/>
			{userHasPermissions.WRITE && (
				<div className={`${styles.footerButton}`}>
					<Button
						onClick={() => handleSave(modServices)}
						className={styles.btnHeader}
						disabled={
							!userHasPermissions.WRITE || loadingSave || !canSave || !checkServicesValid(modServices, lang)
						}
						color="primary"
					>
						{t('btn_save_data')}
					</Button>
				</div>
			)}
			<Feedback error={error} success={success} info={info} onDidDismissToast={handleDismissToast} />
		</LayoutNoTabs>
	);
};

export default ServicesTransversal;
