import _ from 'lodash';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Icon } from '@acciona/ui-ionic-kit';
import Can from '../../../hoc/Can/Can';
import { SubMenuItem } from './SubMenuItem';
import { SplitMenuItem } from '../menuConfiguration';
import styles from '../styles.module.scss';

type Props = {
	item: SplitMenuItem;
};

export const MenuItem = ({ item }: Props) => {
	const { t } = useTranslation();

	const getSubMenuLinks = (submenus) => {
		return submenus ? submenus?.map((s) => s.link) : [];
	};
	const isActive = item.link == location.pathname || getSubMenuLinks(item.submenu).includes(location.pathname);

	return (
		<Can functionality={item.functionality} action="READ">
			<Link to={item.link}>
				<div className={`${styles.itemList} ${isActive ? styles.itemListSelected : styles.itemList}`}>
					<div>
						<Icon className={`${item.icon} icon20`} slot="start"></Icon>
						<div className={styles.footnote}>{t(item.text)}</div>
					</div>
					<div className={`${styles.triangle} ${isActive ? styles.triangleSelected : styles.triangle}`} />
				</div>
			</Link>
			{!_.isEmpty(item?.submenu) && (
				<div
					className={styles.subMenuContainer}
					aria-expanded={getSubMenuLinks(item.submenu).includes(location.pathname)}
				>
					{item.submenu.map((itemSubmenu) => {
						return <SubMenuItem key={itemSubmenu.id} item={itemSubmenu} />;
					})}
				</div>
			)}
		</Can>
	);
};
