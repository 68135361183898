import { useEffect, useRef, useState } from 'react';
import { IonContent, IonLabel } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { SegmentButton, Segmented } from '@acciona/ui-ionic-kit';
import { useScrollBar } from '../../hooks/useScrollBar';
import { usePermissions } from '../../hooks/usePermissions';
import useAppContext from '../../hooks/useAppContext';
import { TermsGeneral } from './General/TermsGeneral';
import { TermsReservations } from './Reservations/TermsReservations';
import { OtherDocuments } from './OtherDocuments/OtherDocuments';
import { HeaderPage } from '../../components/HeaderPage/HeaderPage';
import { Feedback } from '../../components/Feedback/Feedback';
import { FUNCTIONALITIES } from '../../utils/constants';
import styles from './styles.module.scss';

const Terms: React.FC = () => {
	const { t } = useTranslation();
	const { setThereAreUnsavedChanges } = useAppContext();
	const [segmentedSelected, setSegmentedSelected] = useState('general');
	const [error, setError] = useState('');
	const [success, setSuccess] = useState('');
	const [info, setInfo] = useState(null);
	const { permissions, error: errorPermissions } = usePermissions(FUNCTIONALITIES.transversal, 'WRITE');
	const [scroll, setScroll] = useState(false);
	let footerStyle = null;
	const refContainer = useRef(null);
	const refHeader = useRef(null);
	const hasScroll = useScrollBar(refContainer, refHeader, segmentedSelected);
	footerStyle = hasScroll ? styles.hasScroll : styles.noScroll;

	const handleDismissToast = (type: 'error' | 'success' | 'info') => {
		if (type === 'error') {
			setError('');
		}
		if (type === 'success') {
			setSuccess('');
		}
		if (type === 'info') {
			setInfo('');
		}
	};

	useEffect(() => {
		setError(errorPermissions);
	}, [errorPermissions]);

	useEffect(() => {
		return () => {
			setThereAreUnsavedChanges(false);
		};
	}, []);

	return (
		<IonContent scrollEvents forceOverscroll={true} onIonScroll={(x) => setScroll(x.detail.scrollTop > 0)}>
			<HeaderPage scroll={scroll} title={t('terms_and_policies')}>
				<div ref={refHeader}>
					<Segmented value={segmentedSelected}>
						<SegmentButton onClick={() => setSegmentedSelected('general')} value={'general'} mode={'md'}>
							<IonLabel>{t('tab_policy_general')}</IonLabel>
						</SegmentButton>
						<SegmentButton onClick={() => setSegmentedSelected('reservation')} value={'reservation'} mode={'md'}>
							<IonLabel>{t('tab_policy_reservations')}</IonLabel>
						</SegmentButton>
						<SegmentButton
							onClick={() => setSegmentedSelected('other_documents')}
							value={'other_documents'}
							mode={'md'}
						>
							<IonLabel>{t('tab_other_documents')}</IonLabel>
						</SegmentButton>
					</Segmented>
				</div>
			</HeaderPage>
			<div className={`container ${styles.terms}`} ref={refContainer}>
				<div className={segmentedSelected === 'general' ? styles.display : styles.hide}>
					<TermsGeneral
						setError={setError}
						setSuccess={setSuccess}
						hasWritePermission={permissions.WRITE}
						footerStyle={footerStyle}
					/>
				</div>
				<div className={segmentedSelected === 'reservation' ? styles.display : styles.hide}>
					<TermsReservations
						setError={setError}
						setSuccess={setSuccess}
						hasWritePermission={permissions.WRITE}
						footerStyle={footerStyle}
					/>
				</div>
				<div className={segmentedSelected === 'other_documents' ? styles.display : styles.hide}>
					<OtherDocuments
						setError={setError}
						setSuccess={setSuccess}
						setInfo={setInfo}
						hasWritePermission={permissions.WRITE}
						footerStyle={footerStyle}
					/>
				</div>
			</div>
			<Feedback error={error} success={success} info={info} onDidDismissToast={handleDismissToast} />
		</IonContent>
	);
};

export default Terms;
