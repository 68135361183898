import { useCallback, useEffect, useRef, useState } from 'react';
import { SegmentButton, Segmented, Toast } from '@acciona/ui-ionic-kit';
import { IonContent, IonLabel } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import useAppContext from '../../hooks/useAppContext';
import { authServices } from '../../_api/services/auth';
import { useScrollBar } from '../../hooks/useScrollBar';
import { Login } from './Login/Login';
import { Onboarding } from './Onboarding/Onboarding';
import { Dashboard } from './Dashboard/Dashboard';
import { News } from './News/News';
import { Other } from './Other/Other';
import { HeaderPage } from '../../components/HeaderPage/HeaderPage';
import styles from './styles.module.scss';

const Communications: React.FC = () => {
	const { t } = useTranslation();
	const { setThereAreUnsavedChanges } = useAppContext();
	const [segmentedSelected, setSegmentedSelected] = useState('dashboard');
	const [error, setError] = useState('');
	const [success, setSuccess] = useState('');
	const [hasWritePermission, setHasWritePermission] = useState(false);
	const [scroll, setScroll] = useState(false);
	const mountedRef = useRef(true);
	const refContainer = useRef(null);
	const refHeader = useRef(null);
	const hasScroll = useScrollBar(refContainer, refHeader, segmentedSelected);
	const footerStyle = hasScroll ? styles.hasScroll : styles.noScroll;

	const permissions = useCallback(async () => {
		try {
			const resultQuery = await authServices.checkPermission('COMMUNICATIONS', 'WRITE');
			setHasWritePermission(resultQuery.hasPermission);
		} catch (err) {
			setError(err);
			throw new Error(err);
		}
	}, [mountedRef]);

	useEffect(() => {
		permissions();
		return () => {
			mountedRef.current = false;
		};
	}, [permissions]);

	useEffect(() => {
		return () => {
			setThereAreUnsavedChanges(false);
		};
	}, []);

	return (
		<IonContent scrollEvents forceOverscroll={true} onIonScroll={(x) => setScroll(x.detail.scrollTop > 0)}>
			<HeaderPage scroll={scroll} title={t('menu_communications')}>
				<div ref={refHeader}>
					<Segmented value={segmentedSelected}>
						{/* <SegmentButton onClick={() => setSegmentedSelected('login')} value={'login'} mode={'md'}>
							<IonLabel>{t('tab_login')}</IonLabel>
						</SegmentButton>
						<SegmentButton onClick={() => setSegmentedSelected('onboarding')} value={'onboarding'} mode={'md'}>
							<IonLabel>{t('tab_onboarding')}</IonLabel>
						</SegmentButton> */}
						<SegmentButton onClick={() => setSegmentedSelected('dashboard')} value={'dashboard'} mode={'md'}>
							<IonLabel>{t('tab_dashboard')}</IonLabel>
						</SegmentButton>
						<SegmentButton onClick={() => setSegmentedSelected('news')} value={'news'} mode={'md'}>
							<IonLabel>{t('tab_news')}</IonLabel>
						</SegmentButton>
						<SegmentButton onClick={() => setSegmentedSelected('other')} value={'other'} mode={'md'}>
							<IonLabel>{t('tab_other')}</IonLabel>
						</SegmentButton>
					</Segmented>
				</div>
			</HeaderPage>
			<div className="container" ref={refContainer}>
				<div className={segmentedSelected === 'login' ? styles.display : styles.hide}>
					<Login />
				</div>
				<div className={segmentedSelected === 'onboarding' ? styles.display : styles.hide}>
					<Onboarding />
				</div>
				<div className={segmentedSelected === 'dashboard' ? styles.display : styles.hide}>
					<Dashboard
						setError={setError}
						setSuccess={setSuccess}
						hasWritePermission={hasWritePermission}
						footerStyle={footerStyle}
					/>
				</div>
				<div className={segmentedSelected === 'news' ? styles.display : styles.hide}>
					<News
						setError={setError}
						setSuccess={setSuccess}
						hasWritePermission={hasWritePermission}
						footerStyle={footerStyle}
					/>
				</div>
				<div className={segmentedSelected === 'other' ? styles.display : styles.hide}>
					<Other />
				</div>
			</div>
			<Toast isOpen={!!error} message={error} onDidDismiss={() => setError('')} position="bottom" type="error" />
			<Toast
				isOpen={!!success}
				message={success}
				onDidDismiss={() => setSuccess('')}
				position="bottom"
				type="success"
			/>
		</IonContent>
	);
};

export default Communications;
