import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Toast } from '@acciona/ui-ionic-kit';
import { IonLabel } from '@ionic/react';
import useAppContext from '../../../../hooks/useAppContext';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import _ from 'lodash';
import Table from '../../../../components/Table/Table';
import { IndeterminateCheckbox } from '../../../../components/Table/IndeterminateCheckbox';
import { ActionIcon } from '../../../../components/Table/ActionIcon/ActionIcon';
import {
	getNewAllSelectedRows,
	getNewSelectedRows,
	allRowsAreSelected,
	rowIsSelected,
	customTableFilter,
} from '../../../../utils/table';

import styles from './styles.module.scss';
import UsersModal from '../../../../components/UsersModal/UsersModal';
import { useQuery } from 'react-query';
import { roleService } from '../../../../_api/services/roles';
import CustomModal from '../../../../components/CustomModal/CustomModal';
import { Employee } from '../../../../_api/services/roles/types';

type Props = {
	users: Employee[];
	handleUpdate: (users: Employee[]) => void;
	hasWritePermissions: boolean;
	disabled?: boolean;
};
export const UsersExcluded: React.FC<Props> = (props) => {
	const { disabled = false, users: usersExcluded, handleUpdate } = props;
	const { t } = useTranslation();
	const { userLanguage } = useAppContext();
	const columnHelper = createColumnHelper<Employee>();
	const [selectedRows, setSelectedRows] = useState<Employee[]>([]);
	const [showModalAddUsers, setShowModalAddUsers] = useState(false);
	const [showModalDeleteUsers, setShowModalDeleteUsers] = useState(false);
	const [toastMessage, setToastMessage] = useState('');
	const [employees, setEmployees] = useState<Employee[]>([]);

	const { isLoading: isLoadingEmployees } = useQuery(
		['employeesPortal'],
		async () => await roleService.getAppEmployees(),
		{
			onSuccess: (data) => {
				setEmployees(data);
			},
			refetchOnWindowFocus: false,
		},
	);

	const handleAddUsers = () => {
		setShowModalAddUsers(true);
	};

	const saveUsers = (newUsers: Employee[]) => {
		const newUsersExcluded = [...usersExcluded, ...newUsers];
		handleUpdate(newUsersExcluded);
		setToastMessage(t('lbl_usersModal_added'));
	};

	const handleDeleteUser = (selected: Employee = null) => {
		if (selected?.employeeId) {
			setSelectedRows([selected]);
		}
		setShowModalDeleteUsers(true);
	};

	const handleConfirmDeleteUsers = () => {
		const prevUsersExcluded = _.cloneDeep(usersExcluded);
		const newUsersExcluded = prevUsersExcluded.filter(
			(user) => !selectedRows.some((userToDelete) => userToDelete.employeeId === user.employeeId),
		);
		handleUpdate(newUsersExcluded);
		setToastMessage(t(selectedRows.length === 1 ? 'lbl_userModal_deleted' : 'lbl_usersModal_deleted'));
	};

	const selectAllRowsInPageHandler = (currentPageRows) => {
		setSelectedRows((prev) => getNewAllSelectedRows(prev, currentPageRows, 'employeeId'));
	};

	const selectRowHandler = (rowSelected) => {
		setSelectedRows((prev) => getNewSelectedRows(prev, rowSelected, 'employeeId'));
	};

	const columns = useMemo<ColumnDef<Employee, any>[]>(
		() => [
			{
				id: 'select',
				header: ({ table }) => {
					const currentPageRows = table.getRowModel().rows.map((row) => row.original);

					return (
						<div>
							<IndeterminateCheckbox
								indeterminate={table.getIsSomeRowsSelected()}
								checked={allRowsAreSelected(currentPageRows, selectedRows, 'employeeId')}
								onChange={() => selectAllRowsInPageHandler(currentPageRows)}
								disabled={!props.hasWritePermissions}
							/>
						</div>
					);
				},
				cell: ({ row }) => (
					<div>
						<IndeterminateCheckbox
							indeterminate={row.getIsSomeSelected()}
							checked={rowIsSelected(selectedRows, row.original, 'employeeId')}
							onChange={() => selectRowHandler(row.original)}
							disabled={!props.hasWritePermissions}
						/>
					</div>
				),
				size: 15,
				enableSorting: false,
			},
			{
				id: 'fullName',
				accessorKey: 'fullName',
				header: () => t('table_header_fullname'),
				cell: ({ row }) => `${row.original.employeeId} - ${row.original.fullName}`,
				size: 100,
				enableSorting: true,
				enableColumnFilter: true,
				filterFn: customTableFilter,
			},
			columnHelper.accessor('profile', {
				header: () => t('table_header_profile'),
				cell: (info) => info.getValue() || '',
				size: 100,
				enableSorting: true,
			}),
			{
				id: 'delete',
				cell: ({ row }) => (
					<ActionIcon
						hasPermission={props.hasWritePermissions}
						icon="delete"
						onClick={() => handleDeleteUser(row.original)}
						disabled={selectedRows.length > 0}
					/>
				),
				size: 25,
				enableSorting: false,
			},
		],
		[selectedRows, props.hasWritePermissions, t],
	);

	return (
		<>
			<div className={styles.userPermissions}>
				<h3 className={styles.blockTitle}>{t(`lbl_seeker_users_excluded`)}</h3>
				<p className={styles.blockSubtitle}>{t('lbl_seeker_users_excluded_desc')}</p>
				{props.hasWritePermissions && (
					<Button slot="start" className={styles.addUserBtn} onClick={handleAddUsers} disabled={disabled}>
						<Icon className="icon icon-plus iconPlus" />
						<IonLabel>{t('app_permissions.add_user')}</IonLabel>
					</Button>
				)}
				{!_.isEmpty(usersExcluded) && (
					<Table
						key={userLanguage.code}
						initialData={usersExcluded}
						columns={columns}
						showHeader
						showFooter
						showSelectedInfo
						sortColumn={'fullName'}
						sortPlaceholder={t('sort')}
						searchColumn={'fullName'}
						searchPlaceholder={t('plholder_search_user')}
						totalSelectedRows={selectedRows.length}
						handleDelete={handleDeleteUser}
						hasWritePermission={props.hasWritePermissions}
						isLoading={false}
					/>
				)}
			</div>
			{showModalAddUsers && (
				<UsersModal
					showModal={showModalAddUsers}
					onClose={() => setShowModalAddUsers(false)}
					onSave={saveUsers}
					usersData={employees.filter(
						(user) => !usersExcluded.some((userExcluded) => userExcluded.employeeId === user.employeeId),
					)}
					label={t('lbl_select_users_excluded')}
					isLoading={isLoadingEmployees}
					hasWritePermission={props.hasWritePermissions}
					allowEmptySelection={false}
				/>
			)}
			<CustomModal
				showModal={showModalDeleteUsers}
				onConfirm={() => {
					handleConfirmDeleteUsers();
					setSelectedRows([]);
					setShowModalDeleteUsers(false);
				}}
				onClose={() => {
					setSelectedRows([]);
					setShowModalDeleteUsers(false);
				}}
				title={t(
					selectedRows.length === 1
						? 'usersExcluded_delete_modal.title_singular'
						: 'usersExcluded_delete_modal.title_plural',
				)}
				description={t(
					selectedRows.length === 1
						? 'usersExcluded_delete_modal.desc_singular'
						: 'usersExcluded_delete_modal.desc_plural',
				)}
			></CustomModal>
			<Toast
				isOpen={!!toastMessage}
				message={toastMessage}
				onDidDismiss={() => setToastMessage('')}
				position="bottom"
				type="success"
			/>
		</>
	);
};
