import _ from 'lodash';
import { OrganizerHostItem } from '../../../_api/services/rooms/types';
import { sortAscDiatriticInsensitive } from '../../../utils/functions';

export const getOrganizersData = (employees, data, currentItemId) => {
	const selectedRow = data.find((item) => item.organizerId === currentItemId);
	if (!selectedRow) {
		return [];
	}

	const activeOrganizersIds = data
		.filter((item) => !item.deleted && item.organizerId !== currentItemId)
		.map((item) => item.organizerId);

	const selectedUsers = [];
	const nonSelectedUsers = [];

	employees?.forEach((user) => {
		const isChecked = user.employeeId === selectedRow.organizerId;
		const disabled = activeOrganizersIds.includes(user.employeeId);

		const nextUserData = {
			...user,
			isChecked: isChecked,
			disabled: disabled,
		};

		if (isChecked) {
			selectedUsers.push(nextUserData);
		} else {
			nonSelectedUsers.push(nextUserData);
		}
	});

	return [
		...sortAscDiatriticInsensitive(selectedUsers, 'fullName'),
		...sortAscDiatriticInsensitive(nonSelectedUsers, 'fullName'),
	];
};

export const getHostsData = (employees, data, currentItemId) => {
	const selectedRow = data.find((item) => item.organizerId === currentItemId);
	if (!selectedRow) {
		return [];
	}

	const selectedUsers = [];
	const nonSelectedUsers = [];

	employees?.forEach((user) => {
		const isChecked = selectedRow.hosts.includes(user.employeeId);
		const disabled = selectedRow.organizerId === user.employeeId;

		const nextUserData = {
			...user,
			isChecked: isChecked,
			disabled: disabled,
		};

		if (isChecked) {
			selectedUsers.push(nextUserData);
		} else {
			nonSelectedUsers.push(nextUserData);
		}
	});

	return [
		...sortAscDiatriticInsensitive(selectedUsers, 'fullName'),
		...sortAscDiatriticInsensitive(nonSelectedUsers, 'fullName'),
	];
};

export const getUpdatedHosts = (
	initialData: OrganizerHostItem[],
	currentData: OrganizerHostItem[],
	currentItemId: number,
	newHosts: number[],
): OrganizerHostItem[] => {
	const currentItemInitialData = initialData.find((item) => item?.organizerId === currentItemId);

	return currentData.map((item) => {
		if (item.organizerId === currentItemId) {
			const deleted = [];
			item.hosts.forEach((host) => {
				if (!newHosts.includes(host)) {
					deleted.push(host);
				}
			});

			const currentItemInitialHosts = currentItemInitialData ? currentItemInitialData.hosts : [];
			const deletedHosts = deleted.filter((host) => currentItemInitialHosts.includes(host));

			return {
				...item,
				hosts: newHosts,
				...(deletedHosts.length > 0 ? { deletedHosts: deletedHosts } : {}),
			};
		} else {
			return item;
		}
	});
};

export const checkOrganizerHostHasChanged = (prevItem: OrganizerHostItem, newItem: OrganizerHostItem) => {
	if (!prevItem && newItem?.deleted) {
		return false;
	} else if (prevItem?.organizerId !== newItem.organizerId) {
		return true;
	} else if (newItem?.deleted) {
		return true;
	} else if (!_.isEmpty(newItem.deletedHosts)) {
		return true;
	} else if (!_.isEqual(prevItem?.hosts.sort(), newItem.hosts.sort())) {
		return true;
	} else {
		return false;
	}
};

export const getUpdatedOrganizerHostsItems = (initialData: OrganizerHostItem[], newData: OrganizerHostItem[]) => {
	const updatedData = [];

	newData.forEach((newDataItem) => {
		const initialDataItem = initialData.find(
			(initialDataItem) => initialDataItem?.organizerId === newDataItem.organizerId,
		);

		const isNew = !initialDataItem;
		const hasChanged = checkOrganizerHostHasChanged(initialDataItem, newDataItem);

		if (isNew || hasChanged) {
			updatedData.push(newDataItem);
		}
	});

	return updatedData;
};
