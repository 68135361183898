import { useEffect, useMemo, useState } from 'react';
import { Button, Icon, Modal, Reorder, ReorderGroup } from '@acciona/ui-ionic-kit';
import {
	IonButton,
	IonContent,
	IonFooter,
	IonItem,
	IonLabel,
	IonTitle,
	IonToolbar,
	RefresherEventDetail,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { PolicyCategory } from '../../../../../_api/services/terms/types';
import { Toggle } from '../../../../../components/Toggle/Toggle';
import { arraymove } from '../../../../../utils/functions';
import {
	editCategory,
	deleteCategory,
	addNewCategory,
	updateCategoryToAdd,
	inputIsValid,
	getCategoriesData,
	someFieldIsEmpty,
	getCategoryName,
	contentHasBeenEdited,
} from './helpers';
import { emptyCategory } from '../../helpers';
import styles from '../../styles.module.scss';
import { TextInput } from '../../../../../components/TextInput/TextInput';

type Props = {
	onClose: () => void;
	showModal: boolean;
	categories: PolicyCategory[];
	saveChanges: (changes: any) => void;
	hasWritePermission: boolean;
	lang: string;
};

const PoliciesCategoriesModal: React.FC<Props> = (props) => {
	const { onClose, showModal, categories, saveChanges, hasWritePermission, lang } = props;
	const { t } = useTranslation();
	const [changes, setChanges] = useState<PolicyCategory[]>([]);
	const [order, setOrder] = useState<PolicyCategory[]>([]);
	const [editMode, setEditMode] = useState(false);
	const [categoryToAdd, setCategoryToAdd] = useState<PolicyCategory>(emptyCategory);

	useEffect(() => {
		if (categories) {
			setChanges(categories);
			setOrder(categories);
		}
	}, [categories]);

	const saveIsDisabled = useMemo(() => {
		const isEdited = contentHasBeenEdited(categories, changes);

		if (!isEdited) {
			return true;
		} else if (!_.isEmpty(changes)) {
			return someFieldIsEmpty(changes);
		}

		return false;
	}, [categories, changes]);

	const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
		event.detail.complete();
		const action = event.detail as any;
		const changesMoved: PolicyCategory[] = arraymove(_.sortBy(changes, 'ordinal'), action.from, action.to).map(
			(e: PolicyCategory, index) => {
				return {
					...e,
					ordinal: index + 1,
				};
			},
		);
		setChanges(changesMoved);
	};

	const handleSaveChanges = () => {
		onClose();
		saveChanges(changes);
	};

	const editCategoryHandler = (id: number, value: string) => {
		setChanges((prev) => editCategory(id, value, prev, lang));
	};

	const deleteCategoryHandler = (id: number) => {
		setChanges((prev) => deleteCategory(id, prev));
	};

	const confirmAddCategoryHandler = () => {
		const nextCategories = addNewCategory(changes, categoryToAdd);
		setChanges(nextCategories);
		setOrder(nextCategories);
		setCategoryToAdd(emptyCategory);
	};

	const updateCategoryToAddHandler = (value: string) => {
		setCategoryToAdd((prev) => updateCategoryToAdd(prev, value));
	};

	const toggleEditModeHandler = () => {
		editMode && setOrder(_.sortBy(changes, 'ordinal'));
		setEditMode(!editMode);
	};

	return (
		<Modal
			key={`policiesCategoriesModal-${showModal.toString()}`}
			isOpen={showModal}
			onDidDismiss={onClose}
			className={styles.modal}
		>
			<IonToolbar>
				<IonTitle>
					<h3 className="ion-text-center">
						<b>{`${t('edit_categories')} (${t('lang_label')}: ${t('language_' + lang)})`}</b>
					</h3>
				</IonTitle>
				<Button slot="end" fill="clear" color="light" className={styles.closeModalBtn} onClick={onClose}>
					<IonLabel className="dark">
						<Icon className="icon icon24 icon-close" />
					</IonLabel>
				</Button>
			</IonToolbar>

			<IonContent>
				<div>
					<IonItem className={styles.categoryListText} lines="none">
						<p className={styles.footnote}>{t('category_modal_text')}</p>
					</IonItem>
					<div className="subHeader">
						<div className="title"></div>
						{hasWritePermission &&
						!_.isEmpty(changes) &&
						!_.isEmpty(changes.filter((change) => !change.disabled)) ? (
							<>
								<div className="buttons">
									<Toggle checked={editMode} onChange={toggleEditModeHandler} />
								</div>
								<div className="label">
									<IonLabel>{t('lbl_edit')}</IonLabel>
								</div>
							</>
						) : null}
					</div>
					{editMode ? (
						<div>
							{changes &&
								getCategoriesData(changes, lang).map((item) => (
									<div key={`changes-${item.id}`} className={styles.inputCategoryContainer}>
										<TextInput
											name="category"
											className={`ion-text-end`}
											wrapperClass={`${item.name === '' ? 'borderAlert' : ''}`}
											value={item.name}
											onIonInput={(e) => editCategoryHandler(item.id, e.detail.value)}
											placeholder={t('name')}
											label={t('category')}
										/>
										<IonButton
											fill="clear"
											onClick={() => deleteCategoryHandler(item.id)}
											className={styles.buttonDelete}
											slot="end"
										>
											<Icon className={`icon icon-delete icon24`} />
										</IonButton>
									</div>
								))}
						</div>
					) : (
						<ReorderGroup
							className="orderGroup"
							disabled={!hasWritePermission}
							onIonItemReorder={(e) => doRefresh(e)}
						>
							{order &&
								getCategoriesData(order, lang).map((item) => (
									<Reorder key={`order-${item.id}`}>
										<div className={`${styles.body} ${styles.inputCategoryContainer}`}>
											<IonItem lines="none" className={styles.inputModal}>
												<IonLabel>{item.name}</IonLabel>
												{hasWritePermission && <Icon slot="end" className="icon icon-reordenar" />}
											</IonItem>
										</div>
									</Reorder>
								))}
						</ReorderGroup>
					)}
					{hasWritePermission && categoryToAdd && (
						<>
							<IonItem lines="none" className={styles.titleNewCategory}>
								<p className={styles.footnote}>{t('policy_category_modal_text_create')}</p>
							</IonItem>
							<div className={styles.newCategoryContainer}>
								<TextInput
									name="category"
									className="ion-text-end"
									onIonChange={(e) => updateCategoryToAddHandler(e.detail.value)}
									value={getCategoryName(categoryToAdd)}
									placeholder={t('name')}
									label={t('category')}
								/>
								<div className={styles.inputAddCategory}>
									<Button
										disabled={!inputIsValid(categoryToAdd)}
										className={`${styles.btnAdd} ${styles.secondaryBtn}`}
										onClick={confirmAddCategoryHandler}
										slot="end"
									>
										<Icon className="icon icon-plus iconPlus" />
										<IonLabel>{t('btn_add')}</IonLabel>
									</Button>
								</div>
							</div>
						</>
					)}
				</div>
			</IonContent>

			<IonFooter className={styles.btnModal}>
				<div>
					<Button className={`${styles.btnHeader} ${styles.secondaryBtn}`} onClick={onClose}>
						{t('btn_cancel')}
					</Button>
				</div>
				<div>
					<Button
						color="primary"
						className={styles.btnHeader}
						disabled={saveIsDisabled}
						onClick={handleSaveChanges}
					>
						{t('btn_accept')}
					</Button>
				</div>
			</IonFooter>
		</Modal>
	);
};

export default PoliciesCategoriesModal;
