import { useEffect, useRef, useState } from 'react';
import { IonContent, IonLabel } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { SegmentButton, Segmented } from '@acciona/ui-ionic-kit';
import useAppContext from '../../hooks/useAppContext';
import { useScrollBar } from '../../hooks/useScrollBar';
import { Contact } from './Contact/Contact';
import { Categories } from './Categories/Categories';
import { HeaderPage } from '../../components/HeaderPage/HeaderPage';
import { Feedback } from '../../components/Feedback/Feedback';
import { usePermissions } from '../../hooks/usePermissions';
import { FUNCTIONALITIES } from '../../utils/constants';
import styles from './styles.module.scss';

const Incidents: React.FC = () => {
	const { t } = useTranslation();
	const { setThereAreUnsavedChanges } = useAppContext();
	const [segmentedSelected, setSegmentedSelected] = useState('categories');
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);
	const [info, setInfo] = useState(null);
	const { permissions, error: errorPermissions } = usePermissions(FUNCTIONALITIES.incidents, 'WRITE');

	const [scroll, setScroll] = useState(false);
	let footerStyle = null;
	const refContainer = useRef(null);
	const refHeader = useRef(null);
	const hasScroll = useScrollBar(refContainer, refHeader, segmentedSelected);
	footerStyle = hasScroll ? styles.hasScroll : styles.noScroll;

	const handleDismissToast = (type: 'error' | 'success' | 'info') => {
		if (type === 'error') {
			setError('');
		}
		if (type === 'success') {
			setSuccess('');
		}
		if (type === 'info') {
			setInfo(null);
		}
	};

	useEffect(() => {
		setError(errorPermissions);
	}, [errorPermissions]);

	useEffect(() => {
		return () => {
			setThereAreUnsavedChanges(false);
		};
	}, []);

	return (
		<IonContent scrollEvents forceOverscroll={true} onIonScroll={(x) => setScroll(x.detail.scrollTop > 0)}>
			<HeaderPage scroll={scroll} title={t('menu_incidents')}>
				<div ref={refHeader}>
					<Segmented value={segmentedSelected}>
						<SegmentButton onClick={() => setSegmentedSelected('categories')} value={'categories'} mode={'md'}>
							<IonLabel>{t('tab_category')}</IonLabel>
						</SegmentButton>
						<SegmentButton onClick={() => setSegmentedSelected('contact')} value={'contact'} mode={'md'}>
							<IonLabel>{t('tab_contact_phone')}</IonLabel>
						</SegmentButton>
					</Segmented>
				</div>
			</HeaderPage>
			<div className={styles.container} ref={refContainer}>
				<div className={segmentedSelected === 'categories' ? styles.display : styles.hide}>
					<Categories
						hasWritePermission={permissions.WRITE}
						setError={setError}
						setSuccess={setSuccess}
						footerStyle={footerStyle}
					/>
				</div>
				<div className={segmentedSelected === 'contact' ? styles.display : styles.hide}>
					<Contact
						hasWritePermission={permissions.WRITE}
						setError={setError}
						setSuccess={setSuccess}
						footerStyle={footerStyle}
					/>
				</div>
			</div>
			<Feedback error={error} success={success} info={info} onDidDismissToast={handleDismissToast} />
		</IonContent>
	);
};

export default Incidents;
