import { IonButton, IonContent, IonItem, IonLabel, IonList, IonPopover } from '@ionic/react';
import { Icon } from '@acciona/ui-ionic-kit';
import styles from './styles.module.scss';

type Props = {
	hasWritePermission: boolean;
	onChange: (selected: any, value: any) => void;
	row: any;
	actions: { id: number; text: string }[];
};

export const EditionModeSelector = (props: Props) => {
	const { hasWritePermission, onChange, row, actions } = props;
	return (
		<div>
			{hasWritePermission && (
				<>
					<IonButton
						className={`btnSelector ${styles.parkingPopoverButton}`}
						disabled={!hasWritePermission}
						fill="clear"
						id={`parking-edit-${row.employeeId}`}
						slot="end"
					>
						<Icon className="icon icon-ellipsis-vertical md" />
					</IonButton>
					<IonPopover
						trigger={`parking-edit-${row.employeeId}`}
						dismissOnSelect={true}
						side="bottom"
						alignment="end"
					>
						<IonContent>
							<IonList className={`${styles.listPadding}`}>
								{actions.map((item) => {
									return (
										<IonItem
											lines="full"
											key={item.id}
											button={true}
											onClick={() => onChange([row], item.id)}
											detail={false}
										>
											<IonLabel className={styles.headline}>{item.text}</IonLabel>
										</IonItem>
									);
								})}
							</IonList>
						</IonContent>
					</IonPopover>
				</>
			)}
		</div>
	);
};
