import { authServices } from '../services/auth';
import { tokenServices } from '../services/token';
import muleSoftInstance from './httpCommon';

export const errorInterceptor = () => {
	muleSoftInstance.interceptors.response.use(
		(res) => {
			return res;
		},
		async (err) => {
			const originalConfig = err.config;

			if (originalConfig.url !== '/auth/signin' && err.response) {
				// Access Token was expired
				// TODO: instead of 500 use 403 when back-end returns de error correctly
				if ([401, 403].includes(err.response.status) && !originalConfig._retry) {
					originalConfig._retry = true;

					try {
						const { access_token, id_token } = await authServices.refreshToken();
						tokenServices.updateLocalTokens(id_token, access_token);

						originalConfig.headers.Authorization = `Bearer ${id_token}`;

						return muleSoftInstance(originalConfig);
					} catch (_error) {
						return Promise.reject(_error);
					}
				}
			}
			return Promise.reject(err);
		},
	);
};
