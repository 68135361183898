import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { InputCustomEvent, IonItem, IonLabel, IonLoading, IonSelect, IonSelectOption } from '@ionic/react';
import { Button, List, MultiSelect } from '@acciona/ui-ionic-kit';
import { ReservationPolicy } from '../../../_api/services/parking/types';
import useAppContext from '../../../hooks/useAppContext';
import CustomModal from '../../../components/CustomModal/CustomModal';
import { DefaultPreferencesConfiguration } from '../../../components/DefaultPreferencesConfiguration/DefaultPreferencesConfiguration';
import {
	PARKING_BUFFER_OPTIONS,
	POLICIES_AVAILABLE_OPTIONS,
	POLICIES_SERVICE,
	SEDE_POLICIES,
	defaultParkingReservationsConfig,
	getAvailableOptions,
	getSelectedOption,
	getSelectedPolicies,
	getUpdatedPolicies,
	hasEmptyValues,
	validateHours,
} from '../../../utils/reservations';
import { TimeAndReservationsProps, IndexedPolicy } from './types';
import { HourSelector } from '../../../components/HourSelector/HourSelector';
import { parkingService } from '../../../_api/services/parking';
import { roundHour } from '../../../utils/functions';
import styles from './styles.module.scss';
import { NumberInput } from '../../../components/NumberInput/NumberInput';
import { DaySelector } from '../../Workstations/TimeAndReservations/components/ModalTurningPointConfiguration/DaySelector';
import { Toggle } from '../../../components/Toggle/Toggle';

export const Reservations: React.FC<TimeAndReservationsProps> = ({
	setError,
	setSuccess,
	hasWritePermission,
	footerStyle,
}) => {
	const { setThereAreUnsavedChanges } = useAppContext();
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const [reservationsConfig, setReservationsConfig] = useState(defaultParkingReservationsConfig);
	const [sedeDefaultPoliciesConfig, setSedeDefaultPoliciesConfig] = useState<ReservationPolicy[]>([]);
	const [policiesAvailableInThisSede, setPoliciesAvailableInThisSede] = useState<string[]>([]);
	const [middayHour, setMiddayHour] = useState('');
	const [minReservationHour, setMinReservationHour] = useState('');
	const [maxReservationHour, setMaxReservationHour] = useState('');
	const [showInvalidScheduleConfigurationModal, setShowInvalidScheduleConfigurationModal] = useState(false);
	const [showInvalidDefaultScheduleConfigurationModal, setShowInvalidDefaultScheduleConfigurationModal] =
		useState(false);

	const sedeDefaultPoliciesConfigCurrent = useMemo(
		() => sedeDefaultPoliciesConfig.find((p) => p?.isDefault),
		[sedeDefaultPoliciesConfig],
	);

	const { isLoading: isLoadingReservationsConfig, data: initialReservationsConfig } = useQuery(
		['pkReservationsConfig'],
		async () => parkingService.getPkReservationsConfig(),
		{
			refetchOnWindowFocus: false,
			onError: (error) => setError(error as string),
			onSuccess: (data) => {
				setReservationsConfig(data);
				const indexedPolicies: IndexedPolicy[] = _.keyBy(data.policies, 'name');
				setPoliciesAvailableInThisSede(getSelectedPolicies(indexedPolicies));
				setMiddayHour(indexedPolicies[POLICIES_SERVICE.Morning]?.fullHoraMax);
				setMinReservationHour(indexedPolicies[POLICIES_SERVICE.Personalizada]?.fullHoraMin);
				setMaxReservationHour(indexedPolicies[POLICIES_SERVICE.Personalizada]?.fullHoraMax);
			},
		},
	);

	const { mutate: handleSaveReservationsConfig, isLoading: loadingSaveReservationsConfig } = useMutation(
		parkingService.updatePkReservationsConfig,
		{
			onSuccess: () => {
				handleSaveDefaultSedePolicyConfig(sedeDefaultPoliciesConfigCurrent);
			},
			onError: (error) => {
				setError(error as string);
			},
		},
	);

	const { isLoading: isLoadingSedeDefaultPolicyConfig, data: initialSedeDefaultPolicyConfig } = useQuery(
		['pkSedeDefaultPolicyConfig'],
		async () => parkingService.getPkDefaultSedePolicy(),
		{
			refetchOnWindowFocus: false,
			onError: (error) => setError(error as string),
			onSuccess: (data) => {
				setSedeDefaultPoliciesConfig(data);
			},
		},
	);

	const { mutate: handleSaveDefaultSedePolicyConfig, isLoading: loadingSaveDefaultSedePolicyConfig } = useMutation(
		parkingService.updatePkDefaultSedePolicy,
		{
			onSuccess: () => {
				setSuccess(t('msg_success'));
				queryClient.refetchQueries('pkReservationsConfig');
				queryClient.refetchQueries('pkSedeDefaultPolicyConfig');
			},
			onError: (error) => {
				setError(error as string);
			},
		},
	);

	const isLoading = useMemo(
		() =>
			isLoadingReservationsConfig ||
			isLoadingSedeDefaultPolicyConfig ||
			loadingSaveReservationsConfig ||
			loadingSaveDefaultSedePolicyConfig,
		[
			isLoadingReservationsConfig,
			isLoadingSedeDefaultPolicyConfig,
			loadingSaveReservationsConfig,
			loadingSaveDefaultSedePolicyConfig,
		],
	);

	const isEdited = useMemo(() => {
		return (
			!_.isEqual(initialReservationsConfig, reservationsConfig) ||
			!_.isEqual(initialSedeDefaultPolicyConfig, sedeDefaultPoliciesConfig)
		);
	}, [initialReservationsConfig, reservationsConfig, initialSedeDefaultPolicyConfig, sedeDefaultPoliciesConfig]);

	const minAndMaxHoursAreValid = useMemo(() => {
		return (
			(policiesAvailableInThisSede &&
				!policiesAvailableInThisSede.includes(POLICIES_AVAILABLE_OPTIONS.personalized)) ||
			validateHours(minReservationHour, maxReservationHour)
		);
	}, [policiesAvailableInThisSede, minReservationHour, maxReservationHour]);

	const defaultHoursAreValid = useMemo(() => {
		if (sedeDefaultPoliciesConfigCurrent?.id !== SEDE_POLICIES.personalized) {
			return true;
		}

		return validateHours(
			sedeDefaultPoliciesConfigCurrent?.fullHoraMin,
			sedeDefaultPoliciesConfigCurrent?.fullHoraMax,
		);
	}, [
		sedeDefaultPoliciesConfigCurrent?.id,
		sedeDefaultPoliciesConfigCurrent?.fullHoraMin,
		sedeDefaultPoliciesConfigCurrent?.fullHoraMax,
	]);

	const saveIsDisabled = useMemo(() => {
		return !isEdited || isLoading || !minAndMaxHoursAreValid || !defaultHoursAreValid;
	}, [isEdited, isLoading, minAndMaxHoursAreValid, defaultHoursAreValid]);

	const policiesAvailableOptions = useMemo(
		() =>
			Object.values(POLICIES_AVAILABLE_OPTIONS).map((value) => ({
				value: value,
				label: t(`lbl_reservations_policies_options.${value}`),
			})),
		[],
	);

	const availableOptions = useMemo(
		() => getAvailableOptions(sedeDefaultPoliciesConfig, policiesAvailableInThisSede),
		[sedeDefaultPoliciesConfig, policiesAvailableInThisSede],
	);

	const selectedOption = useMemo(
		() => getSelectedOption(sedeDefaultPoliciesConfig, policiesAvailableInThisSede),
		[sedeDefaultPoliciesConfig, policiesAvailableInThisSede],
	);

	useEffect(() => {
		setThereAreUnsavedChanges(isEdited);
	}, [isEdited]);

	const handleScheduleChange = (e: any) => {
		if (_.isEqual(reservationsConfig, defaultParkingReservationsConfig)) {
			return;
		}

		const { name, value } = e.target;

		const updatedPolicies = reservationsConfig.policies.map((policy) => {
			if (policy.name === POLICIES_SERVICE.Morning && name === 'middayHour') {
				setMiddayHour(value);
				return {
					...policy,
					fullHoraMax: value,
				};
			}
			if (policy.name === POLICIES_SERVICE.Tarde && name === 'middayHour') {
				setMiddayHour(value);
				return {
					...policy,
					fullHoraMin: value,
				};
			} else if (policy.name === POLICIES_SERVICE.Personalizada && name === 'minReservationHour') {
				setMinReservationHour(roundHour(value));
				return {
					...policy,
					fullHoraMin: roundHour(value),
				};
			} else if (policy.name === POLICIES_SERVICE.Personalizada && name === 'maxReservationHour') {
				setMaxReservationHour(roundHour(value));
				return {
					...policy,
					fullHoraMax: roundHour(value),
				};
			} else {
				return policy;
			}
		});

		setReservationsConfig({ ...reservationsConfig, policies: updatedPolicies });
	};

	const updatePoliciesSelection = (newSelectedOption: string) => {
		let nextAvailableOptions = [];

		if (!policiesAvailableInThisSede.includes(newSelectedOption)) {
			nextAvailableOptions = [...policiesAvailableInThisSede, newSelectedOption];
		} else if (policiesAvailableInThisSede.includes(newSelectedOption) && policiesAvailableInThisSede.length === 1) {
			nextAvailableOptions = [newSelectedOption];
		} else {
			nextAvailableOptions = policiesAvailableInThisSede.filter((option) => option !== newSelectedOption);
		}

		setPoliciesAvailableInThisSede(nextAvailableOptions);

		setReservationsConfig({
			...reservationsConfig,
			policies: getUpdatedPolicies(reservationsConfig.policies, nextAvailableOptions),
		});
	};
	const handleUpdateDefaultPolicy = (id: number) => {
		setSedeDefaultPoliciesConfig((prev) =>
			prev.map((item) => ({
				...item,
				isActive: item.id === id,
				isDefault: item.id === id,
			})),
		);
	};

	const handleUpdateDefaultPolicyMinHour = (id: number, hour: string) => {
		setSedeDefaultPoliciesConfig((prev) =>
			prev.map((item) => ({
				...item,
				fullHoraMin: item.id === id ? hour : item.fullHoraMin,
			})),
		);
	};

	const handleUpdateDefaultPolicyMaxHour = (id: number, hour: string) => {
		setSedeDefaultPoliciesConfig((prev) =>
			prev.map((item) => ({
				...item,
				fullHoraMax: item.id === id ? hour : item.fullHoraMax,
			})),
		);
	};
	const handleBufferConfig = (value: string) => {
		const newConfig = { ...reservationsConfig, buffer: value };
		setReservationsConfig(newConfig);
	};

	const handlePlannedWeeksExtend = (value: number) => {
		setReservationsConfig((prevState) => ({ ...prevState, parkingPlannedWeeksExtend: value }));
	};

	const handlePlannedDayTurningPoint = (day: number) => {
		setReservationsConfig((prevState) => ({
			...prevState,
			parkingPlannedTurningPoint: {
				...prevState.parkingPlannedTurningPoint,
				day: day,
			},
		}));
	};

	const handlePlanneHourTurningPoint = (hour: string) => {
		setReservationsConfig((prevState) => ({
			...prevState,
			parkingPlannedTurningPoint: {
				...prevState.parkingPlannedTurningPoint,
				hour: hour,
			},
		}));
	};

	const handleCompanionPermissionRestriction = () => {
		setReservationsConfig((prevState) => ({
			...prevState,
			companionPermissionRestriction: !prevState.companionPermissionRestriction,
		}));
	};

	const handleOnSave = () => {
		const availablePoliciesIds = availableOptions.map((option) => option?.id);

		if (!availablePoliciesIds.includes(sedeDefaultPoliciesConfigCurrent.id)) {
			setShowInvalidDefaultScheduleConfigurationModal(true);
			return;
		}

		if (sedeDefaultPoliciesConfigCurrent.id === SEDE_POLICIES.personalized) {
			const sedeDefaultPolicyHoursAreValid =
				validateHours(sedeDefaultPoliciesConfigCurrent.fullHoraMin, sedeDefaultPoliciesConfigCurrent.fullHoraMax) &&
				validateHours(minReservationHour, sedeDefaultPoliciesConfigCurrent.fullHoraMin, 'isEqualOrGreater') &&
				validateHours(maxReservationHour, sedeDefaultPoliciesConfigCurrent.fullHoraMax, 'isEqualOrLess');
			if (!sedeDefaultPolicyHoursAreValid) {
				setShowInvalidScheduleConfigurationModal(true);
				return;
			}
		}
		confirmSave();
	};

	const confirmSave = () => {
		if (
			hasEmptyValues(reservationsConfig) ||
			!sedeDefaultPoliciesConfigCurrent ||
			hasEmptyValues(sedeDefaultPoliciesConfig)
		) {
			setError(t('msg_error_all_fields_required'));
		} else {
			handleSaveReservationsConfig(reservationsConfig);
		}
	};

	return (
		<>
			{isLoadingReservationsConfig ? (
				<IonLoading isOpen={isLoading} message={t('msg_loading')} duration={0} />
			) : (
				<>
					{/* -------- BOOKINGS CONFIGURATION -------------------------------------------------------------- */}
					<List>
						{/* PLANNED */}
						<header className={`${styles.h2} ${styles.headerSpace}`}>{t('lbl_planned_reservation')}</header>
						{/* PLANNED WEEKS EXTEND */}
						<div className={styles.element}>
							<div className={`${styles.footnote} ${styles.space}`}>{t('lbl_parking_planned_weeks_extend')}</div>
							<NumberInput
								name="plannedWeeksExtend"
								className="ion-text-end"
								value={reservationsConfig.parkingPlannedWeeksExtend}
								onIonChange={(e: InputCustomEvent) => handlePlannedWeeksExtend(+e.target.value)}
								min={1}
								disabled={!hasWritePermission}
								required
								aria-label={t('value_numeric')}
								label={t('value_numeric')}
								keysDisabled
							></NumberInput>
						</div>
						{/* PLANNED_TURNING_POINT */}
						<div className={styles.element}>
							<div className={`${styles.footnote} ${styles.space}`}>{t('lbl_parking_planned_turningpoint')}</div>
							<DaySelector
								updateHour={handlePlanneHourTurningPoint}
								updateDay={handlePlannedDayTurningPoint}
								turningPoint={reservationsConfig.parkingPlannedTurningPoint}
								disabled={!hasWritePermission}
							/>
						</div>
						{/* LAST-MINUTE */}
						<header className={`${styles.h2} ${styles.headerSpace}`}>{t('lbl_last_minute_reservation')}</header>
						{/* DURATION */}
						<div className={styles.element}>
							<p className={styles.blockSubtitle}>
								{t(`lbl_timeAndReservations_reservation_types_description`)}
							</p>
							<MultiSelect
								className={`${styles.multiSelect} ${
									policiesAvailableInThisSede.length > 0 && styles.touchedInput
								}`}
								label={t('lbl_duration')}
								options={policiesAvailableOptions}
								selected={policiesAvailableInThisSede}
								updateSelection={updatePoliciesSelection}
								disabled={!hasWritePermission}
							/>
						</div>
						{/* MORNING / AFTERNOON TURNING POINT */}
						<div className={`${styles.element}`}>
							<div className={styles.blockSubtitle}>
								{t('lbl_timeAndReservations_morning_turning_point_description')}
							</div>
							<HourSelector
								name="middayHour"
								label={t('lbl_hour_selector')}
								value={middayHour}
								disabled={
									!hasWritePermission ||
									!policiesAvailableInThisSede.includes(POLICIES_AVAILABLE_OPTIONS.halfDays)
								}
								onChange={handleScheduleChange}
							/>
						</div>
						{/* MIN AND MAX RESERVATION HOUR */}
						<div className={styles.element}>
							<div className={styles.blockSubtitle}>
								{t('lbl_timeAndReservations_min_and_max_reservation_hour')}
							</div>
							<div className={styles.multipleInputsContainer}>
								<HourSelector
									name="minReservationHour"
									label={t('lbl_start_hour')}
									value={minReservationHour}
									disabled={
										!hasWritePermission ||
										!policiesAvailableInThisSede.includes(POLICIES_AVAILABLE_OPTIONS.personalized)
									}
									isValid={minAndMaxHoursAreValid}
									onChange={handleScheduleChange}
								/>
								<HourSelector
									name="maxReservationHour"
									label={t('lbl_end_hour')}
									value={maxReservationHour}
									disabled={
										!hasWritePermission ||
										!policiesAvailableInThisSede.includes(POLICIES_AVAILABLE_OPTIONS.personalized)
									}
									isValid={minAndMaxHoursAreValid}
									onChange={handleScheduleChange}
								/>
							</div>
						</div>
						{/* DEFAULT PREFERENCES CONFIGURATION */}
						<DefaultPreferencesConfiguration
							sedePolicies={SEDE_POLICIES}
							handleUpdateDefaultPolicy={handleUpdateDefaultPolicy}
							handleUpdateMinHour={handleUpdateDefaultPolicyMinHour}
							handleUpdateMaxHour={handleUpdateDefaultPolicyMaxHour}
							availableOptions={availableOptions}
							selectedOption={selectedOption}
							hasWritePermission={hasWritePermission}
						/>

						{/* BUFFER */}
						<div className={styles.element}>
							<p className={`${styles.blockSubtitle}`}>{t('lbl_pk_reservation_buffer')}</p>
							<IonItem
								lines="none"
								className={`${styles.inputModal} ${
									reservationsConfig.buffer !== initialReservationsConfig.buffer && styles.touchedInput
								}`}
							>
								<IonLabel slot="start" className="lblSelector">
									{t('tab_time')}
								</IonLabel>
								<IonSelect
									name="bufferConfiguration"
									slot="end"
									className={`lblSelector ${styles.selector}`}
									interfaceOptions={{
										cssClass: styles.selectInterface,
									}}
									interface="popover"
									value={reservationsConfig.buffer}
									onIonChange={(e) => handleBufferConfig(e.detail.value)}
									disabled={!hasWritePermission}
									aria-label={t('tab_time')}
								>
									{PARKING_BUFFER_OPTIONS.map((permission) => {
										return (
											<IonSelectOption key={permission.id} value={permission.description}>
												{permission.description}
											</IonSelectOption>
										);
									})}
								</IonSelect>
							</IonItem>
						</div>
						{/* COMPANION PERMISSION RESTRICTION */}
						<header className={`${styles.h2} ${styles.headerSpace}`}>{t('lbl_companions')}</header>
						<div className={styles.element}>
							<div className={`${styles.footnote} ${styles.space}`}>
								{t('lbl_pk_reservations_companion_permission_restriction')}
							</div>
							<IonItem lines="none" className={`${styles.inputWithLabel} ${styles.bottomLine}`}>
								<IonLabel className={styles.labelInput}>
									{t('lbl_pk_reservationscompanion_permision_apply_restriction')}
								</IonLabel>
								<Toggle
									checked={reservationsConfig.companionPermissionRestriction}
									onChange={handleCompanionPermissionRestriction}
									disabled={!hasWritePermission}
								/>
							</IonItem>
						</div>
					</List>
					{/* -------- FOOTER -------------------------------------------------------- */}
					{hasWritePermission && (
						<div className={`${footerStyle} ${styles.footerButton}`}>
							<Button
								onClick={handleOnSave}
								className={styles.btnHeader}
								disabled={saveIsDisabled}
								color="primary"
							>
								{t('btn_save_data')}
							</Button>
						</div>
					)}
				</>
			)}
			<CustomModal
				showModal={showInvalidDefaultScheduleConfigurationModal}
				onConfirm={() => setShowInvalidDefaultScheduleConfigurationModal(false)}
				onClose={() => setShowInvalidDefaultScheduleConfigurationModal(false)}
				title={t('lbl_timeAndReservations_invalid_schedule_configuration_modal_title')}
				description={t('lbl_timeAndReservations_invalid_default_schedule_configuration_modal_description')}
				confirmText={t('btn_accept')}
				cancelText={t('btn_cancel')}
			/>
			<CustomModal
				showModal={showInvalidScheduleConfigurationModal}
				onConfirm={() => setShowInvalidScheduleConfigurationModal(false)}
				onClose={() => setShowInvalidScheduleConfigurationModal(false)}
				title={t('lbl_timeAndReservations_invalid_schedule_configuration_modal_title')}
				description={t('lbl_timeAndReservations_invalid_schedule_configuration_modal_description')}
				confirmText={t('btn_accept')}
				cancelText={t('btn_cancel')}
			/>
		</>
	);
};
