import _ from 'lodash';
import { Policy } from '../../../../../_api/services/terms/types';

export const checkIsEdited = (
	initialData: Policy,
	currentData: Policy,
	selectedOption: 'file' | 'link',
	language: string,
) => {
	const initialTranslationData = !_.isEmpty(initialData?.translations)
		? initialData.translations.find((translation) => translation.language === language)
		: null;

	const currentTranslationData = !_.isEmpty(currentData?.translations)
		? currentData.translations.find((translation) => translation.language === language)
		: null;

	if (!initialTranslationData || !currentTranslationData) {
		return false;
	}

	const nameHasChanged = initialTranslationData.name !== currentTranslationData.name;
	const linkHasChanged = selectedOption === 'link' && initialTranslationData.link !== currentTranslationData.link;

	const filenameHasChanged =
		selectedOption === 'file' && initialTranslationData.fileName !== currentTranslationData.fileName;
	return nameHasChanged || linkHasChanged || filenameHasChanged;
};

export const checkEmptyFields = (currentData: Policy, selectedOption: 'file' | 'link', language: string) => {
	const currentTranslationData = !_.isEmpty(currentData?.translations)
		? currentData.translations.find((translation) => translation.language === language)
		: null;

	if (!currentTranslationData) {
		return true;
	}

	const nameIsEmpty = !currentTranslationData?.name;
	const linkIsEmpty = selectedOption === 'link' && !currentTranslationData?.link;
	const filenameIsEmpty = selectedOption === 'file' && !currentTranslationData?.fileName;
	return nameIsEmpty || linkIsEmpty || filenameIsEmpty;
};

export const getCurrentTranslationData = (policy: Policy, language: string, key: string): string => {
	const currentData = !_.isEmpty(policy?.translations)
		? policy.translations.find((translation) => translation.language === language)
		: null;

	if (!currentData) {
		return null;
	}

	if (typeof currentData[key] !== 'string') {
		return null;
	} else {
		return currentData[key] ?? '';
	}
};
