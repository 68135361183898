import { useEffect, useState } from 'react';
import { Button, Icon, Modal, Reorder, ReorderGroup } from '@acciona/ui-ionic-kit';
import {
	IonButton,
	IonContent,
	IonFooter,
	IonInput,
	IonItem,
	IonLabel,
	IonSelect,
	IonSelectOption,
	IonTitle,
	IonToolbar,
	RefresherEventDetail,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Category } from '../../../../../_api/services/incidents/types';
import { Props } from './types';
import { arraymove, getNext } from '../../../../../utils/functions';
import styles from '../../styles.module.scss';
import { availableLanguages } from '../../../../../i18nextConf';
import { Toggle } from '../../../../../components/Toggle/Toggle';
import { TextInput } from '../../../../../components/TextInput/TextInput';

const icons = [
	{ text: 'Seleccionar', icon: null },
	{ text: 'Campus', icon: 'dashboard' },
	{ text: 'Edificio', icon: 'building' },
	{ text: 'Gimnasio', icon: 'gym' },
	{ text: 'Servicios', icon: 'servicios' },
	{ text: 'Service Hub', icon: 'service-hub' },
	{ text: 'Parking', icon: 'parking' },
	{ text: 'Puesto', icon: 'seat' },
	{ text: 'Restaurante', icon: 'plate' },
	{ text: 'Sala', icon: 'room' },
	{ text: 'Wallet', icon: 'app' },
];

const colors = [
	{ text: 'Seleccionar', color: null, style: '' },
	{ text: 'Rojo', color: 'secondary', style: styles.backgroundSecondary },
	{ text: 'Blanco', color: 'tertiary', style: styles.backgroundTertiary },
	{ text: 'Verde', color: 'success', style: styles.backgroundSuccess },
	{ text: 'Amarillo', color: 'warning', style: styles.backgroundWarning },
	{ text: 'Gris', color: 'light', style: styles.backgroundLight },
	{ text: 'Parking', color: 'parking', style: styles.backgroundParking },
	{ text: 'Salas', color: 'room', style: styles.backgroundRoom },
	{ text: 'Puestos', color: 'desk', style: styles.backgroundDesk },
	{ text: 'Home', color: 'septenary', style: styles.backgroundSeptenary },
];

const emptyCategory: Category = {
	categoryId: 0,
	name: '',
	nameEn: '',
	ordinal: 1,
	icon: '',
	color: '',
	disabled: false,
	translations: [{}],
};

const CategoriesModal: React.FC<Props> = (props) => {
	const { t } = useTranslation();
	const [changes, setChanges] = useState<Category[]>([]);
	const [order, setOrder] = useState<Category[]>([]);
	const [edit, setEdit] = useState(false);
	const [addCategory, setAddCategory] = useState<Category>(emptyCategory);
	const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
		event.detail.complete();
		const action = event.detail as any;
		const changesMoved = arraymove(_.sortBy(changes, 'ordinal'), action.from, action.to).map((e: Category, index) => {
			return {
				...e,
				ordinal: index + 1,
			};
		});
		setChanges(changesMoved);
	};

	const handleSaveChanges = () => {
		if (!_.isEmpty(_.filter(changes, (c) => _.isEmpty(_.first(c.translations).name)))) {
			alert('Debes rellenar todos los campos');
		} else {
			props.onClose();
			props.saveChanges(changes);
		}
	};

	const deleteCategory = (id) => {
		const ordinal = _.first(changes.filter((c) => c.categoryId === id)).ordinal;
		setChanges(
			changes.map((o) => {
				if (o.categoryId === id) {
					return { ...o, disabled: true, ordinal: null };
				} else {
					if (o.ordinal > ordinal) {
						return { ...o, ordinal: o.ordinal - 1 };
					} else {
						return o;
					}
				}
			}),
		);
	};

	const changeEditItem = (id, item, value) => {
		if (item == 'name') {
			const changedItem = changes.find((o) => o.categoryId === id);
			if (changedItem) {
				const updatedTranslations = changedItem.translations.map((translation) => {
					if (translation.language === props.lang) {
						return { ...translation, name: value.detail.value };
					}
					return translation;
				});

				const updatedChanges = [
					...changes.filter((o) => o.categoryId !== id),
					{ ...changedItem, translations: updatedTranslations },
				];

				setChanges(updatedChanges);
			}
		} else {
			setChanges([
				..._.filter(changes, (o) => o.categoryId !== id),
				{ ..._.first(_.filter(changes, (o) => o.categoryId === id)), [item]: value.detail.value },
			]);
		}
	};

	const changeAddItem = (item, value) => {
		if (item == 'name') {
			setAddCategory({
				...addCategory,
				translations: availableLanguages.map((language) => {
					return {
						language: language,
						name: value,
					};
				}),
			});
		} else {
			setAddCategory({
				...addCategory,
				[item]: value,
			});
		}
	};

	useEffect(() => {
		props.categories && setChanges(props.categories);

		props.categories && setOrder(props.categories);
	}, [props.categories, props.showModal]);

	const handleChangeToggle = () => {
		edit && setOrder(_.sortBy(changes, 'ordinal'));
		setEdit(!edit);
	};

	const handleAddCategory = () => {
		const newCategory = {
			...addCategory,
			categoryId: getNext(changes, 'categoryId'),
			ordinal: getNext(changes, 'ordinal'),
		};
		const nextCategories = _.sortBy([...changes, newCategory], 'ordinal');
		setChanges(nextCategories);
		setOrder(nextCategories);
		setAddCategory(emptyCategory);
	};

	return (
		<Modal
			isOpen={props.showModal}
			onDidDismiss={() => {
				props.onClose();
			}}
			className={styles.modal}
		>
			<IonToolbar>
				<IonTitle>
					<h3 className="ion-text-center">
						<b>{`${t('edit_categories')} (${t('lang_label')}: ${t('language_' + props.lang)})`}</b>
					</h3>
				</IonTitle>
			</IonToolbar>

			<IonContent>
				<div>
					<IonItem className={styles.categoryListText} lines="none">
						<p>{t('category_modal_text')}</p>
					</IonItem>
					<div className="subHeader">
						<div className="title"></div>

						{props.hasWritePermission && (
							<>
								<div className="buttons">
									<Toggle disabled={!props.hasWritePermission} checked={edit} onChange={handleChangeToggle} />
								</div>
								<div className="label">
									<IonLabel>{t('lbl_edit')}</IonLabel>
								</div>
							</>
						)}
					</div>
					{edit ? (
						<div>
							{changes &&
								_.sortBy(changes, 'ordinal')
									.filter((c) => !c.disabled)
									.map((cats, index) => {
										const categoriesData = cats?.translations.find((e) => e.language == props.lang);
										return (
											<IonItem lines="none" key={index} className={styles.inputCategory}>
												<IonItem
													lines="none"
													className={`${styles.inputModal} ${
														categoriesData.name === '' ? 'borderAlert' : ''
													}`}
												>
													<IonInput
														className={`ion-text-end`}
														value={categoriesData.name}
														onIonChange={(e) => changeEditItem(cats.categoryId, 'name', e)}
														placeholder={t('name')}
														aria-label={t('category')}
														label={t('category')}
													></IonInput>
												</IonItem>
												<IonItem lines="none" className={`${styles.inputModal}`}>
													<IonSelect
														slot="end"
														className={`lblSelector ${styles.selector}`}
														interface="popover"
														value={colors.filter((i) => i.color === cats.color)[0]?.color}
														onIonChange={(e) => changeEditItem(cats.categoryId, 'color', e)}
														aria-label={t('lbl_delete_color')}
														label={t('lbl_delete_color')}
													>
														{colors
															.filter((c) => c.color != null)
															.map((item, index) => {
																return (
																	<IonSelectOption key={index} value={item.color}>
																		{item.text}
																	</IonSelectOption>
																);
															})}
													</IonSelect>
												</IonItem>
												<IonItem lines="none" className={`${styles.inputModal}`}>
													<IonSelect
														slot="end"
														className={`lblSelector ${styles.selector}`}
														interface="popover"
														value={icons.filter((i) => i.icon === cats.icon)[0]?.icon}
														onIonChange={(e) => changeEditItem(cats.categoryId, 'icon', e)}
														aria-label={t('icon')}
														label={t('icon')}
													>
														{icons
															.filter((c) => c.icon != null)
															.map((item, index) => {
																return (
																	<IonSelectOption key={index} value={item.icon}>
																		{item.text}
																	</IonSelectOption>
																);
															})}
													</IonSelect>
												</IonItem>

												<IonButton
													fill="clear"
													onClick={() => deleteCategory(cats.categoryId)}
													className={styles.buttonDelete}
													slot="end"
												>
													<Icon className={`icon icon-delete icon24`} />
												</IonButton>
											</IonItem>
										);
									})}
						</div>
					) : (
						<ReorderGroup
							className="orderGroup"
							disabled={!props.hasWritePermission}
							onIonItemReorder={(e) => doRefresh(e)}
						>
							{order &&
								_.sortBy(order, 'ordinal')
									.filter((c) => !c.disabled)
									.map((item, index) => {
										const categoriesData = item?.translations.find((e) => e.language == props.lang);
										return (
											<Reorder key={index}>
												<IonItem lines="none" className={styles.inputModal}>
													<IonLabel className={styles.headline}>{categoriesData.name}</IonLabel>
													{props.hasWritePermission && (
														<Icon slot="end" className="icon icon-reordenar"></Icon>
													)}
												</IonItem>
											</Reorder>
										);
									})}
						</ReorderGroup>
					)}
					{props.hasWritePermission && (
						<>
							{addCategory && (
								<>
									<IonItem lines="none" className={styles.titleNewCategory}>
										<p className={styles.footnote}>{t('category_modal_text_create')}</p>
									</IonItem>
									<TextInput
										name="category"
										className="ion-text-end"
										onIonChange={(e) => changeAddItem('name', e.detail.value)}
										value={_.first(addCategory.translations).name}
										placeholder={t('name')}
										aria-label={t('category')}
										label={t('category')}
									></TextInput>
									<div className={styles.inputAddCategory}>
										<div>
											<IonItem lines="none" slot="start" className={styles.smallInputModal}>
												<IonLabel slot="start" className="lblSelector labelSelector">
													{t('lbl_delete_color')}
												</IonLabel>
												<IonSelect
													slot="end"
													className={`lblSelector ${styles.selector}`}
													interface="popover"
													onIonChange={(e) => changeAddItem('color', e.detail.value)}
													aria-label={t('lbl_delete_color')}
												>
													{colors
														.filter((c) => !_.isEmpty(c.color))
														.map((item, index) => {
															return (
																<IonSelectOption key={index} value={item.color}>
																	{item.text}
																</IonSelectOption>
															);
														})}
												</IonSelect>
											</IonItem>

											{addCategory && addCategory.color !== '' && (
												<div className="inputAddPreview" slot="start">
													<div
														className={`ion-text-center divColor ${
															colors.filter((i) => i.color === addCategory.color)[0]?.style
														}`}
													>
														{' '}
													</div>
												</div>
											)}
											<IonItem lines="none" slot="start" className={styles.smallInputModal}>
												<IonLabel slot="start" className="lblSelector labelSelector">
													{t('icon')}
												</IonLabel>
												<IonSelect
													slot="end"
													className={`lblSelector ${styles.selector}`}
													interface="popover"
													onIonChange={(e) => changeAddItem('icon', e.detail.value)}
													aria-label={t('icon')}
												>
													{icons
														.filter((c) => c.icon != null)
														.map((item, index) => {
															return (
																<IonSelectOption key={index} value={item.icon}>
																	{item.text}
																</IonSelectOption>
															);
														})}
												</IonSelect>
											</IonItem>
											{addCategory && addCategory.icon !== '' && (
												<div className="inputAddPreview">
													<div className="ion-text-center">
														<Icon className={`icon icon-${addCategory.icon}`} />
													</div>
												</div>
											)}
										</div>
										<div>
											<Button
												disabled={
													_.isEqual(addCategory.translations[0], {}) ||
													_.first(addCategory.translations)?.name === '' ||
													addCategory.icon === '' ||
													addCategory.color === ''
												}
												className={`${styles.btnAdd} ${styles.secondaryBtn}`}
												onClick={handleAddCategory}
												slot="end"
											>
												<Icon className="icon icon-plus iconPlus" />
												<IonLabel>{t('btn_add')}</IonLabel>
											</Button>
										</div>
									</div>
								</>
							)}
						</>
					)}
				</div>
			</IonContent>

			<IonFooter className={styles.btnModal}>
				<div>
					<Button
						className={`${styles.btnHeader} ${styles.secondaryBtn}`}
						onClick={() => {
							props.onClose();
						}}
					>
						{t('btn_cancel')}
					</Button>
				</div>
				<div>
					<Button
						color="primary"
						className={styles.btnHeader}
						disabled={
							props.categories &&
							changes &&
							_.isEqual(
								_.sortBy(props.categories, 'categoryId', 'ordinal'),
								_.sortBy(changes, 'categoryId', 'ordinal'),
							)
						}
						onClick={handleSaveChanges}
					>
						{t('btn_accept')}
					</Button>
				</div>
			</IonFooter>
		</Modal>
	);
};

export default CategoriesModal;
