import { Policy, PolicyCategory } from '../../../_api/services/terms/types';
import _ from 'lodash';
import { arraymove, getMax } from '../../../utils/functions';

export const emptyCategory: PolicyCategory = {
	id: 0,
	ordinal: 1,
	disabled: false,
	translations: [
		{
			language: 'es',
			name: '',
		},
	],
};

export const emptyPolicy: Policy = {
	id: 0,
	ordinal: 0,
	categoryId: 0,
	disabled: false,
	translations: [],
};

export const updatePoliciesState = (previousState: Policy[], updatedPolicy: Policy) => {
	if (_.isEmpty(previousState)) {
		return [updatedPolicy];
	}

	const nextPoliciesState = previousState.map((policy) => {
		if (policy.categoryId === updatedPolicy.categoryId && policy.id === updatedPolicy.id) {
			return updatedPolicy;
		} else {
			return policy;
		}
	});

	if (
		!nextPoliciesState
			.filter((item) => item.categoryId === updatedPolicy.categoryId)
			.map((p) => p.id)
			.includes(updatedPolicy.id)
	) {
		nextPoliciesState.push(updatedPolicy);
	}

	return nextPoliciesState;
};

export const deletePolicyFromState = (
	previousState: Policy[],
	itemToDelete: { policyId: number; categoryId: number },
): Policy[] => {
	if (_.isEmpty(previousState)) {
		return [];
	}

	const { policyId, categoryId } = itemToDelete;

	const itemToDeleteData = previousState.find((item) => item.id === policyId && item.categoryId === categoryId);

	if (!itemToDeleteData) {
		return;
	}

	const nextPoliciesState = previousState.map((policy) => {
		if (policy.categoryId === categoryId && policy.id === policyId) {
			return { ...policy, disabled: true, ordinal: null };
		} else if (policy.categoryId === categoryId) {
			return {
				...policy,
				ordinal: policy.ordinal > itemToDeleteData.ordinal ? policy.ordinal - 1 : policy.ordinal,
			};
		} else {
			return policy;
		}
	});

	return nextPoliciesState;
};

export const policyIsEditedOrNew = (policy: Policy, initialPoliciesState: Policy[]) => {
	if (_.isEmpty(initialPoliciesState)) {
		return true;
	}

	if (_.isEmpty(policy.translations)) {
		return false;
	}

	if (policy.translations.some((t) => t?.isEdited || t?.isNew)) {
		return true;
	}

	const previousPolicyData = initialPoliciesState.find((p) => p.id === policy.id);

	if (previousPolicyData.ordinal !== policy.ordinal) {
		return true;
	}

	if (previousPolicyData.disabled !== policy.disabled) {
		return true;
	}

	return false;
};

export const getEditedPolicies = (initialPoliciesState: Policy[], policiesState: Policy[]) => {
	if (_.isEmpty(initialPoliciesState)) {
		return policiesState;
	}

	const policies = policiesState.map((policy) => {
		const previousPolicyData = initialPoliciesState.find((p) => p.id === policy.id);

		return {
			...policy,
			...(previousPolicyData?.ordinal !== policy.ordinal ? { isEditedOrdinal: true } : {}),
		};
	});

	return policies.filter((policy) => policyIsEditedOrNew(policy, initialPoliciesState));
};

export const categoryIsEditedOrNew = (category: PolicyCategory, initialCategoriesState: PolicyCategory[]) => {
	if (_.isEmpty(initialCategoriesState)) {
		return true;
	}

	const initialCategoriesIds = initialCategoriesState.map((c) => c.id);
	if (!category?.id || !initialCategoriesIds.includes(category.id)) {
		// is new
		return true;
	}

	const previousCategoryData = initialCategoriesState.find((c) => c.id === category.id);

	if (previousCategoryData.ordinal !== category.ordinal) {
		return true;
	}

	if (previousCategoryData.disabled !== category.disabled) {
		return true;
	}

	if (!_.isEmpty(category.translations)) {
		const previousIndexedTranslations = {};

		previousCategoryData.translations.forEach(({ language, name }) => {
			previousIndexedTranslations[language] = name;
		});

		return category.translations.some((t) => t.name !== previousIndexedTranslations[t.language]);
	}

	return false;
};

export const getEditedCategories = (initialState: PolicyCategory[], categoriesState: PolicyCategory[]) => {
	const editedCategories = categoriesState.filter((category) => categoryIsEditedOrNew(category, initialState));
	return editedCategories.map((category) => {
		const isNewCategory = category.id > getMax(initialState, 'id');
		return {
			...category,
			id: isNewCategory ? null : category.id,
		};
	});
};

export const getReorderedPolicies = (
	previousPolicyState: Policy[],
	selectedCategoryId: number,
	from: number,
	to: number,
) => {
	const policies: Policy[] = _.cloneDeep(previousPolicyState);
	const selectedCategoryPolicies = policies.filter((policy) => policy.categoryId === selectedCategoryId);
	const nonSelectedCategoryPolicies = policies.filter((e) => e.categoryId !== selectedCategoryId);

	const changesMoved = _.sortBy(
		arraymove(selectedCategoryPolicies, from, to).map((item, index) => {
			return {
				...item,
				ordinal: index + 1,
			};
		}),
		'ordinal',
	);
	return [...nonSelectedCategoryPolicies, ...changesMoved];
};
