import i18n from '../i18nextConf';

export const MILLISECONDS_IN_A_DAY = 86400000;

export const FUNCTIONALITIES = {
	admin: 'ADMIN',
	communications: 'COMMUNICATIONS',
	desk: 'DESK',
	incidents: 'INCIDENTS',
	parking: 'PARKING',
	services: 'SERVICES',
	room: 'ROOM',
	transversal: 'TRANSVERSAL',
	public: 'PUBLIC',
	superadmin: 'SUPERADMIN',
};

export const LANGUAGES = ['es', 'en', 'pt'];
export const USER_APP_PERMISSIONS_TYPES = {
	undefined: 'undefined',
	start_date: 'start_date',
	temporal: 'temporal',
	no_permission: 'no_permission',
};

export const WEEKDAYS = [
	{ id: 1, description: i18n.t('weekdays.monday') },
	{ id: 2, description: i18n.t('weekdays.tuesday') },
	{ id: 3, description: i18n.t('weekdays.wednesday') },
	{ id: 4, description: i18n.t('weekdays.thursday') },
	{ id: 5, description: i18n.t('weekdays.friday') },
	{ id: 6, description: i18n.t('weekdays.saturday') },
	{ id: 0, description: i18n.t('weekdays.sunday') },
];

export const DEFAULT_REACT_QUILL_EDITOR_CONFIG = {
	toolbar: [
		[
			{
				header: [1, 2, 3, false],
			},
		],
		['bold', 'italic', 'underline', 'strike'],
		[
			{
				list: 'ordered',
			},
			{
				list: 'bullet',
			},
		],
		[
			{
				color: [],
			},
			{
				background: [],
			},
		],
		['link', 'image'],
		['clean'],
	],
};

export const LOCKERS_MODE = {
	LOCKER_BY_DESK: '0', // Old configuration
	LOCKER_BY_USER: '1',
};

export const JAVA_INT = {
	MAX: 2147483647,
	MIN: -2147483648,
};

export const CONFIGURATION_PREFERENCES = {
	sede: {
		id: 'sede',
		description: i18n.t('lbl_configuration_by_head_office'),
	},
	transversal: {
		id: 'transversal',
		description: i18n.t('lbl_configuration_transversal'),
	},
};

export const VEHICLE_TYPES = {
	car: 1,
	motorcycle: 2,
	motoSilence: 3,
	microcar: 4,
};

export const RESERVATIONS_SCHEDULES = {
	'Dia completo': 'complete_day',
	Morning: 'morning_schedule',
	Tarde: 'afternoon_schedule',
	Personalizada: 'custom_schedule',
};
