import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { UserVehicle, VehicleAttributes } from '../../../../../_api/services/parking/types';
import { TextInput } from '../../../../../components/TextInput/TextInput';
import { VehicleConfigSelector } from '../VehicleConfigSelector/VehicleConfigSelector';
import { VEHICLE_TYPES } from '../../../../../utils/constants';
import styles from './styles.module.scss';

type Props = {
	vehicle: UserVehicle;
	onChange: (id: number, field: string, value: string) => void;
	listAttributes: VehicleAttributes;
};

export const EditVehicleItem = (props: Props) => {
	const { vehicle, onChange, listAttributes } = props;
	const { t } = useTranslation();
	const vehicleTypes = listAttributes?.types.map((item) => ({ ...item, id: item.typeId }));
	const ecoLabels = listAttributes?.environmentalBadges.map((item) => ({ ...item, id: item.badgeId }));
	const transportZones = listAttributes?.transportZones.map((item) => ({ ...item, id: item.zoneId }));
	const pluginOptions = [
		{ id: 1, description: t('yes') },
		{ id: 2, description: t('no') },
	];

	const onChangeHandler = (e: any) => {
		const { name, value } = e.target;
		onChange(vehicle.vehicleId, name, value);
	};

	if (!vehicle) {
		return <></>;
	}

	return (
		<>
			<p>{t('modify_selected_vehicle_data')}</p>
			<div className={styles.editVehicleContent}>
				<TextInput
					name="brand"
					className="ion-text-end"
					value={vehicle.brand}
					onIonInput={onChangeHandler}
					placeholder={t(`lbl_vehicle_brand`)}
					label={t(`lbl_vehicle_brand`)}
					disabled={false}
				/>
				<TextInput
					name="model"
					className="ion-text-end"
					value={vehicle.model}
					onIonInput={onChangeHandler}
					placeholder={t(`lbl_vehicle_model`)}
					label={t(`lbl_vehicle_model`)}
					disabled={false}
				/>
				<TextInput
					name="color"
					className="ion-text-end"
					value={vehicle.color}
					onIonInput={onChangeHandler}
					placeholder={t(`lbl_vehicle_color`)}
					label={t(`lbl_vehicle_color`)}
					disabled={false}
				/>
				<VehicleConfigSelector
					name="type"
					value={vehicle.type}
					options={vehicleTypes}
					onChange={onChangeHandler}
					label={t('lbl_vehicle_type')}
					disabled={false}
				/>
				<TextInput
					name="plate"
					className="ion-text-end"
					value={vehicle.plate}
					onIonInput={onChangeHandler}
					placeholder={t(`lbl_vehicle_plate`)}
					label={t(`lbl_vehicle_plate`)}
					disabled={true}
				/>
				{!_.isEmpty(ecoLabels) && vehicle?.environmentalBadge && (
					<VehicleConfigSelector
						name="environmentalBadge"
						value={vehicle?.environmentalBadge}
						options={ecoLabels}
						onChange={onChangeHandler}
						label={t('lbl_vehicle_eco_label')}
						disabled={false}
					/>
				)}
				{!_.isEmpty(transportZones) && vehicle?.transportZone && (
					<VehicleConfigSelector
						name="transportZone"
						value={vehicle?.transportZone}
						options={transportZones}
						onChange={onChangeHandler}
						label={t('lbl_vehicle_transport_zone')}
						disabled={false}
					/>
				)}
				{[VEHICLE_TYPES.car, VEHICLE_TYPES.microcar].includes(vehicle.type) && vehicle?.plugin !== undefined && (
					<VehicleConfigSelector
						name="plugin"
						value={vehicle?.plugin ? 1 : 2}
						options={pluginOptions}
						onChange={onChangeHandler}
						label={t('lbl_vehicle_electric_charge')}
						disabled={false}
					/>
				)}
			</div>
		</>
	);
};
