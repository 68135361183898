import { IonItem, IonSelect, IonSelectOption } from '@ionic/react';
import styles from './styles.module.scss';

export const VehicleConfigSelector = ({ label, name, value, options, disabled, onChange }) => {
	return (
		<IonItem lines="none" className={`${styles.inputModal}`}>
			<IonSelect
				label={label}
				name={name}
				slot="end"
				className={`lblSelector ${styles.selector} ${value !== undefined && styles.touchedInput}`}
				interfaceOptions={{
					cssClass: styles.selectInterface,
				}}
				interface="popover"
				value={value}
				onIonChange={onChange}
				disabled={disabled}
			>
				{options?.map((option) => (
					<IonSelectOption key={option.id} value={option.id}>
						{option.description}
					</IonSelectOption>
				))}
			</IonSelect>
		</IonItem>
	);
};
