import { useContext, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IonLoading } from '@ionic/react';
import { Button, Toast } from '@acciona/ui-ionic-kit';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AppPermission, Profile } from '../../_api/services/roles/types';
import { roleService } from '../../_api/services/roles';
import { AppPermissionsContext } from '../../context/AppPermissions/AppPermissions.context';
import { AppPermissionsActions } from '../../context/AppPermissions/AppPermissions.actions';
import { ProfilesPermissionsSection } from './components/ProfilesPermissionsSection/ProfilesPermissionsSection';
import { UsersPermissionsSection } from './components/UsersPermissionsSection/UsersPermissionsSection';
import { checkIsEdited, getEmployeeChanges, getProfilesChanges } from './helpers';
import styles from './styles.module.scss';

type Props = {
	permission: AppPermission;
	profilesList: Profile[];
	footerStyle: string;
};

export const AppPermissionsEdition: React.FC<Props> = (props) => {
	const { permission, profilesList, footerStyle } = props;
	const mountedRef = useRef(true);
	const { state, dispatch } = useContext(AppPermissionsContext);
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const [isEdited, setIsEdited] = useState(false);
	const [permissionSelected, setPermissionSelected] = useState('');

	const { data: permissionsData, isError: isErrorPermissionsData } = useQuery(
		['permissionsData'],
		async () => await roleService.getAppPermissions(permission.permission),
		{
			onSettled: () => {
				dispatch(AppPermissionsActions.setIsLoading(false));
			},
			onError: (error) => {
				dispatch(AppPermissionsActions.setErrorMessage(error as string));
			},
		},
	);

	const { mutate: handleSave, isLoading: loadingSave } = useMutation(roleService.updateAppPermissions, {
		onSuccess: () => {
			dispatch(AppPermissionsActions.setSuccessMessage(t('msg_success')));
			queryClient.refetchQueries('permissionsData');
		},
		onError: (error) => {
			dispatch(AppPermissionsActions.setErrorMessage(error as string));
		},
	});

	const handleSaveChanges = () => {
		handleSave({
			permission: state.permission.permission,
			profiles: getProfilesChanges(permissionsData.profiles, state.selectedProfiles, state.profiles),
			employees: getEmployeeChanges(permissionsData.employees, state.usersData),
		});
	};

	useEffect(() => {
		if (mountedRef.current && !state.isLoading) {
			dispatch(AppPermissionsActions.setUsersData(permissionsData?.employees));
			dispatch(AppPermissionsActions.setSelectedProfiles(permissionsData?.profiles));
		}
	}, [state.isLoading, permissionsData]);

	useEffect(() => {
		if (mountedRef.current) {
			setPermissionSelected(permission.permission);
			dispatch(AppPermissionsActions.setInitialState());
			dispatch(AppPermissionsActions.setPermission(permission));
			queryClient.refetchQueries('permissionsData');
		}
		return () => {
			queryClient.cancelQueries('permissionsData');
			mountedRef.current = false;
		};
	}, [permission]);

	useEffect(() => {
		dispatch(AppPermissionsActions.setProfilesList(profilesList));
	}, [profilesList]);

	useEffect(() => {
		setIsEdited(checkIsEdited(permissionsData, state.usersData, state.selectedProfiles, state.profiles));
	}, [permissionsData, state.usersData, state.selectedProfiles, state.profiles]);

	return (
		<div>
			<ProfilesPermissionsSection permission={permission} disabled={isErrorPermissionsData} />
			<UsersPermissionsSection permission={permission} disabled={isErrorPermissionsData} />
			<div className={`${footerStyle} ${styles.footerButton}`}>
				<Button
					color="primary"
					className={styles.saveBtn}
					onClick={handleSaveChanges}
					disabled={isErrorPermissionsData || !isEdited}
				>
					{t('btn_save_data')}
				</Button>
			</div>

			<IonLoading isOpen={state.isLoading || loadingSave} message={t('msg_loading')} duration={0} />

			<Toast
				isOpen={!!state.errorMessage && permissionSelected === permission.permission}
				message={state.errorMessage}
				position="bottom"
				type="error"
				onDidDismiss={() => dispatch(AppPermissionsActions.setErrorMessage(''))}
			/>

			<Toast
				isOpen={!!state.successMessage && permissionSelected === permission.permission}
				message={state.successMessage}
				position="bottom"
				type="success"
				onDidDismiss={() => dispatch(AppPermissionsActions.setSuccessMessage(''))}
			/>
		</div>
	);
};
