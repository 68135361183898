import { IonIcon, IonText } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { checkIfServiceIsConfigured } from './helpers';

import styles from './styles.module.scss';
import { MenuItem } from '../../_api/services/generalServices/types';

const WarningNotConfiguredService = ({ item, hasWritePermission }: { item: MenuItem; hasWritePermission: boolean }) => {
	const { t } = useTranslation();

	if (!checkIfServiceIsConfigured(item) && hasWritePermission) {
		return (
			<div className={styles.containerConfigPending}>
				<IonIcon className={`icon24 icon-incident ${styles.warningIcon}`}></IonIcon>
				<IonText className={styles.body}>{t('lbl_configuration_pending')}</IonText>
			</div>
		);
	}
	return <></>;
};

export default WarningNotConfiguredService;
