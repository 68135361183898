import { useEffect, useState } from 'react';
import { IonApp, setupIonicReact } from '@ionic/react';
import { Routes } from './routes';
import './App.scss';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import '@acciona/ui-ionic-kit/lib/assets/base.min.css';

import { QueryClientProvider, QueryClient } from 'react-query';
import i18n from './i18nextConf';
import AppContext from './context/App/App.context';
import { ConfigurationPreference, Language, Locale, Sede } from './_api/services/user/types';
import { userServices } from './_api/services/user';
import { history } from './_helpers/history';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { CONFIGURATION_PREFERENCES } from './utils/constants';
import { menuCurrentHeadOffice, menuTransversal, SplitMenuItem } from './components/SplitPane/menuConfiguration';

setupIonicReact();
const queryClient = new QueryClient();

const App: React.FC = () => {
	dayjs.extend(timezone);
	dayjs.extend(utc);

	const [isAuth, setAuth] = useState(false);
	const [thereAreUnsavedChanges, setThereAreUnsavedChanges] = useState(false);
	const [userLanguage, setUserLanguage] = useState<Language>(null);
	const [sedePortal, setSedePortal] = useState<Sede>(null);
	const [sedePortalOptions, setSedePortalOptions] = useState<Sede[]>(null);
	const [transversal, setTransversal] = useState(null);
	const [configurationPreference, setConfigurationPreference] = useState<ConfigurationPreference>(null);
	const [menuOptions, setMenuOptions] = useState<SplitMenuItem[]>([]);

	useEffect(() => {
		setMenuOptions(
			configurationPreference === CONFIGURATION_PREFERENCES.transversal.id
				? menuTransversal
				: configurationPreference === CONFIGURATION_PREFERENCES.sede.id
				? menuCurrentHeadOffice
				: [],
		);
	}, [configurationPreference]);

	const updateSedePortalOptions = (
		options: Sede[],
		defaultSede: Sede,
		transversal: boolean,
		configurationPreference: ConfigurationPreference,
	) => {
		setSedePortalOptions(options);
		setSedePortal(defaultSede);
		setTransversal(transversal);
		updateConfigurationPreference(configurationPreference);
		userServices.updateDefaultPreferences(defaultSede, configurationPreference);
		setThereAreUnsavedChanges(false);
	};

	const updateSedePortal = (defaultSede: Sede, preference: ConfigurationPreference) => {
		userServices.updateDefaultPreferences(defaultSede, preference);
		updateConfigurationPreference(preference);
		setSedePortal(defaultSede);
		setThereAreUnsavedChanges(false);
	};

	const updateUserLanguage = async (language: Language) => {
		setUserLanguage(language);
		await userServices.setUserLanguage(language.code as Locale);
		await i18n.changeLanguage(language.code);
		history.replace({
			pathname: '/login',
			state: { forcedRefresh: true },
		});
	};

	const updateConfigurationPreference = (configuration: ConfigurationPreference) => {
		if (!configuration) {
			return;
		}
		localStorage.setItem('configurationPreference', configuration);
		setConfigurationPreference(configuration);
		userServices.updateDefaultPreferences(sedePortal, configuration);
	};

	const value = {
		isAuth,
		setAuth,
		thereAreUnsavedChanges,
		setThereAreUnsavedChanges,
		userLanguage,
		setUserLanguage,
		sedePortal,
		setSedePortal,
		sedePortalOptions,
		setSedePortalOptions,
		updateSedePortal,
		updateUserLanguage,
		configurationPreference,
		updateConfigurationPreference,
		transversal,
		setTransversal,
		menuOptions,
		updateSedePortalOptions,
	};

	return (
		<IonApp>
			<QueryClientProvider client={queryClient}>
				<AppContext.Provider value={value}>
					<Routes />
				</AppContext.Provider>
			</QueryClientProvider>
		</IonApp>
	);
};

export default App;
