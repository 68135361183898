import { Button, Icon, Modal } from '@acciona/ui-ionic-kit';
import { IonContent, IonFooter, IonTitle, IonToolbar } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

export type Row = any;

type Props = {
	onClose: () => void;
	saveChanges?: Function;
	showModal: boolean;
	selectedRows: Row[] | null;
	onConfirm: () => void;
};

export const ModalDeleteUsers: React.FC<Props> = (props) => {
	const { t } = useTranslation();
	const { onClose, selectedRows, onConfirm } = props;
	const textUsers =
		selectedRows.length === 1 ? (
			<>
				{t('modal_delete_users_permissions_text_individual')}
				<b>{` ${selectedRows[0].employeeId} - ${selectedRows[0].fullName}`}.</b>
			</>
		) : (
			t('modal_delete_users_permissions_text_multiple')
		);

	return (
		<Modal isOpen={props.showModal} onDidDismiss={onClose} className={styles.modal}>
			<IonToolbar className={styles.toolbar}>
				<Button
					slot="end"
					fill={'clear'}
					color="light"
					className={styles.closeModalBtn}
					aria-label={t('lbl_aria_close_modal')}
					onClick={onClose}
				>
					<Icon className="icon icon-close" aria-hidden="true"></Icon>
				</Button>
				<IonTitle className={`${styles.centerTitle} ${styles.headline}`}>
					{t('modal_delete_users_permissions_title')}
				</IonTitle>
			</IonToolbar>
			<IonContent>
				<p className={styles.body}>{textUsers}</p>
				<p className={styles.body}>{t('modal_delete_users_permissions_are_you_sure')}</p>
			</IonContent>
			<IonFooter className={styles.btnModal}>
				<div>
					<Button color="lighter" className={styles.btnHeader} onClick={onClose}>
						{t('no')}
					</Button>
				</div>

				<div>
					<Button color="primary" className={styles.btnHeader} onClick={onConfirm}>
						{t('yes')}
					</Button>
				</div>
			</IonFooter>
		</Modal>
	);
};
