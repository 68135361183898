import { IonSkeletonText } from '@ionic/react';

export const SkeletonTable = ({ pageSize }) => {
	const rows = Array(pageSize).fill('column');
	const cells = Array(8).fill('cell');

	return (
		<>
			{rows.map((_, index) => (
				<tr key={index}>
					{cells.map((_, index) => (
						<td key={index}>
							<IonSkeletonText animated={true}></IonSkeletonText>
						</td>
					))}
				</tr>
			))}
		</>
	);
};
