import { IonContent, IonLoading, IonPage } from '@ionic/react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { authServices } from '../../_api/services/auth';
import useAppContext from '../../hooks/useAppContext';

const Callback: React.FC<any> = () => {
	const query = new URLSearchParams(useLocation().search);
	const { t } = useTranslation();
	const isLoading = true;
	const { setAuth } = useAppContext();

	async function initLogin(code) {
		authServices.getCurrentUserSession(code);
		setAuth(true);
	}

	useEffect(() => {
		if (query.get('code')) {
			const code = query.get('code');
			initLogin(code);
		}
	}, []);
	return (
		<IonPage id="main">
			<IonContent>
				<IonLoading isOpen={isLoading} message={t('msg_loading')} duration={5000} />
			</IonContent>
		</IonPage>
	);
};

export default Callback;
