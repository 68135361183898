import { flexRender } from '@tanstack/react-table';
import styles from './styles.module.scss';

export const TableGrid = ({ headers, rows, isLoading = false }) => (
	<table className={styles.table}>
		<thead>
			<tr>
				{headers.map((header) => {
					return (
						<th key={header.id} colSpan={header.colSpan} style={{ width: header.getSize() }}>
							{header.isPlaceholder ? null : (
								<>
									<div
										className={header.column.getCanSort() ? 'cursor-pointer select-none' : ''}
										onClick={header.column.getToggleSortingHandler()}
									>
										{flexRender(header.column.columnDef.header, header.getContext())}
										{{
											asc: ' ↑',
											desc: ' ↓',
										}[header.column.getIsSorted() as string] ?? null}
									</div>
								</>
							)}
						</th>
					);
				})}
			</tr>
		</thead>
		<tbody>
			{isLoading ? (
				<></>
			) : (
				rows.rows.map((row) => (
					<tr key={row.id}>
						{row.getVisibleCells().map((cell) => {
							return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
						})}
					</tr>
				))
			)}
		</tbody>
	</table>
);
