import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { MultiSelect } from '@acciona/ui-ionic-kit';
import { AppPermissionsActions } from '../../../../context/AppPermissions/AppPermissions.actions';
import { AppPermissionsContext } from '../../../../context/AppPermissions/AppPermissions.context';
import { usePermissions } from '../../../../hooks/usePermissions';
import { FUNCTIONALITIES } from '../../../../utils/constants';
import { AppPermission } from '../../../../_api/services/roles/types';
import styles from './styles.module.scss';

type Props = {
	permission: AppPermission;
	disabled?: boolean;
};

export const ProfilesPermissionsSection: React.FC<Props> = (props) => {
	const { permission, disabled } = props;
	const { state, dispatch } = useContext(AppPermissionsContext);
	const { permissions: userHasPermissions } = usePermissions(FUNCTIONALITIES.admin, 'WRITE');
	const { t } = useTranslation();
	const [profilesData, setProfilesData] = useState<{ value: string; label: string }[]>([]);

	const updateSelectedProfiles = (profileId: string) => {
		dispatch(AppPermissionsActions.toggleSelectedProfile(profileId));
	};

	useEffect(() => {
		if (!_.isEmpty(state.profiles)) {
			setProfilesData(
				_.sortBy(
					state.profiles.map((profile) => ({
						value: profile.profileId,
						label: profile.name,
					})),
					['label'],
				),
			);
		}
	}, [state.profiles]);

	return (
		<>
			{!_.isEmpty(state.profiles) && (
				<>
					<h3 className={styles.blockTitle}>{t(`app_permissions.profile_permission_title`)}</h3>
					<p className={styles.blockSubtitle}>{permission.profile_permission_subtitle}</p>
					<MultiSelect
						className={`${styles.multiSelect} ${state.selectedProfiles.length > 0 && styles.touchedInput}`}
						label={t('lbl_profile')}
						options={profilesData}
						selected={state.selectedProfiles}
						updateSelection={updateSelectedProfiles}
						disabled={disabled || !userHasPermissions.WRITE}
					/>
				</>
			)}
		</>
	);
};
