import { useEffect } from 'react';
import { Icon } from '@acciona/ui-ionic-kit';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

type Props = {
	table: any;
	display: boolean;
};

export const TableFooter: React.FC<Props> = ({ table, display }) => {
	const { t } = useTranslation();
	const handlePreviousPage = () => table.previousPage();
	const handleNextPage = () => table.nextPage();

	const paginationOptions = [5, 10, 50, 80, 100];

	useEffect(() => {
		const callback = (e) => {
			if (e.keyCode === 37 || e.keyCode === 38 || e.keyCode === 33) {
				// ArrowLeft / ArrowUp / PageUp
				handlePreviousPage();
			} else if (e.keyCode === 39 || e.keyCode === 40 || e.keyCode === 34) {
				// ArrowRight / ArrowDown / PageDown
				handleNextPage();
			}
		};

		window.addEventListener('keydown', callback);

		// cleanup this component
		return () => {
			window.removeEventListener('keydown', callback);
		};
	}, []);

	return (
		<div className={styles.footer}>
			{display && (
				<>
					<div className={styles.controls}>
						<button onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()}>
							<Icon className="icon icon-first-page" />
						</button>
						<button onClick={handlePreviousPage} disabled={!table.getCanPreviousPage()}>
							<Icon className="icon icon-page-chevron-left" />
						</button>
						<button onClick={handleNextPage} disabled={!table.getCanNextPage()}>
							<Icon className="icon icon-page-chevron-right" />
						</button>
						<button
							onClick={() => table.setPageIndex(table.getPageCount() - 1)}
							disabled={!table.getCanNextPage()}
						>
							<Icon className="icon icon-last-page" />
						</button>

						<span>
							{t('table_page_x_of_x', {
								page: table.getState().pagination.pageIndex + 1,
								total: table.getPageCount(),
							})}
						</span>
					</div>
					<div className={styles.pagination}>
						<span>
							{t('table_go_to_page')}
							<input
								type="number"
								min="1"
								max={table.getPageCount()}
								defaultValue={table.getState().pagination.pageIndex + 1}
								onChange={(e) => {
									const page = e.target.value ? Number(e.target.value) - 1 : 0;
									table.setPageIndex(page);
								}}
							/>
						</span>
						<span>
							{t('table_display_x_registers')}
							<select
								className={styles.selectPageSize}
								value={table.getState().pagination.pageSize}
								onChange={(e) => {
									table.setPageSize(Number(e.target.value));
								}}
							>
								{paginationOptions.map((pageSize) => (
									<option key={pageSize} value={pageSize}>
										{pageSize}
									</option>
								))}
							</select>
						</span>
					</div>
				</>
			)}
		</div>
	);
};
