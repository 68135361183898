import muleSoftInstance from '../../utils/httpCommon';
import { News, ImageDashboard, TextDashboard, ImagesFormData } from './types';

const getNewsConfigurations = async (): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/v1/communication/newsPanels`;
	try {
		const resp: News = (await muleSoftInstance.get(path)).data;
		return resp;
	} catch (err) {
		throw new Error(err);
	}
};

const saveNewsConfigurations = async (config: News): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/v1/communication/newsPanels`;
	try {
		const resp = await muleSoftInstance.put(path, config);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const getDashboardImages = async (): Promise<ImageDashboard[]> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/v1/configurationHomeImage`;
	try {
		const resp = (await muleSoftInstance.get(path)).data;
		return resp?.images;
	} catch (err) {
		throw new Error(err);
	}
};

const getDashboardTexts = async (): Promise<TextDashboard[]> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/v1/configurationHome`;
	try {
		const resp = (await muleSoftInstance.get(path)).data;
		return resp?.translations;
	} catch (err) {
		throw new Error(err);
	}
};

const saveDashboardTexts = async (texts: TextDashboard[]): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/v1/configurationHome`;
	try {
		const resp = await muleSoftInstance.put(path, { translations: texts });
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const saveDashboardImages = async (formData: ImagesFormData): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/v1/configurationHomeImage`;
	try {
		const resp = await muleSoftInstance.post(path, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};
export const communicationsServices = {
	getNewsConfigurations,
	saveNewsConfigurations,
	getDashboardImages,
	getDashboardTexts,
	saveDashboardTexts,
	saveDashboardImages,
};
