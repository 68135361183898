import { Icon } from '@acciona/ui-ionic-kit';
import { SplitMenuExternalLink } from '../menuConfiguration';
import styles from '../styles.module.scss';

const openNewTab = (link) => {
	window.open(link, '_blank');
};

type Props = {
	item: SplitMenuExternalLink;
};

export const ExternalMenuItem = ({ item }: Props) => {
	return (
		<div className={styles.itemList} key={item.id} onClick={() => openNewTab(item.link)}>
			<div>
				<Icon className={`${item.icon} icon20`} slot="start"></Icon>
				<div className={styles.footnote}>{item.text}</div>
			</div>
		</div>
	);
};
