import { Button, List } from '@acciona/ui-ionic-kit';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { FC, useEffect, useState } from 'react';
import { NumberInput } from '../../../components/NumberInput/NumberInput';
import _ from 'lodash';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import useAppContext from '../../../hooks/useAppContext';
import { IProfileMovingDate } from '../../../_api/services/transversal/types';
import { transversalServices } from '../../../_api/services/transversal';
import { IonLoading } from '@ionic/react';

interface Props {
	footerStyle?: string;
	setSuccess: Function;
	setError: Function;
	hasWritePermission: boolean;
}

export const ProfileMovingDate: FC<Props> = ({ footerStyle, setError, setSuccess, hasWritePermission }) => {
	const { t } = useTranslation();
	const [isEdited, setIsEdited] = useState(false);
	const queryClient = useQueryClient();
	const { setThereAreUnsavedChanges } = useAppContext();
	const [profileConfig, setProfileConfig] = useState<IProfileMovingDate>();

	useEffect(() => {
		setThereAreUnsavedChanges(isEdited);
	}, [isEdited]);

	useEffect(() => {
		setIsEdited(!_.isEqual(profileConfig, initialValues));
	}, [profileConfig]);

	const { data: initialValues, isLoading: isLoading } = useQuery(
		['movingDate_configuration'],
		async () => await transversalServices.getMovingDateConfiguration(),
		{
			refetchOnWindowFocus: false,
			onError: (error) => setError(error as string),
			onSuccess: (data) => setProfileConfig(data),
		},
	);

	const { mutate: handleSave, isLoading: loadingSave } = useMutation(
		transversalServices.updateMovingDateConfiguration,
		{
			onSuccess: () => {
				setSuccess(t('msg_success'));
				setIsEdited(false);
				queryClient.refetchQueries('movingDate_configuration');
			},
			onError: (error: Error) => {
				setError(error.message);
			},
		},
	);

	const handleInputChange = (e) => {
		const fieldName = e.target.name;
		const value = +e.target.value;
		setProfileConfig((prevValues) => {
			const nextValues = _.cloneDeep(prevValues);
			return {
				...nextValues,
				[fieldName]: value,
			};
		});
	};

	const handleSaveConfig = () => {
		handleSave(profileConfig);
	};

	return (
		<>
			{isLoading ? (
				<IonLoading isOpen={isLoading} message={t('msg_loading')} />
			) : (
				profileConfig && (
					<List>
						<div className={styles.h3}>{t('lbl_profile_moving_date')}</div>
						<div className={styles.element}>
							<div className={`${styles.footnote} ${styles.space}`}>
								{t('lbl_profle_moving_date_daysBeforeRemoval')}
							</div>
							<NumberInput
								name="daysBeforeRemoval"
								className={`ion-text-end`}
								value={profileConfig.daysBeforeRemoval}
								onIonChange={handleInputChange}
								min={0}
								max={1000}
								disabled={!hasWritePermission}
								aria-label={t('lbl_number_of_days')}
								label={t('lbl_number_of_days')}
								required
								allowDecimals={false}
							></NumberInput>
						</div>
					</List>
				)
			)}

			{hasWritePermission && (
				<div className={`${footerStyle} ${styles.footerButton}`}>
					<Button
						disabled={!isEdited || loadingSave}
						color="primary"
						className={styles.btnHeader}
						onClick={handleSaveConfig}
					>
						{t('btn_save_data')}
					</Button>
				</div>
			)}
		</>
	);
};
