import { useEffect, useRef, useState } from 'react';
import { IonContent, IonLabel } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { SegmentButton, Segmented, Toast } from '@acciona/ui-ionic-kit';
import useAppContext from '../../hooks/useAppContext';
import { useScrollBar } from '../../hooks/useScrollBar';
import { HeaderPage } from '../../components/HeaderPage/HeaderPage';
import { CalendarConfiguration } from './CalendarConfiguration/CalendarConfiguration';
import styles from './styles.module.scss';
import { usePermissions } from '../../hooks/usePermissions';
import { FUNCTIONALITIES } from '../../utils/constants';
import { Seeker } from './Seeker/Seeker';
import { ProfileMovingDate } from './ProfileMovingDate/ProfileMovingDate';

const Transversal = () => {
	const { t } = useTranslation();
	const [segmentedSelected, setSegmentedSelected] = useState('seeker');
	const refContainer = useRef(null);
	const refHeader = useRef(null);
	const hasScroll = useScrollBar(refContainer, refHeader, segmentedSelected);
	let footerStyle = null;
	const [scroll, setScroll] = useState(false);
	const [success, setSuccess] = useState('');
	const [error, setError] = useState('');
	const { permissions, error: errorPermissions } = usePermissions(FUNCTIONALITIES.transversal, 'WRITE');
	const { setThereAreUnsavedChanges } = useAppContext();

	footerStyle = hasScroll ? styles.hasScroll : styles.noScroll;

	useEffect(() => {
		return () => {
			setThereAreUnsavedChanges(false);
		};
	}, []);

	useEffect(() => {
		setError(errorPermissions);
	}, [errorPermissions]);

	return (
		<IonContent scrollEvents forceOverscroll={true} onIonScroll={(x) => setScroll(x.detail.scrollTop > 0)}>
			<HeaderPage title={t('lbl_transversal')} scroll={scroll}>
				<Segmented value={segmentedSelected}>
					<SegmentButton onClick={() => setSegmentedSelected('seeker')} value={'seeker'} mode={'md'}>
						<IonLabel>{t('lbl_seeker')}</IonLabel>
					</SegmentButton>
					<SegmentButton
						onClick={() => setSegmentedSelected('calendarConfig')}
						value={'calendarConfig'}
						mode={'md'}
					>
						<IonLabel>{t('lbl_calendarConfig')}</IonLabel>
					</SegmentButton>
					<SegmentButton onClick={() => setSegmentedSelected('moving')} value={'moving'} mode={'md'}>
						<IonLabel>{t('lbl_profile_moving_date')}</IonLabel>
					</SegmentButton>
				</Segmented>
			</HeaderPage>
			<div className={`container ${styles.terms}`} ref={refContainer}>
				<div className={segmentedSelected === 'seeker' ? styles.display : styles.hide}>
					<Seeker
						footerStyle={footerStyle}
						setSuccess={setSuccess}
						setError={setError}
						hasWritePermission={permissions.WRITE}
					/>
				</div>
				<div className={segmentedSelected === 'calendarConfig' ? styles.display : styles.hide}>
					<CalendarConfiguration
						footerStyle={footerStyle}
						setSuccess={setSuccess}
						setError={setError}
						hasWritePermission={permissions.WRITE}
					/>
				</div>
				<div className={segmentedSelected === 'moving' ? styles.display : styles.hide}>
					<ProfileMovingDate
						footerStyle={footerStyle}
						setSuccess={setSuccess}
						setError={setError}
						hasWritePermission={permissions.WRITE}
					/>
				</div>
			</div>
			<Toast isOpen={!!error} message={error} onDidDismiss={() => setError('')} position="bottom" type="error" />

			<Toast
				isOpen={!!success}
				message={success}
				onDidDismiss={() => setSuccess('')}
				position="bottom"
				type="success"
			/>
		</IonContent>
	);
};

export default Transversal;
