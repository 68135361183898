import { Button } from '@acciona/ui-ionic-kit';
import { IonLoading } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import _ from 'lodash';
import { incidentServices } from '../../../_api/services/incidents';
import useAppContext from '../../../hooks/useAppContext';
import { isValidPhone } from '../../../utils/functions';
import styles from './styles.module.scss';
import { TextInput } from '../../../components/TextInput/TextInput';

export type Props = {
	hasWritePermission: boolean;
	setError: (msg: string) => void;
	setSuccess: (msg: string) => void;
	footerStyle: string;
};

export const Contact: React.FC<Props> = (props) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const { setThereAreUnsavedChanges } = useAppContext();
	const [phone, setPhoneValue] = useState('');
	const [errorPhone, setErrorPhone] = useState(false);
	const [isEdited, setIsEdited] = useState(false);

	useEffect(() => {
		setThereAreUnsavedChanges(isEdited);
	}, [isEdited]);

	const { data, isLoading: isLoading } = useQuery<string>(
		['phone_emergency'],
		async () => await incidentServices.getPhoneEmergency(),
		{
			refetchOnWindowFocus: false,
			onError: (error) => props.setError(error as string),
			onSuccess: (data) => setPhoneValue(data),
		},
	);

	const { mutate: handleSave, isLoading: loadingSave } = useMutation(incidentServices.savePhoneEmergency, {
		onSuccess: () => {
			props.setSuccess(t('msg_success'));
			queryClient.refetchQueries();
			setIsEdited(false);
		},
		onError: (error) => {
			props.setError(error as string);
		},
	});

	const handlePhoneChange = (phone) => {
		setErrorPhone(!isValidPhone(phone));
		setPhoneValue(phone);
	};

	useEffect(() => {
		setIsEdited(!_.isEqual(phone, data));
	}, [phone]);

	return (
		<>
			<div className={styles.h3}>{t('emergency_call')}</div>
			<div className={`${styles.footnote} ${styles.space}`}>{t('label_phone_emergency')}</div>
			<TextInput
				name="emergency_phone"
				className={`ion-text-end`}
				value={phone}
				defaultValue={data}
				onIonChange={(e) => handlePhoneChange(e.detail.value)}
				placeholder={t('write_text')}
				isValid={!errorPhone}
				maxlength={30}
				disabled={!props.hasWritePermission}
				aria-label={t('phone')}
				label={t('phone')}
			/>
			{props.hasWritePermission ? (
				<div className={`${props.footerStyle} ${styles.footerButton}`}>
					<Button
						disabled={!(isEdited && phone !== '')}
						color="primary"
						className={styles.btnHeader}
						onClick={() => (errorPhone ? props.setError(t('msg_error_phone')) : handleSave(phone))}
					>
						{t('btn_save_data')}
					</Button>
				</div>
			) : null}
			<IonLoading isOpen={isLoading || loadingSave} message={t('msg_loading')} duration={1500} />
		</>
	);
};
