import _ from 'lodash';
import { RestaurantData, Restaurant } from '../../../../_api/services/generalServices/types';
import { LANGUAGES } from '../../../../utils/constants';

const getNewId = (restaurants) => (_.isEmpty(restaurants) ? 1 : _.maxBy(restaurants, (o) => o.id).id + 1);

/**
 * @param {Restaurant} restaurant
 * @returns {boolean} true if restaurant has empty fields or false if it hasn't
 */
export const checkEmptyFields = (restaurant: Restaurant) => {
	if (_.isEmpty(restaurant?.translations)) {
		return true;
	}

	const acceptedLanguagesTranslations = restaurant.translations.filter((t) => LANGUAGES.includes(t.language));
	const translationsToCheck = fillEmptyTranslations(acceptedLanguagesTranslations);

	const hasEmptyFields = (translation) => {
		const { name, details } = translation;
		const nameIsEmpty = !name || _.isEmpty(name) || name === '';
		const descriptionIsEmpty = !details || _.isEmpty(details) || details === '' || details === '<p><br></p>';
		return nameIsEmpty || descriptionIsEmpty;
	};

	if (restaurant.new) {
		return translationsToCheck.every((translation) => hasEmptyFields(translation));
	} else if (restaurant.enabled) {
		return translationsToCheck.some((translation) => hasEmptyFields(translation));
	} else if (restaurant.edited && !restaurant.enabled) {
		// In case of deleted restaurant, empty fields are irrelevant
		return false;
	}

	return true;
};

/**
 * @param {Restaurant[]} restaurants
 * @returns {boolean} true if none restaurant has empty fields or false if almost one restaurant has empty fields
 */
export const validateData = (restaurants: Restaurant[]): boolean => {
	return restaurants.every((restaurant) => !checkEmptyFields(restaurant));
};

export const fillEmptyTranslations = (translations: RestaurantData[]) => {
	if (_.isEmpty(translations)) {
		return [];
	}
	return translations.map((tr) => ({
		...tr,
		name: _.isEmpty(tr.name) ? translations.find((l) => !_.isEmpty(l.name))?.name : tr.name,
		details: _.isEmpty(tr.details) ? translations.find((l) => !_.isEmpty(l.details))?.details : tr.details,
	}));
};

export const getRestaurantsDataToSave = (restaurants: Restaurant[]) => {
	if (_.isEmpty(restaurants)) {
		return [];
	}

	return restaurants
		.filter((restaurant) => restaurant.edited || restaurant.new)
		.map((r) => ({
			id: r.id,
			enabled: r.enabled,
			translations: fillEmptyTranslations(r?.translations),
		}));
};

export const getNewRestaurantData = (restaurants: Restaurant[]) => {
	return {
		id: getNewId(restaurants),
		enabled: true,
		edited: false,
		new: true,
		translations: LANGUAGES.map((lang) => {
			return { language: lang, name: '', details: '' };
		}),
	};
};

export const getRestaurantsDataAfterEdit = (
	restaurants: Restaurant[],
	id: number,
	item: string,
	newValue: string,
	selectedLang: string,
) => {
	return restaurants.map((restaurant) =>
		restaurant.id === id
			? {
					...restaurant,
					edited: true,
					translations: restaurant.translations.map((t) => ({
						...t,
						[item]: t.language === selectedLang ? newValue : t[item],
					})),
			  }
			: restaurant,
	);
};

export const getRestaurantsDataAfterDelete = (
	restaurants: Restaurant[],
	initialData: Restaurant[],
	idRestaurantToDelete: number,
) => {
	const restaurantToDeleteIsInInitialData = idRestaurantToDelete <= _.maxBy(initialData, (o) => o.id).id;

	if (restaurantToDeleteIsInInitialData) {
		return restaurants?.map((restaurant) =>
			restaurant.id === idRestaurantToDelete ? { ...restaurant, enabled: false, edited: true } : restaurant,
		);
	} else {
		return restaurants?.filter((s) => s.id !== idRestaurantToDelete);
	}
};

export const getDefaultName = (initialData: Restaurant[], item: Restaurant, selectedLang: string) => {
	const defaultData = initialData.find((e) => e.id === item.id);
	return defaultData?.translations ? defaultData?.translations.find((e) => e.language === selectedLang).name : '';
};
