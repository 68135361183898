import { useEffect, useState } from 'react';
import { Button, Icon, Modal, SegmentButton, Segmented } from '@acciona/ui-ionic-kit';
import { IonContent, IonFooter, IonLabel, IonSpinner, IonTitle, IonToolbar } from '@ionic/react';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { parkingService } from '../../../../../_api/services/parking';
import {
	ModifiedVehicleReservationsData,
	ParkingReservationsAndRequests,
} from '../../../../../_api/services/parking/types';
import { ModalEditReservationProps as Props } from '../../types';
import { TabContent } from '../../../../../components/TabContent/TabContent';
import { EditReservationItemsList } from '../EditReservationItemsList/EditReservationItemsList';
import { getModifiedObject, getUpdatedVehicleReservations } from './helpers';
import styles from './styles.module.scss';
import _ from 'lodash';

type EmployeeData = {
	employeeId: number;
	fullName: string;
};

export const ModalEditReservation: React.FC<Props> = (props) => {
	const { t } = useTranslation();
	const { selectedRows: originalData, onClose, showModal, hasWritePermission, setFeedback } = props;
	const employeeData = (originalData[0] as EmployeeData) ?? { employeeId: null, fullName: '' };
	const { employeeId, fullName } = employeeData;
	const [reservationsData, setReservationsData] = useState<ParkingReservationsAndRequests>();
	const [segmentedSelected, setSegmentedSelected] = useState('reservations');
	const [isEdited, setIsEdited] = useState(false);

	const { data: initialData, isLoading } = useQuery<any>(
		['get_parking_reservations'],
		async () => await parkingService.getUserParkingReservations(employeeId),
		{
			refetchOnWindowFocus: false,
			onSuccess: (data) => {
				setReservationsData(data);
			},
			onError: (error) => setFeedback('error', error as string),
		},
	);

	const { mutate: updateParkingReservations } = useMutation(parkingService.updateParkingReservations, {
		onSuccess: () => {
			setFeedback('success', t('reservation_updated_success'));
		},
		onError: (error) => {
			setFeedback('error', error as string);
		},
	});

	const onChangeHandler = (reservationId: number, vehicleId: number) => {
		setReservationsData((prev) => ({
			reservations: getUpdatedVehicleReservations(prev?.reservations, reservationId, vehicleId),
			requests: getUpdatedVehicleReservations(prev?.requests, reservationId, vehicleId),
		}));
	};

	const onSaveHandler = () => {
		const getModifiedReservations = (
			initialData: ParkingReservationsAndRequests,
			currentData: ParkingReservationsAndRequests,
		): ModifiedVehicleReservationsData => {
			const reservationsModified = getModifiedObject(currentData.reservations, initialData.reservations);
			const requestsModified = getModifiedObject(currentData.requests, initialData.requests);
			return {
				reservations: reservationsModified,
				requests: requestsModified,
			};
		};

		const modifiedReservations = getModifiedReservations(initialData, reservationsData);
		updateParkingReservations(modifiedReservations);
		onClose();
	};

	const onCancelHandler = () => {
		onClose();
	};

	useEffect(() => {
		setIsEdited(!_.isEqual(reservationsData, initialData));
	}, [reservationsData]);

	return (
		<Modal isOpen={showModal} onDidDismiss={onCancelHandler} className={styles.modal}>
			<IonToolbar className={styles.toolbar}>
				<Button
					slot="end"
					fill={'clear'}
					color="light"
					className={styles.closeModalBtn}
					aria-label={t('close_text')}
					onClick={onCancelHandler}
				>
					<Icon className="icon icon-close" aria-hidden="true" />
				</Button>
				<IonTitle className={`${styles.centerTitle} ${styles.headline}`}>
					{`${fullName} - ${t('modify_reservation')}`}
				</IonTitle>
			</IonToolbar>
			<IonContent>
				{isLoading ? (
					<div className={`ion-text-center ${styles.spinnerContainer}`}>
						<IonSpinner />
					</div>
				) : !isLoading && reservationsData?.requests.length < 1 && reservationsData?.reservations.length < 1 ? (
					<p>{t('user_does_not_have_reservations_or_requests')}</p>
				) : (
					<>
						<div className={`${styles.tabsMenu} ${styles.noMarginLeft}`}>
							<Segmented value={segmentedSelected}>
								<SegmentButton
									onClick={() => setSegmentedSelected('reservations')}
									value={'reservations'}
									mode={'md'}
								>
									<IonLabel>{t('reservations')}</IonLabel>
								</SegmentButton>
								<SegmentButton onClick={() => setSegmentedSelected('requests')} value={'requests'} mode={'md'}>
									<IonLabel>{t('requests')}</IonLabel>
								</SegmentButton>
							</Segmented>
						</div>
						<div className={styles.tabsLineSeparator} />
						<div className={styles.contentContainer}>
							<TabContent id="requests" selectedId={segmentedSelected}>
								<EditReservationItemsList
									reservations={reservationsData?.requests}
									reservationType="request"
									onChange={onChangeHandler}
								/>
							</TabContent>
							<TabContent id="reservations" selectedId={segmentedSelected}>
								<EditReservationItemsList
									reservations={reservationsData?.reservations}
									reservationType="reservation"
									onChange={onChangeHandler}
								/>
							</TabContent>
						</div>
					</>
				)}
			</IonContent>
			<IonFooter className={styles.btnModal}>
				<div>
					<Button color="lighter" className={styles.btnHeader} onClick={onCancelHandler}>
						{t('btn_cancel')}
					</Button>
				</div>

				{hasWritePermission && (
					<div>
						<Button color="primary" className={styles.btnHeader} disabled={!isEdited} onClick={onSaveHandler}>
							{t('btn_save')}
						</Button>
					</div>
				)}
			</IonFooter>
		</Modal>
	);
};
