import { useCallback } from 'react';
import { IonCheckbox } from '@ionic/react';
import { Item } from '@acciona/ui-ionic-kit';
import { List } from 'react-virtualized';
import { Employee } from '../../../../_api/services/roles/types';
import styles from './styles.module.scss';

type Props = {
	usersList: Employee[];
	checkUser: (detailChecked: boolean, id: number) => void;
};

export const VirtualizedList: React.FC<Props> = (props) => {
	const { usersList, checkUser } = props;

	const listHeight = 248;
	const rowHeight = 44;
	const rowWidth = 752;

	const renderRow = useCallback(
		({ index, style }) => {
			const user = usersList[index];

			return (
				<Item key={user.employeeId} mode="ios" lines="none" type="button" style={style}>
					<IonCheckbox
						color="primary"
						checked={user.isChecked}
						onClick={() => {
							if (!user.disabled) {
								checkUser(!user.isChecked, user.employeeId);
							}
						}}
						disabled={user.disabled}
						justify="start"
						labelPlacement="end"
						aria-label={user.employeeId.toString()}
					>
						{`${user.employeeId} - ${user.fullName} ${user?.profile ? ` (${user.profile})` : ''}`}
					</IonCheckbox>
				</Item>
			);
		},
		[usersList, checkUser],
	);

	return (
		<>
			<List
				width={rowWidth}
				height={listHeight}
				rowHeight={rowHeight}
				rowRenderer={renderRow}
				rowCount={usersList.length}
				overscanRowCount={3}
				className={styles.virtualizedList}
			/>
		</>
	);
};
