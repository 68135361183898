import { RefObject } from 'react';
import {
	ImageDashboard,
	ImagesDashboardIndexed,
	TextDashboard,
	TextsDashboardIndexed,
} from '../../../_api/services/Communications/types';
import _ from 'lodash';

export const transformTextsFromResponse = (dashboardTexts: TextDashboard[]): TextsDashboardIndexed => {
	const indexedTexts = {};

	dashboardTexts.forEach((text) => {
		indexedTexts[text.language] = {
			desktop: text?.welcomeWeb ?? '',
			mobile: text?.welcomePhone ?? '',
		};
	});

	return indexedTexts;
};

export const transformTextsForRequest = (texts: TextsDashboardIndexed): TextDashboard[] => {
	const updatedTexts = [];

	Object.keys(texts).forEach((language) => {
		updatedTexts.push({
			language: language,
			welcomeWeb: texts[language]?.desktop ?? '',
			welcomePhone: texts[language]?.mobile ?? '',
		});
	});

	return updatedTexts;
};

export const transformImagesFromResponse = (dashboardImages: ImageDashboard[]): ImagesDashboardIndexed => {
	const indexedImages = {};

	dashboardImages.forEach((image) => {
		if (!Object.keys(indexedImages).includes(image.language)) {
			indexedImages[image.language] = {};
		}
		indexedImages[image.language][image.platform] = image.imageName;
	});

	return indexedImages;
};

export const setInitialFormImages = async (dashboardImages: ImageDashboard[], formData: RefObject<any>) => {
	if (_.isEmpty(dashboardImages)) {
		return;
	}

	for (let i = 0; i < dashboardImages.length; i++) {
		const { imageUrl, imageName, language, platform } = dashboardImages[i];

		try {
			const base64Response = await fetch(imageUrl, { mode: 'no-cors' });
			const file = new File([await base64Response.blob()], imageName);
			const key = `${language}_${platform}`;
			formData.current.set(key, file);
		} catch (error) {
			console.error(error);
		}
	}
};
