import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@acciona/ui-ionic-kit';
import styles from './styles.module.scss';

type Props = {
	title?: string;
	subtitle?: string;
	fileName: string;
	disabled: boolean;
	onChange: (e: ChangeEvent<HTMLInputElement>) => void;
	inputOptions?: Object;
	className?: string;
	accept?: string;
};

export const FileInput: React.FC<Props> = ({
	title,
	subtitle,
	fileName,
	disabled,
	onChange,
	inputOptions = {},
	className,
	accept = '*',
}) => {
	const { t } = useTranslation();

	return (
		<div className={`${styles.containerInputs} ${className ? className : ''}`}>
			{title && subtitle && (
				<div>
					<div className={styles.h3}>{title}</div>
					<div className={`${styles.footnoteInput}`}>{subtitle}</div>
				</div>
			)}

			<label className={`${styles.input}`}>
				<span className={`${styles.body} ${styles.color_dark}`}>
					{!fileName ? (
						<span>
							<u>{t('lbl_select_one')}</u> {t('or_add_file')}
						</span>
					) : (
						fileName
					)}
				</span>
				<input
					type="file"
					accept={accept}
					multiple={false}
					onClick={(e) => (e.currentTarget.value = '')}
					onChange={onChange}
					disabled={disabled}
					{...inputOptions}
				/>
				{!fileName ? (
					<Icon className={`icon icon-arrow_down icon24 ${styles.iconRotate} ${styles.icon}`} />
				) : (
					<Icon className={`icon icon-close icon24  ${styles.icon}`} />
				)}
			</label>
		</div>
	);
};
