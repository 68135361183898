import { HTMLProps, useEffect, useRef } from 'react';
import styles from './styles.module.scss';

export const IndeterminateCheckbox = ({
	indeterminate,
	disabled,
	...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) => {
	const ref = useRef<HTMLInputElement>(null!);

	useEffect(() => {
		if (typeof indeterminate === 'boolean') {
			ref.current.indeterminate = !rest.checked && indeterminate;
		}
	}, [ref, indeterminate]);

	return (
		<label className={styles.container}>
			<input type="checkbox" ref={ref} {...rest} disabled={disabled} />
			<span className={`${styles.checkmark} ${disabled ? styles.disabled : ''}`}></span>
		</label>
	);
};
