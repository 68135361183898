import { useCallback, useEffect, useRef, useState } from 'react';
import { IonContent, IonLabel } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { SegmentButton, Segmented, Toast } from '@acciona/ui-ionic-kit';
import { authServices } from '../../_api/services/auth';
import useAppContext from '../../hooks/useAppContext';
import { useScrollBar } from '../../hooks/useScrollBar';
import { HeaderPage } from '../../components/HeaderPage/HeaderPage';
import { Time } from './Time/Time';
import { Cleaner } from './Cleaner/Cleaner';
import { Permissions } from './Permissions/Permissions';
import { ImagesForm } from './Images/Images';
import { Other } from './Other/Other';
import styles from './styles.module.scss';

const Rooms: React.FC = () => {
	const { t } = useTranslation();
	const { setThereAreUnsavedChanges } = useAppContext();
	const [segmentedSelected, setSegmentedSelected] = useState('images');
	const [error, setError] = useState('');
	const [success, setSuccess] = useState('');
	const [hasWritePermission, setHasWritePermission] = useState(false);
	const mountedRef = useRef(true);
	const [scroll, setScroll] = useState(false);
	let footerStyle = null;
	const refContainer = useRef(null);
	const refHeader = useRef(null);
	const permissions = useCallback(async () => {
		try {
			const resultQuery = await authServices.checkPermission('ROOM', 'WRITE');
			setHasWritePermission(resultQuery.hasPermission);
		} catch (err) {
			setError(err);
			throw new Error(err);
		}
	}, [mountedRef]);

	useEffect(() => {
		permissions();
		return () => {
			mountedRef.current = false;
		};
	}, [permissions]);

	useEffect(() => {
		return () => {
			setThereAreUnsavedChanges(false);
		};
	}, []);

	const hasScroll = useScrollBar(refContainer, refHeader, segmentedSelected);
	footerStyle = hasScroll ? styles.hasScroll : styles.noScroll;
	return (
		<IonContent scrollEvents forceOverscroll={true} onIonScroll={(x) => setScroll(x.detail.scrollTop > 0)}>
			<HeaderPage scroll={scroll} title={t('menu_rooms')}>
				<div ref={refHeader}>
					<Segmented value={segmentedSelected}>
						<SegmentButton onClick={() => setSegmentedSelected('images')} value={'images'} mode={'md'}>
							<IonLabel>{t('tab_images')}</IonLabel>
						</SegmentButton>
						<SegmentButton onClick={() => setSegmentedSelected('time')} value={'time'} mode={'md'}>
							<IonLabel>{t('tab_time')}</IonLabel>
						</SegmentButton>
						<SegmentButton onClick={() => setSegmentedSelected('permissions')} value={'permissions'} mode={'md'}>
							<IonLabel>{t('tab_permissions')}</IonLabel>
						</SegmentButton>
						<SegmentButton onClick={() => setSegmentedSelected('cleaner')} value={'cleaner'} mode={'md'}>
							<IonLabel>{t('tab_cleaner')}</IonLabel>
						</SegmentButton>
						<SegmentButton onClick={() => setSegmentedSelected('other')} value={'other'} mode={'md'}>
							<IonLabel>{t('tab_other')}</IonLabel>
						</SegmentButton>
					</Segmented>
				</div>
			</HeaderPage>
			<div className={`container ${styles.roomsContainer}`} ref={refContainer}>
				<div className={segmentedSelected === 'images' ? styles.display : styles.hide}>
					<ImagesForm setError={setError} setSuccess={setSuccess} hasWritePermission={hasWritePermission} />
				</div>
				<div className={segmentedSelected === 'time' ? styles.display : styles.hide}>
					<Time
						setError={setError}
						setSuccess={setSuccess}
						hasWritePermission={hasWritePermission}
						footerStyle={footerStyle}
					/>
				</div>
				<div className={segmentedSelected === 'permissions' ? styles.display : styles.hide}>
					<Permissions
						setError={setError}
						setSuccess={setSuccess}
						hasWritePermission={hasWritePermission}
						footerStyle={footerStyle}
					/>
				</div>
				<div className={segmentedSelected === 'cleaner' ? styles.display : styles.hide}>
					<Cleaner setError={setError} setSuccess={setSuccess} hasWritePermission={hasWritePermission} />
				</div>
				<div className={segmentedSelected === 'other' ? styles.display : styles.hide}>
					<Other />
				</div>
			</div>
			<Toast isOpen={!!error} message={error} onDidDismiss={() => setError('')} position="bottom" type="error" />
			<Toast
				isOpen={!!success}
				message={success}
				onDidDismiss={() => setSuccess('')}
				position="bottom"
				type="success"
			/>
		</IonContent>
	);
};

export default Rooms;
