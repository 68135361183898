import { Policy, PolicyTranslation, PolicyUpdate } from './types';
import _ from 'lodash';

export const transformPolicyTranslation = (policy: Policy, translation: PolicyTranslation): PolicyUpdate => {
	return {
		...(translation?.isNew ? {} : { id: policy.id.toString() }),
		ordinal: policy?.ordinal ? policy.ordinal.toString() : '',
		categoryId: policy.categoryId.toString(),
		disabled: policy.disabled.toString(),
		language: translation.language,
		name: translation.name,
		...(translation?.fileName ? { fileName: translation.fileName } : {}),
		...(translation?.link ? { link: translation.link } : {}),
		...(translation?.file ? { file: translation.file } : {}),
	};
};

export const createFormData = (policy: PolicyUpdate) => {
	const formData = new FormData(document.forms[0]);
	Object.entries(policy).forEach(([key, value]) => {
		formData.set(key, value);
	});

	return formData;
};

export const getFormDataArray = (policies: Policy[]): FormData[] => {
	const formDataArray: FormData[] = [];

	const appendPolicy = (policy: Policy, translation: PolicyTranslation) => {
		const data: PolicyUpdate = transformPolicyTranslation(policy, translation);
		const formDataItem: FormData = createFormData(data);
		formDataArray.push(formDataItem);
	};

	policies.forEach((policy) => {
		if (_.isEmpty(policy?.translations)) {
			return;
		} else if (policy.disabled) {
			// When you want to delete a policy, only one request should be sent (not one for each translation).
			appendPolicy(policy, policy.translations[0]);
		} else if (policy.translations.some((translation) => translation?.isNew)) {
			appendPolicy(
				policy,
				policy.translations.find((translation) => translation?.isNew),
			);
		} else if (policy?.isEditedOrdinal && !policy.translations.some((translation) => translation?.isEdited)) {
			// When you only want to edit the ordinal of a policy, only one request should be sent (not one for each translation).
			appendPolicy(policy, policy.translations[0]);
		} else {
			policy.translations.forEach((translation) => {
				if (translation.isEdited) {
					appendPolicy(policy, translation);
				}
			});
		}
	});

	return formDataArray;
};
