import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import useAppContext from '../../hooks/useAppContext';

interface PrivateRouteProps extends RouteProps {
	children?: any;
}
const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, ...rest }) => {
	const { isAuth } = useAppContext();

	return <Route {...rest}>{isAuth ? children : <Redirect to="/login" />}</Route>;
};
export default PrivateRoute;
