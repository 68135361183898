import { Button, Icon, Modal, Toast } from '@acciona/ui-ionic-kit';
import {
	IonButton,
	IonContent,
	IonFooter,
	IonInput,
	IonItem,
	IonLabel,
	IonList,
	IonPopover,
	IonText,
	IonTextarea,
	IonTitle,
	IonToolbar,
} from '@ionic/react';
import { Props } from './types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import styles from '../../styles.module.scss';
import { availableLanguages } from '../../../../../i18nextConf';
import { NumberInput } from '../../../../../components/NumberInput/NumberInput';
import { TextInput } from '../../../../../components/TextInput/TextInput';

const SubCategoriesModal: React.FC<Props> = (props) => {
	const { t } = useTranslation();
	const [changes, setChanges] = useState<any>({});
	const [errors, setErrors] = useState<any>({});
	const [msgError, setMsgError] = useState('');
	const [changesData, setChangesData] = useState<any>({});

	const actions = [
		{
			text: 'Llamada',
			id: 1,
			input: (
				<NumberInput
					name="llamada"
					onIonChange={(e) => changeEditItem('recipient', e.detail.value)}
					value={changes?.recipient}
					className={`ion-text-end ${errors.phonenumber && styles.isInvalid} ${styles.removeInputArrows}`}
					isValid={!errors.phonenumber}
					placeholder={t('contact_number')}
					label={t('phone')}
					aria-label={t('phone')}
					labelPlacement="start"
					disabled={!props.hasWritePermission}
					wrapperSlot="start"
				/>
			),
			check: 'phonenumber',
		},
		{
			text: 'Correo',
			id: 2,
			input: (
				<TextInput
					name="email"
					wrapperSlot="start"
					className={`ion-text-end ${errors.email && styles.isInvalid}`}
					isValid={!errors.mail}
					onIonChange={(e) => changeEditItem('recipient', e.detail.value)}
					value={changes?.recipient}
					placeholder="Email"
					label="Email"
					aria-label="Email"
					disabled={!props.hasWritePermission}
				/>
			),
			check: 'email',
			placeholder: 'Correo electrónico',
		},
		{
			text: 'ServiceNow TIC',
			id: 3,
			input: (
				<div className={`${styles.inputDouble} ${errors.email && styles.isInvalid}`}>
					<div className={styles.inputModal}>
						<IonItem lines="none" disabled={!props.hasWritePermission} slot="start" className="inputItem ">
							<IonLabel>{t('category')}</IonLabel>
							<IonInput
								className="ion-text-end"
								onIonChange={(e) => changeEditItem('ucategory', e.detail.value)}
								value={changes?.ucategory}
								placeholder={t('id_category')}
								aria-label={t('category')}
								label={t('category')}
								labelPlacement="start"
							/>
						</IonItem>
					</div>
					<div className={styles.inputModal}>
						<IonItem lines="none" disabled={!props.hasWritePermission} slot="start" className="inputItem ">
							<IonLabel>{t('subcategory')}</IonLabel>
							<IonInput
								className="ion-text-end"
								onIonChange={(e) => changeEditItem('usubcategory', e.detail.value)}
								value={changes?.usubcategory}
								placeholder={t('id_subcategory')}
								aria-label={t('subcategory')}
								label={t('subcategory')}
								labelPlacement="start"
							/>
						</IonItem>
					</div>
				</div>
			),
			placeholder: 'Categoría ServiceNow TIC',
			check: 'ServiceNow TIC',
		},
		{
			text: 'ServiceNow Servicios',
			id: 5,
			input: (
				<div className={`${styles.inputDouble} ${errors.email && styles.isInvalid}`}>
					<div className={styles.inputModal}>
						<IonItem lines="none" disabled={!props.hasWritePermission} slot="start" className="inputItem ">
							<IonLabel>{t('category')}</IonLabel>
							<IonInput
								className="ion-text-end"
								onIonChange={(e) => changeEditItem('ucategory', e.detail.value)}
								value={changes?.ucategory}
								placeholder={t('id_category')}
								label={t('category')}
								aria-label={t('category')}
								labelPlacement="start"
							/>
						</IonItem>
					</div>
					<div className={styles.inputModal}>
						<IonItem lines="none" disabled={!props.hasWritePermission} slot="start" className="inputItem ">
							<IonLabel>{t('subcategory')}</IonLabel>
							<IonInput
								className="ion-text-end"
								onIonChange={(e) => changeEditItem('usubcategory', e.detail.value)}
								value={changes?.usubcategory}
								placeholder={t('id_subcategory')}
								label={t('subcategory')}
								aria-label={t('subcategory')}
								labelPlacement="start"
							/>
						</IonItem>
					</div>
				</div>
			),
			check: 'ServiceNow Servicios',
			placeholder: 'Id categoría ServiceNow Servicios',
		},
	];

	const isValidEmail = (email: string) => /\S+@\S+\.\S+/.test(email);

	const validateForm = (formValues: any) => {
		let formErrors = {
			phonenumber: false,
			email: false,
			ServiceNowServicios: false,
			name: false,
			nameEn: false,
			description: false,
			descriptionEn: false,
			ServiceNowTIC: false,
		};

		let errorMessage = '';
		let isValid = false;

		if (_.isEmpty(formValues.recipient) && formValues.actionType === 1)
			formErrors = { ...formErrors, phonenumber: true };
		if ((_.isEmpty(formValues.ucategory) || _.isEmpty(formValues.usubcategory)) && formValues.actionType === 3)
			formErrors = { ...formErrors, ServiceNowTIC: true };
		if ((_.isEmpty(formValues.ucategory) || _.isEmpty(formValues.usubcategory)) && formValues.actionType === 5)
			formErrors = { ...formErrors, ServiceNowServicios: true };

		if (!formValues?.translations) {
			formErrors = { ...formErrors, name: true, description: true };
		} else {
			if (_.isEmpty(formValues.translations.find((e) => e.language == props.lang).name))
				formErrors = { ...formErrors, name: true };
			if (_.isEmpty(formValues.translations.find((e) => e.language == props.lang).description))
				formErrors = { ...formErrors, description: true };
		}

		const thereAreErrors = !_.isEmpty(_.keys(_.pickBy(formErrors)));

		if (thereAreErrors) {
			errorMessage = 'Debe rellenar todos los campos';
		} else if (formValues.actionType === 2 && !isValidEmail(formValues.recipient)) {
			formErrors = { ...formErrors, email: true };
			errorMessage = 'Debe introducir un email válido';
		} else {
			isValid = true;
		}

		return { isValid, formErrors, errorMessage };
	};

	const handleSaveChanges = () => {
		let newChanges;
		if (_.isEqual(changes?.translations, [])) {
			newChanges = {
				...changes,
				translations: availableLanguages.map((language) => {
					return {
						language: language,
						name: changesData.name,
						description: changesData.description,
						exampleDescription: changesData.exampleDescription,
						exampleLocation: changesData.exampleLocation,
					};
				}),
			};
		} else {
			newChanges = {
				...changes,
				translations: changes.translations.map((translation) => {
					if (translation.language === props.lang) {
						return {
							...translation,
							name: _.isEmpty(changesData.name) ? changesData.nameEn : changesData.name,
							description: _.isEmpty(changesData.description)
								? changesData.descriptionEn
								: changesData.description,
							exampleDescription: _.isEmpty(changesData.exampleDescription)
								? changesData.exampleDescriptionEn
								: changesData.exampleDescription,
							exampleLocation: _.isEmpty(changesData.exampleLocation)
								? changesData.exampleLocationEn
								: changesData.exampleLocation,
						};
					}
					return translation;
				}),
			};
		}
		const { isValid, formErrors, errorMessage } = validateForm(newChanges);
		setErrors(formErrors);
		setMsgError(errorMessage);
		setChanges(newChanges);

		if (isValid) {
			props.onClose();
			props.saveChanges({
				...newChanges,
				translations: newChanges.translations,
				name: _.isEmpty(newChanges.name) ? newChanges.nameEn : newChanges.name,
				nameEn: _.isEmpty(newChanges.nameEn) ? newChanges.name : newChanges.nameEn,
				description: _.isEmpty(newChanges.description) ? newChanges.descriptionEn : newChanges.description,
				descriptionEn: _.isEmpty(newChanges.descriptionEn) ? newChanges.description : newChanges.descriptionEn,
				exampleDescription: _.isEmpty(newChanges.exampleDescription)
					? newChanges.exampleDescriptionEn
					: newChanges.exampleDescription,
				exampleDescriptionEn: _.isEmpty(newChanges.exampleDescriptionEn)
					? newChanges.exampleDescription
					: newChanges.exampleDescriptionEn,
				exampleLocation: _.isEmpty(newChanges.exampleLocation)
					? newChanges.exampleLocationEn
					: newChanges.exampleLocation,
				exampleLocationEn: _.isEmpty(newChanges.exampleLocationEn)
					? newChanges.exampleLocation
					: newChanges.exampleLocationEn,
			});
		}
	};

	const changeEditItem = (item, value) => {
		if (item === 'name' || item === 'description' || item === 'exampleDescription' || item === 'exampleLocation') {
			setChangesData({ ...changesData, [item]: value });
		} else {
			setChanges({ ...changes, [item]: value });
		}
		if (_.has(errors, item)) setErrors({ ...errors, [item]: _.isEmpty(value) });
		if (item === 'recipient') setErrors({ ...errors, email: false, phonenumber: _.isEmpty(value) });
		if (item === 'ucategory' && changes.actionType === 3) setErrors({ ...errors, ServiceNowTIC: _.isEmpty(value) });
		if (item === 'usubcategory' && changes.actionType === 3)
			setErrors({ ...errors, ServiceNowTIC: _.isEmpty(value) });
		if (item === 'ucategory' && changes.actionType === 5)
			setErrors({ ...errors, ServiceNowServicios: _.isEmpty(value) });
		if (item === 'usubcategory' && changes.actionType === 5)
			setErrors({ ...errors, ServiceNowServicios: _.isEmpty(value) });
	};
	const setAllChanges = () => {
		setChanges(_.first(props.subcategory));
		setChangesData(_.first(props.subcategory).translations.find((e) => e.language == props.lang));
	};

	useEffect(() => {
		props.subcategory && !_.isEmpty(props.subcategory) && setAllChanges();
		props.subcategory && _.isEmpty(props.subcategory) && setChangesData({});
		props.subcategory &&
			_.isEmpty(props.subcategory) &&
			setChanges({
				name: '',
				description: '',
				actionType: 1,
				recipient: '',
				ordinal: props.ordinal,
				categoryId: props.categoryId,
				exampleDescription: '',
				exampleLocation: '',
				disabled: false,
				ucategory: '',
				usubcategory: '',
				nameEn: '',
				descriptionEn: '',
				exampleDescriptionEn: '',
				exampleLocationEn: '',
				translations: [],
			});

		setErrors({
			phonenumber: false,
			email: false,
			ServiceNowServicios: false,
			name: false,
			nameEn: false,
			description: false,
			descriptionEn: false,
			ServiceNowTIC: false,
		});
	}, [props.subcategory, props.showModal]);
	return (
		<Modal
			isOpen={props.showModal}
			onDidDismiss={() => {
				props.onClose();
			}}
			className={styles.modal}
		>
			<IonToolbar>
				<IonTitle>
					<h3 className="ion-text-center">
						<b>{`${t('edit_subcategories')} (${t('lang_label')}: ${t('language_' + props.lang)})`}</b>
					</h3>
				</IonTitle>
			</IonToolbar>
			{changes && !_.isEmpty(changes) && (
				<IonContent>
					<div>
						<IonItem lines="none" className={styles.categoryListText}>
							<IonText className={styles.footnote}>{t('edit_subcategories_text')}</IonText>
						</IonItem>
						<TextInput
							name="subcategory"
							className="ion-text-end"
							onIonChange={(e) => changeEditItem('name', e.detail.value)}
							value={changesData.name}
							isValid={!(errors.name || errors.nameEn)}
							placeholder={t('name')}
							label={t('subcategory')}
							aria-label={t('subcategory')}
							labelPlacement="start"
							disabled={!props.hasWritePermission}
						/>
						<IonItem lines="none" className={styles.descriptionText}>
							<IonText className={`${styles.footnote} color_dark`}>
								{t('edit_subcategories_description')}
							</IonText>
						</IonItem>
						<IonItem
							lines="full"
							disabled={!props.hasWritePermission}
							className={`${styles.inputModal} ${
								(errors.description || errors.descriptionEn) && styles.isInvalid
							}`}
						>
							<IonTextarea
								onIonChange={(e) => changeEditItem('description', e.detail.value)}
								value={changesData.description}
								placeholder={t('edit_subcategories_description_placeholder')}
								rows={2}
							/>
						</IonItem>
						<div className={styles.body}>
							<div className={styles.inputActions}>
								<IonItem
									lines="none"
									slot="start"
									className={`${styles.inputModal} ${!props.hasWritePermission && styles.listDisabled}`}
								>
									<IonLabel slot="start" className="lblSelector labelSelector">
										{t('actions')}
									</IonLabel>
									<IonLabel slot="end" className="lblSelector ion-text-end">
										{actions.filter((i) => i.id === changes.actionType)[0].text}
									</IonLabel>
									<IonButton
										className="btnSelector"
										disabled={!props.hasWritePermission}
										fill="clear"
										id={`icon-add-popover`}
										slot="end"
									>
										<Icon className="icon icon-chevron-down" />
									</IonButton>
									<IonPopover
										trigger={`icon-add-popover`}
										dismissOnSelect={true}
										side="bottom"
										alignment="end"
										className={styles.popoverLargeSty}
									>
										<IonContent>
											<IonList className={`${styles.listPadding}`}>
												{actions.map((item, index) => {
													return (
														<IonItem
															lines="full"
															key={index}
															button={true}
															onClick={() => changeEditItem('actionType', item.id)}
															detail={false}
														>
															<IonLabel className={styles.headline}>{item.text}</IonLabel>
														</IonItem>
													);
												})}
											</IonList>
										</IonContent>
									</IonPopover>
								</IonItem>
								{actions.filter((i) => i.id === changes.actionType)[0].input}
							</div>
							{changes && changes.actionType !== 1 && (
								<>
									<IonText className={styles.footnote}>{t('edit_subcategories_example_description')}</IonText>

									<IonItem lines="full" disabled={!props.hasWritePermission} className={styles.input}>
										<IonInput
											onIonChange={(e) => changeEditItem('exampleDescription', e.detail.value)}
											value={changesData.exampleDescription}
											placeholder={t('edit_subcategories_example_description_placeholder')}
											aria-label={t('edit_subcategories_example_description')}
										/>
									</IonItem>
									<IonText className={styles.footnote}>{t('edit_subcategories_example_location')}</IonText>
									<IonItem lines="full" disabled={!props.hasWritePermission} className={styles.input}>
										<IonInput
											onIonChange={(e) => changeEditItem('exampleLocation', e.detail.value)}
											value={changesData.exampleLocation}
											placeholder={t('edit_subcategories_example_location_placeholder')}
											aria-label={t('edit_subcategories_example_location')}
										/>
									</IonItem>
								</>
							)}
						</div>
					</div>
				</IonContent>
			)}
			<IonFooter className={styles.btnModal}>
				<div>
					<Button
						color="light"
						className={styles.btnHeader}
						onClick={() => {
							props.onClose();
						}}
					>
						{t('btn_cancel')}
					</Button>
				</div>
				<div>
					<Button
						color="primary"
						disabled={!props.hasWritePermission}
						className={styles.btnHeader}
						onClick={handleSaveChanges}
					>
						{t('btn_accept')}
					</Button>
				</div>
			</IonFooter>
			<Toast
				isOpen={!!msgError}
				onDidDismiss={() => setMsgError('')}
				message={msgError}
				position="bottom"
				type={'error'}
			/>
		</Modal>
	);
};

export default SubCategoriesModal;
