import { ConfigType, MenuItem } from '../../_api/services/generalServices/types';

export const checkIfServiceIsConfigured = (service: MenuItem) => {
	let isConfigured = false;
	service.translations.forEach((t) => {
		const allConfigsInactive = Object.values(ConfigType).every((config) => {
			return !t[config].active;
		});
		if (!allConfigsInactive) {
			isConfigured = true;
		}
	});
	return isConfigured;
};
